import React, { useEffect, useState } from 'react';

import {
  Form,
  Panel,
} from 'rsuite';


import { DropDownField, TextField } from '@components/Customers/AddEditCustomer';
import { CustomFieldsType } from '@api/query/customFieldsApi/types';
type CustomFieldsProps = {
  className?: string;
  fields: CustomFieldsType[];
  setCustomFieldsData;
  customFieldsData;
  action?: string;
}
export const CustomFields: React.FC<CustomFieldsProps> = ({
  className,
  fields,
  setCustomFieldsData,
  customFieldsData,
  action,
}) => {

  const [defaultValues, setDefaultValues] = useState<false | true>(true);
  useEffect(() => {
    if (fields) {
      setDefaultValues(true)
      if (!(action === 'edit')) {
        fields?.map((field) => {
          if (!customFieldsData?.[field?.title]) {
            if (field?.defaultValue) {
              customFieldsData = {
                ...customFieldsData,
                [field?.title]: field?.defaultValue,
              }
              if (defaultValues === true) {
                setCustomFieldsData({
                  ...customFieldsData,
                  [field?.title]: field?.defaultValue,
                });
              }
            }
          }
        })
        setDefaultValues(false)
      } else {
        if (Array.isArray(customFieldsData) && customFieldsData.length > 0) {
          const nuevoObjeto = {};
          customFieldsData?.forEach(item => {
            const newKey = item.title
            nuevoObjeto[newKey] = item.value;
          });
          setCustomFieldsData(nuevoObjeto)
        }
      }
    }
  }, [fields]);

  const handleDynamicFieldChange = (fieldName, value) => {
      setCustomFieldsData({
        ...customFieldsData,
        [fieldName]: value,
      });
    };
    const renderDynamicFields = () => {
      return fields?.map((field, index) => {
        if (!customFieldsData?.[field?.title]) {
          if (field?.defaultValue && (action !== 'edit') && (defaultValues === true)) {
            customFieldsData = {
              ...customFieldsData,
              [field?.title]: field?.defaultValue,
            }
            setCustomFieldsData(customFieldsData)
          }
        }
        switch (field?.fieldType) {
          case 'INPUT':
            return (
              <div key={index} >
                <TextField
                  name={field?.title}
                  label={field?.title}
                  defaultValue={action !== 'edit' ? field?.defaultValue : undefined}
                  value={customFieldsData?.[field?.title]}
                  required={field?.mandatory}
                  onChange={value => handleDynamicFieldChange(field?.title, value)}
                />
              </div>
            );
          case 'LIST':
            return (
              <div key={index} >
                <DropDownField
                  name={field?.title}
                  label={field?.title}
                  data={field?.values.map(({ label, value }) => ({ label, value }))}
                  required={field?.mandatory}
                  defaultValue={action !== 'edit' ? field?.defaultValue : undefined}
                  value={customFieldsData?.[field?.title]}
                  onChange={(selectedValue) => handleDynamicFieldChange(field?.title, selectedValue)}
                />
              </div>
            );
          default:
            return null;
        }
      });
    };

  return (
    <Panel
      className={className}
      header={
        <div>
          <span className="font-medium">Campos dinámicos</span>
        </div>
      }>
      <Form
        fluid
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2 px-8">
            {renderDynamicFields()}
        </div>
      </Form>
    </Panel>
  );
};
