import { useMemo } from 'react';
import { useMediaMatch } from 'rooks';

export type UseViewportInstance = { isNarrow: boolean; isWide: boolean };

const md = '(min-width: 768px)';

const useViewport = () => {
  const isWide = useMediaMatch(md);

  const instance = useMemo(() => ({ isNarrow: !isWide, isWide }), [isWide]);

  return instance;
};

export default useViewport;
