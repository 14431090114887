import queryString from 'query-string';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { PRICE_LIST_EXCLUDED_ID } from '@utils/Constants';
import { ordoxBaseQuery } from './baseQuery';
import { PaginationQuery, PaginationProps } from './types';

type FindPriceListRequest = {
  active?: boolean;
};

type FindPriceListResponse = {
  items: PriceListItem[];
  total: number;
};

type CreatePriceListRequest = {
  active?: boolean;
  description: string;
};

type CreatePriceListResponse = {
  id: string;
  active: boolean;
  companyId: string;
  createdAt: Date;
  description: string;
  updatedAt: Date;
};

type FindProductsInPriceListRequest = PaginationQuery & {
  listPriceId: string;
};

type FindProductsInPriceListResponse = PaginationProps<Product>;

type UpdateProductInPriceListRequest = {
  id: string;
  data: {
    price: number;
    minQuantity: number;
  };
};

type UpdateProductInPriceListResponse = {};

type DeleteProductInPriceListRequest = {
  id: string;
};

type DeleteProductInPriceListResponse = {};

type AddProductInPriceListRequest = {
  product: {
    id: string;
    name: string;
    priceBeforeTax: number;
    sku: string;
  };
  listPrice: {
    id: string;
    name: string;
  };
  price: number;
  minQuantity: number;
  isShippingItem: boolean;
};

type AddProductInPriceListResponse = {};

export type Product = {
  id: string;
  productId: string;
  listPriceId: string;
  price: number;
  minQuantity: number;
  companyId: string;
  product: {
    id: string;
    companyId: string;
    name: string;
    sku?: string;
    active: true;
    cost: number;
    createdAt: string;
    idERP: string;
    inventory: number;
    listingOrder: number;
    priceAfterTax: number;
    priceBeforeTax: number;
    tax: number;
    taxIdERP: string;
    unit: string;
    unitsCount: true;
    updatedAt: string;
  };
};

export type PriceListItem = {
  id: string;
  description: string;
  active: boolean;
  companyId: string;
  origin?: 'shopify'[];
};

const priceListApi = createApi({
  reducerPath: 'priceList-api',
  tagTypes: ['priceList', 'priceList/Products', 'priceList/Customers'],
  baseQuery: ordoxBaseQuery(),
  endpoints: ({ query, mutation }) => ({
    findPriceList: query({
      providesTags: ['priceList'],
      query: (filters: FindPriceListRequest) => ({
        url: `/prices?active=${filters.active}`,
        method: 'GET',
      }),
      transformResponse: (response: FindPriceListResponse) => {
        response.items = response.items
          .slice()
          .reverse()
          .filter(item => item.id !== PRICE_LIST_EXCLUDED_ID);

        return response;
      },
    }),
    createPriceList: mutation({
      invalidatesTags: ['priceList'],
      query: (data: CreatePriceListRequest) => ({
        url: '/prices',
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: CreatePriceListResponse) => response,
    }),
    deleteProductInPriceList: mutation({
      invalidatesTags: ['priceList/Products'],
      query: ({ id }: DeleteProductInPriceListRequest) => ({
        url: `/products/prices/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (response: DeleteProductInPriceListResponse) =>
        response,
    }),
    updateProductInPriceList: mutation({
      invalidatesTags: ['priceList/Products'],
      query: ({ id, data }: UpdateProductInPriceListRequest) => ({
        url: `/products/prices/${id}`,
        method: 'PATCH',
        body: data,
      }),
      transformResponse: (response: UpdateProductInPriceListResponse) =>
        response,
    }),
    addProductInPriceList: mutation({
      invalidatesTags: ['priceList/Products'],
      query: (data: AddProductInPriceListRequest) => ({
        url: '/products/prices',
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: AddProductInPriceListResponse) => response,
    }),
    findProductsInPriceList: query({
      providesTags: ['priceList/Products'],
      query: (filters: FindProductsInPriceListRequest) => {
        const params = queryString.stringify(filters, {
          skipNull: true,
          skipEmptyString: true,
        });

        return {
          url: `products/prices${(params && `?${params}`) || ''}`,
          method: 'GET',
        };
      },
      transformResponse: (
        response: FindProductsInPriceListResponse,
        _meta,
        request,
      ) => ({
        ...response,
        limit: request.limit,
        offset: request.offset,
        total: response.total || response.items.length,
      }),
    }),
    findCustomerInPriceList: query({
      providesTags: ['priceList/Customers'],
      query: (data: FindProductsInPriceListRequest) => ({
        url: '/listPriceCustomer/find',
        method: 'POST',
        body: data,
      }),
      transformResponse: (
        response: FindProductsInPriceListResponse,
        _meta,
        request,
      ) => ({
        ...response,
        limit: request.limit,
        offset: request.offset,
        count: response.total || response.items.length,
      }),
    }),
  }),
});

export const {
  useFindPriceListQuery,
  useCreatePriceListMutation,
  useFindProductsInPriceListQuery,
  useLazyFindProductsInPriceListQuery,
  useAddProductInPriceListMutation,
  useDeleteProductInPriceListMutation,
  useUpdateProductInPriceListMutation,
  util: { invalidateTags: priceListInvalidateTagsAction },
} = priceListApi;

export default priceListApi;
