import dayjs from '@utils/dayjs';
import { firebaseAnalytics } from '../firebase/firebaseConfig';
import { ACTIVE_EVENT_LOGGING } from '../utils/Constants';

export const logOrdoxEvent = (event,data) => {
    const params = getEventParameters(event,data);
    if(ACTIVE_EVENT_LOGGING){
        firebaseAnalytics.logEvent(event,params);
    }
}

const getEventParameters = (event,data) => {

    let eventParams = {};
    switch(event){

        case events.statusChanged:
            eventParams.version = data.main.version;
            eventParams.company = data.main.company;
            eventParams.user = data.main.user;
            eventParams.timeStamp = getLocalTimeStamp();
            eventParams.orderId = data.orderId;
            eventParams.newStatus = data.newStatus;
            return eventParams;

            case events.orderCreated:
                eventParams.version = data.main.version;
                eventParams.company = data.main.company;
                eventParams.user = data.main.user;
                eventParams.timeStamp = getLocalTimeStamp();
                eventParams.orderId = data.orderId;
                return eventParams;

            case events.invoiceCreated:
                eventParams.version = data.main.version;
                eventParams.company = data.main.company;
                eventParams.user = data.main.user;
                eventParams.timeStamp = getLocalTimeStamp();
                eventParams.orderId = data.orderId;
                eventParams.invoiceId = data.invoiceId;
                return eventParams;

            case events.purchaseOrderCreated:
                eventParams.version = data.main.version;
                eventParams.company = data.main.company;
                eventParams.user = data.main.user;
                eventParams.timeStamp = getLocalTimeStamp();
                eventParams.orderId = data.orderId;
                eventParams.purchaseOrderId = data.purchaseOrderId;
                return eventParams;

            case events.customerCreated:
            case events.customerUpdated:
                eventParams.version = data.main.version;
                eventParams.company = data.main.company;
                eventParams.user = data.main.user;
                eventParams.timeStamp = getLocalTimeStamp();
                eventParams.customerId = data.customerId;
                return eventParams;

            case events.dashboardViewed:
            case events.productionListViewed:
            case events.deliveryCalendarViewed:
            case events.orderDetailViewed:
                eventParams.version = data.main.version;
                eventParams.company = data.main.company;
                eventParams.user = data.main.user;
                eventParams.timeStamp = getLocalTimeStamp();
                return eventParams;

            case events.dataExported:
                eventParams.version = data.main.version;
                eventParams.company = data.main.company;
                eventParams.user = data.main.user;
                eventParams.timeStamp = getLocalTimeStamp();
                eventParams.entity = data.entity;
                return eventParams;

            case events.error:
                eventParams.version = data.main.version;
                eventParams.company = data.main.company;
                eventParams.user = data.main.user;
                eventParams.message = data.error.name;
                eventParams.stackTrace = data.error.message;
                eventParams.action = data.action;
                eventParams.data = data.data;
                eventParams.timeStamp = getLocalTimeStamp();
                return eventParams;

            case events.behaviourTrace:
                eventParams.version = data.main.version;
                eventParams.company = data.main.company;
                eventParams.user = data.main.user;
                eventParams.timeStamp = getLocalTimeStamp();
                eventParams.data = data.data;
                eventParams.details = data.details;
                eventParams.orderId = data.orderId;
                return eventParams;

            case events.shippingListExported:
                eventParams.version = data.main.version;
                eventParams.company = data.main.company;
                eventParams.user = data.main.user;
                eventParams.timeStamp = getLocalTimeStamp();
                return eventParams;

            case events.invoiceCreateAttempt:
                eventParams.version = '0.0';
                eventParams.company = 'API attempt';
                eventParams.user = 'API user';
                eventParams.timeStamp = getLocalTimeStamp();
                return eventParams;

        default:
            return null;
    }
}

export const events = {
    statusChanged: 'STATUS_CHANGED',
    orderCreated: 'ORDER_CREATED',
    invoiceCreated: 'INVOICE_CREATED',
    purchaseOrderCreated: 'PURCHASE_ORDER_CREATED',
    customerCreated: 'CUSTOMER_CREATED',
    customerUpdated: 'CUSTOMER_UPDATED',
    dataExported: 'DATA_EXPORTED',
    dashboardViewed: 'DASHBOARD_VIEWED',
    productionListViewed: 'PRODUCTION_LIST_VIEWED',
    deliveryCalendarViewed: 'DELIVERY_CALENDAR_VIEWED',
    orderDetailViewed: 'ORDER_DETAIL_VIEWED',
    behaviourTrace: 'BEHAVIOUR_TRACE',
    productCreated: 'PRODUCT_CREATED',
    shippingListExported: 'SHIPPING_LIST_EXPORTED',
    invoiceCreateAttempt: 'INVOICE CREATE ATTEMPT',
    error: 'ERROR',
}

// const getLocalTimeStamp = () => {
//     var d = new Date();
//     return d.toLocaleString();
// }

const getLocalTimeStamp = () => {
    return new dayjs().format('DD/MM/YYYY HH:mm:ss.SSS');
}
