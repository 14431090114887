/* eslint-disable */
import React from 'react';
import ReactDataGrid from 'react-data-grid';
import copyIcon from '../images/copyIcon.png';
import viewDetails from '../images/details4.png';
import editIcon from '../images/edit5.png';
import invoiceIcon from '../images/invoiceIcon.png';
import updateStatus from '../images/updateStatus.png';
import '../styles/Grid.css';
import * as constants from '../utils/Constants';
const { Data: { Selectors } } = require('react-data-grid-addons');

// export const SelectList = ({items,clearable=true,input, onBlurControl, placeHolder, showRequired,label, 
//     disabled = false, asterisk = false,value,onChange}) =>{
//     return ( 
//   <div className="section">
//     {label?
//       <label>{label} {asterisk ? "*" : ''} </label>
//       :''
//     } 
//     <div className={asterisk ? 'required-error' : ''}>
//       <Select simpleValue
//         value={input.value}
//         placeholder={placeHolder}
//         options={items}
//         onChange={input.GridonChange} 
//         //onBlur={onBlurControl}
//         className={showRequired ? 'required-error' : ''}
//         disabled={disabled}
//         clearable={clearable}
//       />
//     </div>
//   </div>)};

// export const InputBasic = ({idControl,type,placeHolder,showRequired,input,label,required=false,disabled=false,
//   asterisk=false}) =>
// <div className='section'>
//   {label?
//     <label>{label} {asterisk ? "*" : ''} </label>
//     :''
//   } 
//   <div className= {asterisk ? 'required-error form-group box' : 'form-group box'} >
//     <FormControl  id={idControl}
//                   type={type}
//                   required={required}
//                   placeholder={placeHolder}
//                   value={input.value}
//                   onChange={input.onChange}
//                   className={showRequired ? 'required-error' : ''}
//                   disabled={disabled}
//                   >
//     </FormControl>
//   </div>
// </div>

export const TextArea = ({idControl,asterisk=false,placeHolder,onChange,showRequired,input,label,required=false,disabled=false,rows,value}) =>
  <div className="form-group box">
     {label?
      <label>{label} {asterisk ? "*" : ''} </label>
      :''
    } 
    <div className={asterisk? 'required-error' : ''}>
      <textarea  id={idControl}
                    required={required}
                    placeholder={placeHolder}
                    value={value}
                    onChange={onChange}
                    className={showRequired ? 'form-control required-error' : 'form-control'}
                    disabled={disabled}
                    rows={rows}
                    >
      </textarea>
    </div>
  </div>

export const Grid = ({label,columns,rows,onGridRowsUpdated,rowSelection,disabled=true,contextMenu,
  enableCellSelect=true,toolbar, onRowDoubleClick, minHeight}) =>{
  
    function rowGetter(i) {
    return rows[i]
  }

  return (
  <div className="form-group box">
    <label>{label}</label>
    <ReactDataGrid
      enableCellSelect={enableCellSelect}
      columns={columns}
      rowGetter={rowGetter}
      rowsCount={rows.length}
      onGridRowsUpdated={onGridRowsUpdated}
      rowSelection={rowSelection}
      contextMenu={contextMenu}
      disabled={disabled}
      toolbar={toolbar}
      onRowDoubleClick={onRowDoubleClick}
      rowHeight={constants.GRID_ROWS_HEIGHT}
      headerRowHeight = {constants.GRID_HEADER_HEIGHT}
      minHeight={minHeight}
    />
  </div>
  )
}

export const GridFilter = ({label,columns,datoGrid,toolbar,handleFilterChange,
  onClearFilters,getValidFilterValues,contextMenu,minHeight,editAction,copyAction,viewDetailsAction,updateStatusAction,invoiceFromPurchaseOrderAction}) =>{

    function getRows() {
      return Selectors.getRows(datoGrid);
    };

    function rowGetter(rowIdx) {
      let rowss = getRows();
      return rowss[rowIdx];
    };

    function getCellActions(column, row)
    {
      let cellActions = [];

      if(row.StatusId != constants.ENTREGADA && row.StatusId != constants.CANCELADA)
      {
        cellActions = {
          Actions : [
            {
              icon: <div className="column-center no-img-space"><img src={copyIcon} title="Copiar Orden"/></div>,
              width: 20,
              callback: () => {copyAction(row.Id)
              }
            },
            {
              icon: <div className="column-center no-img-space"><img src={editIcon} title="Editar"/></div>,
              width: 20,
              callback: () => {editAction(row.Id)
              }
            },
            {
              icon: <div className="column-center no-img-space"><img src={viewDetails} title="Ver detalles"/></div>,
              width: 20,
              callback: () => {viewDetailsAction(row.Id)
              }
            },
            {
              icon: <div className="column-center no-img-space"><img src={updateStatus} title="Actualizar estado"/></div>,
              width: 20,
              callback: () => {updateStatusAction(row.Id)
              }
            }
          ]
        };
    }

    else if(row.StatusId === constants.ENTREGADA && !row.InvoiceOnShipment && row.InvoiceId === 0 && row.TotalBeforeTax > 0 )
    {
      cellActions = {
        Actions : [
          {
            icon: <div className="column-center no-img-space"><img src={copyIcon} title="Copiar Orden"/></div>,
            width: 20,
            callback: () => {copyAction(row.Id)
            }
          },
          {
            icon: <div className="column-center no-img-space"><img src={viewDetails} title="Ver detalle"/></div>,
            width: 20,
            callback: () => {viewDetailsAction(row.Id)
            }
          },
          {
            icon: <div className="column-center no-img-space"><img src={invoiceIcon} title="Facturar"/> </div>,
            width: 20,
            callback: () => {invoiceFromPurchaseOrderAction(row.Id)
            }
          },  
        ]
      };
    }

    else{
      cellActions = {
        Actions : [
          {
            icon: <div className="column-center no-img-space"><img src={copyIcon} title="Copiar Orden"/></div>,
            width: 20,
            callback: () => {copyAction(row.Id)
            }
          },
          {
            icon: <div className="column-center no-img-space"><img src={viewDetails} title="Ver detalle"/> </div>,
            width: 20,
            callback: () => {viewDetailsAction(row.Id)
            }
          },
        ]
      };
    }

      return cellActions[column.key];
    };
  
  return (
    <div className="form-group box">
      <label>{label}</label>
      <ReactDataGrid
        enableCellSelect={true}
        columns={columns}
        rowGetter={rowGetter}
        rowsCount={getRows().length}
        toolbar={toolbar}
        onAddFilter={handleFilterChange}
        getValidFilterValues={getValidFilterValues}
        onClearFilters={onClearFilters}
        // contextMenu={contextMenu}
        minHeight={minHeight}
        rowKey='Id'
        getCellActions = {getCellActions}
        rowHeight={constants.GRID_ROWS_HEIGHT}
        headerFiltersHeight={constants.GRID_FILTER_HEADER_HEIGHT}
        headerRowHeight = {constants.GRID_HEADER_HEIGHT}
      />
    </div>
  )
}



