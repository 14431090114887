import React, { useDeferredValue, useEffect, useMemo, useRef, useState } from "react";
import { Button, ButtonToolbar, Form, Message, Modal, useToaster } from "rsuite";
import { validateCreateUser } from "../helpers/user";
import { DropDownField, TextField } from "@components/Customers/AddEditCustomer";
import { APP_TYPES, DEFAULT_USER_DATA } from "../constants/user";
import { InputPickerField } from "@components/Forms/InputPickerField";
import { useCreateUserMutation, useUpdateUserMutation } from "@api/query/userApi/userApi";
import { CheckboxField } from "@components/Forms/CheckboxField";
import { NOTIFICATION_DURATION } from "@utils/Constants";
import { FormattedMessage } from "react-intl";
import { useFindCustomerQuery, useLazyFindCustomerByIdQuery } from "@api/query/customerApi/customerApi";


export function ModalCreateEditUser(props) {
  const {
    visible,
    onClose,
    userInfo,
    title,
    isEditing
  } = props;
  const [addUser] = useCreateUserMutation()
  const [editUser] = useUpdateUserMutation()
  const [searchClient, setSearchClient] = useState('')
  const [customersFinal, setCustomersFinal] = useState([])
  const [customersData, setCustomersData] = useState([])
  const formRef = useRef();
  const [formValue, setFormValue] = useState(userInfo)
  const searchDeferred = useDeferredValue(searchClient);
  const model = validateCreateUser()
  const toaster = useToaster();

  const customerQuery = useFindCustomerQuery({
    keyword: searchDeferred,
    active: true,
    limit: 100,
    offset: 0,
  });

  const [getCustomerById, customerData] = useLazyFindCustomerByIdQuery({});

  useEffect(() => {
    if (isEditing) {
      setFormValue(userInfo)
    }
  }, [visible])

  useEffect(() => {
    if (userInfo?.customerId) {
      getCustomerById(userInfo?.customerId)
    }
  }, [userInfo?.customerId])

  useEffect(() => {
    if (customerQuery?.data) {
      const result = customerQuery.data?.items?.map(user => {
        return { label: user.fullName, value: user.id }
      })
      setCustomersData(result)
    }
  }, [searchClient, formValue.customerId, customerQuery])

  useEffect(() => {
    if (customersData && customerData?.data) {
      let flag = false;
      for (let i = 0; i < customersData.length; i++) {
        if (customersData[i].value === customerData?.id) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        const newCustomer = { label: customerData?.data?.fullName, value: customerData?.data?.id }
        customersData.push(newCustomer)
        setCustomersFinal(customersData)
      } else {
        setCustomersFinal(customersData)
      }
    }
    if (customersData && !customerData?.data) {
      setCustomersFinal(customersData)
    }
  }, [customersData, customerData])

  const displayListClients = formValue.appType === 'B2BPORTAL'
  const submit = (type) => {
    if (!formRef.current.check()) {
      return
    }
    const customerId = formValue.appType === 'SAVIA_CORE' ? undefined : formValue?.customerId
    const {fullName, primaryEmail, mobile, appType, listDelimiter, decimalSeparator, active } = formValue
    const userData = {
      fullName, primaryEmail, mobile, appType, listDelimiter, decimalSeparator, active, customerId
    }
    
    if (isEditing === false) {
      addUser(userData)
        .then(resp => {
          const menssageError = resp?.error?.data?.message
          if (resp?.error?.status === 422) {
            toaster.push(
              <Message
                type="error"
                showIcon
                closable
                duration={NOTIFICATION_DURATION}
              >
                {menssageError}
              </Message>
            );
          } else {
            toaster.push(
              <Message
                type="success"
                showIcon
                closable
                duration={NOTIFICATION_DURATION}
              >
                <FormattedMessage id="create-user.success" />
              </Message>
            );
          }
        })
        .catch(_error => {
          toaster.push(
            <Message
              type="error"
              showIcon
              closable
              duration={NOTIFICATION_DURATION}
            >
              <FormattedMessage id="create-user.error" />
            </Message>
          );
        })
    } else {
      debugger
      editUser({ id: formValue.id, data: userData })
      .then(res => {
        toaster.push(
          <Message
            type="success"
            showIcon
            closable
            duration={NOTIFICATION_DURATION}
          >
            <FormattedMessage id="edit-user.success" />
          </Message>
        );
      })

    }

    setFormValue(DEFAULT_USER_DATA)
    if (type === 1) {
      onCloseModal()
    }
  }


  const onCloseModal = () => {
    setFormValue(DEFAULT_USER_DATA)

    onClose()
  }

  return (
    <div>
      <Modal
        className="modal-dialog-container"
        open={visible}
        size="xs"
        keyboard
        onClose={onCloseModal}
      >
        <Modal.Header>
          <Modal.Title style={{ marginBottom: '10px' }}> {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form
              fluid
              ref={formRef}
              onChange={setFormValue}
              formValue={formValue}
              model={model}
            >
              <div className="grid grid-cols-1 gap-x-4 gap-y-2">
                <div>
                  {" "}
                  <TextField name="fullName" label="Nombre *" />{" "}
                  <TextField name="primaryEmail" label="Correo Electrónico *" />{" "}
                  <TextField name="mobile" label="Teléfono *" />{" "}
                  <div className="flex gap-2">
                    <TextField name="listDelimiter" label="Listas *" />{" "}
                    <TextField name="decimalSeparator" label="Decimales *" />{" "}
                  </div>
                  <DropDownField
                    style={{ width: '100%' }}
                    name="appType"
                    label="App *"
                    data={APP_TYPES}
                  />

                  {displayListClients && customersFinal ? <>
                    <InputPickerField
                      onSearch={(text) => setSearchClient(text)}
                      onClean={() => setSearchClient('')}
                      label="Cliente *"
                      data={customersFinal ?? []} className='w-full' name='customerId' />
                      
                  </> : null}

                  <CheckboxField name="active" label="Activo" value={formValue.active} />
                </div>
              </div>
            </Form>
          </div>
          <div style={{ paddingTop: '20px' }}>
            <ButtonToolbar>
              <Button
                appearance="primary"
                onClick={() => submit(1)}
              >
                Guardar
              </Button>
              {isEditing ? null : <Button onClick={() => submit(2)} appearance="primary">Guardar y crear otro</Button>}

              <Button onClick={onCloseModal}>Cancelar</Button>
            </ButtonToolbar>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}