import React, { SVGProps } from "react";

const SaviaIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    version="1.1"
    viewBox="0 0 1972 1969"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-1273,-500)">
      <path
        d="m1327 2415-53.991-54.018v-1753.5l107.51-107.5 419.92 0.5047 0.053 107c0.029 58.85-0.1171 107.56-0.3251 108.25-0.3004 0.993-32.225 1.25-155.27 1.25h-154.89v1535l694.07-0.5028-0.052-52.726-0.051-52.726-426.29-426.22 115.82-115.83 310.5 310.5v-179.14l-426.44-426.41 57.467-57.474c31.607-31.61 57.918-57.474 58.469-57.474 0.5512 0 70.64 69.637 155.75 154.75l154.75 154.75v-90.749c0-71.921-0.2593-90.753-1.25-90.77-0.6875-0.012-96.635-95.425-213.22-212.03l-211.97-212.01 57.464-57.471c31.605-31.609 57.915-57.471 58.466-57.471 0.5512 0 70.64 69.637 155.75 154.75l154.75 154.75v-592.5h953.51l107.49 107.51v1753.3l-108.3 108.2h-440.7v-217h332v-1535l-343.82-0.2528c-189.1-0.1391-344.58-0.1074-345.5 0.07-1.5636 0.3012-1.6787 11.768-1.6787 167.25 0 91.81 0.2249 166.93 0.4997 166.93s60.827-60.328 134.56-134.06c73.733-73.735 134.52-133.91 135.09-133.72 1.2338 0.4112 114.85 113.89 114.85 114.71 0 0.3129-86.627 87.197-192.51 193.08l-192.51 192.51 0.2554 89.488 0.2554 89.488 134.25-134.24c73.839-73.834 134.59-134.24 135-134.24 0.4131 0 26.624 25.876 58.246 57.501l57.495 57.501-385.5 385.5v180l270.22-270.27 57.386 57.38c31.563 31.559 57.387 57.721 57.387 58.137 0 0.4159-86.625 87.38-192.5 193.25l-192.5 192.5v363.5h-956.02l-53.991-54.018z"
        fill="#00e4af"
      />
    </g>
  </svg>
);

export default SaviaIcon;
