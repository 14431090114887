import { UNITS_LIST } from "../utils/Constants";

export const getAllStates = (state) => state.ordox.masterData.statesList;
export const getSelectedStateCities = (state) =>
  state.ordox.masterData.selectedStateCities;
export const getChannels = (state) => state.ordox.masterData.channels;
export const getInvoicingSettings = (state) =>
  state.ordox.masterData.invoicingSettings;
export const getListPrice = (state) => state.ordox.masterData.listPrice;
export const getTaxesList = (state) => state.ordox.masterData.taxes;
export const getFulfillmentTypesList = (state) => state.ordox.fulfillmentTypes;
export const getUnitsList = () => UNITS_LIST;
export const getFoundStates = (state) => state.ordox.masterData.foundStates;
export const getFoundCities = (state) => state.ordox.masterData.foundCities;
export const getFoundCitiesContact = (state) => state.ordox.masterData.foundCitiesContact;
export const getCitiesDisabled = (state) =>
  state.ordox.masterData.citiesDisabled;

export const getPaymentMethods = (state) =>
  state.ordox.paymentMethods.map((paymentMethod) => {
    return {
      value: paymentMethod.id,
      label: paymentMethod.description,
      idERP: paymentMethod.idERP,
    };
  });
