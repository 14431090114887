import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./actions";
import NewVersionFound from "./components/System/NewVersionFound";
import * as selector from "./selectors/NewVersionSelector";
import * as sw from "./serviceWorker";

type Props = Record<any, any>;

class App extends Component<Props> {
  constructor(props) {
    super(props);
    this.props.setNewVersionAvailable(false);
    this.props.setWaitingWorker({});
  }

  onServiceWorkerUpdate = (registration) => {
    this.props.setNewVersionAvailable(true);
    this.props.setWaitingWorker(registration && registration.waiting);
  };

  updateServiceWorker = () => {
    try {
      const waitingWorker = this.props.waitingWorker;
      waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
      this.props.setNewVersionAvailable(false);
      window.location.reload();
      window.location.href = "/logout";
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount = () => {
    try {
      if (process.env.NODE_ENV === "production") {
        sw.register({ onUpdate: this.onServiceWorkerUpdate });
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <div className="App">
        {this.props.newVersionAvailable && (
          <NewVersionFound
            visible={this.props.newVersionAvailable}
            handleOnClick={this.updateServiceWorker}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newVersionAvailable: selector.getNewVersionAvailable(state),
    waitingWorker: selector.getWaitingWorker(state),
  };
};

export default connect(mapStateToProps, actions)(App);
