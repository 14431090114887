import { GetUsersRequest, User } from "@api/query/userApi/types";
import { useLazyGetUsersQuery } from "@api/query/userApi/userApi";
import { DataGrid, DataGridRowContent, DataGridToolbar, GlobalFilterCallback, HighlightText, cellsById } from "@components/DataGrid";
import PageHeader from "@components/PageHeader";
import { useLazyPagination } from "@containers/PriceList/UseLazyPagination";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CellProps, Column } from "react-table";
import { CheckPicker, Checkbox, DateRangePicker, Divider, IconButton, Tooltip, Whisper } from "rsuite";
import PlusIcon from '@rsuite/icons/Plus';
import { Close as CloseIcon, Edit as EditIcon } from '@rsuite/icons';
import dayjs, { dayjsCalendar, dayjsRanges, formats } from "@utils/dayjs";
import { ModalCreateEditUser } from "./components/ModalCreateEditUser";
import { APP_TYPES, DEFAULT_USER_DATA } from "./constants/user";
import { useDispatch, useSelector } from "react-redux";
import { getAuthModalData } from "@selectors/SystemSelector";
import { setOpenAuthModal } from "@actions/index";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { ACTIVE } from "@utils/Constants";

export default function ListUsersContainer() {
	const dispatch = useDispatch();
	const authModalVisible = useSelector(getAuthModalData);
	const [userEdit, setUserEdit] = useState({})
	const [openEditModal, setOpenEditModal] = useState(false);
	const [openCreateModal, setOpenCreateModal] = useState(false);
	const [triggerGetUsers, result] = useLazyGetUsersQuery()


	const trigger = useCallback(
		(value: GetUsersRequest) => {
			return triggerGetUsers({
				...value,
			})
				.then(res => {
					if ((res?.error as FetchBaseQueryError)?.status === 401) {
						dispatch(setOpenAuthModal({ open: true }))
					}
				})
				.catch(err => {
					dispatch(setOpenAuthModal({ open: true }))
				})
		},
		[triggerGetUsers],
	);

	const { virtualArray, setViewRange, setExtraQuery, resetPagination } =
		useLazyPagination({
			trigger,
			result,
			initialLimit: 100,
			initialExtraQuery: { orderBy: 'lastLogin:DESC' }
		});

	const handleEditUser = (user: User) => {
		setUserEdit(user)
		setOpenEditModal(true)
	}
	const columns = useMemo<Column<User>[]>(
		(): Column<User>[] => [
			{
				id: 'fullName',
				Header: <FormattedMessage id="user" />,
				accessor: row => row.fullName,
				width: 200,
				maxWidth: 200,
				minWidth: 200,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
							? undefined
							: `fullName:${kind.toUpperCase()}`,
					}));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;
					setExtraQuery(value => ({
						...value,
						fullName: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'primaryEmail',
				Header: <FormattedMessage id="email" />,
				accessor: row => row.primaryEmail,
				width: 200,
				maxWidth: 200,
				minWidth: 200,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
							? undefined
							: `primaryEmail:${kind.toUpperCase()}`,
					}));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						primaryEmail: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'mobile',
				Header: <FormattedMessage id="mobile" />,
				accessor: row => row.mobile,
				width: 100,
				maxWidth: 150,
				minWidth: 150,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
							? undefined
							: `mobile:${kind.toUpperCase()}`,
					}));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						mobile: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},


			},
			{
				id: 'appType',
				Header: <FormattedMessage id="appSavia" />,
				accessor: row => row.appType,
				width: 200,
				maxWidth: 200,
				minWidth: 200,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
							? undefined
							: `appType:${kind.toUpperCase()}`,
					}));
				},
				Filter({ column }) {
					const { id, filterValue, setFilter } = column;

					return (
						<CheckPicker
							id={id}
							name={id}
							placeholder="-"
							size="xs"
							data={APP_TYPES}
							block
							searchable={false}
							cleanable={false}
							value={filterValue || []}
							onChange={setFilter}
						/>
					);
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						appType: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'active',
				Header: <FormattedMessage id="status" />,
				accessor: row => row.active,
				sticky: true,
				width: 70,
				maxWidth: 100,
				minWidth: 70,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
					}));
				},
				Filter({ column }) {
					const { id, filterValue, setFilter } = column;

					return (
						<CheckPicker
							id={id}
							name={id}
							placeholder="-"
							size="xs"
							data={ACTIVE}
							block
							searchable={false}
							cleanable={false}
							value={filterValue || []}
							onChange={setFilter}
						/>
					);
				},
				Cell: ({ value, state }) => (
					<div>{<Checkbox checked={value} />}</div>
				),
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						active: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'lastLogin',
				Header: <FormattedMessage id="lastLogin" />,
				accessor: data => new Date(data.lastLogin),
				minWidth: 150,
				width: 200,
				maxWidth: 200,
				sortType: 'datetime',
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
							? undefined
							: `lastLogin:${kind.toUpperCase()}`,
					}));
				},
				Cell: ({ value, inline, state }: CellProps<User, Date>) => {
					if (value.toString() === "Invalid Date") {
						return null
					}
					const date = dayjs(value);
					if (inline) {
						return (
							<HighlightText
								text={dayjsCalendar(date)}
								subtext={state.globalFilter}
							/>
						);
					}

					return (
						<>
							<Whisper
								trigger={['click', 'hover', 'active']}
								placement="bottomStart"
								speaker={<Tooltip>{date.format(formats.datetime)}</Tooltip>}>
								<div className="text-sm font-light first-letter:capitalize">
									<HighlightText
										text={dayjsCalendar(date)}
										subtext={state.globalFilter}></HighlightText>
								</div>
							</Whisper>
						</>
					);
				},
				Filter({ column }) {
					const { id, filterValue, setFilter } = column;

					return (
						<DateRangePicker
							id={id}
							name={id}
							size="xs"
							block
							showOneCalendar
							value={filterValue || []}
							onChange={setFilter}
							format="dd/MM/yyyy"
							ranges={dayjsRanges([
								'today',
								'tomorrow',
								'yesterday',
								'last7Days',
								'next7Days',
							])}
						/>
					);
				},
				filter: (rows, _, filterValue) => {
					setExtraQuery(value => ({
						...value,
						fromLastLogin: filterValue?.[0],
						toLastLogin: filterValue?.[1],
					}));

					return rows;
				},
			},
			{
				id: 'actions',
				Header: <FormattedMessage id="action" />,
				// accessor: row => row.id,
				width: 100,
				maxWidth: 100,
				minWidth: 100,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
					}));
				},
				Cell: ({ value, row }) => (
					<IconButton
						className="mx-1 -mt-1"
						size="sm"
						icon={<EditIcon />}
						onClick={() => {
							if (!openEditModal) {
								handleEditUser(row.original)
							}
						}}
					/>

				),
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;
					setExtraQuery(value => ({
						...value,
						actions: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},


		], []
	)


	const rowContent = useCallback<DataGridRowContent<User>>(
		(index, row, { viewport }) => {
			if (viewport.isWide) return;

			const cells = cellsById(row.cells);

			return (
				<div
					className={`flex flex-row justify-between items-stretch py-2 px-4 border-y -mb-px border-gray-200`}>
					<div className="flex flex-col flex-nowrap flex-1">
						<div className="mb-1 flex items-center text-xs text-gray-500">
							<span className="flex flex-row">
								{cells.primaryEmail.render('Cell')}
							</span>
							<span className="middle-dot w-1 h-1 mx-2 bg-current rounded" />
							<span className="flex flex-row">
								{cells.lastLogin.render('Cell', { inline: true })}
							</span>

						</div>

						<span className="mb-0.5 text-lg font-normal text-gray-700">
							{cells.fullName.render('Cell')}
						</span>

						<div className="mb-0.5 text-gray-500">

							<span className="flex flex-row">
								{cells.mobile.render('Cell')}
							</span>
							<span className="flex flex-row">
								{cells.appType.render('Cell')}
							</span>
						</div>
					</div>

					<div className="flex flex-col flex-nowrap items-end">
						<div className="mb-1">
							<span className="text-sm">
								{cells.active.render('Cell')}
							</span>
						</div>
						<span>{cells.actions.render('Cell')}</span>
					</div>
				</div>
			);
		},
		[],
	);

	const topToolbar = useCallback<DataGridToolbar<User>>(
		({ filteredFlatRows, selectedData, rows, columns }) => {
			const statusColumn = columns.find(({ id }) => id === 'status');

			return (
				<>
					<IconButton
						className="ml-auto mx-1 bg-gray-100 hover:shadow-md"
						size="sm"
						icon={<PlusIcon />}
						title="Crear Usuario"
						onClick={() => setOpenCreateModal(true)}
					/>
					<Divider vertical className="rs-divider-vertical" />
				</>
			);
		},
		[],
	);


	const globalFilter = useCallback<GlobalFilterCallback<User>>(
		(rows, columnIds, filterValue: string) => {
			setExtraQuery(value => ({
				...value,
				keyword: filterValue.trim() ? filterValue : undefined,
			}));
			return rows;
		},
		[],
	);



	return (<div className="h-screen flex flex-col pb-1">
		<PageHeader title={<FormattedMessage id="users" />} />
		<DataGrid
			className="flex-1"
			data={virtualArray.ref}
			totalCount={virtualArray.ref.length}
			rangeChanged={setViewRange}
			columns={columns}
			selectable
			rowContent={rowContent}
			topToolbar={topToolbar}
			globalFilter={globalFilter}
			overscan={0}
			increaseViewportBy={500}
			autoResetFilters={false}
			autoResetSelectedRows={false}
			autoResetSortBy={false}
			loading={result.isFetching}
		/>
		{openEditModal && (
			<ModalCreateEditUser
				userInfo={userEdit}
				isEditing={true}
				title="Editar Usuario"
				visible={openEditModal} 
				onClose={() => {
					setOpenEditModal(false)
				}}
			/>
		)}
		{openCreateModal && (
			<ModalCreateEditUser
				userInfo={DEFAULT_USER_DATA}
				isEditing={false}
				title="Crear Usuario"
				visible={openCreateModal} 
				onClose={() => {
					setOpenCreateModal(false)
				}}
			/>
		)}
	</div>)
}
