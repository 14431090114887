import Chart from "chart.js";
import React, { Component } from "react";
import "../../styles/Dashboard.css";
Chart.defaults.global.elements.line.tension = 0;

export default class LineGraph extends Component {
  chartRef = React.createRef();

  componentDidMount() {
    const myChartRef = this.chartRef.current.getContext("2d");

    this.myChart = new Chart(myChartRef, {
      type: "line",
      data: {
        labels: this.props.labels,
        datasets: [
          {
            label: this.props.description,
            data: this.props.data,
            fill: false,
            backgroundColor: ["#7CB1CF"],
            borderColor: ["#7CB1CF"],
            datalabels: {
              anchor: "end",
              align: "end",
              offset: 0,
            },
          },
        ],
      },
      options: {
        title: {
          display: true,
          text: this.props.title,
        },
        legend: {
          display: false,
        },
        responsive: true,

        layout: {
          padding: {
            left: 2,
            right: 2,
            top: 2,
            bottom: 2,
          },
        },

        scales: {
          xAxes: [
            {
              ticks: { display: true },
              gridLines: {
                display: false,
                drawBorder: true,
              },
            },
          ],
          yAxes: [
            {
              ticks: { display: true, precision: 0, min: 0 },
              gridLines: {
                display: true,
                drawBorder: true,
              },
            },
          ],
        },
      },
    });
  }

  componentDidUpdate() {
    this.myChart.data.labels = this.props.labels;
    this.myChart.data.datasets[0].data = this.props.data;
    this.myChart.update();
  }

  render() {
    return (
      //   <div class="chart-container">
      <div>
        <canvas id="myChart" ref={this.chartRef} />
      </div>
    );
  }
}
