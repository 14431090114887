import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export const isFetchBaseQueryError = (
  error: unknown,
): error is FetchBaseQueryError => {
  return Boolean(
    error && Object.hasOwn(error as Record<string, unknown>, 'status'),
  );
};

export const isSerializedError = (error: unknown): error is SerializedError => {
  return Boolean(
    error &&
      ['code', 'message', 'name'].reduce(
        (value, prop) =>
          value || Object.hasOwn(error as Record<string, any>, prop),
        false,
      ),
  );
};
