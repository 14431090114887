import queryString from 'query-string';

import axios from '../libs/axios';

const api = {
  async getDateDeliverySlots(filter) {
    return await axios
      .post('deliveryslot/findDate', filter)

      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })

      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
      });
  },

  createDeliverySlot(deliverySlot) {
    return axios.post('delivery-slots', deliverySlot)
  },

  searchDeliverySlots(filters = {}) {
    const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
    return axios.get(`delivery-slots${params && `?${params}`}`)
  },

  editDeliverySlot(id, deliverySlot) {
    return axios.patch(`delivery-slots/${id}`, deliverySlot)
  },
};
export default api;
