import queryString from 'query-string';

import axios from '../libs/axios';

const api = {
  getCustomers(){
    return axios.get('customers')
  },

  saveCustomer(customer){
    return axios.post('customers', customer)
  },

  editCustomer(id, customer){
    return axios.patch(`customers/${id}`, customer)
  },

  async getCustomerById(id){
    return await axios.get(`customers/${id}`)
  },

  async getCostCenter(){
    return await axios.get('/cost-centers/')
  },
  async getCustomFields(filters = {}) {
    const params = queryString.stringify(filters)
    return await axios.get(`custom-fields${params ? `?${params}` : ''}`)
  },
  async getLocations(){
    return await axios.get('/locations/')
  },
  async getTaxes(type) {
    const params = queryString.stringify(type)
    return await axios.get(`/taxes${params && `?${params}`}`)
  },

  searchCustomers(filters = {}){
    const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
    return axios.get(`customers${params ? `?${params}` : ''}`)
  }

}

export default api;
