import React from "react";
import { useSelector } from "react-redux";
import { Loader } from "rsuite";
import { getShowSpinner } from "../selectors/SystemSelector";

const PageSpinner: React.FC = () => {
  const showSpinner = useSelector(getShowSpinner);

  return (
    <>
      {showSpinner && (
        <Loader
          backdrop
          center
          inverse
          size="md"
          className="fixed inset-0 z-50"
        />
      )}
    </>
  );
};

export default PageSpinner;
