import axios from '../libs/axios';

const api = {
  login(body){
    return axios.post('auth/login', body)
  },
  otp(body){
    return axios.post('auth/otp', body)
  },
}

export default api;