import React from 'react';
import { FormattedMessage } from 'react-intl';

export function CustomFields({ customer }) {
  if (!customer?.customFields) {
    return null;
  }

  const renderProperties = (data) => {
    return (
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="border-b border-gray-200 w-full">
                <th
                  scope="row"
                  className="px-3 py-1 font-medium text-gray-900 w-24 bg-gray-50"
                >
                  <FormattedMessage id={item.title} />
                </th>
                <td className="px-3 py-1 w-1 max-w-min">
                  {item.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return renderProperties(customer.customFields);
}