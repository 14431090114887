import React, { useRef, useState } from 'react'
import { FormattedMessage } from "react-intl";
import { DropDownField, TextField } from "@components/Customers/AddEditCustomer";
import { NOTIFICATION_DURATION, TYPES_CHANNELS } from "@utils/Constants";
import { FormInstance, Message, useToaster } from 'rsuite'; 

import { Button, ButtonToolbar, Form } from 'rsuite';
import { CheckboxField } from '@components/Forms/CheckboxField';
import { validateCreateChannel } from '@utils/Validations/ProductValidation';
import { DEFAULT_CHANNEL_DATA } from '../constants/channel';
import { useUpdateChannelMutation, useCreateChannelMutation } from '@api/query/inventoryApi/inventory';

export function GeneralInformation({ channel, onClose, edit }) {

  const formRef = useRef<FormInstance>(null);
  const [formValue, setFormValue] = useState(edit ? channel : {})
  const [editChannel] = useUpdateChannelMutation()
  const [createChannel] = useCreateChannelMutation()
  const model = validateCreateChannel()
  const toaster = useToaster();

  const handleResponse = (response, successMessage) => {
    if (response.error) {
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={NOTIFICATION_DURATION}
        >
          {response.error?.data?.message}
        </Message>
      );
    } else {
      toaster.push(
        <Message
          type="success"
          showIcon
          closable
          duration={NOTIFICATION_DURATION}
        >
          <FormattedMessage id={successMessage} />
        </Message>
      );
    }
  };
  const submit = () => {
    if (formRef.current && !formRef.current.check()) {
      return;
    }
    if (edit) {
      let data = {}
      
      if (channel?.appName != formValue?.appName) {
        data = {
          apiKey: formValue?.apiKey,
          active: formValue?.active,
          username: formValue?.username,
          appName: formValue?.appName,
          password: formValue?.password,
          url: formValue?.url,
          description: formValue?.description,
          inventoryAvailableMaxNumber: formValue?.inventoryAvailableMaxNumber,
          inventoryAvailablePercentage: formValue?.inventoryAvailablePercentage,
          type: formValue?.type
        }
      } else {
        data = {
          apiKey: formValue?.apiKey,
          active: formValue?.active,
          username: formValue?.username,
          password: formValue?.password,
          url: formValue?.url,
          description: formValue?.description,
          inventoryAvailableMaxNumber: formValue?.inventoryAvailableMaxNumber,
          inventoryAvailablePercentage: formValue?.inventoryAvailablePercentage,
          type: formValue?.type
        }
      }
      editChannel({ id: formValue?.id, data: data })
        .then((response) => handleResponse(response, "edit-channel-success"))
        .catch((error) => {
          toaster.push(
            <Message
              type="error"
              showIcon
              closable
              duration={NOTIFICATION_DURATION}
            >
              <FormattedMessage id="edit-channel-error" defaultMessage="Error al editar el canal" />
            </Message>
          );
        });
      setFormValue(DEFAULT_CHANNEL_DATA)
      onCloseModal()
    } else {
      const data = {
        active: formValue?.active,
        appName: formValue?.appName,
        username: formValue?.username,
        password: formValue?.password,
        description: formValue?.description,
        inventoryAvailableMaxNumber: formValue?.inventoryAvailableMaxNumber,
        inventoryAvailablePercentage: formValue?.inventoryAvailablePercentage,
        type: formValue?.type
      }
      createChannel(data)
        .then((response) => handleResponse(response, "create-channel-success"))
        .catch((error) => {
          toaster.push(
            <Message
              type="error"
              showIcon
              closable
              duration={NOTIFICATION_DURATION}
            >
              <FormattedMessage id="edit-channel-error" defaultMessage="Error al crear el canal" />
            </Message>
          );
        });
      setFormValue(DEFAULT_CHANNEL_DATA)
      onCloseModal()
    }
  }

  const onCloseModal = () => {
    setFormValue(DEFAULT_CHANNEL_DATA)
    onClose()
  }
  return (
    <>
      <div style={{textAlign: 'left'}}>
        <Form
          fluid
          ref={formRef}
          onChange={setFormValue}
          formValue={formValue}
          model={model}
        >
          <div className="grid grid-cols-1 gap-x-4 gap-y-2 m-0 p-4">
            <div>
              {" "}
              <TextField name="description" label="Descripción *" />{" "}
              <DropDownField
                style={{ width: '100%', position: 'none' }}
                name="type"
                label="Tipo *"
                data={TYPES_CHANNELS}
              />
              {edit && (
                <TextField name="url" label="Url *" />
              )}
              <TextField name="username" label="Nombre Usuario *" />{" "}
              <TextField name="password" label="Contraseña *" />{" "}
              <TextField name="appName" label="Nombre Aplicación *" />{" "}
              {edit && (
                <TextField name="apiKey" label="Key *" />
              )}
              <TextField name="inventoryAvailableMaxNumber" label="Inventario Disponible *" defaultValue={'0'} />{" "}
              <TextField name="inventoryAvailablePercentage" label="Porcentaje Disponible *" defaultValue={'0'} />{" "}
              
              
              
              <CheckboxField
                name="active"
                label="Activo"
                value={formValue.active} 
                tooltip={false}
                tooltipText=""
              />
            </div>
          </div>
        </Form>
      </div>
      <div style={{ paddingTop: '20px', textAlign: 'left' }}>
        <ButtonToolbar>
          <Button
            appearance="primary"
            onClick={() => submit()}
          >
            Guardar
          </Button>

          <Button onClick={onCloseModal}>Cancelar</Button>
        </ButtonToolbar>
      </div>
    </>
  );
}