import {
  PriceListItem,
  Product,
  useDeleteProductInPriceListMutation,
  useLazyFindProductsInPriceListQuery,
  useUpdateProductInPriceListMutation,
} from '@api/query/priceListApi';
import { PaginationQuery } from '@api/query/types';
import {
  DataGrid,
  DataGridContext,
  DataGridRowContent,
  DataGridToolbar,
} from '@components/DataGrid';
import FormattedCurrency from '@components/ui/FormattedCurrency';
import FormattedPercent from '@components/ui/FormattedPercent';
import InputCurrency from '@components/ui/InputCurrency';
import InputQuantity from '@components/ui/InputQuantity';
import InputPercent from '@components/ui/InputPercent';
import useLocationState from '@router/useLocationState';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiCheckCircle, BiEdit, BiTrash } from 'react-icons/bi';
import { FormattedMessage } from 'react-intl';
import { Column, Row } from 'react-table';
import { useDebouncedValue } from 'rooks';
import { Button, Checkbox, IconButton } from 'rsuite';
import ListItem from 'rsuite/esm/List/ListItem';
import { useLazyPagination } from './UseLazyPagination';
import { rollbarErrors } from '@utils/rollbarErrors';

type ProductsListViewProps = {
  setProductsPrice: React.Dispatch<React.SetStateAction<Product[]>>;
};
export const ProductsListView: React.FC<ProductsListViewProps> = ({ setProductsPrice }) => {
  const priceList = useLocationState<PriceListItem>()!;

  const { companyId } = priceList;

  const [triggerFind_, result] = useLazyFindProductsInPriceListQuery();
  const [selectedId, setSelectedId] = useState('');

  const triggerFind = useCallback(
    (pagination: PaginationQuery) =>
      triggerFind_({
        ...pagination,
        listPriceId: priceList.id,
      }),
    [triggerFind_, companyId, priceList],
  );

  const [triggerDelete, deleteResult] = useDeleteProductInPriceListMutation();

  useEffect(() => {
    if (deleteResult?.isError) {
      rollbarErrors(deleteResult?.error, { id: selectedId }, 'DELETE /prices', companyId, null)
    }
  }, [deleteResult?.isError]);

  const deleteProductsSelected = useCallback(
    async (rows: Row<Product>[]) => {
      const it = await Promise.allSettled(
        rows.map(row => {
          setSelectedId(row.original.id)
          return triggerDelete({
            id: row.original.id,
          }).unwrap();
        }),
      );

      console.log(
        "Can't delete:",
        it.filter(r => r.status === 'rejected'),
      );
    },
    [companyId],
  );

  const { setViewRange, virtualArray } = useLazyPagination<Product>({
    trigger: triggerFind,
    result,
  });

  useEffect(() => {
    if (result?.data?.items) {
      setProductsPrice(result?.data?.items)
    }
  }, [result?.data]);

  const [selectable, setSelectable] = useState(false);

  const columns = useMemo<Column<Product>[]>(
    () => [
      {
        id: 'product',
        width: '100%',
        Header: () => <FormattedMessage id="product" />,
      },
    ],
    [],
  );

  const rowContent = useCallback<DataGridRowContent<Product>>(
    (index, row, context) =>
      context && <PriceListProductRow row={row} context={context} />,
    [],
  );

  const topToolbar: DataGridToolbar<Product> = useCallback(
    ({ selectable, selectedFlatRows, toggleAllRowsSelected }) => {
      return (
        <div className="flex items-center gap-4">
          {selectable && (
            <IconButton
              appearance="primary"
              icon={<BiTrash />}
              circle
              color="red"
              disabled={selectedFlatRows.length === 0}
              loading={deleteResult.isLoading}
              onClick={async () => {
                await deleteProductsSelected(selectedFlatRows);
                setSelectable(false);
              }}
            />
          )}
          <Button
            appearance="link"
            onClick={() => {
              toggleAllRowsSelected(false);
              setSelectable(v => !v);
            }}
            className="flex items-center gap-1">
            <FormattedMessage id={selectable ? 'ready' : 'select'} />
            <BiCheckCircle className="inline text-lg" />
          </Button>
        </div>
      );
    },
    [deleteResult.isLoading],
  );

  const isEmpty = result.isSuccess && virtualArray.ref.length === 0;
  return (
    <>
      {isEmpty && (
        <div className="p-5 h-full flex items-center font-bold text-xl text-center">
          <FormattedMessage id="price-list.products.empty" />
        </div>
      )}

      {!isEmpty && (
        <DataGrid
          className="h-full"
          data={virtualArray.ref}
          totalCount={virtualArray.ref.length}
          columns={columns}
          rowContent={rowContent}
          selectable={selectable}
          disableFilters
          atTopThreshold={25}
          atBottomThreshold={25}
          disableGlobalFilter
          rangeChanged={setViewRange}
          overscan={20}
          topToolbar={topToolbar}
          defaultViewport="narrow"
        />
      )}
    </>
  );
};

type PriceListProductRowProps = {
  row: Row<Product>;
  context: DataGridContext<Product>;
};

export const PriceListProductRow = ({
  row,
  context,
}: PriceListProductRowProps) => {
  const [mutate, mutateResult] = useUpdateProductInPriceListMutation();
  const product = row.original;

  useEffect(() => {
    if (mutateResult?.isError) {
      rollbarErrors(mutateResult?.error, { id: product.id, data: { price: priceDebounced }}, 'PATCH/products/prices', null, null)
    }
  }, [mutateResult?.isError]);
  const { priceBeforeTax, sku } = product.product;
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);

  const [priceDebounced] = useDebouncedValue(price, 1000);

  const discount = useMemo(() => {
    return ((priceBeforeTax - price) * 100) / priceBeforeTax;
  }, [price, priceBeforeTax]);

  const setDiscount = useCallback(
    (percent: number) => {
      const nextPrice = priceBeforeTax - priceBeforeTax * (percent / 100);

      setPrice(price => {
        if (Math.abs(nextPrice - price) <= 0.1) return price;
        else return nextPrice;
      });
    },
    [setPrice],
  );

  const [inputActive, setInputActive] = useState<'price' | 'discount' | 'quantity' | ''>('');

  useEffect(() => {
    if (product) {
      setPrice(product.price)
      setQuantity(product?.minQuantity || 0)
    }
  }, [product, product.price, product?.minQuantity]);
  
  useEffect(() => {
    if (inputActive !== '') {
      mutate({
        id: product.id,
        data: {
          price: priceDebounced,
          minQuantity: quantity,
        },
      });
    }
  }, [priceDebounced, product.id]);

  const handleEnter = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        mutate({
          id: product.id,
          data: {
            price,
            minQuantity: quantity
          },
        });
        setInputActive('');
      }
    },
    [mutate, price, quantity, product.id],
  );

  const handleBlur = useCallback(() => {
    mutate({
      id: product.id,
      data: {
        price,
        minQuantity: quantity,
      },
    });
    setInputActive('');
  }, [mutate, price, quantity, product.id]);

  return (
    <ListItem className="grid grid-cols-2 gap-x-4 px-4 py-2 text-base">
      <label className="col-span-2 font-semibold">
        {context.selectable && (
          <Checkbox
            className="-my-4 -ml-3"
            checked={row.isSelected}
            onChange={(_, checked) => row.toggleRowSelected(checked)}
          />
        )}
        {product.product.name}
      </label>
      {sku && <span className="col-span-2 text-sm">{sku}</span>}
      <label className="h-14" >
        <div className="flex flex-nowrap gap-1 items-center text-black font-bold">
          <FormattedCurrency value={priceBeforeTax} />
        </div>
      </label>
      <label
        className="h-14 cursor-pointer"
        onClick={() => setInputActive('discount')}>
        <span className="text-[14px] h-[10px] flex items-center">
          <FormattedMessage id="discount" />
        </span>
        {inputActive === 'discount' && !context.selectable ? (
          <InputPercent
            signed={true}
            min={Number.MIN_SAFE_INTEGER}
            max={100}
            scale={1}
            defaultValue={discount}
            onValueChange={setDiscount}
            size="sm"
            onBlur={handleBlur}
            onKeyDown={handleEnter}
          />
        ) : (
          <div className="flex flex-nowrap gap-1 items-center text-black font-bold">
            {!context.selectable && <BiEdit />}
            <span>
              {discount.toFixed(2) + '%'}
            </span>
          </div>
        )}
      </label>

      <label
        className="h-14 cursor-pointer"
        onClick={() => setInputActive('price')}>
        <span className="text-[14px] h-[10px] flex items-center">
          <FormattedMessage id="new-price" />
        </span>
        {inputActive === 'price' && !context.selectable ? (
          <InputCurrency
            value={price}
            size="sm"
            onValueChange={setPrice}
            onBlur={handleBlur}
            onKeyDown={handleEnter}
          />
        ) : (
          <div className="flex flex-nowrap gap-1 items-center text-black font-bold">
            {!context.selectable && <BiEdit />}
            <FormattedCurrency value={price} />
          </div>
        )}
      </label>
      <label
        className="h-14 cursor-pointer"
        onClick={() => setInputActive('quantity')}>
        <span className="text-[14px] h-[10px] flex items-center">
          <FormattedMessage id="min-quantity" />
        </span>
        {inputActive === 'quantity' && !context.selectable ? (
          <InputQuantity
            value={quantity}
            size="sm"
            onValueChange={setQuantity}
            onBlur={handleBlur}
            onKeyDown={handleEnter}
          />
        ) : (
          <div className="flex flex-nowrap gap-1 items-center text-black font-bold">
            {!context.selectable && <BiEdit />}
            {quantity}
          </div>
        )}
      </label>
    </ListItem>
  );
};
