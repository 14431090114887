import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import types from '../actions/ActionTypes';
import api from '../api/StatusApi';

export function* getStatus(action) {
  const filters = { orderBy: 'listingOrder:ASC' };
  try {
    const response = yield call(api.getStatus, filters);

    yield put(actions.updateStateStatus(response.data.items));
  } catch (err) {
    if (err?.response?.status === 400) {
      console.log(err.response.data.message);
    } else if (err?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }
  }

  yield put(actions.setShowSpinner(false));
}

export default function* () {
  yield all([takeLatest(types.GET_STATUS, getStatus)]);
}
