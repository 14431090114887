import { setOpenAuthModal } from "@actions/index";
import { useLazyGetDeliverySlotsQuery } from "@api/query/deliverySlot/deliverySlot";
import { DeliverySlot, FindDeliverySlotRequest } from "@api/query/deliverySlot/types";
import { DataGrid, DataGridRowContent, DataGridToolbar, HighlightText, cellsById } from "@components/DataGrid";
import PageHeader from "@components/PageHeader";
import { useLazyPagination } from "@containers/PriceList/UseLazyPagination";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import dayjs, { dayjsCalendar, dayjsRanges, formats } from "@utils/dayjs";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { CellProps, Column } from "react-table";
import { DateRangePicker, Divider, IconButton, Tooltip, Whisper } from "rsuite";
import { Close as CloseIcon, Edit as EditIcon } from '@rsuite/icons';
import ProgressComponent from "@components/Progress/Progress";
import { UpdateDeliverySlot } from "./components/UpdateDeliverySlot";
import { mapperDeliveryData } from "./helpers/deliverySlot";
import PlusIcon from '@rsuite/icons/Plus';
import { CreateDeliverySlot } from "./components/CreateDeliverySlot";



export default function ListDeliverySlotsContainer() {
  const [deliverySlotEdit, setDeliverySlotEdit] = useState({})
  const dispatch = useDispatch();
  const [triggerGetDeliverySlots, result] = useLazyGetDeliverySlotsQuery()
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const trigger = useCallback(
    (value: FindDeliverySlotRequest) => {
      return triggerGetDeliverySlots({
        ...value,
      })
        .then(res => {
          if ((res?.error as FetchBaseQueryError)?.status === 401) {
            dispatch(setOpenAuthModal({ open: true }))
          }
        })
        .catch(err => {
          dispatch(setOpenAuthModal({ open: true }))
        })
    },
    [triggerGetDeliverySlots],
  );

  const { virtualArray, setViewRange, setExtraQuery, resetPagination } =
    useLazyPagination({
      trigger,
      result,
      initialLimit: 100,
      initialExtraQuery: { orderBy: 'startDateTime:DESC' }
    });

  const handleEditDeliverySlot = (deliverySlot: DeliverySlot) => {
    setDeliverySlotEdit(deliverySlot)
    setOpenEditModal(true)
  }


  const columns = useMemo<Column<DeliverySlot>[]>(
    (): Column<DeliverySlot>[] => [

      {
        id: 'startDateTime',
        Header: <FormattedMessage id="home" />,
        accessor: data => new Date(data.startDateTime),
        minWidth: 200,
        width: 200,
        maxWidth: 200,
        sortType: 'datetime',
        onToggleSortBy(kind, columnId) {
          setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `startDateTime:${kind.toUpperCase()}`,
          }));
        },
        Cell: ({ value, inline, state }: CellProps<DeliverySlot, Date>) => {
          if (value.toString() === "Invalid Date") {
            return null
          }
          const date = dayjs(value);
          if (inline) {
            return (
              <HighlightText
                text={dayjsCalendar(date)}
                subtext={state.globalFilter}
              />
            );
          }

          return (
            <>
              <Whisper
                trigger={['click', 'hover', 'active']}
                placement="bottomStart"
                speaker={<Tooltip>{date.format(formats.datetime)}</Tooltip>}>
                <div className="text-sm font-light first-letter:capitalize">
                  <HighlightText
                    text={dayjsCalendar(date)}
                    subtext={state.globalFilter}></HighlightText>
                </div>
              </Whisper>
            </>
          );
        },
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <DateRangePicker
              id={id}
              name={id}
              size="xs"
              block
              showOneCalendar
              value={filterValue || []}
              onChange={setFilter}
              format="dd/MM/yyyy"
              ranges={dayjsRanges([
                'today',
                'tomorrow',
                'yesterday',
                'last7Days',
                'next7Days',
              ])}
            />
          );
        },
        filter: (rows, _, filterValue) => {
          setExtraQuery(value => ({
            ...value,
            fromStartDateTime: filterValue?.[0],
            toStartDateTime: filterValue?.[1],
          }));

          return rows;
        },
      },
      {
        id: 'endDateTime',
        Header: <FormattedMessage id="end" />,
        accessor: data => new Date(data.endDateTime),
        minWidth: 200,
        width: 200,
        maxWidth: 200,
        sortType: 'datetime',
        onToggleSortBy(kind, columnId) {
          setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `endDateTime:${kind.toUpperCase()}`,
          }));
        },
        Cell: ({ value, inline, state }: CellProps<DeliverySlot, Date>) => {
          if (value.toString() === "Invalid Date") {
            return null
          }
          const date = dayjs(value);
          if (inline) {
            return (
              <HighlightText
                text={dayjsCalendar(date)}
                subtext={state.globalFilter}
              />
            );
          }

          return (
            <>
              <Whisper
                trigger={['click', 'hover', 'active']}
                placement="bottomStart"
                speaker={<Tooltip>{date.format(formats.datetime)}</Tooltip>}>
                <div className="text-sm font-light first-letter:capitalize">
                  <HighlightText
                    text={dayjsCalendar(date)}
                    subtext={state.globalFilter}></HighlightText>
                </div>
              </Whisper>
            </>
          );
        },
        Filter: () => null,
      },
      {
        id: 'capacity',
        Header: <FormattedMessage id="capacity-orders" />,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        sticky: true,
        onToggleSortBy(kind, columnId) {
          setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `capacity:${kind.toUpperCase()}`,
          }));
        },
        Cell: ({ value, row }) => {
          return (
            <ProgressComponent capacity={row.original?.capacity} usedCapacity={row.original?.usedCapacity} />
          )
        }


      },
      {
        id: 'actions',
        Header: <FormattedMessage id="action" />,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sticky: true,
        onToggleSortBy(kind, columnId) {
          setExtraQuery(value => ({
            ...value,
            orderBy: !kind
          }));
        },
        Cell: ({ value, row }) => (
          <IconButton
            className="mx-1 -mt-1"
            size="sm"
            icon={<EditIcon />}
            onClick={() => {
              if (!openEditModal) {
                handleEditDeliverySlot(row.original)
              }
            }}
          />

        ),
      },

    ], []
  )

  const rowContent = useCallback<DataGridRowContent<DeliverySlot>>(
    (index, row, { viewport }) => {
      if (viewport.isWide) return;

      const cells = cellsById(row.cells);
      return (
        <div
          className={`flex flex-row justify-between items-stretch py-2 px-4 border-y -mb-px border-gray-200`}>
          <div className="flex flex-col flex-nowrap flex-1">
            <div className="mb-1 flex items-center text-xs text-gray-500">
              <span className="middle-dot w-1 h-1 mx-2 bg-current rounded" />
              <span className="flex flex-row">
                {cells.startDateTime.render('Cell', { inline: true })}
              </span>
              <span className="middle-dot w-1 h-1 mx-2 bg-current rounded" />
              <span className="flex flex-row">
                {cells.endDateTime.render('Cell', { inline: true })}
              </span>

            </div>

            <div className="mb-0.5 text-gray-500">

              <span className="flex flex-row">
                {cells.capacity.render('Cell')}
              </span>
            </div>

          </div>

          <div className="flex items-center">
            <div>
              <span>{cells.actions.render('Cell')}</span>
            </div>

          </div>
        </div>
      );
    },
    [],
  );

  const topToolbar = useCallback<DataGridToolbar<DeliverySlot>>(
    ({ filteredFlatRows, selectedData, rows, columns }) => {
      const statusColumn = columns.find(({ id }) => id === 'status');

      return (
        <>
          <IconButton
            className="ml-auto mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            icon={<PlusIcon />}
            title="Crear Franja Horaria"
            onClick={() => setOpenCreateModal(true)}

          />
          <Divider vertical className="rs-divider-vertical" />
        </>
      );
    },
    [],
  );

  return (<div className="h-screen flex flex-col pb-1">
    <PageHeader title={<FormattedMessage id="route.list-delivery-slots" />} />
    <DataGrid
      className="flex-1"
      data={virtualArray.ref}
      totalCount={virtualArray.ref.length}
      rangeChanged={setViewRange}
      columns={columns}
      selectable
      rowContent={rowContent}
      topToolbar={topToolbar}
      overscan={0}
      increaseViewportBy={500}
      autoResetFilters={false}
      autoResetSelectedRows={false}
      autoResetSortBy={false}
      disableGlobalFilter={true}
      loading={result.isFetching}
    />

    <UpdateDeliverySlot
      deliverySlot={mapperDeliveryData(deliverySlotEdit)}
      visible={openEditModal}
      onClose={() => {
        setOpenEditModal(false)
      }}
    />

    <CreateDeliverySlot
      title="Carga de Franjas Horarias"
      visible={openCreateModal}
      onClose={() => {
        setOpenCreateModal(false)
      }}
      onDeliverySlotLoaded={() => {
        setExtraQuery({ limit: 100 })
      }}
    />
  </div>)

}