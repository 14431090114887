import React from 'react';
import { Col, Container, Row } from 'rsuite-migration';
import { formatCurrencySpanishCO } from '../utils/DataFormat';

export  const OrderSummary = ({props}) =>
    <Container fluid>
        <Row>
            <Col sm={12} md={6}  lg={6}><label>Subtotal:</label></Col>            
            <Col sm={12} md={6}  lg={6}><label> {formatCurrencySpanishCO(props.subtotal)} </label></Col> 

            <Col sm={12} md={6}  lg={6}><label>Descuento:</label></Col>
            <Col sm={12} md={6}  lg={6}><label> -{formatCurrencySpanishCO(props.discount)} </label></Col>  
            
            <Col sm={12} md={6}  lg={6}><label>Subtotal:</label></Col>
            <Col sm={12} md={6}  lg={6}><label> {formatCurrencySpanishCO(props.subtotal2)} </label></Col>  

            <Col sm={12} md={6}  lg={6}><label>Impuestos:</label></Col>
            <Col sm={12} md={6}  lg={6}><label>{formatCurrencySpanishCO(props.totalTax)}</label></Col>    
            <Col sm={12} md={12}  lg={12}><label>________________________________________</label></Col> 
            <Col sm={12} md={6}  lg={6}><label>Total:</label></Col>            
            <Col sm={12} md={6}  lg={6}><label> {formatCurrencySpanishCO(props.total)}</label></Col>     
        </Row>
    </Container>





