import PageHeader from "@components/PageHeader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from '@rsuite/icons/InfoRound';
import { Close as CloseIcon, Edit as EditIcon } from '@rsuite/icons';
import { CheckPicker, Checkbox, DateRangePicker, Divider, IconButton, Message, Tooltip, Whisper, toaster } from "rsuite";


import { useLazyPagination } from "@containers/PriceList/UseLazyPagination";
import { useLazyFindCustomerQuery, useSynchronizationsCustomerMutation, useDownloadDataMutation } from "@api/query/customerApi/customerApi";
import { Customer, FindCustomerRequest } from "@api/query/customerApi/types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { getInvoicingSettings as getInvoicingSettingsAction, getPaymentMethods as getPaymentMethodsAction, getChannels as getChannelsAction, getListPrice as getListPriceAction, loadEditCustomer, redirectCreateCustomer, setOpenAuthModal, searchStates } from "@actions/index";
import { DataGrid, DataGridRowContent, DataGridToolbar, GlobalFilterCallback, HighlightText, cellsById } from "@components/DataGrid";
import { CellProps, Column } from "react-table";
import { FaFileExcel, FaSync } from 'react-icons/fa';
import dayjs, { dayjsCalendar, dayjsRanges, formats } from "@utils/dayjs";
import PlusIcon from '@rsuite/icons/Plus';
import { getListPrice, getChannels, getPaymentMethods, getInvoicingSettings, getFoundStates } from "@selectors/MasterDataSelector";
import { ModalCustomerDetail } from "./components/ModalCustomerDetail";
import { ACTIVE, NOTIFICATION_DURATION, NOTIFICATION_SUCESS_QUICK_DURATION } from "@utils/Constants";
import { EXPORT_SELECT_CLIENT } from "@utils/Notifications";
import { getMainLoggingParams } from "@selectors/SystemSelector";
import { ModalExportData } from "@components/Modals/ModalExportData";



export default function ListCustomersContainer() {
	const dispatch = useDispatch();
	const [customerEdit, setCustomerEdit] = useState({})
	const [extraQueryValues, setExtraQueryValues] = useState({});
	const [openEditModal, setOpenEditModal] = useState(false);
	const [openDetailModal, setOpenDetailModal] = useState(false);
	const [openExportData, setOpenExportData] = useState(false);
	const [customerDetail, setCustomerDetail] = useState<Customer | undefined>(undefined)
	const [triggerGetCustomers, result] = useLazyFindCustomerQuery()
	const { maxNumberDownloadAllowed } = useSelector(getMainLoggingParams);
	const listPrice = useSelector(getListPrice);
	const channels = useSelector(getChannels)
	const paymentMethods = useSelector(getPaymentMethods)
	const invoicingSettings = useSelector(getInvoicingSettings)
	const states = useSelector(getFoundStates)


	const loadMasterData = () => {
		listPrice.length === 0 && dispatch(getListPriceAction({ active: true }))
		channels.length === 0 && dispatch(getChannelsAction({}))
		paymentMethods.length === 0 && dispatch(getPaymentMethodsAction({ active: true }))
		invoicingSettings.length === 0 && dispatch(getInvoicingSettingsAction({ active: true }))
		states.length === 0 && dispatch(searchStates({ orderBy: 'name:ASC' }))

	}

	useEffect(() => {
		loadMasterData()
	}, [])

	const trigger = useCallback(
		(value: FindCustomerRequest) => {
			return triggerGetCustomers({
				...value,
				populateData: true,
				orderBy: 'createdAt:DESC'
			})
				.then(res => {
					if ((res?.error as FetchBaseQueryError)?.status === 401) {
						dispatch(setOpenAuthModal({ open: true }))
					}
				})
				.catch(err => {
					dispatch(setOpenAuthModal({ open: true }))
				})
		},
		[triggerGetCustomers],
	);

	const { virtualArray, setViewRange, setExtraQuery, resetPagination } =
		useLazyPagination({
			trigger,
			result,
			initialLimit: 100,
      initialExtraQuery: { orderBy: 'createdAt:DESC' }
		});
	
	useEffect(() => {
    const unsubscribe = setExtraQuery(newValues => {
      setExtraQueryValues(newValues);
    });

    return () => unsubscribe
	}, [setExtraQuery]);
	const [setDownload] = useDownloadDataMutation();
	const [getSynchronizationsCustomer, synchronizationsCustomer] = useSynchronizationsCustomerMutation();

	useEffect(() => {
		if (synchronizationsCustomer?.data) {
			toaster.push(
        <Message
          type="info"
          showIcon
          closable
          duration={NOTIFICATION_SUCESS_QUICK_DURATION}
        >
          {synchronizationsCustomer?.data?.message}
        </Message>,
      );
		}
		if (synchronizationsCustomer?.error) {
			toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={NOTIFICATION_SUCESS_QUICK_DURATION}
        >
          {'Error al procesar la información'}
        </Message>,
      );
		}
	}, [synchronizationsCustomer])

	const handleEditUser = (customer: Customer) => {
		setCustomerEdit(customer)
		setOpenEditModal(true)
	}

	const handleSync = (customers: Record<string, Customer>) => {
    let flag = true;
    const selectedCustomers = Object.values(customers);
    if (selectedCustomers?.length === 0) {
      flag = false;
      toaster.push(
        <Message
          type="warning"
          showIcon
          closable
          duration={NOTIFICATION_DURATION}>
          {EXPORT_SELECT_CLIENT}
        </Message>,
      );
    }
    if (selectedCustomers?.length > 20 && selectedCustomers?.length !== 0) {
      flag = false;
      toaster.push(
        <Message
          type="warning"
          showIcon
          closable
          duration={NOTIFICATION_DURATION}>
          {'Debes seleccionar un máximo de 20 clientes'}
        </Message>,
      );
    }
		if (flag) {
			const ids = selectedCustomers.map(obj => obj.id);
			getSynchronizationsCustomer({
				ids: ids,
			})
    }
	};
	const handleOnClickExportCSV = () => {
		if (virtualArray?.ref?.length > 0) {
			setOpenExportData(true)
		} else {
			toaster.push(
				<Message
					type="error"
					showIcon
					closable
					duration={NOTIFICATION_DURATION}
				>
					{'La consulta seleccionada no arroja datos.'}
				</Message>
			);
		}
  };
	const submitExport = (data) => {
		const body = { ...data, ...extraQueryValues }
		setDownload(body)
			.then(resp => {
			try {
				const menssageError = resp
				if (menssageError) {
					toaster.push(
						<Message
							type="success"
							showIcon
							closable
							duration={NOTIFICATION_DURATION}
						>
							{'El archivo se está generando. Recibirás en segundos un e-mail con un link para su descarga.'}
						</Message>
					);
				}
			} catch (error) {
				console.error('Error en la promesa:', error);
			}
		})
  };

		const { $t } = useIntl();

	const columns = useMemo<Column<Customer>[]>(
		(): Column<Customer>[] => [
			{
				id: 'fullName',
				Header: <FormattedMessage id="client" />,
				accessor: row => row.fullName,
				width: 250,
				maxWidth: 250,
				minWidth: 250,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
							? undefined
							: `fullName:${kind.toUpperCase()}`,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						orderBy: !kind
							? undefined
							: `fullName:${kind.toUpperCase()}`,
					}));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;
					setExtraQuery(value => ({
						...value,
						fullName: filterValue.trim() ? filterValue : undefined,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						fullName: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'identification',
				Header: <FormattedMessage id="identification" />,
				accessor: row => row.identification,
				width: 100,
				maxWidth: 100,
				minWidth: 100,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
							? undefined
							: `identification:${kind.toUpperCase()}`,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						orderBy: !kind
							? undefined
							: `identification:${kind.toUpperCase()}`,
					}));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						identification: filterValue.trim() ? filterValue : undefined,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						identification: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},


			},
			{
				id: 'city',
				Header: <FormattedMessage id="city" />,
				accessor: row => row.city.name,
				width: 120,
				maxWidth: 120,
				minWidth: 120,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
							? undefined
							: `city.name:${kind.toUpperCase()}`,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						orderBy: !kind
							? undefined
							: `city.name:${kind.toUpperCase()}`,
					}));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						city: filterValue.trim() ? filterValue : undefined,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						city: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'listPrice',
				Header: <FormattedMessage id="list-price" />,
				accessor: row => row?.listPrice?.description,
				width: 200,
				maxWidth: 200,
				minWidth: 200,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
							? undefined
							: `listPrice.description:${kind.toUpperCase()}`,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						orderBy: !kind
							? undefined
							: `listPrice.description:${kind.toUpperCase()}`,
					}));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;
					setExtraQuery(value => ({
						...value,
						listPriceId: filterValue.trim() ? filterValue : undefined,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						listPriceId: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'createdAt',
				Header: <FormattedMessage id="createdAt" />,
				accessor: data => new Date(data.createdAt),
				minWidth: 150,
				width: 200,
				maxWidth: 200,
				sortType: 'datetime',
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
							? undefined
							: `createdAt:${kind.toUpperCase()}`,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						orderBy: !kind
							? undefined
							: `createdAt:${kind.toUpperCase()}`,
					}));
				},
				Cell: ({ value, inline, state }: CellProps<Customer, Date>) => {
					if (value.toString() === "Invalid Date") {
						return null
					}
					const date = dayjs(value);
					if (inline) {
						return (
							<HighlightText
								text={dayjsCalendar(date)}
								subtext={state.globalFilter}
							/>
						);
					}

					return (
						<>
							<Whisper
								trigger={['click', 'hover', 'active']}
								placement="bottomStart"
								speaker={<Tooltip>{date.format(formats.datetime)}</Tooltip>}>
								<div className="text-sm font-light first-letter:capitalize">
									<HighlightText
										text={dayjsCalendar(date)}
										subtext={state.globalFilter}></HighlightText>
								</div>
							</Whisper>
						</>
					);
				},
				Filter({ column }) {
					const { id, filterValue, setFilter } = column;

					return (
						<DateRangePicker
							id={id}
							name={id}
							size="xs"
							block
							showOneCalendar
							value={filterValue || []}
							onChange={setFilter}
							format="dd/MM/yyyy"
							ranges={dayjsRanges([
								'today',
								'tomorrow',
								'yesterday',
								'last7Days',
								'next7Days',
							])}
						/>
					);
				},
				filter: (rows, _, filterValue) => {
					setExtraQuery(value => ({
						...value,
						fromCreatedAt: filterValue?.[0],
						toCreatedAt: filterValue?.[1],
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						fromCreatedAt: filterValue?.[0],
						toCreatedAt: filterValue?.[1],
					}));

					return rows;
				},
			},
			{
				id: 'active',
				Header: <FormattedMessage id="status" />,
				accessor: row => row.active,
				sticky: true,
				width: 70,
				maxWidth: 100,
				minWidth: 70,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
					}));
				},
				Filter({ column }) {
					const { id, filterValue, setFilter } = column;

					return (
						<CheckPicker
							id={id}
							name={id}
							placeholder="-"
							size="xs"
							data={ACTIVE}
							block
							searchable={false}
							cleanable={false}
							value={filterValue || []}
							onChange={setFilter}
						/>
					);
				},
				Cell: ({ value, state }) => (
					<div>{<Checkbox checked={value} />}</div>
				),
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						active: filterValue.trim() ? filterValue : undefined,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						active: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'actions',
				Header: <FormattedMessage id="action" />,
				width: 100,
				maxWidth: 100,
				minWidth: 100,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						orderBy: !kind
					}));
				},
				Cell: ({ value, row }) => {
					const id = row.original.id
					return (
						<div>
							<IconButton
								title="Ver detalles"
								size="sm"
								icon={<InfoIcon />}
								onClick={() => {
									setOpenDetailModal(true)
									setCustomerDetail(row.original as Customer)
								}}
							/>

							<IconButton
								className="mx-1 -mt-1"
								size="sm"
								icon={<EditIcon />}
								onClick={() => {
									dispatch(loadEditCustomer({ id }))
								}}
							/>
						</div>
					)

				}
				,
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;
					setExtraQuery(value => ({
						...value,
						actions: filterValue.trim() ? filterValue : undefined,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						actions: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},


		], []
	)


	const rowContent = useCallback<DataGridRowContent<Customer>>(
		(index, row, { viewport }) => {
			if (viewport.isWide) return;

			const cells = cellsById(row.cells);
			
			return (
				<div
					className={`flex flex-row justify-between items-stretch py-2 px-4 border-y -mb-px border-gray-200`}>
					<div className="flex flex-col flex-nowrap flex-1">
						<div className="mb-1 flex items-center text-xs text-gray-500">
							<span className="middle-dot w-1 h-1 mx-2 bg-current rounded" />
							<span className="flex flex-row">
								{cells.createdAt.render('Cell', { inline: true })}
							</span>
						</div>
						<span className="mb-0.5 text-lg font-normal text-gray-700">
							{cells.fullName.render('Cell')}
						</span>

						<div className="mb-0.5 text-gray-500">

							<span className="flex flex-row">
								{cells.identification.render('Cell')}
							</span>
							<span className="flex flex-row">
								{cells.city.render('Cell')}
							</span>
							<span className="flex flex-row">
								{cells.listPrice.render('Cell')}
							</span>
						</div>
					</div>

					<div className="flex items-center">
						<div>
							<span>
								{cells.active.render('Cell')}
							</span>
						</div>
						<div>
							<span>{cells.actions.render('Cell')}</span>
						</div>
					</div>
				</div>
			);
		},
		[],
	);

	const topToolbar = useCallback<DataGridToolbar<Customer>>(
		({ filteredFlatRows, selectedData, rows, columns }) => {
			const statusColumn = columns.find(({ id }) => id === 'status');

			return (
				<>
					<Divider vertical className="rs-divider-vertical" />
					<IconButton
            className="mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            icon={<FaFileExcel className="rs-icon text-green-500" />}
            title="Exportar a csv"
						onClick={() => handleOnClickExportCSV()}></IconButton>
					<Divider vertical className="rs-divider-vertical" />
					<IconButton
            className="ml-0 mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            title={'Sincronizar clientes'}
            onClick={() => handleSync(selectedData)}
            icon={
              <FaSync className="rs-icon text-blue-400" />
            }></IconButton>
					<Divider vertical className="rs-divider-vertical" />
					<IconButton
						className="ml-0 mx-1 bg-gray-100 hover:shadow-md"
						size="sm"
						icon={<PlusIcon />}
						title="Crear Cliente"
						onClick={() => {
							dispatch(redirectCreateCustomer())
						}}

					/>
					<Divider vertical className="rs-divider-vertical" />
				</>
			);
		},
		[],
	);

	const globalFilter = useCallback<GlobalFilterCallback<Customer>>(
		(rows, columnIds, filterValue: string) => {
			setExtraQuery(value => ({
				...value,
				keyword: filterValue.trim() ? filterValue : undefined,
			}));
			setExtraQueryValues(prevValues => ({
				...prevValues,
				keyword: filterValue.trim() ? filterValue : undefined,
			}));
			return rows;
		},
		[],
	);


	return (<div className="h-screen flex flex-col pb-1">
		<PageHeader title={<FormattedMessage id="customers" />} />
		{openDetailModal
			? <ModalCustomerDetail
				isOpenDetailModal={openDetailModal}
				oncloseModal={() => setOpenDetailModal(false)}
				customer={customerDetail} />
			: null}
		{openExportData && (
			<ModalExportData
				title={<FormattedMessage id="export-data-customers" />}
				visible={openExportData}
				onClose={() => {
					setOpenExportData(false)
				}}
				submitExport={submitExport}
				disabled={virtualArray?.ref?.length > maxNumberDownloadAllowed}
				pages={virtualArray?.ref?.length / maxNumberDownloadAllowed}
			/>
		)}

		<DataGrid
			className="flex-1"
			data={virtualArray.ref}
			totalCount={virtualArray.ref.length}
			rangeChanged={setViewRange}
			columns={columns}
			selectable
			rowContent={rowContent}
			topToolbar={topToolbar}
			globalFilter={globalFilter}
			overscan={0}
			increaseViewportBy={500}
			autoResetFilters={false}
			autoResetSelectedRows={false}
			autoResetSortBy={false}
			loading={result.isFetching}

		/>


	</div>)
}


