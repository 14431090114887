import React, { Component } from 'react';
import editIcon from '../images/edit5.png';
import deleteIcon from '../images/remove.png';
import '../styles/Grid.css';
import * as constants from '../utils/Constants';
import { formatCurrencySpanishCO, formatDateSpanishCO, formatDateSpanishWeekDay } from '../utils/DataFormat';
import getOrderStatusAlert from '../utils/OrderHandling';

export const ColumnCenterFormatter = ({ value }) => {
    // return <div className="column-center"> <label> {value} </label> </div>
    return <div className="column-center"> {value} </div>
  };

  // export const ColumnCenterFormatterShortDateTime = ({ value }) => {
  //   return <div className="text-center"> <label> {formatDateAndHourSpanishCOShort(value)} </label> </div>
  // };

  export class CenterColumnPromiseAlert extends Component{
    render() {

      const orderAlert = getOrderStatusAlert(this.props.row.TimeRemaining);

      if( orderAlert === constants.ORDER_DELAYED )
        return (<div className="column-center order-delayed">{this.props.value}</div>);

      else if( orderAlert === constants.ORDER_CLOSE_TO_DELAY)
        return (<div className="column-center order-warning">{this.props.value}</div>);
      
      else
        return (<div className="column-center">{this.props.value}</div>);
     }
  };

  export class LeftColumnPromiseAlert extends Component{
    render() {

      const orderAlert = getOrderStatusAlert(this.props.row.TimeRemaining);

      if( orderAlert === constants.ORDER_DELAYED )
        return (<div className="column-left order-delayed">{this.props.value}</div>);

      else if( orderAlert === constants.ORDER_CLOSE_TO_DELAY)
        return (<div className="column-left order-warning">{this.props.value}</div>);
      
      else
        return (<div className="column-left"> {this.props.value}</div>);
     }
  };

 export const ColumnEditFormatter = ({value})  => {
   return <div className="text-center"> <img style={{ objectFit: 'scale-down' }} alt="edit-icon" src={editIcon}></img> </div>
 }

 export const ColumnDeleteFormatter = ({value})  => {
   return <div className="text-center"> <img style={{ objectFit: 'scale-down' }} alt="delete-icon" src={deleteIcon}></img> </div>
 }
 
export const ColumnRightCurrencyFormatter = ({ value }) => {
    return <div className="column-right"> {formatCurrencySpanishCO(value)} </div>
  };

  export const ColumnCenterDateFormatter = ({ value }) => {
    return <div className="column-center"> {formatDateSpanishCO(value)} </div>
  };

  
  export const ColumnRightFormatter = ({ value }) => {
    return <div className="column-right"> {(value)} </div>
  };

  export const HeaderCenterFormatter = ({ column }) => {
    return <div className="text-center"> {column.name} </div>
  };

  export const OrderCalendarEventColor = (timeRemaining,statusId) => {

    const orderAlert = getOrderStatusAlert(timeRemaining);
    if( orderAlert === constants.ORDER_DELAYED )
        return '#D00000';

      else if( orderAlert === constants.ORDER_CLOSE_TO_DELAY)
        return '#F3C408';

      else if(statusId === constants.ENTREGADA)
        return '#85BB65';

      else if(statusId === constants.CANCELADA)
        return '#C0C0C0';
      
      else
        return '';
  }

  export class CenteredExtendedDate extends Component{
    render() 
    {
      return (<div className="column-center">{formatDateSpanishWeekDay(this.props.value)}</div>);
     }
  };