import queryString from 'query-string';
import axiosRetry from 'axios-retry';
import axios from '../libs/axios';
import * as constants from '../utils/Constants';

const api = {
  async saveOrder(companyId, order) {
    return await axios
      .post('Order/Add/' + companyId, order, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          return {};
        }
      });
  },

  async createOrder(order) {
    return await axios
      .post('order/create', order)
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          return {};
        }
      });
  },

  async getAllOrders(companyId, loggedUser) {
    const apiURL =
      'Order/GetAll?companyId=' +
      companyId +
      '&userId=' +
      loggedUser.userId +
      '&roleId=' +
      loggedUser.roleId +
      '&locationId=' +
      loggedUser.locationId;

    return await axios
      .get(apiURL)
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        console.log('error here!');
        console.log(error);
        return {
          status: error.response.status,
          data: error.response.data,
        };
      });
  },
  async getCurrentOrders(loggedUser) {
    const apiURL =
      'Order/GetAll?userId=' +
      loggedUser.userId +
      '&roleId=' +
      loggedUser.roleId +
      '&locationId=' +
      loggedUser.locationId;

    return await axios
      .get(apiURL)
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        return {
          status: error.response.status,
          data: error.response.data,
        };
      });
  },
  async getPendingInvoiceOrders(companyId, customerId) {
    const apiURL =
      'Order/GetPendingInvoiceOrders?companyId=' +
      companyId +
      '&customerId=' +
      customerId;

    return await axios
      .get(apiURL)
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        return {
          status: error.response.status,
          data: error.response.data,
        };
      });
  },
  
  editOrder({id, data}){
    return axios.patch(`orders/${id}`, data)
  },

  async updateOrderInvoiceData(order) {
    return await axios
      .post('Order/UpdateInvoiceData', order)
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          return {};
        }
      });
  },

  async createPurchaseOrder(companyId, order) {
    axiosRetry(axios, {
      retries: constants.INVOICE_ORDER_API.retries,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition: error => {
        return error.response.status != 200;
      },
    });

    return await axios
      .post('Order/CreatePurchaseOrder/' + companyId, order)
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          return {};
        }
      });
  },

  findOrder(id, filters = {}) {
    const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
    return axios.get(`orders/${id}${params && `?${params}`}`)
  },

  find(filters = {}) {
    
    const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
    return axios.get(`orders/${params ? `?${params}` : ''}`)
  },

  findOrderDetails(id) {
    return axios.get(`orders/${id}/items`)
  },

  async searchActiveOrders(matches) {
    return await axios
      .post('order/findactive', matches)
      .then(response => {
        return {
          status: response.status,
          data: response.data.result,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
      });
  },

  async getInvoiceURL(id, docType) {
    return await axios
      .get(`invoices/${id}/${docType}/download`)
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          return {};
        }
      });
  },

  async getPurchaseOrderURL(companyId, purchaseOrderIdERP) {
    return await axios
      .get(
        'Order/GetPurchaseOrderURL?companyId=' +
          companyId +
          '&purchaseOrderIdERP=' +
          purchaseOrderIdERP,
      )
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
      });
  },

  async updateOrderTags(companyId, orderTags) {
    return await axios
      .post('Order/UpdateOrderTags/' + companyId, orderTags)
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          return {};
        }
      });
  },
};

export default api;

// useful resources about API's idempotency
// https://medium.com/gin-and-tonic/understanding-idempotency-in-rest-apis-7a5568f41406
// https://stackoverflow.com/questions/56074531/how-to-retry-5xx-requests-using-axios
