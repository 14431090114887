import { ACTIVE } from "@utils/Constants";
import { Schema } from "rsuite";

export const validateCreateProduct = () => {
  const { StringType, NumberType } = Schema.Types;
  let schema = Schema.Model({
    name: StringType().isRequired("Requerido").maxLength(100, "La longitud debe ser inferior a 100"),
    priceBeforeTax: NumberType("Valor inválido").isRequired("Requerido").range(0, 100000000, "Valor inválido"),
    tax: NumberType().isRequired("Requerido").min(0, "Requerido"),
    cost: NumberType("Valor inválido").range(0, 100000000, "Valor inválido"),
    unit: StringType().isRequired("Requerido"),
    sku: StringType().maxLength(45, "La longitud debe ser inferior a 45"),
  })
  return schema;
}

export const productsFormInitialValues = (taxId) => {
  let initialValues = {
    name: "",
    priceBeforeTax: "",
    tax: taxId,
    cost: "",
    unit: "unit",
    sku: "",
    active: true,
  };
  return initialValues;
};

export const productsFormInitialValuesEdit = (product) => {
  let initialValues = {
    name: product?.name,
    priceBeforeTax: product?.priceBeforeTax,
    tax: product?.taxIdERP,
    cost: product?.priceAfterTax,
    unit: product?.unit === 'uds' ? 'unit' : product?.unit,
    sku: product?.sku,
    active: product?.active,
  };
  return initialValues;
};