import React, {
  createContext,
  useContext,
  useState,
} from 'react';

const TabsContext = createContext(null);

export function TabsContextProvider({children}){
  const [tabSelected, setTabSelected] = useState(0)
  const onChangeTabs = (tabId) => {
		setTabSelected(tabId)
	}
  return (
    <TabsContext.Provider value={{ tabSelected, onChangeTabs }}>
      {children}
    </TabsContext.Provider>
  )
}

export function useTabsContext(){
  return useContext(TabsContext)
}