export const getCurrentMonthOnTime = state => state.ordox.mainOpsDashboard.currentMonthOnTime;
export const getCurrentMonthOnTimeOrders = state => state.ordox.mainOpsDashboard.currentMonthOnTimeOrders;
export const getCurrentMonthOffTimeOrders = state => state.ordox.mainOpsDashboard.currentMonthOffTimeOrders;

// units / $$
export const getTotalOrders = state => state.ordox.mainOpsDashboard.totalOrders;
export const getTotalAmount = state => state.ordox.mainOpsDashboard.totalAmount;
export const getTotalUnits = state => state.ordox.mainOpsDashboard.totalUnits;


// graphs
export const getOnTimeSeries = state => state.ordox.mainOpsDashboard.onTimeSeries;
export const getOnTimeData = state => state.ordox.mainOpsDashboard.onTimeData;
export const getDailyOrdersData = state => state.ordox.mainOpsDashboard.dailyOrdersData;
export const getDailyOrdersSeries = state => state.ordox.mainOpsDashboard.dailyOrdersSeries;
export const getTopCustomersData = state => state.ordox.mainOpsDashboard.topCustomersData;
export const getTopCustomersSeries = state => state.ordox.mainOpsDashboard.topCustomersSeries;
export const getLastMonthStatus = state => state.ordox.mainOpsDashboardController.buttonGroupStatus[0].active;
export const getCurrentMonthStatus = state => state.ordox.mainOpsDashboardController.buttonGroupStatus[1].active;
export const getLiveStatusStatus = state => state.ordox.mainOpsDashboardController.buttonGroupStatus[2].active;
export const getLastUpdate = state => state.ordox.mainOpsDashboardController.lastUpdate;
export const getCurrentSelection = state => state.ordox.mainOpsDashboardController.currentSelection;
export const getTopTenDimension = state => state.ordox.mainOpsDashboardController.topTenDimension;

// live status
export const getOrdersSet = state => state.ordox.mainOpsDashboardLiveStatus.ordersSet;
export const getAmountsSet = state => state.ordox.mainOpsDashboardLiveStatus.amountsSet;
export const getUnitsSet = state => state.ordox.mainOpsDashboardLiveStatus.unitsSet;

// modal
export const getAuthModalData = state => state.ordox.authModal
