import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "rsuite";
import { useGetKardexQuery } from "@api/query/inventoryApi/inventory";
import { dayjsCalendar } from "@utils/dayjs";
import { HighlightText } from "@components/DataGrid";

export function ModalInventoryDetail(props) {
  const { inventoryInfo, visible, onClose, title } = props;

  const [array, setArray] = useState([])
  const { data: inventory } = useGetKardexQuery({
    limit: 20,
    offset: 0,
    productId: inventoryInfo?.product?.id,
    locationId: inventoryInfo?.location?.id,
    orderBy: 'createdAt:DESC'
  });

  const columnLabels = {
    recordType: "Tipo movimiento",
    quantity: "Cantidad",
    user: "Usuario",
    createAt: "Fecha de creación",
    comments: "Comentarios",
  };
  useEffect(() => {
    if (inventory) {
      const result = inventory?.items.map((value) => ({
      recordType: value.recordType?.description,
      quantity: value.quantity,
      user: value.user?.name,
      createAt: value.updatedAt,
      comments: value.comments,
      }));
    setArray(result)
    }
  }, [inventory]);

  return (
    <Modal
      className="modal-dialog-container md:w-9/12"
      placement="top"
      open={visible}
      onClose={onClose}
      autoFocus
      keyboard
    >
      <Modal.Header>
        <Modal.Title style={{ marginBottom: '10px' }}> {title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="py-2 inline-block min-w-full">
              <div className="overflow-auto rounded-lg shadow">
                <table className="min-w-full border">
                  <thead className="bg-gray-50 border-b-2 border-gray-200">
                    <tr>
                      {array.length > 0 &&
                        Object.keys(array[0]).map((columnName, index) => (
                          <th
                            key={index}
                            scope="col"
                            className={`text-sm font-medium text-gray-600 px-6 py-2 text-center ${
                            columnName === "quantity" ? "w-20" : columnName === "recordType" ? "w-32" : columnName === "user" ? "w-32" : "w-52"
                          }`}
                          >
                            {columnLabels[columnName]}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {array.map((option, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.entries(option).map(([key, value], colIndex) => (
                          <td key={colIndex} className={`text-sm px-6 py-2 ${ key === 'quantity' ? 'text-right' : 'text-left'}`}>
                            {key === "createAt" ? (
                              <HighlightText text={dayjsCalendar(value)} />
                            ) : (
                              value
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}