import * as constants from '../utils/Constants';

const getOrderStatusAlert = (timeRemaining) => {    

    switch(true)
    {
        case(timeRemaining < 0):
            return constants.ORDER_DELAYED;
        case(timeRemaining > 0 && timeRemaining < constants.MINUTES_TO_ORDER_ALERT):
            return constants.ORDER_CLOSE_TO_DELAY;
        default:
            return constants.ORDER_ON_TIME;
    }
}

export default getOrderStatusAlert;