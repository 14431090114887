import React, { HTMLAttributes } from 'react';

type AddressLinkProps = Omit<HTMLAttributes<HTMLAnchorElement>, 'href'> & {
  query: string;
  place?: string;
};

const AddressLink: React.FC<AddressLinkProps> = ({
  query,
  place = '',
  children,
  ...props
}) => {
  const params = new URLSearchParams({
    api: '1',
    query: [query, place].filter(v => v).join(', '),
  });

  return (
    <a
      href={`https://www.google.com/maps/search/?${params}`}
      target="_blank"
      rel="noreferrer"
      {...props}>
      {children}
    </a>
  );
};

export default AddressLink;
