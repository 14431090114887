import axios from '../libs/axios';

const api = {
  async trigerInvoicing(id, data) {
    return axios.patch(`/orders/${id}/invoices`, data)
  },
  
  async multipleInvoicing(orders) {
    return await axios
      .post('orderEvent/invoice/_bulk', orders)
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          return {};
        }
      });
  },

  async multipleUpdateStatus(orders) {
    return await axios
      .post('order/updatestatus/_bulk', orders)
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          return {};
        }
      });
  },
  
  triggerOrderEvent(id, data) {
    return axios.patch(`/orders/${id}/invoices`, data)
  },
};

export default api;
