import * as OrdoxTracker from '../eventManagement/OrdoxTracker';
import axios from '../libs/axios';


const api = {
    async invoiceOrder(companyId,order) {   
    
        return await axios.post('Invoice/InvoiceOrder/' +  companyId, order,{ headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*"
        } })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {
                console.log('error here!');
                console.log(error);

                OrdoxTracker.logOrdoxEvent(OrdoxTracker.events.invoiceCreateAttempt,{})

                if (error.response) {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    }
                } else { return {} }
            })
    },
    async isInvoiceable(currentStatus,newStatus)
    {
        const apiURL = 'Invoice/IsInvoiceableEvent?currentStatus='+ currentStatus + '&newStatus=' + newStatus;
        
        return await axios.get(apiURL)
        .then(response => {
            return{
                status: response.status,
                data: response.data
            }
        })
        .catch(error => {
            console.log('error here!');
            console.log(error);
            return{
                status: error.response.status,
                data: error.response.data
            }
        })
    },
    async getRecentOrders(customerIdERP)
    {
        const apiURL = 'Invoice/GetRecentInvoices?customerIdERP='+ customerIdERP;
        
        return await axios.get(apiURL)
        .then(response => {
            return{
                status: response.status,
                data: response.data
            }
        })
        .catch(error => {
            console.log('error here!');
            console.log(error);
            return{
                status: error.response.status,
                data: error.response.data
            }
        })
    },
    async updateOrderInvoiceData(order) {   
        return await axios.post('Invoice/UpdateInvoiceData', order)
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {
                if (error.response) {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    }
                } else { return {} }
            })
    },
}

export default api;
