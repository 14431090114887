import apiListPriceProduct from '@api/ListPriceProductApi';
import orderApi from '@api/query/orderApi/orderApi';
import history from '@router/history';
import { openPDF } from '@utils/configERP';
import dayjs from '@utils/dayjs';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Message, toaster } from 'rsuite';
import * as actions from '../actions';
import types from '../actions/ActionTypes';
import apiInvoice from '../api/InvoiceApi';
import { default as apiOrderTracking } from '../api/OrderTrackingApi';
import api from '../api/_OrderApi';
import * as OrdoxTracker from '../eventManagement/OrdoxTracker';
import * as constants from '../utils/Constants';
import { getDateTimeNow } from '../utils/DataFormat';
import { mapOrderList } from '../utils/Mappers.js';
import {
  getOrderCreationDto,
  mapInvoiceMultiOrder,
  mapSearchFilters
} from '../utils/Mappers/OrderMappers';
import * as Notifications from '../utils/Notifications.js';
import {
  getNotificationMessage,
  ORDER_TAGS_SUCESS_UPDATED
} from '../utils/Notifications.js';

function* saveOrder(action) {
  let notificationMessage;

  try {
    yield put(actions.setShowSpinner(true));
    let orderDto = action.payload.newOrder;
    orderDto.companyId = action.payload.loggingParams.companyId;

    orderDto = getOrderCreationDto(orderDto);

    let response = yield call(api.createOrder, orderDto);
    if (response && response.status === 200) {
      notificationMessage =
        getNotificationMessage(constants.ORDER_CREATED_SUCCESS1) +
        response.data.orderNumber +
        getNotificationMessage(constants.ORDER_CREATED_SUCCESS2);
      toaster.push(
        <Message
          type="success"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
          {notificationMessage}
        </Message>,
      );
    } else if (response.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      notificationMessage = getNotificationMessage(
        constants.ORDER_CREATED_FAILURE,
      );
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
          {notificationMessage}
        </Message>,
      );
    }
  } catch (error) {
    console.log(error);
    notificationMessage = getNotificationMessage(
      constants.ORDER_CREATED_FAILURE,
    );
    toaster.push(
      <Message
        type="error"
        showIcon
        closable
        duration={constants.NOTIFICATION_DURATION}
      >
        {notificationMessage}
      </Message>,
    );
    // this.props.disableButton(false);
  } finally {
    yield put(actions.updateOrdersIsFirstLoad(true));
    yield put(actions.setShowSpinner(false));
    yield call(history.push, action.payload.redirect.page);
    if (action.callback) {
      action.callback();
    }
    yield put(orderApi.util.invalidateTags(['order']));
  }
}


function* loadItemsEditOrder({ payload }) {
  try {
    yield put(actions.setShowSpinner(true));

    const { data: order } = yield call(api.findOrder, payload.order.id, payload.filters);
    order.deliverySlot = {
      startDateTime: order.deliverySlot?.startDateTime,
      endDateTime: order.deliverySlot?.endDateTime,
      id: order.deliverySlot?.id,
    };
    if (order.customer.listPriceId) {
      const { items } = yield call(apiListPriceProduct.getListPrice, { listPriceId: order.customer.listPriceId });

      yield put(actions.updateSelectedPriceList(items));
    }

    const customer = {
      value: order.customer.id,
      label: order.customer.fullName,
      mobile: order.shippingAddress.mobile,
      primaryEmail: order.shippingAddress.primaryEmail, 
      address: order.shippingAddress.address,
      state: order.shippingAddress.state,
      city: order.shippingAddress.city,
      ...order.customer,
    };

    order.items = order.items.map(item => {
      return {
        item: item.product.name,
        itemPrice: item.priceBeforeTax,
        discount: item.discount ? item.discount : 0,
        itemTaxNumeric: item.tax,
        itemTax: item.tax + '%',
        itemQuantity: item.quantity,
        itemTotal:
          item.priceBeforeTax * item.quantity * (1 + item.tax / 100),
        delete: 'x',
        productId: item.productId,
        id: item.id,
        unitsCount: item.product.unitsCount,
        taxIdERP: item.taxIdERP,
        idERP: item.product.idERP,
        notes: item.notes,
        active: item.active,
      };
    });

    yield put(actions.updateStateCustomers([customer]));

    if (payload.action === 'EDIT') {
      order.action = 'EDIT';
      order.deletedItems = [];
      order.fulfillmentType = order.fulfillmentType
      order.fulfillmentTypeId = order.fulfillmentType.id
      yield put(actions.loadEditOrder(order));
      yield put(
        actions.updateFormAction({ form: 'ORDER', action: 'EDIT' }),
      );

      yield call(history.push, 'create-order');
    } else {
      order.action = 'COPY';
      // reset some the order's parameters
      order.promisedDeliveryDate = getDateTimeNow();

      // delete ids
      delete order.id;

      order.channelId = order.channel.id
      order.paymentMethodId = order.paymentMethod.id
      order.fulfillmentType = order.fulfillmentType
      order.fulfillmentTypeId = order.fulfillmentType.id

      yield put(actions.copyOrder(order));
      yield put(
        actions.updateFormAction({ form: 'ORDER', action: 'COPY' }),
      );
      yield call(history.push, 'create-order');
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

function* getAllOrders(action) {
  try {
    yield put(actions.setShowSpinner(true));
    const companyId = action.payload.companyId;
    const loggedUser = {
      userId: action.payload.loggedUser.id,
      roleId: action.payload.loggedUser.roleId,
      locationId: action.payload.loggedUser.locationId,
    };

    let orders = [];
    orders = yield call(api.getAllOrders, companyId, loggedUser);

    if (orders.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      yield put(actions.setLastUpdatedOrdersGrid(Date.now()));
      yield put(actions.updateStateOrders(mapOrderList(orders.data)));
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

function* getCurrentOrders(action) {
  try {
    yield put(actions.setShowSpinner(true));

    const loggedUser = {
      userId: action.payload.id,
      roleId: action.payload.roleId,
      locationId: action.payload.locationId,
    };

    let orders = [];
    orders = yield call(api.getCurrentOrders, loggedUser);

    if (orders.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      const mappedOrders = mapOrderList(orders.data);

      yield put(actions.setLastUpdatedOrdersGrid(Date.now()));
      yield put(
        actions.updateOrdersGridRows({ orders: mappedOrders, history: false }),
      );
      // yield put(actions.updateStateOrders(mapOrderList(orders.data)));
    }
  } catch (error) {
    alert(error);
    console.log(error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

function* getCustomerPendingInvoicingOrders(action) {
  let orders;
  try {
    yield put(actions.setShowSpinner(true));
    const companyId = action.payload.companyId;
    orders = yield call(
      api.getPendingInvoiceOrders,
      companyId,
      action.payload.customerId,
    );

    if (orders.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actions.setShowSpinner(false));
    yield put(actions.setPendingInvoicingOrders(orders.data));
  }
}

function* editOrder(action) {
  try {
    let notificationMessage;
    yield put(actions.setShowSpinner(true));
    let orderDto = action.payload.editedOrder;
    const data = {
      source: 'savia',
      data: {tags: orderDto?.tags},
    }
    let response = yield call(api.editOrder, { id: orderDto?.id, data: data });

    if (response && response.status === 200) {
      notificationMessage = getNotificationMessage(
        constants.ORDER_EDITED_SUCCESS,
        response.data.orderId,
      );
      toaster.push(
        <Message
          type="success"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
          {notificationMessage}
        </Message>,
      );
      yield put(actions.disableButton(false));
    } else if (response && response.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={constants.ERROR_NOTIFICATION_DURATION}
        >
          {response.data}
        </Message>,
      );
    }
  } catch (error) {
    toaster.push(
      <Message
        type="error"
        showIcon
        closable
        duration={constants.ERROR_NOTIFICATION_DURATION}
      >
        {getNotificationMessage(constants.ORDER_EDIT_FAILURE, null)}
      </Message>,
    );
    console.log(error);
  } finally {
    yield put(actions.updateOrdersIsFirstLoad(true));
    yield put(actions.hideUpdateOrderStatus());
    yield put(actions.setShowSpinner(false));
    yield put(actions.updateFormAction({ form: 'ORDER', action: 'CREATE' }));
    if (action.payload.redirect)
      yield call(history.push, action.payload.redirect.page);
    yield put(actions.disableButton(false));
    yield put(orderApi.util.invalidateTags(['order']));
  }
}

function* invoiceMultiOrder(action) {
  let response;
  try {
    console.log(action);
    yield put(actions.setShowSpinner(true));
    const orderDto = mapInvoiceMultiOrder(
      action.payload.selectedOrders,
      action.payload.orders,
      action.payload.notes,
      action.payload.customer,
    );
    const companyId = action.payload.companyId;
    response = yield call(apiInvoice.invoiceOrder, companyId, orderDto);
    yield put(actions.setShowSpinner(false));

    if (response && response.status === 200) {
      if (response.data !== constants.NO_ACTION_PERFORMED) {
        toaster.push(
          <Message
            type="success"
            showIcon
            closable
            duration={constants.NOTIFICATION_DURATION}
          >
            {getNotificationMessage(constants.ORDER_INVOICED) +
              '#' +
              response.data[1]}
          </Message>,
        );
      }
    } else if (response && response.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={constants.INVOICING_ERROR_NOTIFICATION_DURATION}
        >
          {response.data}
        </Message>,
      );
    }
  } catch (error) {
    console.error(error);
  } finally {
    if (action.callback) {
      action.callback({
        invoicedOrders: action.payload.selectedOrders,
        invoiceData: response.data,
      });
    }
    yield call(history.push, 'list-order');
  }
}

function getRecentInvoice(orderDto, invoicesList) {
  let createdInvoice, elapsedTime, orderIdIncluded, totalMatch;
  const now = dayjs();

  invoicesList.forEach(invoice => {
    elapsedTime = now.diff(dayjs(invoice.createdAt), 'milliseconds');
    orderIdIncluded = invoice.annotation.includes(
      '#' + orderDto.OrderId.toString(),
    );
    totalMatch = orderDto.TotalAfterTax == invoice.totalAfterTax;

    if (
      elapsedTime < constants.ALLOWED_MILLISECONDS &&
      orderIdIncluded &&
      totalMatch
    ) {
      createdInvoice = invoice;
    }
  });
  return createdInvoice;
}

function* invoiceFromPurchaseOrder(action) {
  try {
    const companyId = action.payload.companyId;
    const orderDto = action.payload.order;
    yield put(actions.setShowSpinner(true));

    // call invoicing service
    let response = yield call(apiInvoice.invoiceOrder, companyId, orderDto);

    if (response && response.status === 200) {
      toaster.push(
        <Message
          type="success"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
          {Notifications.INVOICE_FROM_PURCHASE_ORDER_SUCCESS_1 +
            response.data[1] +
            Notifications.INVOICE_FROM_PURCHASE_ORDER_SUCCESS_2}
        </Message>,
      );
      yield put(
        actions.setOrderInvoiceData({
          id: orderDto.OrderId,
          invoiceData: response.data,
        }),
      );
    } else if (response && response.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={constants.INVOICING_ERROR_NOTIFICATION_DURATION}
        >
          {response.data}
        </Message>,
      );
      const recentInvoices = yield call(
        apiInvoice.getRecentOrders,
        orderDto.CustomerMatch.idERP,
      );
      const validInvoice = getRecentInvoice(orderDto, recentInvoices.data);
      yield call(apiInvoice.updateOrderInvoiceData, {
        OrderId: orderDto.OrderId,
        InvoiceId: validInvoice.invoiceId,
        InvoiceIdERP: validInvoice.invoiceIdERP,
      });
    }
  } catch (error) {
    toaster.push(
      <Message
        type="error"
        showIcon
        closable
        duration={constants.ERROR_NOTIFICATION_DURATION}
      >
        {Notifications.GENERAL_ERROR_MESSAGE}
      </Message>,
    );
    console.log(error);
  } finally {
    yield put(actions.updateOrdersIsFirstLoad(true));
    yield put(actions.setShowSpinner(false));
    if (action.callback) {
      action.callback();
    }
  }
}

function* createPurchaseOrder(action) {
  try {
    yield put(actions.setShowSpinner(true));
    const companyId = action.payload.loggingParams.companyId;
    let orderDto = action.payload.order;
    let response = yield call(api.createPurchaseOrder, companyId, orderDto);

    if (response && response.status === 200) {
      if (response.data !== constants.NO_ACTION_PERFORMED) {
        toaster.push(
          <Message
            type="success"
            showIcon
            closable
            duration={constants.NOTIFICATION_DURATION}
          >
            {'La remisión será creada en unos instantes!'}
          </Message>,
        );
      }

      // an event has occured, log it!
      const eventParams = {
        main: action.payload.loggingParams,
        orderId: orderDto.orderId,
        purchaseOrderId: response.data.purchaseOrderId,
      };
      OrdoxTracker.logOrdoxEvent(
        OrdoxTracker.events.purchaseOrderCreated,
        eventParams,
      );
    } else if (response && response.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={constants.ERROR_NOTIFICATION_DURATION}
        >
          {response.data}
        </Message>,
      );
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actions.setShowSpinner(false));
    if (action.callback) {
      action.callback();
    }
  }
}

function* openConfirmInvoiceAction(action) {
  try {
    const companyId = action.payload.orderId;
    const orderTracking = yield call(
      apiOrderTracking.getOrderTrackingInformation,
      companyId,
      action.payload.orderId,
    );

    if (orderTracking && orderTracking.status === 200) {
      yield put(actions.setOrderTracking(orderTracking.data));
      yield put(actions.setInvoiceConfirmActionVisible(true));
    } else if (orderTracking && orderTracking.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* cancelOrder(action) {
  yield put(actions.updateFormAction({ form: 'ORDER', action: 'CREATE' }));
  yield call(history.push, 'list-order');
}

function* searchActiveOrders(action) {
  try {
    const filters = {
      statusId: ['6254e4320a6068062a2893f9', '62b09e02cb49fd43e41b7e6f'],
      ...action.payload,
    };

    const orders = yield call(api.searchActiveOrders, { matches: filters });

    if (orders.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      const mappedOrders = mapOrderList(orders.data);

      yield put(
        actions.updateOrdersGridRows({ orders: mappedOrders, history: false }),
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* searchOrders(action) {
  try {
    yield put(actions.setShowSpinner(true));
    let rawFilters = action.payload.filters;
    const companyId = action.payload.companyId;
    const filters = mapSearchFilters(rawFilters);
    const paging = action.payload.paging;
    const locationId = action.payload.loggedUser.locationId;
    const roleId = action.payload.loggedUser.roleId;
    const isMobile = action.payload.isMobile;
    const getHistory = action.payload.history;
    const tagsList = action.payload.tagsList;

    const totalRows = yield call(
      api.countOrders,
      companyId,
      filters.columns,
      filters.filters,
      locationId,
      roleId,
      isMobile,
      getHistory,
    );

    if (totalRows.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      let orders = [];

      if (!getHistory) {
        totalRows.data.count > 0
          ? (orders = yield call(
              api.searchOrders,
              companyId,
              filters.columns,
              filters.filters,
              totalRows.data.count,
              1,
              locationId,
              roleId,
              isMobile,
              getHistory,
            ))
          : (orders.data = undefined);

        const mappedOrders = mapOrderList(orders.data, tagsList);
        yield put(
          actions.updateOrdersGridRows({
            orders: mappedOrders,
            history: getHistory,
          }),
        );
      } else {
        orders = yield call(
          api.searchOrders,
          filters.columns,
          filters.filters,
          paging.pageSize,
          paging.currentPage,
          locationId,
          roleId,
          isMobile,
          getHistory,
        );

        if (orders.status === 401) {
          yield put(actions.setOpenAuthModal({ open: true }));
        } else {
          yield put(
            actions.updateOrdersPaging({
              name: 'totalRows',
              value: totalRows.data.count,
              history: getHistory,
            }),
          );
          yield put(
            actions.updateOrdersGridRows({
              orders: mapOrderList(orders.data, tagsList),
              history: getHistory,
            }),
          );
        }
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(actions.setShowSpinner(false));
    yield put(actions.updateOrdersIsFirstLoad(false));
  }
}

function* updateOrderTags(action) {
  try {
    const companyId = action.payload.companyId;
    yield put(actions.setShowSpinner(true));
    let tags;

    action.payload.tags
      ? (tags = action.payload.tags.toString())
      : (tags = null);

    const response = yield call(api.updateOrderTags, companyId, {
      orderId: action.payload.orderId,
      tags: tags,
    });

    if (response.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      toaster.push(
        <Message
          type="success"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
          {ORDER_TAGS_SUCESS_UPDATED}
        </Message>,
      );
    }
  } catch (error) {
    console.error('oups, an error has occured!');
    console.error(error);
  } finally {
    if (action.callback) {
      action.callback();
    }

    yield put(actions.setShowSpinner(false));
  }
}

function* getOrderInvoiceURL(action) {
  try {
    yield put(actions.setShowSpinner(true));
    const response = yield call(api.getInvoiceURL, action.payload.id, action.payload.docType);
    openPDF(response.data, action.payload.erp, constants.WINDOW_FEATURES);

    if (response.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      window.open(response.data.url, action.payload.windowFeatures);
    }
  } catch (error) {
    console.error('oups, an error has occured!');
    console.error(error);
  } finally {
    if (action.callback) {
      action.callback();
    }
    yield put(actions.setShowSpinner(false));
  }
}

function* getPurchaseOrderURL(action) {
  try {
    const companyId = action.payload.companyId;
    yield put(actions.setShowSpinner(true));
    const URL = yield call(
      api.getPurchaseOrderURL,
      companyId,
      action.payload.purchaseOrderId,
    );

    if (URL.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      yield put(actions.updateStatePurchaseOrderURL(URL.data.url));
    }
  } catch (error) {
    console.error('oups, an error has occured!');
    console.error(error);
  } finally {
    if (action.callback) {
      action.callback();
    }
    yield put(actions.setShowSpinner(false));
  }
}

export default function* () {
  yield all([
    takeLatest(types.SAVE_ORDER, saveOrder),
    takeLatest(types.GET_ALL_ORDERS, getAllOrders),
    takeLatest(types.LOAD_ITEMS_FOR_EDIT_ORDER, loadItemsEditOrder),
    takeLatest(types.EDIT_ORDER, editOrder),
    takeLatest(types.OPEN_CONFIRM_INVOICE_ACTION, openConfirmInvoiceAction),
    takeLatest(types.INVOICE_FROM_PURCHASE_ORDER, invoiceFromPurchaseOrder),
    takeLatest(types.CREATE_PURCHASE_ORDER, createPurchaseOrder),
    takeLatest(types.CANCEL_ORDER, cancelOrder),
    takeLatest(
      types.GET_PENDING_INVOICING_ORDERS,
      getCustomerPendingInvoicingOrders,
    ),
    takeLatest(types.INVOICE_MULTI_ORDER, invoiceMultiOrder),
    takeLatest(types.SEARCH_ORDERS, searchOrders),
    takeLatest(types.SEARCH_ACTIVE_ORDERS, searchActiveOrders),
    takeLatest(types.UPDATE_ORDER_TAGS, updateOrderTags),
    takeLatest(types.GET_ORDER_INVOICE_URL, getOrderInvoiceURL),
    takeLatest(types.GET_PURCHASE_ORDER_URL, getPurchaseOrderURL),
  ]);
}
