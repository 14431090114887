import { formatToCalendarDate } from "@utils/DataFormat";
import { Schema } from "rsuite";


export function mapperDeliveryData(devliverySlot) {
  return { ...devliverySlot, 
    endDateTime: new Date(devliverySlot.endDateTime),
    startDateTime: new Date(devliverySlot.startDateTime)
   }
}

export const mapDeliverySlotEdit = (devliverySlot) => {
  return { ...devliverySlot, 
    endDateTime: formatToCalendarDate(devliverySlot.endDateTime),
    startDateTime: formatToCalendarDate(devliverySlot.startDateTime)
   }
};

export const getModelDeliverySlot = () => {
  const { NumberType, DateType } = Schema.Types;
  let schema = Schema.Model({
    startDateTime: DateType().isRequired("Requerido"),
    endDateTime: DateType().isRequired("Requerido"),
    usedCapacity: NumberType().isRequired("Requerido"),
    
  })
  return schema;
}