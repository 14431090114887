import queryString from 'query-string';

import axios from '../libs/axios';

const api = {
  getStatus(filters) {
    const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
    return axios.get(`status${params && `?${params}`}`)
  },
};
export default api;
