import { createApi } from '@reduxjs/toolkit/query/react';
import { ordoxBaseQuery } from '../baseQuery';
import { GetEventRequest, GetEventResponse } from './types';

export const eventApi = createApi({
  reducerPath: 'event-api',
  tagTypes: ['event'],
  baseQuery: ordoxBaseQuery(),
  endpoints: builder => ({
    getEvents: builder.query({
      providesTags: ['event'],
      query: (data?: GetEventRequest) => {
        return {
          params: data,
          url: 'events',
          method: 'GET'
        };
      },
      transformResponse: (data: GetEventResponse, _, request) => {
        return {
          limit: request.limit,
          offset: request.offset,
          total: data.total,
          items: data.items,
        };
      },
    }),
  }),
});

export const { useGetEventsQuery, useLazyGetEventsQuery } = eventApi;

export default eventApi;
