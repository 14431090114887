import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import AssignOrderTag from "../../components/Order/AssignOrderTag";
import { getLoggedUser } from "../../selectors/AuthorizationSelector";
import * as TagSelector from "../../selectors/OrderTagsSelector";
import {
  getMainLoggingParams,
  getUserDevice,
} from "../../selectors/SystemSelector";

class AssignOrderTagContainer extends Component {
  constructor(props) {
    super(props);
    this.handleOnHide = this.handleOnHide.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnHide() {
    this.props.setUpdateTagsVisible(false);
    this.props.updateSelectedTags({ orderId: 0, tags: [] });
  }

  handleOnChange(value) {
    this.props.updateSelectedTags({
      tags: value,
    });
  }

  getTagsData() {
    let tagsFullData = [];
    this.props.selectedTags.forEach((tag) => {
      const foundTag = this.props.tagsList.find(
        (tagItem) => tagItem.value == tag
      );
      tagsFullData.push({
        text: foundTag.value,
        color: foundTag.color,
      });
    });
    return tagsFullData;
  }

  handleOnClick() {
    this.props.editOrder({
      editedOrder: { id: this.props.orderId, tags: this.getTagsData() },
    });
    this.handleOnHide();
  }

  render() {
    return (
      <div>
        <AssignOrderTag
          visible={this.props.updateTagsVisible}
          tagsList={this.props.tagsList}
          onHideAssignTag={this.handleOnHide}
          onTagChanged={this.handleOnChange}
          selectedTags={this.props.selectedTags}
          onClickAssignTag={this.handleOnClick}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tagsList: TagSelector.getTagsList(state),
    updateTagsVisible: TagSelector.getUpdateTagsVisible(state),
    selectedTags: TagSelector.getSelectedTagValues(state),
    orderId: TagSelector.getOrderId(state),
    isMobile: getUserDevice(state),
    loggedUser: getLoggedUser(state),
    companyId: getMainLoggingParams(state).companyId,
  };
};

export default connect(mapStateToProps, actions)(AssignOrderTagContainer);
