import dayjs from "@utils/dayjs";
import React from "react";
import { Editors } from "react-data-grid-addons";
import { createSelector } from "reselect";
import * as constants from "../utils/Constants";
import { composeDateAndTime } from "../utils/DataFormat";
import {
  ColumnDeleteFormatter,
  ColumnRightFormatter,
  HeaderCenterFormatter,
} from "../utils/Styling.js";
import { getTaxesList } from "./MasterDataSelector";
import { getActiveUser } from "./SystemSelector";

export const getCustomer = (state) => state.ordox.customer;
const getIsMobile = (state) => state.ordox.isMobile;
export const getPaymentMethods = (state) =>
  state.ordox.paymentMethods.map((paymentMethod) => {
    return {
      value: paymentMethod.id,
      label: paymentMethod.description,
      idERP: paymentMethod.idERP,
    };
  });

export const getDeliverySlots = (state) => {
  // console.log("my slots------------");
  // console.log(state.ordox.selectedDateDeliverySlots);
  return state.ordox.fullDeliverySlots;
};

export const getOrderCaptureAction = (state) => state.ordox.orderCaptureAction;

export const getNewOrderItems = (state) => state.ordox.newOrder.items;
export const getOrderComments = (state) => state.ordox.newOrder.comments;
export const getOrderNotes = (state) => state.ordox.newOrder.notes;
export const getItems = (state) => state.ordox.items;
export const getIdOrderEdit = (state) =>
  state.ordox.newOrder.Id ? state.ordox.newOrder.Id : 0;
export const getNewEditOrder = (state) => state.ordox.newOrder;

// selected values when editting
export const getSelectedCustomer = (state) => {
  return state.ordox.newOrder.customer.value
    ? state.ordox.newOrder.customer
    : { value: null, label: "" };
};

export const getSelectedPaymentMethod = (state) => {
  if (state.ordox.newOrder.paymentMethodId) {
    return {
      value: state.ordox.newOrder.paymentMethodId,
      label: state.ordox.newOrder.paymentMethod.description,
    };
  }
};

export const getSelectedDeliverySlot = (state) => {
  if (state.ordox.newOrder.deliverySlot.startDateTime) {
    const label =
      dayjs(
        state.ordox.newOrder.deliverySlot.startDateTime,
        "YYYY-MM-DD HH:mm"
      ).format("h:mm A") +
      " - " +
      dayjs(
        state.ordox.newOrder.deliverySlot.endDateTime,
        "YYYY-MM-DD HH:mm"
      ).format("h:mm A");

    return {
      value: state.ordox.newOrder.deliverySlot.value,
      label: label,
    };
  }
};

export const getSelectedChannel = (state) => {
  if (state.ordox.newOrder.channelId) {
    let description;
    if (state.ordox.masterData.channels.length > 0) {
      description = state.ordox.masterData.channels.find(
        (channel) => channel.value == state.ordox.newOrder.channelId
      ).label;
    }
    return {
      value: state.ordox.newOrder.channelId,
      label: description,
    };
  }
};

export const getSelectedInvoicingSetting = (state) => {
  if (state.ordox.newOrder.invoicingSettingsId) {
    let description;
    if (state.ordox.masterData.invoicingSettings.length > 0) {
      description = state.ordox.masterData.invoicingSettings.find(
        (setting) => setting.value == state.ordox.newOrder.invoicingSettingsId
      ).label;
    }
    return {
      value: state.ordox.newOrder.invoicingSettingsId,
      label: description,
    };
  }
};

export const getSelectedActionOnShipment = (state) => {
  if (state.ordox.newOrder.actionOnShipment) {
    let description;
    description = constants.ACTIONS_ONSHIPMENT.find(
      (action) =>
        action.value ==
        (state.ordox.newOrder.actionOnShipment.value ||
          state.ordox.newOrder.actionOnShipment)
    ).label;

    return {
      value: state.ordox.newOrder.actionOnShipment,
      label: description,
    };
  }
};

export const getOrderStatus = (state) =>
  state.ordox.status.map((status) => {
    return { value: status.id, label: status.description };
  });

export const getSelectedPromisedDeliveryDate = (state) => {
  return state.ordox.newOrder.promisedDeliveryDateTime;
};

export const getSelectedStatus = (state) => {
  return {
    value: state.ordox.newOrder.StatusId,
    label: state.ordox.newOrder.Status,
  };
};
export const getSelectedOrderComments = (state) =>
  state.ordox.newOrder.comments;
export const getSelectedOrderNotes = (state) => state.ordox.newOrder.notes;
export const getConfirmOrderActionVisible = (state) =>
  state.ordox.confirmOrderActionVisible;

export const getNewOrderComment = (state) =>
  state.reduxForm.OrderDetail && state.reduxForm.OrderDetail.values
    ? state.reduxForm.OrderDetail.values.orderComment
    : null;

// passed to item grid
export const getItemGridColumns = createSelector(
  [getItems, getTaxesList],
  (items, taxes) => {
    const { AutoComplete: AutoCompleteEditor } = Editors;
    const IssueTypeEditor = <AutoCompleteEditor options={items} />;

    const columns = [
      {
        key: "item",
        name: "Producto",
        width: 478,
        editor: IssueTypeEditor,
        headerRenderer: HeaderCenterFormatter,
      },
      {
        key: "itemQuantity",
        name: "Cantidad",
        width: 105,
        editable: true,
        headerRenderer: HeaderCenterFormatter,
        formatter: ColumnRightFormatter,
      },
      {
        key: "itemPrice",
        name: "Precio",
        width: 110,
        editable: true,
        headerRenderer: HeaderCenterFormatter,
        formatter: ColumnRightFormatter,
      },
      {
        key: "discount",
        name: "Descuento %",
        width: 105,
        editable: true,
        headerRenderer: HeaderCenterFormatter,
        formatter: ColumnRightFormatter,
      },
      {
        key: "itemTax",
        name: "Impuesto",
        width: 90,
        editable: true,
        headerRenderer: HeaderCenterFormatter,
        formatter: ColumnRightFormatter,
      },
      {
        key: "itemTotal",
        name: "Total",
        width: 150,
        headerRenderer: HeaderCenterFormatter,
        formatter: ColumnRightFormatter,
      },
      {
        key: "delete",
        name: "",
        width: 40,
        headerRenderer: HeaderCenterFormatter,
        formatter: ColumnDeleteFormatter,
      },
    ];

    return columns;
  }
);

export const getOrderSummary = createSelector(
  [getNewOrderItems],
  (orderItems) => {
    if (orderItems) {
      let subtotal = 0;
      let subtotal2 = 0;
      let totalTax = 0;
      let totalUnits = 0;
      let discount = 0;
      let itemDiscount = 0;

      orderItems.forEach((element) => {
        itemDiscount = parseFloat(
          (
            element.itemPrice *
            element.itemQuantity *
            (element.discount / 100)
          ).toFixed(0)
        );
        subtotal = parseFloat(
          (subtotal + element.itemPrice * element.itemQuantity).toFixed(0)
        );

        subtotal2 = parseFloat(
          (
            subtotal2 +
            (element.itemPrice * element.itemQuantity - itemDiscount)
          ).toFixed(0)
        );

        totalTax = parseFloat(
          (
            totalTax +
            (element.itemPrice * element.itemQuantity - itemDiscount) *
              (element.itemTaxNumeric / 100)
          ).toFixed(0)
        );
        discount = parseFloat((discount + itemDiscount).toFixed(0));

        // is item measurable?
        if (element.unitsCount) {
          totalUnits += eval(element.itemQuantity);
        }
      });

      const total = subtotal2 + totalTax;

      let summary = {
        subtotal,
        discount,
        subtotal2,
        totalTax,
        total,
        totalUnits,
      };
      return summary;
    } else {
      return {
        subtotal: 0,
        discount: 0,
        subtotal2: 0,
        totalTax: 0,
        total: 0,
        totalUnits: 0,
      };
    }
  }
);

export const createOrderDto = createSelector(
  [getNewEditOrder, getOrderSummary, getSelectedCustomer, getActiveUser],
  (newEditOrder, orderSummary, selectedCustomer, activeUser) => {
    if (newEditOrder) {
      const itemsSelected = newEditOrder.items.map((item) => {
        return {
          id: item.id,
          productId: item.id,
          quantity: item.itemQuantity,
          priceBeforeTax: item.itemPrice,
          priceAfterTax: item.itemPrice * (1 + item.itemTaxNumeric / 100),
          discount: item.discount,
          taxIdERP: item.taxIdERP,
          tax: item.itemTaxNumeric,
        };
      });

      let orderDto = {
        id: newEditOrder.id,
        companyId: newEditOrder.companyId,
        orderId: newEditOrder.orderId ? newEditOrder.orderId : 0,
        discount: orderSummary.discount,
        customerId: newEditOrder.customer ? newEditOrder.customer.value : null,
        customer: {
          id: newEditOrder.customer.value,
        },
        paymentMethodId: newEditOrder.paymentMethodId,
        channelId: newEditOrder.channelId,
        invoicingSettingsId: newEditOrder.invoicingSettingsId,
        actionOnShipment: newEditOrder.actionOnShipment,
        locationId: newEditOrder.locationId,
        paymentIdERP: newEditOrder.idERP,
        promisedDeliveryDate: composeDateAndTime(
          newEditOrder.promisedDeliveryDate,
          newEditOrder.deliverySlot.endDateTime
        ),
        promisedDeliveryDateTime: newEditOrder.deliverySlot.endDateTime,
        totalAfterTax: orderSummary.total,
        totalBeforeTax: orderSummary.subtotal2,
        totalUnits: orderSummary.totalUnits,
        comments: newEditOrder.comments,
        notes: newEditOrder.notes,
        items: itemsSelected,
        deletedItems: newEditOrder.deletedItems,
        invoiceId: newEditOrder.invoiceId,
        invoiceIdERP: newEditOrder.invoiceIdERP,
        purchaseOrderId: newEditOrder.purchaseOrderId,
        purchaseOrderIdERP: newEditOrder.purchaseOrderIdERP,
        deliverySlotId: newEditOrder.deliverySlotId,
        orderNumber: newEditOrder ? newEditOrder.orderNumber : undefined,
        userId: activeUser.id,
        customerPaymentTerms: selectedCustomer
          ? selectedCustomer.paymentTerms
          : undefined,
        customerName: selectedCustomer ? selectedCustomer.fullName : undefined,
      };
      return orderDto;
    }
  }
);

export const getOrderStatusFiltered = createSelector(
  [getSelectedStatus, getOrderStatus],
  (selectedStatus, orderStatus) => {
    let orderStatusFiltered = orderStatus.filter(
      (x) => x.value >= selectedStatus.value
    );
    if (selectedStatus.value > constants.EN_PROCESO) {
      orderStatusFiltered = orderStatusFiltered.filter(
        (x) => x.value !== constants.CANCELADA
      );
    }
    return orderStatusFiltered;
  }
);
