import React from "react";
import { Progress } from "rsuite";

const ProgressComponent = ({ capacity, usedCapacity }) => {
  if (usedCapacity === undefined) {
    usedCapacity = 0
  }
  const percentage = usedCapacity / parseInt(capacity) * 100
  let color = "#00BB2D"
  
  if (percentage > 50 && percentage < 100) {
    color = '#ffc107'
  } else if (percentage >= 100) {
    color = '#ff0000'
  }

  return (
    <div className="flex">
      <Progress.Line className="p-0" status="active" strokeColor={color} percent={percentage} showInfo={false} />
      <p className={" text-wrap text-xs pt-1 min-w-[60px] text-center	"}> {`${usedCapacity} / ${capacity}`} </p>
    </div>
  );
}

export default ProgressComponent;
