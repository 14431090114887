
import { PriceListItem } from '@api/query/priceListApi';
import { PaginationQuery } from '@api/query/types';
import {
  DataGrid,
  DataGridContext,
  DataGridRowContent,
  DataGridToolbar,
} from '@components/DataGrid';
import useLocationState from '@router/useLocationState';
import { PRICE_LIST_EXCLUDED_ID } from '@utils/Constants';
import React, { useCallback, useMemo, useState } from 'react';
import { BiCheckCircle, BiTrash } from 'react-icons/bi';
import { FormattedMessage } from 'react-intl';
import { Column, Row } from 'react-table';
import { Button, Checkbox, IconButton } from 'rsuite';
import ListItem from 'rsuite/esm/List/ListItem';
import { useLazyPagination } from './UseLazyPagination';
import { useLazyFindCustomerQuery, useUpdateCustomerMutation } from '@api/query/customerApi/customerApi';
import { Customer } from '@api/query/customerApi/types';

export const CustomerListView = () => {
  const priceList = useLocationState<PriceListItem>()!;

  const { id: priceListId } = priceList;

  const [findCustomerTrigger_, result] = useLazyFindCustomerQuery();

  const findCustomerTrigger = useCallback(
    (pagination: PaginationQuery) =>
      findCustomerTrigger_({
        ...pagination,
        listPriceId: priceListId,
      }),
    [findCustomerTrigger_, priceList],
  );

  const [customerMutate, customerMutateResult] = useUpdateCustomerMutation();

  const deleteCustomersSelected = useCallback(async (rows: Row<Customer>[]) => {
    const it = await Promise.allSettled(
      rows.map(row => {
        const { id } = row.original;
        return customerMutate({
          id,
          data: {
            source: 'savia',
            listPriceId: null,
          }
        }).unwrap();
      }),
    );
  }, []);

  const { setViewRange, virtualArray } = useLazyPagination({
    trigger: findCustomerTrigger,
    result,
  });

  const [selectable, setSelectable] = useState(false);

  const columns = useMemo<Column<Customer>[]>(
    () => [{ id: 'id', width: '100px' }],
    [],
  );

  const rowContent = useCallback<DataGridRowContent<Customer>>(
    (index, row, context) => (
      <PriceListCustomerRow row={row} context={context} />
    ),
    [],
  );

  const topToolbar: DataGridToolbar<Customer> = useCallback(
    ({ selectable, selectedFlatRows, toggleAllRowsSelected }) => {
      return (
        <div className="flex items-center gap-4">
          {selectable && (
            <IconButton
              appearance="primary"
              icon={<BiTrash />}
              circle
              color="red"
              disabled={selectedFlatRows.length === 0}
              loading={customerMutateResult.isLoading}
              onClick={async () => {
                await deleteCustomersSelected(selectedFlatRows);
                setSelectable(false);
              }}
            />
          )}
          <Button
            appearance="link"
            onClick={() => {
              toggleAllRowsSelected(false);
              setSelectable(v => !v);
            }}
            className="flex items-center gap-1">
            <FormattedMessage id={selectable ? 'ready' : 'select'} />
            <BiCheckCircle className="inline text-lg" />
          </Button>
        </div>
      );
    },
    [customerMutateResult.isLoading],
  );

  return (
    <>
      {virtualArray.ref.length === 0 && (
        <div className="p-5 h-full flex items-center font-bold text-xl text-center">
          <FormattedMessage id="price-list.customers.empty" />
        </div>
      )}
      {virtualArray.ref.length > 0 && (
        <DataGrid
          className="h-full"
          data={virtualArray.ref}
          totalCount={virtualArray.ref.length}
          columns={columns}
          topToolbar={topToolbar}
          rowContent={rowContent}
          disableFilters
          selectable={selectable}
          atTopThreshold={25}
          atBottomThreshold={25}
          disableGlobalFilter
          rangeChanged={setViewRange}
          overscan={20}
          defaultViewport="narrow"
        />
      )}
    </>
  );
};

type PriceListCustomerRowProps = {
  row: Row<Customer>;
  context: DataGridContext<Customer>;
};

export const PriceListCustomerRow = ({
  row,
  context,
}: PriceListCustomerRowProps) => {
  const customer = row.original;

  const { fullName } = customer;

  return (
    <ListItem as="label" className="flex items-center px-4 py-2 text-base">
      {context.selectable && (
        <Checkbox
          className="-my-4 -ml-3"
          checked={row.isSelected}
          onChange={(_, checked) => row.toggleRowSelected(checked)}
        />
      )}
      <span className="">{fullName}</span>
    </ListItem>
  );
};
