import orderApi_ from '@api/query/orderApi/orderApi';
import { mapOrderItems } from '@utils/Mappers/OrderMappers';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Message, toaster } from 'rsuite';
import * as actions from '../actions';
import types from '../actions/ActionTypes';
import api from '../api/OrderTrackingApi';
import orderApi from '../api/_OrderApi';
import {
  MAX_BATCH_PROCESSING_EXPORT_PDF,
  MAX_LENGTH_BACKGROUND_PROCESSING,
  NOTIFICATION_DURATION,
} from '../utils/Constants';
import {
  BATCH_PROCESSING_LIMIT_EXCEEDED,
  EXPORT_BEING_PROCESSED,
  EXPORT_SELECT_ORDER,
  ORDER_STATUS_UPDATED_SUCCESS,
} from '../utils/Notifications';
import { triggerOrderEvent } from './OrderEventSaga';

function* getOrderTracking({ payload: { action, orderIds, ...filters } }) {
  try {
    if (orderIds.length > 0) {
      if (orderIds.length <= MAX_LENGTH_BACKGROUND_PROCESSING) {
        yield put(actions.setShowSpinner(true));
      } else if (orderIds.length < MAX_BATCH_PROCESSING_EXPORT_PDF) {
        toaster.push(
          <Message
            type="success"
            showIcon
            closable
            duration={NOTIFICATION_DURATION}>
            {EXPORT_BEING_PROCESSED}
          </Message>,
        );
      } else {
        toaster.push(
          <Message
            type="error"
            showIcon
            closable
            duration={NOTIFICATION_DURATION}>
            {BATCH_PROCESSING_LIMIT_EXCEEDED}
          </Message>,
        );
        return [];
      }

      const orderTrackingList = [];

      for (let id of orderIds) {
        const {
          data: { items, ...order },
        } = yield call(orderApi.findOrder, id, filters);
        const { data } = yield call(api.getTrackingFullData, id, filters);

        orderTrackingList.push({
          order,
          orderItems: mapOrderItems(items),
          orderTracking: data.items,
        });
      }

      yield put(
        actions.showOrderTracking({
          action,
          data: orderTrackingList,
        }),
      );
    } else {
      toaster.push(
        <Message
          type="warning"
          showIcon
          closable
          duration={NOTIFICATION_DURATION}>
          {EXPORT_SELECT_ORDER}
        </Message>,
      );
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }

    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
    if (action.callback) {
      action.callback(action ? { orderBy: 'startDateTime:DESC' } : '');
    }
  }
}

function* getOrderData(action) {
  try {
    const orderTrackingInformation = yield call(
      api.getOrderTrackingInformation,
      action.payload,
    );

    if (orderTrackingInformation.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      yield put(actions.setOrderTracking(orderTrackingInformation.data));
    }
  } catch (error) {
  } finally {
    yield put(actions.setShowSpinner(false));

    // if (action.callback) {
    //   action.callback();
    // }
  }
}

function* openUpdateOrderStatus(action) {
  try {
    const order = action.payload;
    yield put(actions.showUpdateOrderStatus(order));
  } catch (error) {
    console.log(error);
  }
}

function* updateOrderStatus({
  payload: { orderId, orderEvent, originalData, ...data },
}) {
  try {
    yield call(api.updateOrderStatus, orderId, data);

    if (originalData) {
      originalData.status = data.status;
    }

    toaster.push(
      <Message
        type="success"
        showIcon
        closable
        duration={NOTIFICATION_DURATION}>
        {ORDER_STATUS_UPDATED_SUCCESS}
      </Message>,
    );

    if (
      ['DELIVERY', 'SHIPMENT'].includes(orderEvent.statusRole) &&
      ['INVOICE', 'PURCHASE_ORDER'].includes(orderEvent.newStatus)
    ) {
      yield call(triggerOrderEvent, { payload: orderEvent.data });
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }

    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
    yield put(orderApi_.util.invalidateTags(['order']));
  }
}

export default function* () {
  yield all([
    takeLatest(types.GET_ORDER_TRACKING, getOrderTracking),
    takeLatest(types.OPEN_UPDATE_ORDER_STATUS, openUpdateOrderStatus),
    takeLatest(types.UPDATE_ORDER_STATUS, updateOrderStatus),
    takeLatest(types.GET_ORDER_DATA, getOrderData),
  ]);
}
