import {put,takeLatest,call,all } from 'redux-saga/effects';
import types from '../actions/ActionTypes';
import api from '../api/ProductionListApi';
import * as actions from '../actions';
import { toaster, Message } from 'rsuite';
import * as constants from '../utils/Constants';
import * as Notifications from '../utils/Notifications';
import * as Mappers from '../utils/Mappers/ProductionListMapper';
import * as OrdoxTracker from '../eventManagement/OrdoxTracker';

function* getProductionList(action)
{
   try
   {
       const companyId = action.payload.loggingParams.companyId;
        yield put(actions.setShowSpinner(true));
        const response = yield call(api.getProductionList,companyId,action.payload.value);

        if(response && response.status === 200)
        {
            const productionList = Mappers.mapOrdersToProductionList(response.data);
            yield put (actions.setProductionListData(productionList));

            // an event has occured, log it!
            const eventParams = {main:action.payload.loggingParams}
            OrdoxTracker.logOrdoxEvent(OrdoxTracker.events.productionListViewed,eventParams);
        }
        else if (response && response.status === 401)
        {
            yield put(actions.setOpenAuthModal({ open: true }));
        }
        else
        {
            toaster.push(
                <Message type="error" showIcon closable duration={constants.NOTIFICATION_DURATION}>{response.data}</Message>
            );
        }

   } 
   catch(error)
   {
    toaster.push(
        <Message type="error" showIcon closable duration={constants.NOTIFICATION_DURATION}>{Notifications.GENERAL_ERROR_MESSAGE}</Message>
    );
    console.log(error);
   }finally{
    yield put(actions.setShowSpinner(false));
   }
}

export default function*(){
    yield all([
        takeLatest(types.GET_PRODUCTION_LIST,getProductionList),
    ]);
}