import dayjs from '@utils/dayjs';
import { DELIVERY_SLOTS_DATETIME_SECONDARY_FORMAT, DELIVERY_SLOTS_DATETIME_STANDARD_FORMAT } from '../Constants';
import { formatToCalendarDate } from '../DataFormat';

export const validateSlots = (slots) => {

    let validSlots = [];
    let invalidSlots = [];
    let totalSlots = {};
    let validationResult = undefined;

    slots.forEach(slot => {
        validationResult = checkValues(slot['start'],slot['end'],slot['capacity']);
        if(validationResult.valid !== null)
        {
            if(validationResult.valid){
                validSlots.push(slot);
            }else{
                invalidSlots.push(validationResult);
            }
        }
    });

    totalSlots.valid = validSlots;
    totalSlots.invalid = groupMessages(invalidSlots);
    return totalSlots;
}

const checkValues = (start,end,capacity) => {

    let result = {valid:true, message:''};

    const isValidStart = validateDate(start);
    const isValidEnd = validateDate(end);
    const isValidCapacity = (Number.isInteger(capacity));

    if(!isValidStart){
        result.valid = false;
        result.message = 'Fecha/hora inicio no es válida - \n' + start + '\n';
    }
    if(!isValidEnd){
        result.valid = false;
        result.message += 'Fecha/hora fin no es válida - ' + end + '\n';
    }
    if(!isValidCapacity){
        result.valid = false;
        result.message += 'Capacidad no es válida - ' + capacity + '\n';
    }
    if(start === undefined && end === undefined && capacity === undefined)
    {
        result.valid = null;
    }
    return result;
}

const validateDate = date => {

    let validDate = false;

    const formattedDate = dayjs(date,["yyyy-mm-dd hh:mm","yyyy/mm/dd hh:mm"]);

    if(formattedDate.isValid()){ 
        validDate = true;
    }

    else{
        // try standard format first
        let parsedDate = Date.parse(date,DELIVERY_SLOTS_DATETIME_STANDARD_FORMAT);

        if(!isNaN(parsedDate)){
            validDate = true;
        } else{
            // give another format a second chance
            parsedDate = Date.parse(date,DELIVERY_SLOTS_DATETIME_SECONDARY_FORMAT);
            if(!isNaN(parsedDate)){
                validDate = true;
            } else{
                // if a numeric value is passed here, it might be a excel-conversion
                if(!isNaN(date)){
                    const convertedDate = convertExcelDate(date);
                    parsedDate = Date.parse(convertedDate,DELIVERY_SLOTS_DATETIME_STANDARD_FORMAT);
                    if(!isNaN(parsedDate)){
                        validDate = true;
                    }
                }
            }
        }        
    }

    return validDate;
}

export const convertExcelValues = values => {

    let start,end;
    let convertedValues = [];
    values.forEach(item => {
        start = !isNaN(item[0]) ? convertExcelDate(item[0]) : item[0];
        end = !isNaN(item[1]) ? convertExcelDate(item[1]) : item[1];
        convertedValues.push({'start':start, 'end':end, 'capacity':item[2]});
    });
    return convertedValues;
}

const groupMessages = (invalid) => {
    let validationMessage = '';

    invalid.forEach(item => {
        validationMessage += item.message;
    });
    return validationMessage;
}

const convertExcelDate = value => {
    
    // var tmp =  new Date((value - (25567 + 2))*86400*1000);
    var tmp =  new Date(Math.round((value - (25567 + 2))*86400)*1000); 
    var localTime = new Date(tmp.getTime() + (new Date()).getTimezoneOffset() * 60000);
    return formatToCalendarDate(localTime);
}