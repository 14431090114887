import { createApi } from '@reduxjs/toolkit/query/react';
import { ordoxBaseQuery } from '../baseQuery';
import { InvoiceOrdersRequest, InvoiceOrdersResponse } from './types';

export const orderEventApi = createApi({
  reducerPath: 'orderEvent-api',
  tagTypes: ['order'],
  baseQuery: ordoxBaseQuery(),
  endpoints: builder => ({
    invoiceOrders: builder.mutation<InvoiceOrdersResponse, InvoiceOrdersRequest>({
      invalidatesTags: ['order'],
      query: data => ({
        url: '/orders/invoices/_bulk',
        method: 'PATCH',
        body: { ...data },
      }),
    }),
    invoiceConsolidated: builder.mutation<InvoiceOrdersResponse, InvoiceOrdersRequest>({
      invalidatesTags: ['order'],
      query: data => ({
        url: '/orders/invoices/consolidates',
        method: 'PATCH',
        body: data,
      }),
    }),
    creditNote: builder.mutation<InvoiceOrdersResponse, InvoiceOrdersRequest>({
      invalidatesTags: ['order'],
      query: data => ({
        url: '/credit-notes',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useInvoiceOrdersMutation,
  useInvoiceConsolidatedMutation,
  useCreditNoteMutation
} = orderEventApi;

export default orderEventApi;
