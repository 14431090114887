import queryString from 'query-string';

import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ordoxBaseQuery } from './baseQuery';

export type Invoice = {
  id: string;
  idERP: string;
  description: string;
  companyId: string;
  origin: string[];
  generateStamp: boolean;
};

type FindInvoicingSettingRequest = {
  active?: boolean;
};
type FindInvoicingSettingResponse = {
  total: number;
  items: Invoice[];
};

export const invoicingSettingApi = createApi({
  reducerPath: 'invoicingSetting-api',
  baseQuery: ordoxBaseQuery(),
  endpoints: builder => ({
    findInvoicingSettings: builder.query<
      FindInvoicingSettingResponse,
      FindInvoicingSettingRequest
    >({
      query: (filters = {}) => {
        const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
        return {
          url: `invoicing-settings${params ? `?${params}` : ''}`,
          method: 'GET'
        }
      },
    }),
  }),
});

export const { useFindInvoicingSettingsQuery } = invoicingSettingApi;

export default invoicingSettingApi;
