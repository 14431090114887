import React from 'react';
import { Panel } from "rsuite";

export function Card(props) {
    return (
        <Panel {...props} bordered>
            {props.children}
        </Panel>
    )
}
