
export const mapOrdersToProductionList = (orders) => 
{
    let ordersByDate = {};
    let productionList = [];

    orders.forEach(order => {
        const date = order.promisedDeliveryDateTime.split('T')[0];
        if(ordersByDate[date])
        {
            ordersByDate[date].push({date: date, items: order.items});
        }
        else
        {
            ordersByDate[date] = [{date: date, items: order.items}];
        }
    });

    for(let deliveryDate of Object.values(ordersByDate))
    {
        let dateOrders = [];
        deliveryDate.forEach(order => {
            order.items.forEach(orderItem => {
                if(!dateOrders[orderItem.product.name])
                {
                    dateOrders[orderItem.product.name] = {date: order.date, product: orderItem.product.name , quantity: 0, amount: 0}
                }
                dateOrders[orderItem.product.name].quantity += orderItem.quantity;
                dateOrders[orderItem.product.name].amount += orderItem.priceBeforeTax * orderItem.quantity;
            });
        });
        
        for(let productionListItem of Object.values(dateOrders))
        {
            productionList.push(productionListItem);
        }
        
    }
    return productionList.sort(compareDates);
}

function compareDates(a, b)
{
    const diffA = a.date;
    const diffB = b.date;

    let comparison = 0;
    if (diffA < diffB)
        comparison = -1;
    else if (diffA > diffB)
        comparison = 1;
  
    return comparison;
}

export const getOutputExcelProductionList = (rawData) => 
{
    return rawData.map(item => {
        const formattedData = {};
        formattedData.PromesaEntrega = item.date;
        formattedData.Producto = item.product;
        formattedData.Cantidad = item.quantity;
        formattedData.Valor = item.amount;
        return formattedData;
    });
}