import { styled } from 'goober';
import React from 'react';
import { FormatNumberOptions, FormattedNumberParts } from 'react-intl';

const FormattedNumberStyled = styled('span')`
  display: inline-flex;
  align-items: baseline;
  user-select: all;

  & .part {
    order: 10;
  }
  & .part-currency {
    order: 1;
    margin-right: 0.2rem;
  }
  & .part-fraction {
    font-size: 0.92em;
  }
`;

export type FormattedNumberProps = Omit<FormatNumberOptions, 'style'> & {
  className?: string;
  style?: React.CSSProperties;
  value: number | string;
  numberStyle?: FormatNumberOptions['style'];
  render?: {
    [Part in Intl.NumberFormatPart as Part['type']]?: (
      value: string,
      index: number,
      parts: Intl.NumberFormatPart[],
    ) => React.ReactNode;
  };
};

const defaultPartRender: FormattedNumberProps['render'] = {
  nan: () => '－',
  group: () => <span className="pl-1" />,
};

const FormattedNumber: React.FC<FormattedNumberProps> = ({
  className,
  style,
  numberStyle,
  value,
  render,
  ...numberOptions
}) => {
  render = {
    ...defaultPartRender,
    ...render,
  };

  return (
    <FormattedNumberParts
      value={Number(value)}
      minimumIntegerDigits={1}
      style={numberStyle}
      {...numberOptions}>
      {parts => (
        <FormattedNumberStyled
          className={`formatted-number ${
            className || 'text-base text-current'
          }`}
          style={style}>
          {parts.map(({ value, type }, index) => (
            <span key={index} className={`part part-${type}`}>
              {render[type]?.(value, index, parts) ?? value}
            </span>
          ))}
        </FormattedNumberStyled>
      )}
    </FormattedNumberParts>
  );
};

export default FormattedNumber;
