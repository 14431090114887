import LegacyFileExcelOIcon from "@rsuite/icons/legacy/FileExcelO";
import React from 'react';
import { Panel } from 'rsuite';
import { Col, Row } from 'rsuite-migration';
import '../../styles/General.css';

const FileCenterContainer = () => {
    return (
        <Panel header="Plantillas para carga de datos" collapsible bordered>
            <div className="pb-4">
                <Row>
                    <Col>
                        <LegacyFileExcelOIcon />
                        <a href="https://firebasestorage.googleapis.com/v0/b/ordoxco.appspot.com/o/FileCenter%2Fordox_plantilla_franjasHorarias%20v1.5.xlsx?alt=media&token=bd4e36f4-0b8c-41f8-86cc-f85d9ee70030" className="stretched-link" target="_blank"> &nbsp; Franjas horarias</a>
                    </Col>
                </Row>
            </div>
        </Panel>
    );
}

export default FileCenterContainer;

