import React from 'react';
import { RecordAny } from '../DataGrid/index';

export function mergeStyle(
  ...sources: {
    style?: React.CSSProperties;
    className?: string;
    [prop: string]: any;
  }[]
): RecordAny {
  return sources.reduce(
    (obj, props) => {
      if (!props) return obj;

      const { className, style, ...restProps } = props;

      if (className) obj.className += ` ${className || ''}`;
      if (style) Object.assign(obj.style!, style);

      Object.assign(obj, restProps);

      return obj;
    },
    { className: '', style: {} as React.CSSProperties },
  );
}
