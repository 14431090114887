type ApiCollection<Apis extends any[]> = {
  reducers: {
    [Api in Apis[number] as Api['reducerPath']]: Api['reducer'];
  };
  middlewares: Apis[number]['middleware'][];
};

export default function getApiCollection<T extends any[]>(
  apis: T,
): ApiCollection<T> {
  return apis.reduce(
    (apis, api) => {
      apis.reducers[api.reducerPath] = api.reducer;
      apis.middlewares.push(api.middleware);

      return apis;
    },
    { reducers: {}, middlewares: [] },
  );
}
