import React from 'react';

import { formatCurrencySpanishCO } from '@utils/DataFormat';
import { roundResult } from '@utils/configERP';


export function OrderItemTable({ item, isMobile }) {
	const discountNumber = parseFloat(item?.discount)
	const discountResult = item.priceAfterTax * (discountNumber / 100)
	return (
		<tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
			<td className="px-6 py-2 text-wrap text-sm font-light text-gray-900">
				{item.productName}
				{item.notes && (
					<p className="text-xs text-blue-700">{item.notes}</p>
				)}
			</td>
			<td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap text-right">
				{item.quantity}
			</td>
			{!isMobile &&
				<td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap text-right">
          {formatCurrencySpanishCO(
            roundResult(item.priceAfterTax - discountResult)
					)}
					
					{item?.discount > 0 && (
						<div className="flex justify-end">
							<p className="text-xs text-blue-700 line-through">
                {formatCurrencySpanishCO(
                  roundResult(item.priceAfterTax)
                )}
							</p>
							<p className="text-xs text-blue-700 mt-0 ml-1">
								{`(${item?.discount}%)`}
							</p>
						</div>
					)}
				</td>
			}
			{!isMobile &&
				<td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap text-right">
					{formatCurrencySpanishCO(
						roundResult(item.priceBeforeTax * (1 - item?.discount / 100) * (item?.tax / 100))
					)}
				</td>
			}
			<td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap text-right">
				{formatCurrencySpanishCO(
					item.priceAfterTax *
					(1 - item.discount / 100) *
					item.quantity,
				)}
			</td>
		</tr>
	)
}