import { createSelector } from "reselect";
import { getMappedCalendarOrders } from "../utils/Mappers/OrderMappers";

export const getUserIsMobile = (state) => state.ordox.isMobile;
export const getConfirmInvoiceActionVisible = (state) =>
  state.ordox.invoiceConfirmActionVisible;

export const getOrderGridColumns = (state) => {
  // const maxTags = getMaximumTags(state);
  const maxTags = 0;
  let tagsWidth = 0;
  maxTags > 1 ? (tagsWidth = maxTags * 28 + 12) : (tagsWidth = 50);

  if (maxTags > 0) {
    const columns = [
      {
        key: "orderId",
        headerAlign: "center",
        headerText: "Ordox#",
        flexGrow: 2,
        width: 63,
        headerClassName: "grid-header",
        contentClassName: "grid-row-center",
      },
      {
        key: "customerName",
        headerAlign: "center",
        headerText: "Cliente",
        flexGrow: 2,
        width: 350,
        headerClassName: "grid-header",
        contentClassName: "grid-row-left",
      },
      {
        key: "status",
        headerAlign: "center",
        headerText: "Estado",
        flexGrow: 2,
        width: 150,
        headerClassName: "grid-header",
        contentClassName: "grid-row-center",
      },
      {
        key: "promisedDeliveryDateView",
        headerAlign: "center",
        headerText: "Fecha entrega",
        flexGrow: 2,
        width: 137,
        headerClassName: "grid-header",
        contentClassName: "grid-row-center",
      },
      {
        key: "totalAfterTaxView",
        headerAlign: "center",
        headerText: "Total $",
        flexGrow: 2,
        width: 117,
        headerClassName: "grid-header",
        contentClassName: "grid-row-right",
      },
      {
        key: "totalUnits",
        headerAlign: "center",
        headerText: "Uds.",
        flexGrow: 2,
        width: 60,
        headerClassName: "grid-header",
        contentClassName: "grid-row-right",
      },
      {
        key: "paymentMethod",
        headerAlign: "center",
        headerText: "Pago",
        flexGrow: 2,
        width: 105,
        headerClassName: "grid-header",
        contentClassName: "grid-row-center",
      },
      // {
      //   key: "tags",
      //   headerAlign: "center",
      //   headerText: "Tags",
      //   flexGrow: 2,
      //   width: tagsWidth,
      //   headerClassName: "grid-header",
      //   contentClassName: "grid-row-center",
      // },
    ];
    return columns;
  } else {
    const columns = [
      {
        key: "orderId",
        headerAlign: "center",
        headerText: "Ordox#",
        flexGrow: 2,
        width: 75,
        headerClassName: "grid-header",
        contentClassName: "grid-row-center",
      },
      {
        key: "customerName",
        headerAlign: "center",
        headerText: "Cliente",
        flexGrow: 2,
        width: 370,
        headerClassName: "grid-header",
        contentClassName: "grid-row-left",
      },
      {
        key: "status",
        headerAlign: "center",
        headerText: "Estado",
        flexGrow: 2,
        width: 150,
        headerClassName: "grid-header",
        contentClassName: "grid-row-center",
      },
      {
        key: "promisedDeliveryDateView",
        headerAlign: "center",
        headerText: "Fecha entrega",
        flexGrow: 2,
        width: 155,
        headerClassName: "grid-header",
        contentClassName: "grid-row-center",
      },
      {
        key: "totalAfterTaxView",
        headerAlign: "center",
        headerText: "Total $",
        flexGrow: 2,
        width: 117,
        headerClassName: "grid-header",
        contentClassName: "grid-row-right",
      },
      {
        key: "totalUnits",
        headerAlign: "center",
        headerText: "Uds.",
        flexGrow: 2,
        width: 60,
        headerClassName: "grid-header",
        contentClassName: "grid-row-right",
      },
      {
        key: "paymentMethod",
        headerAlign: "center",
        headerText: "Pago",
        flexGrow: 2,
        width: 105,
        headerClassName: "grid-header",
        contentClassName: "grid-row-center",
      },
    ];
    return columns;
  }
};

const getMaximumTags = (state) => {
  const gridRows = getSortedOrders(state);
  const validTags = gridRows.filter((row) => row["TagsColor"].length > 0);
  const tags = validTags.map((tag) => tag["TagsColor"].length);
  const max = Math.max.apply(Math, tags);
  return max;
};

export const getOrdersHistoryGridColumns = () => {
  const columns = [
    {
      key: "Id",
      headerAlign: "center",
      headerText: "Ordox #",
      flexGrow: 2,
      width: 90,
      headerClassName: "grid-header",
      contentClassName: "grid-row-center",
    },
    {
      key: "CustomerName",
      headerAlign: "center",
      headerText: "Cliente",
      flexGrow: 2,
      width: 365,
      headerClassName: "grid-header",
      contentClassName: "grid-row-left",
    },
    {
      key: "Status",
      headerAlign: "center",
      headerText: "Estado",
      flexGrow: 2,
      width: 130,
      headerClassName: "grid-header",
      contentClassName: "grid-row-center",
    },
    {
      key: "PromisedDeliveryDateView",
      headerAlign: "center",
      headerText: "Fecha entrega",
      flexGrow: 2,
      width: 140,
      headerClassName: "grid-header",
      contentClassName: "grid-row-center",
    },
    {
      key: "TotalAfterTaxView",
      headerAlign: "center",
      headerText: "Total $",
      flexGrow: 2,
      width: 150,
      headerClassName: "grid-header",
      contentClassName: "grid-row-right",
    },
    {
      key: "TotalUnits",
      headerAlign: "center",
      headerText: "Total uds.",
      flexGrow: 2,
      width: 80,
      headerClassName: "grid-header",
      contentClassName: "grid-row-right",
    },
    {
      key: "PaymentMethod",
      headerAlign: "center",
      headerText: "Método pago",
      flexGrow: 2,
      width: 110,
      headerClassName: "grid-header",
      contentClassName: "grid-row-center",
    },
  ];
  return columns;
};

export const getMobileGridColumns = () => {
  const columns = [
    { key: "Id", align: "center", headerText: "#", flexGrow: 1 },
    {
      key: "CustomerName",
      align: "center",
      headerText: "Cliente",
      flexGrow: 2,
    },
    { key: "Status", align: "center", headerText: "Estado", flexGrow: 1 },
  ];
  return columns;
};

export const getExpandedRowKeys = (state) =>
  state.ordox.gridsHandling.expandedRowKeys;

export const getOrders = (state) => state.ordox.orders.list;
export const getOrdersHistory = (state) => state.ordox.ordersHistory.list;
export const getSlotStatus = (state) => state.ordox.slotStatus;
export const getConfirmOrderActionVisible = (state) =>
  state.ordox.confirmOrderActionVisible;

export const getSortedOrders = (state) => {
  if (state.ordox.orders.list) {
    return state.ordox.orders.list.slice().sort(compare);
  }
};

function compare(a, b) {
  const diffA = a.timeRemaining;
  const diffB = b.timeRemaining;

  if (diffA > diffB) return 1;
  if (diffA < diffB) return -1;

  if (a.Id < b.Id) return 1;
  if (a.Id > b.Id) return -1;
}

export const getRedirectTo = (state) => state.ordox.redirectTo;
export const getOrderFilter = (state) => state.ordox.ordersFilters;
const getDeliverySlots = (state) => state.ordox.fullDeliverySlots;

// paging capabilities
export const getOrdersPaging = (state, params, history) => {
  let paging;
  !history
    ? (paging = state.ordox.orders.paging[params])
    : (paging = state.ordox.ordersHistory.paging[params]);
  return paging;
};
export const getOrdersFilters = (state, history) => {
  let filters;
  !history
    ? (filters = state.ordox.orders.filters)
    : (filters = state.ordox.ordersHistory.filters);
  return filters;
};

export const getCalendarOrders = createSelector(
  [getOrders, getDeliverySlots],
  (orders, deliverySlots) => {
    return getMappedCalendarOrders(orders, deliverySlots);
  }
);

export const getOrdersCheckedKeys = (state) => state.ordox.orders.checkedKeys;
export const getExportOrdersDisabled = (state) =>
  state.ordox.orders.exportDisabled;
export const getOrderInvoiceURL = (state) => state.ordox.orders.orderInvoiceURL;
export const getPurchaseOrderURL = (state) =>
  state.ordox.orders.purchaseOrderURL;
export const getOrdersIsFirstLoad = (state) => state.ordox.orders.isFirstLoad;
