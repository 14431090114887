import store from '../store';
import * as constants from '../utils/Constants';

export function getNotificationMessage(type) {
  let message = '';

  switch (type) {
    case constants.ORDER_CREATED_SUCCESS1:
      message = 'Savia #';
      return message;

    case constants.ORDER_CREATED_SUCCESS2:
      message = ' creada correctamente';
      return message;

    case constants.ORDER_CREATED_FAILURE:
      message = 'Oups! Ha ocurrido un error al crear la orden :(';
      return message;

    case constants.MASTER_DATA_LOADING_FAILURE:
      message = 'Oups! Algo falló al buscar nuestros datos :(';
      return message;

    case constants.ORDER_EDITED_SUCCESS:
      message = 'La orden ha sido actualizada correctamente';
      return message;

    case constants.ORDER_STATUS_UPDATED_SUCCESS:
      message = 'El estado de la orden ha sido actualizado!';
      return message;

    case constants.ORDER_EDIT_FAILURE:
      message = 'Oups! Ha ocurrido un error al editar la orden :(';
      return message;

    case constants.ORDER_INVOICED:
      message = 'La factura ha sido creada: ';
      return message;

    case constants.PURCHASE_ORDER_CREATED:
      message = 'La remisión ha sido creada: ';
      return message;

    default:
      message = '';
      return message;
  }
}

export const getDocumentSucessNotification = ({ documentType, id }) => {
  const { erp: currentERP } = store.getState().ordox.company;
  if (currentERP === constants.ERP.siigo) {
    return 'La solicitud de facturación ha sido enviada con éxito';
  } else if (documentType === 'purchase') {
    return 'La remisión será creada en unos instantes!'
  } else if ((documentType === 'INVOICE' || documentType === 'invoice') && !id) { 
    return 'La factura será creada en unos instantes!'
  }
  const type = documentType == 'INVOICE' || documentType == 'invoice' ? 'factura' : 'remisión';
  return `La ${type} #${id} ha sido creada!`;
};

export const GENERAL_ERROR_MESSAGE =
  'Oups! Algo salió mal al procesar tus datos';
export const INVOICE_FROM_PURCHASE_ORDER_SUCCESS_1 = 'La factura #';
export const INVOICE_FROM_PURCHASE_ORDER_SUCCESS_2 =
  ' ha sido creada correctamente';
export const CUSTOMER_CREATED_SUCCESS =
  'El cliente ha sido creado correctamente';
export const CUSTOMER_EDITED_SUCCESS =
  'El cliente ha sido actualizado correctamente';
export const CUSTOMER_CREATED_FAILURE = 'Oups! No se pudo crear el cliente';
export const CONFIRM_ORDER_SLOT1 =
  'La franja seleccionada ha alcanzado su capacidad máxima';
export const CONFIRM_ORDER_SLOT2 = '. ¿Desea confirmar la orden?';
export const FILE_UPLOADING_ERROR =
  'Oh man! Ocurrió un error al cargar tu archivo';
export const FILE_UPLOADING_SUCESS = 'Tu archivo ha sido cargado correctamente';
export const FILE_UPLOADING_INVALID_FILETYPE =
  'El formato de archivo no es válido';
export const ORDER_STATUS_UPDATED_SUCCESS =
  'El estado de la orden ha sido actualizado!';

export const PRODUCT_CREATED_SUCCESS =
  'El producto ha sido creado correctamente';
export const PRODUCT_EDIT_SUCCESS =
  'El producto ha sido actualizado correctamente';
export const PRODUCT_CREATED_FAILURE = 'Oups! No se pudo crear el producto';
export const PRODUCT_EDIT_FAILURE = 'Oups! No se pudo actualizar el producto';

// delivery slots
export const FILE_WITH_ERRORS = 'Algunos datos en tu archivo contienen errores';
export const PREVIEW_SUCESS_DELIVERY_SLOTS =
  ' franjas horarias serán creadas mientras continúas trabajando en Savia';
export const SUCESS_DELIVERY_SLOTS = ' franjas horarias creadas correctamente';
export const BATCH_PROCESSING_LIMIT_EXCEEDED =
  'Oups! intenta procesar menos datos!';
export const DELIVERY_SLOT_SUCCESS_UPDATED =
  'La franja horaria ha sido actualizada';
export const ORDER_TAGS_SUCESS_UPDATED = 'Las etiquetas han sido actualizadas';

// order export
export const EXPORT_BEING_PROCESSED =
  'El archivo está siendo procesado y será descargado en algunos instantes';
export const EXPORT_SELECT_ORDER = 'Debes seleccionar al menos una orden';
export const EXPORT_SELECT_CLIENT = 'Debes seleccionar al menos un cliente';

export const SIGNUP_SUCESS =
  'La cuenta ha sido creada. Puedes ingresar con tu usuario y contraseña de Google';

export const INVOICING_IN_PROGRESS =
  'Existe un proceso de facturación en curso para esta orden. No podemos procesar tu solicitud';

export const ALREADY_INVOICED = 'La orden ya ha sido facturada';
