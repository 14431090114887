import React from 'react';

export type FormattedIndentificationProps =
  React.HTMLAttributes<HTMLSpanElement> & {
    value?: string | number;
  };

const FormattedIndentification: React.FC<FormattedIndentificationProps> = ({
  className,
  value = '',
  ...props
}) => {
  return (
    <span className={`${className} select-all`} {...props}>
      {`${value}`
        .split('')
        .reduceRight(
          (out, char, index, value) =>
            (value.length - index) % 3 === 0
              ? `${index ? '.' : ''}${char}${out}`
              : `${char}${out}`,
          '',
        )}
    </span>
  );
};

export default FormattedIndentification;
