export const getFileHandler = (state) =>
  state.ordox.deliverySlotsManagement.fileUploader.fileHandler;
export const getInputValue = (state) =>
  state.ordox.deliverySlotsManagement.fileUploader.inputValue;
export const getUploadedFileName = (state) =>
  state.ordox.deliverySlotsManagement.fileUploader.uploadedFileName;
export const getSlots = (state) =>
  state.ordox.deliverySlotsManagement.fileUploader.rows;
export const getRetrievedSlots = (state) =>
  state.ordox.deliverySlotsManagement.gridRows;
export const getProcessEnabled = (state) =>
  state.ordox.deliverySlotsManagement.processEnabled;
export const getDeliverySlotsPaging = (state, params) =>
  state.ordox.deliverySlotsManagement.paging[params];

export const getDeliverySlotsGridColumns = () => {
  const columns = [
    {
      key: "formattedStartDateTime",
      headerAlign: "center",
      headerText: "Inicio",
      flexGrow: 2,
      width: 250,
      headerClassName: "grid-header",
      contentClassName: "grid-row-center",
    },
    {
      key: "formattedEndDateTime",
      headerAlign: "center",
      headerText: "Fin",
      flexGrow: 2,
      width: 250,
      headerClassName: "grid-header",
      contentClassName: "grid-row-center",
    },
    {
      key: "ordersCapacity",
      headerAlign: "center",
      headerText: "Capacidad (ordenes)",
      flexGrow: 2,
      width: 370,
      headerClassName: "grid-header",
      contentClassName: "grid-row-right",
    },
  ];
  return columns;
};

export const getEditedDeliverySlot = (state) =>
  state.ordox.deliverySlotsManagement.editedSlot;
export const getEditDeliverySlotVisible = (state) =>
  state.ordox.deliverySlotsManagement.slotModalVisible;
