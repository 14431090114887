import React from 'react';
import { Card } from '@components/ui/Card';
import { formatCurrencySpanishCO } from '@utils/DataFormat';
import { roundResult } from '@utils/configERP';
import { FormattedMessage } from 'react-intl';


export function OrderItemCard({ item }) {
	const discountNumber = parseFloat(item?.discount)
	const discountResult = item.priceBeforeTax * (discountNumber / 100)
	return (
		<div>
			<Card >
				<div className='flex justify-between'>
					<div className="mb-0.5 text-gray-700 w-full">
						<div>
							<h6>{item.product.name}</h6>
						</div>
						<div className="flex text-gray-700 ">
							<b className="flex flex-row w-1/2 ml-2">
								Precio:
							</b>
							<b className="flex flex-row justify-end w-1/2 mr-2">
								<td className=" text-sm text-gray-900 font-light whitespace-nowrap text-right">
									{formatCurrencySpanishCO(
										roundResult(item.priceBeforeTax)
									)}
									{item?.discount > 0 && (
										<div className="flex mt-1">
											<p className="text-xs text-blue-700 line-through mr-1">
												{formatCurrencySpanishCO(
												roundResult(item.priceBeforeTax - discountResult)
												)}
											</p>
											<p className="text-xs text-blue-700 mt-0 ml-1">
												{`(${item?.discount}%)`}
											</p>
										</div>
									)}
								</td>
							</b>
						</div>
						<div className="flex text-gray-700 ">
							<b className="flex flex-row w-1/2 ml-2">
								Impuesto:
							</b>
							<b className="flex flex-row justify-end w-1/2 mr-2">
								<td className="text-sm text-gray-900 font-light whitespace-nowrap text-right">
									{formatCurrencySpanishCO(
										roundResult(item.priceBeforeTax * (1 - item?.discount / 100) * (item?.tax / 100))
									)}
								</td>
							</b>
						</div>
						<div className="flex text-gray-700 ">
							<b className="flex flex-row w-1/2 ml-2">
								Cantidad:
							</b>
							<b className="flex flex-row justify-end w-1/2 mr-2">
								<td className="text-sm text-gray-900 font-light whitespace-nowrap text-right">
									{item.quantity}
								</td>
							</b>
						</div>
						<div className="flex text-gray-700 ">
							<b className="flex flex-row w-1/2 ml-2">
								Total:
							</b>
							<b className="flex flex-row justify-end w-1/2 mr-2">
								<td className="text-sm text-gray-900 font-light  whitespace-nowrap text-right">
									{formatCurrencySpanishCO(
										item.priceAfterTax *
										(1 - item.discount / 100) *
										item.quantity,
									)}
								</td>
							</b>
						</div>
					</div>
				</div>
			</Card>
		</div>
	)
}