import React, { useEffect } from "react";
import { FormattedNumberParts, useIntl } from 'react-intl';
import { Button, Table, InputPicker, Modal, Input, Col, ButtonToolbar, Message, toaster } from "rsuite";
import { useFindCreditNoteCausesQuery, useFindByIdQuery } from '@api/query/orderApi/orderApi';

import { roundResult } from '@utils/configERP';
import { useCreditNoteMutation } from '@api/query/orderEventApi/orderEventApi';
import { useFindInvoicingSettingsQuery } from "@api/query/invoicingSetting";
import { NOTIFICATION_DURATION } from "@utils/Constants";

const { Column, HeaderCell, Cell } = Table;
const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
  const editing = rowData.status === 'EDIT';
  return (
    <Cell className="rs-input pt-10" {...props} className={editing ? 'table-content-editing text-end' : 'text-end'}>
      {editing ? (
        <input
          className="rs-input pt-0"
          defaultValue={rowData[dataKey]}
          onChange={event => {
            onChange && onChange(rowData.id, dataKey, event.target.value);
          }}
        />
      ) : (
        <FormattedNumberParts
          value={rowData[dataKey]}
          maximumFractionDigits={2}
          minimumFractionDigits={2}>
          {parts => (
            <span className="font-light text-base">
              <span className="mr-1 select-none">$</span>
              {parts.slice(0, -1).reduce((str, part) => str + part.value, '')}
              <span className="text-xs">{'00'}</span>
            </span>
          )}
        </FormattedNumberParts>
      )}
    </Cell>
  );
};
const EditableCellQuantity = ({ rowData, dataKey, onChange, ...props }) => {
  const editing = rowData.status === 'EDIT';
  return (
    <Cell className="rs-input pt-10" {...props} className={editing ? 'table-content-editing text-end' : 'text-end'}>
      {editing ? (
        <input
          className="rs-input pt-0"
          defaultValue={rowData[dataKey]}
          onChange={event => {
            onChange && onChange(rowData.id, dataKey, event.target.value);
          }}
        />
      ) : (
        <span className="table-content-edit-span">{rowData[dataKey]}</span>
      )}
    </Cell>
  );
};
const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
  return (
    <Cell {...props} >
      <Button
        appearance="link"
        onClick={() => {
          onClick(rowData);
        }}
      >
        {rowData.status === 'EDIT' ? 'Guardar' : 'Editar'}
      </Button>
    </Cell>
  );
};
const ModalCreditNote = ({
  visible,
  onClose,
  ordersSelected
}) => {
  const [invoices, setInvoices] = React.useState([]);
  const [valueInvoice, setValueInvoice] = React.useState();
  const [creditNotes, setValueCreditNotes] = React.useState();
  const [comment, setComment] = React.useState('');
  const [notes, setNotes] = React.useState('');
  const [valueInvoiceTotal, setValueInvoiceTotal] = React.useState(0);
  const [creditNoteTotal, setCreditNoteTotal] = React.useState(0);
  const [flagOperation, setFlagOperation] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [save, setSave] = React.useState(false);
  const [triggerCreditNote, creditNote] = useCreditNoteMutation({});
  const [invoicing, setInvoicing] = React.useState({});

  const creditNoteCauses = useFindCreditNoteCausesQuery({});
  const invoicingSetting = useFindInvoicingSettingsQuery({ active: true, numberingType: 'CREDIT_NOTE' });


  useEffect(() => {
    if (invoicingSetting?.data?.items.length) {
      setInvoicing(invoicingSetting?.data?.items[0])
    }
  }, [invoicingSetting]);

  const creditNoteCausesrDefault = creditNoteCauses?.data?.items?.find(
    item => item.isDefault === true,
  );

  const onSubmit = () => {
    const cause = creditNoteCauses?.data?.items.find(
      item => item.id === creditNotes,
    );
    delete cause.isDefault
    delete cause.companyId
    const items = []
    for (let i = 0; i < data?.length; i++) {
      const dataObject = {
        id: data[i]?.id,
        name: data[i]?.name,
        quantity: data[i]?.quantity,
        priceBeforeTax: data[i]?.priceBeforeTax,
        priceAfterTax: data[i]?.priceAfterTax,
        tax: data[i]?.tax,
        taxIdERP: data[i]?.taxIdERP,
      }
      items.push(dataObject)
    }
    const dataPayload = {
      orderId: ordersSelected?.id,
      orderNumber: ordersSelected?.orderNumber,
      comments: comment ? comment : undefined,
      notes: notes ? notes : undefined,
      customer: {
        id: ordersSelected?.customer?.id,
        fullName: ordersSelected?.customer?.fullName,
      },
      invoicingSetting: {
        id: invoicing?.id,
        idERP: invoicing?.idERP,
        description: invoicing?.description
      },
      amount: creditNoteTotal,
      invoiceId: valueInvoice,
      invoiceIdERP: ordersSelected?.invoiceIdERP,
      cause: cause,
    }

    triggerCreditNote(dataPayload)
    toaster.push(
      <Message type="info" showIcon closable duration={NOTIFICATION_DURATION}>
        {'La nota crédito será creada en unos instantes!'}
      </Message>,
    );
    onCloseModal()
  }

  const onCloseModal = () => {
    setValueInvoice(null)
    setComment('')
    setNotes('')
    onClose()
  }

  const onChageInvoice = value => {
    setValueInvoice(value)
  }

  const onChageCreditNotesCaouses = value => {
    setValueCreditNotes(value)
  }

  const changeComments = value => {
    setComment(value)
  }

  const changeNotes = value => {
    setNotes(value)
  }

  useEffect(() => {
    if (invoices && !valueInvoice) {
      setValueInvoice(ordersSelected?.invoiceId)
    }
  }, [invoices, valueInvoice, ordersSelected]);

  useEffect(() => {
    if (valueInvoiceTotal) {
      setCreditNoteTotal(valueInvoiceTotal)
    }
  }, [valueInvoiceTotal]);

  useEffect(() => {
    if (ordersSelected) {
      const totalCreditNote = (ordersSelected?.totalAfterTax - (ordersSelected?.totalCredited ? ordersSelected?.totalCredited : 0))
      const invoices = [{ label: ordersSelected?.invoiceId, value: ordersSelected?.invoiceId }]
      setInvoices(invoices)
      setValueInvoiceTotal(totalCreditNote)
    }
  }, [ordersSelected]);

  useEffect(() => {
    if (creditNoteCausesrDefault && !creditNotes) {
      setValueCreditNotes(creditNoteCausesrDefault?.id)
    }
  }, [creditNoteCausesrDefault, creditNotes]);

  const { $t } = useIntl();

  const [data, setData] = React.useState([]);

  const { data: dataOrder } = useFindByIdQuery({
    data: { populateData: true },
    id: ordersSelected?.id
  });

  useEffect(() => {
    if (data && save) {
      const subtotal = roundResult(
        data.reduce(
          (result, { priceBeforeTax, quantity }) =>
            result + priceBeforeTax * quantity,
          0,
        ),
      );

      const discount = roundResult(
        data.reduce(
          (result, { discount, priceBeforeTax, quantity }) =>
            result + ((discount || 0) / 100) * priceBeforeTax * quantity,
          0,
        ),
      );

      const taxes = roundResult(
        data.reduce(
          (result, { tax, priceBeforeTax, quantity, discount }) =>
            result +
            priceBeforeTax * (1 - (discount || 0) / 100) * quantity * (tax / 100),
          0,
        ),
      );
      const totalBeforeTax = roundResult(subtotal - discount);

      const totalAfterTax = roundResult(totalBeforeTax + taxes);

      const total = totalAfterTax - valueInvoiceTotal
      if (totalAfterTax > valueInvoiceTotal) {
        setDisabled(true)
        toaster.push(
          <Message
            type="warning"
            showIcon
            closable
            duration={5000}
          >
            {$t({ id: 'exceed-the-amount' })}
          </Message>,
        );
      } else {
        setDisabled(false)
      }
      setCreditNoteTotal(totalAfterTax)
    }
  }, [data, valueInvoiceTotal, flagOperation, save]);
  useEffect(() => {
    if (dataOrder) {
      const arrayData = []
      for (let i = 0; i < dataOrder?.items?.length; i++) {
        const data = { ...dataOrder?.items[i], name: dataOrder?.items[i]?.product?.name }
        arrayData.push(data)
      }
      setData(arrayData)
    }
  }, [dataOrder]);

  const handleChange = (id, key, value) => {
    const nextData = Object.assign([], data);
    nextData.find(item => item.id === id)[key] = value;
    setData(nextData);
  };
  const handleEditState = value => {
    const nextData = Object.assign([], data);
    const activeItem = nextData.find(item => item.id === value.id);
    activeItem.status = activeItem.status ? null : 'EDIT';
    setFlagOperation(true)
    if (!value?.status) {
      setSave(true);
    } else {
      setSave(false);
    }
    setData(nextData);
  };
  return (
    <div className="w-80" >
      <Modal
        className="modal-dialog-container"
        open={visible}
        size="sm"
        keyboard
        onClose={onCloseModal}
      >
        <Modal.Header>
          <Modal.Title>{$t({ id: 'action.create-credit-note' })}</Modal.Title>
        </Modal.Header>
        <Modal.Body sm={6} md={6} lg={6} >
          {/* <div className="flex pb-1 pt-0">
            <div className='pt-2 pr-10'>
              Factura:
            </div>
            <Col className="no-spaces-left" sm={12} md={12} lg={12}>
              <InputPicker
                placeholder="Factura"
                data={invoices}
                cleanable={false}
                onChange={onChageInvoice}
                value={valueInvoice || []}
                defaultValue={valueInvoice}
                block
              // placeholder={$t({ id: 'action.change-state' })}
              />
            </Col>
          </div> */}
          <div className="flex pb-1 pt-0">
            <div className='pt-2 pr-20'>
              Causa:
            </div>
            <Col className="no-spaces-left" sm={12} md={12} lg={12}>
              <InputPicker
                data={creditNoteCauses?.data?.items?.map(
                  ({
                    id,
                    description,
                  }) => ({
                    label: description,
                    value: id,
                  }),
                )}
                cleanable={false}
                onChange={onChageCreditNotesCaouses}
                value={creditNotes || []}
                block
              />
            </Col>
          </div>
          <div className="flex pb-1 pt-0">
            <div className='pt-2 pr-9'>
              Comentarios:
            </div>
            <Col className="no-spaces-left" sm={12} md={12} lg={12}>
              <Input
                as="textarea"
                onChange={changeComments}
                value={comment}
                rows="1"
              />
            </Col>
          </div>
          <div className="flex pb-1 pt-0">
            <div className='pt-2 pr-20'>
              Notas:
            </div>
            <Col className="no-spaces-left" sm={12} md={12} lg={12}>
              <Input
                as="textarea"
                onChange={changeNotes}
                value={notes}
                rows="1"
              />
            </Col>
          </div>
          <div className="flex pt-0">
            <div className='pt-2 pr-9'>
              Valor factura:
            </div>
            <div className='pt-2 pr-2'>
              <FormattedNumberParts
                value={valueInvoiceTotal}
                maximumFractionDigits={2}
                minimumFractionDigits={2}>
                {parts => (
                  <span className="font-light text-base">
                    <span className="mr-1 select-none">$</span>
                    {parts.slice(0, -1).reduce((str, part) => str + part.value, '')}
                    <span className="text-xs">{'00'}</span>
                  </span>
                )}
              </FormattedNumberParts>
            </div>
          </div>
          <div className="flex">
            <div className='pr-1.5'>
              Total nota crédito:
            </div>
            <div className='pr-2'>
              <FormattedNumberParts
                value={creditNoteTotal}
                maximumFractionDigits={2}
                minimumFractionDigits={2}>
                {parts => (
                  <span className="font-light text-base">
                    <span className="mr-1 select-none">$</span>
                    {parts.slice(0, -1).reduce((str, part) => str + part.value, '')}
                    <span className="text-xs">{'00'}</span>
                  </span>
                )}
              </FormattedNumberParts>
            </div>
          </div>
          <Table Column width={510} bordered cellBordered className='mr-2' height={190} data={data}>
            <Column width={240}>
              <HeaderCell className="text-center">Producto</HeaderCell>
              <Cell dataKey="name" onChange={handleChange} />
            </Column>

            <Column className="text-center" width={110}>
              <HeaderCell className="text-center">Precio</HeaderCell>
              <EditableCell dataKey="priceBeforeTax" onChange={handleChange} />
            </Column>

            <Column width={70}>
              <HeaderCell className="text-center">Cantidad</HeaderCell>
              <EditableCellQuantity dataKey="quantity" onChange={handleChange} />
            </Column>

            <Column width={60} flexGrow={1}>
              <HeaderCell className="text-center">Acción</HeaderCell>
              <ActionCell dataKey="id" onClick={handleEditState} />
            </Column>
          </Table>
          <div>
            <ButtonToolbar className="pt-4">
              <Button appearance="primary" disabled={disabled} onClick={onSubmit} >
                Guardar
              </Button>
              <Button appearance="ghost" onClick={onCloseModal}>
                {$t({ id: 'cancel' })}
              </Button>
            </ButtonToolbar>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalCreditNote;