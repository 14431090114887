import PageHeader from "@components/PageHeader";
import { getProducts } from "@selectors/ProductSelector";
import { formatToDatabaseDate } from "@utils/DataFormat";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Message, toaster } from "rsuite";
import * as actions from "../actions";
import ConfirmOrderAction from "../components/Order/ConfirmOrderAction";
import { OrderButtons } from "../components/OrderButtons";
import { OrderDetail } from "../components/OrderDetail";
import { OrderHeader } from "../components/OrderHeader";
import * as selector from "../selectors/CreateOrderSelector";
import {
  getConfirmOrderActionVisible,
  getOrders,
  getSlotStatus,
} from "../selectors/ListOrderSelector";
import {
  getChannels,
  getInvoicingSettings,
  getTaxesList,
} from "../selectors/MasterDataSelector";
import { getInvoiceData } from "../selectors/OrderTrackingSelector";
import * as statusSelector from "../selectors/StatusSelector";
import * as systemSelector from "../selectors/SystemSelector";
import * as constants from "../utils/Constants";
import orderValidations from "../utils/OrderValidations";

class CreateOrderContainer extends Component {
  constructor(props) {
    super(props);
    this.onGridRowsUpdated = this.onGridRowsUpdated.bind(this);
    this.saveOrder = this.saveOrder.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.deleteGridRow = this.deleteGridRow.bind(this);
    this.addGridOrder = this.addGridOrder.bind(this);
    this.changeCustomer = this.changeCustomer.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.changeDeliverySlot = this.changeDeliverySlot.bind(this);
    this.changeComments = this.changeComments.bind(this);
    this.changeNotes = this.changeNotes.bind(this);
    this.changePaymentMethod = this.changePaymentMethod.bind(this);
    this.changeChannel = this.changeChannel.bind(this);
    this.changeInvoicingSetting = this.changeInvoicingSetting.bind(this);
    this.changeActionOnShipment = this.changeActionOnShipment.bind(this);
    this.changePromisedDeliveryDate = this.changePromisedDeliveryDate.bind(
      this
    );
    this.cleanDateTimePicker = this.cleanDateTimePicker.bind(this);
    this.onClickConfirmAction = this.onClickConfirmAction.bind(this);
    this.onHideConfirmOrderAction = this.onHideConfirmOrderAction.bind(this);
    this.saveAndCreate = this.saveAndCreate.bind(this);
    this.clearOrderFields = this.clearOrderFields.bind(this);
    this.updateOrderInvoiceData = this.updateOrderInvoiceData.bind(this);
    this.handleOnCustomersSearch = this.handleOnCustomersSearch.bind(this);
  }

  componentDidMount() {
    if (this.props.orderHeader.paymentMethods.length == 0) {
      this.props.getPaymentMethods({ active: true });
    }

    if (this.props.orderHeader.channels.length == 0) {
      this.props.getChannels(this.props.loggingParams.companyId);
    }

    if (this.props.orderHeader.invoicingSettings.length == 0) {
      this.props.getInvoicingSettings({ active: true });
    }

    if (this.props.statusList.length == 0) {
      this.props.getStatus({});
    }

    if (this.props.productsList.length == 0) {
      this.props.getProducts({ active: true });
    }

    if (this.props.taxes.length == 0) {
      this.props.getTaxes({});
    }

    if (this.props.orderCaptureAction == "EDIT") {
      if (this.props.orderDto.promisedDeliveryDateTime) {
        this.props.searchDeliverySlots({
          startDateTime: new Date(this.props.orderDto.promisedDeliveryDateTime)
            .toISOString()
            .substring(0, 10),
        });
      }
    }
  }

  componentWillMount() {
    this.props.setConfirmOrderActionVisible(false);
  }

  componentWillUnmount() {
    this.props.resetNewOrder();
    this.props.setConfirmOrderActionVisible(false);
  }

  onGridRowsUpdated({ fromRow, toRow, updated }) {
    const taxes = this.props.taxes;
    this.props.setOrderItems({ fromRow, toRow, updated, taxes });
  }

  saveAndCreate() {
    const result = this.saveOrder({ redirectPage: "create-order" });
    if (result === "SAVED") {
      this.clearOrderFields();
    }
  }

  clearOrderFields() {
    this.changeComments(null);
    this.changeCustomer(null);
    this.changeNotes(null);
    this.props.clearOrderItems();
  }

  saveOrder({ redirectPage }) {
    this.props.disableButton(true);
    let fullParams;
    let errors = null;
    let result = "SAVED";

    errors = orderValidations(this.props.orderDto);

    if (errors.errorsCounter > 0) {
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
          {errors.errormessages}
        </Message>
      );
      this.props.disableButton(false);
      result = "INVALID";
    } else {
      if (
        this.props.orderCaptureAction === "CREATE" ||
        this.props.orderCaptureAction === "COPY"
      ) {
        const statusId = statusSelector.getStatus(
          this.props.statusList,
          statusSelector.STATUS_ACTIONS.START
        ).id;

        fullParams = {
          newOrder: { statusId, ...this.props.orderDto },
          redirect: { page: redirectPage ? redirectPage : "list-order" },
          loggingParams: this.props.loggingParams,
        };

        this.props.saveOrder(fullParams, this.clearOrderFields);
        return result;
      }
      fullParams = {
        editedOrder: this.props.orderDto,
        redirect: { page: "list-order" },
      };
      this.props.editOrder(fullParams);
      return result;
    }
  }

  onHideConfirmOrderAction() {
    this.props.setConfirmOrderActionVisible(false);
  }

  updateOrderInvoiceData() {
    if (this.props.invoiceData) {
      this.props.updateOrderInvoiceData({
        invoiceData: [
          this.props.invoiceData.invoiceIdERP,
          this.props.invoiceData.invoiceId,
        ],
        invoicedOrders: [this.props.invoiceData.orderId],
      });
    }
  }

  onClickConfirmAction() {
    this.props.setConfirmOrderActionVisible(false);
    let fullParams;
    if (
      this.props.orderCaptureAction === "CREATE" ||
      this.props.orderCaptureAction === "COPY"
    ) {
      fullParams = {
        newOrder: this.props.orderDto,
        redirect: { page: "list-order" },
        loggingParams: this.props.loggingParams,
      };
      this.props.saveOrder(fullParams, this.clearOrderFields);
    } else {
      fullParams = {
        editedOrder: this.props.orderDto,
        redirect: { page: "list-order" },
        companyId: this.props.loggingParams.companyId,
      };
      this.props.editOrder(fullParams);
    }
  }

  cancelOrder() {
    this.props.cancelOrder();
  }

  deleteGridRow(ev, args) {
    if (this.props.orderHeader.editDisabled) {
      toaster.push(
        <Message
          type="warning"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
          Solo se pueden editar ordenes en estado "Creada" o "En proceso"
        </Message>
      );
    } else {
      this.props.deleteRowGridItems(args.rowIdx);
    }
  }

  addGridOrder() {
    if (this.props.orderHeader.editDisabled) {
      toaster.push(
        <Message
          type="warning"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
          Solo se pueden editar ordenes en estado "Creada" o "En proceso"
        </Message>
      );
    } else {
      this.props.addRowGridItems();
    }
  }

  changeCustomer(customer) {
    if (customer) {
      let selectedCustomer = this.props.orderHeader.customer.find(
        (items) => items.value === customer
      );
      this.props.updateCustomer(selectedCustomer);

      const paymentMethod = this.props.orderHeader.paymentMethods.find(
        (paymentMethod) =>
          paymentMethod.value === selectedCustomer.paymentMethodId
      );
      this.props.updateSelectedPaymentMethod(paymentMethod);

      const channel = this.props.orderHeader.channels.find(
        (channel) => channel.value === selectedCustomer.channelId
      );
      this.props.updateSelectedChannel(channel);

      const invoicingSetting = this.props.orderHeader.invoicingSettings.find(
        (invoicingSetting) =>
          invoicingSetting.value === selectedCustomer.invoicingSettingId
      );
      this.props.updateSelectedInvoicingSettings(invoicingSetting);

      const actionOnShipment = this.props.orderHeader.actionOnShipment.find(
        (action) => action.value === selectedCustomer.actionOnShipment
      );
      this.props.updateSelectedActionOnShipment(actionOnShipment);
    } else {
      this.props.updateCustomer(null);
      this.props.updateSelectedPaymentMethod(null);
      this.props.updateSelectedChannel(null);
      this.props.updateSelectedInvoicingSettings(null);
    }
  }

  handleOnCustomersSearch(input) {
    if (input.length > 3) {
      const searchParams = {};
      const companyId = this.props.loggingParams.companyId;
      searchParams.filters = { fullName: input };
      searchParams.companyId = companyId;
      searchParams.paging = { pageSize: 50, currentPage: 1 };
      searchParams.source = "create-order";
      this.props.searchCustomers(searchParams);
    }
  }

  changeDeliverySlot(slot) {
    this.props.updateSelectedDeliverySlot(slot);
  }
  changeChannel(channel) {
    this.props.updateSelectedChannel(channel);
  }
  changeInvoicingSetting(invoicingSetting) {
    this.props.updateSelectedInvoicingSettings(invoicingSetting);
  }

  changeActionOnShipment(actionOnShipment) {
    this.props.updateSelectedActionOnShipment(actionOnShipment);
  }

  changeStatus(status) {
    this.props.changeStatus(status.value);
  }

  changeComments(comments) {
    if (comments) this.props.changeComments(comments);
    else this.props.changeComments("");
  }

  changeNotes(notes) {
    if (notes) this.props.changeNotes(notes);
    else this.props.changeNotes("");
  }

  changePaymentMethod(paymentMethod) {
    this.props.changePaymentMethod(paymentMethod);
  }
  changePromisedDeliveryDate(promisedDeliveryDate) {
    const formattedDate = formatToDatabaseDate(promisedDeliveryDate);
    this.props.searchDeliverySlots({
      startDateTime: new Date(formattedDate).toISOString().substring(0, 10),
    });
    // this.props.loadSelectedDateDeliverySlots(formattedDate);
  }
  cleanDateTimePicker() {
    this.props.changePromisedDeliveryDate(null);
  }

  render() {
    const deleteColumIndex = 6;
    this.props.oderDetails.itemsColumns[deleteColumIndex].events = {
      onClick: this.deleteGridRow,
    };

    return (
      <div className="h-screen flex flex-col pb-1">
        <PageHeader
          title={
            this.props.orderCaptureAction === "EDIT" ? (
              <FormattedMessage id="edit-order" />
            ) : (
              <FormattedMessage id="create-order" />
            )
          }
          subtitle={
            this.props.orderCaptureAction === "EDIT" && (
              <label className="font-medium">
                Savia #{this.props.orderDto.orderNumber}
              </label>
            )
          }
        ></PageHeader>

        <div className="px-8">
          <OrderHeader
            props={this.props.orderHeader}
            changeCustomer={this.changeCustomer}
            changeDeliverySlot={this.changeDeliverySlot}
            changePromisedDeliveryDate={this.changePromisedDeliveryDate}
            changePaymentMethod={this.changePaymentMethod}
            cleanDateTimePicker={this.cleanDateTimePicker}
            changeStatus={this.changeStatus}
            changeChannel={this.changeChannel}
            changeInvoicingSetting={this.changeInvoicingSetting}
            changeActionOnShipment={this.changeActionOnShipment}
            handleOnCustomersSearch={this.handleOnCustomersSearch}
          />

          <br />
          <div>
            <OrderDetail
              props={this.props.oderDetails}
              changeComments={this.changeComments}
              changeNotes={this.changeNotes}
              onGridRowsUpdated={this.onGridRowsUpdated}
              addGridOrder={this.addGridOrder}
              numberOfRecords={this.props.orderDto.items.length}
            />
          </div>
          <br />
          <div>
            <OrderButtons
              saveOrder={this.saveOrder}
              cancelOrder={this.cancelOrder}
              saveAndCreate={this.saveAndCreate}
              orderCaptureAction={this.props.orderCaptureAction}
            />
          </div>

          {this.props.confirmOrderActionVisible && (
            <ConfirmOrderAction
              slotCapacity={
                this.props.orderHeader.selectedDeliverySlot.ordersCapacity
              }
              confirmOrderActionVisible={this.props.confirmOrderActionVisible}
              onHideConfirmOrderAction={this.onHideConfirmOrderAction}
              onClickConfirmAction={this.onClickConfirmAction}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let orderDto = selector.createOrderDto(state);
  let editDisabled = false;

  return {
    orderHeader: {
      customer: selector.getCustomer(state),
      selectedPaymentMethod: selector.getSelectedPaymentMethod(state),
      selectedPromisedDeliveryDate: selector.getSelectedPromisedDeliveryDate(
        state
      ),
      selectedCustomer: selector.getSelectedCustomer(state),
      selectedDeliverySlot: selector.getSelectedDeliverySlot(state),
      selectedStatus: selector.getSelectedStatus(state),
      selectedChannel: selector.getSelectedChannel(state),
      selectedInvoicingSettings: selector.getSelectedInvoicingSetting(state),
      selectedActionOnShipment: selector.getSelectedActionOnShipment(state),
      paymentMethods: selector.getPaymentMethods(state),
      deliverySlots: selector.getDeliverySlots(state),
      orderCaptureAction: selector.getOrderCaptureAction(state),
      orderStatus: selector.getOrderStatusFiltered(state),
      orderDto,
      editDisabled,
      channels: getChannels(state),
      invoicingSettings: getInvoicingSettings(state),
      actionOnShipment: constants.ACTIONS_ONSHIPMENT,
    },
    oderDetails: {
      items: selector.getItems(state),
      itemsColumns: selector.getItemGridColumns(state),
      orderRows: selector.getNewOrderItems(state),
      comments: selector.getOrderComments(state),
      notes: selector.getOrderNotes(state),
      selectedComments: selector.getSelectedOrderComments(state),
      selectedNotes: selector.getSelectedOrderNotes(state),
      summary: selector.getOrderSummary(state),
    },
    orderDto,
    loadingVisible: systemSelector.getLoadingVisible(state),
    buttonDisabled: systemSelector.setButtonDisabled(state),
    loggingParams: systemSelector.getMainLoggingParams(state),
    ordersList: getOrders(state),
    confirmOrderActionVisible: getConfirmOrderActionVisible(state),
    slotStatus: getSlotStatus(state),
    orderCaptureAction: selector.getOrderCaptureAction(state),
    invoiceData: getInvoiceData(state),
    taxes: getTaxesList(state),
    statusList: statusSelector.getStatusList(state),
    productsList: getProducts(state),
  };
};
export default connect(mapStateToProps, actions)(CreateOrderContainer);
