export const STATUS = [
    {
        label: 'Disponible',
        value: 'AVAILABLE',
    },
    {
        label: 'Agotado',
        value: 'OUT_OF_STOCK',
    },
]

export const DEFAULT_INVENTORY_DATA = {
    productId: '',
    locations: '',
    recordType: '',
    comment: '',
    quantity: 0,
}