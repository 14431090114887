/* eslint-disable react/jsx-pascal-case */
import React, { ImgHTMLAttributes } from 'react';
import { Col as Col_, ColProps, Grid, Row as Row_, RowProps } from 'rsuite';
import { RsRefForwardingComponent } from 'rsuite/esm/@types/common';

function fixBreakpoint(breakpoint?: number) {
  return breakpoint !== undefined ? Math.min(24, breakpoint * 2) : undefined;
}

export const Container = Grid;

export const Col: RsRefForwardingComponent<
  'div',
  ColProps & { xl?: number }
> = ({ xs, sm, md, lg, xl, ...props }) => (
  // @ts-ignore
  <Col_
    {...props}
    xs={fixBreakpoint(xs || 12)}
    sm={fixBreakpoint(sm || 12)}
    md={fixBreakpoint(md || 12)}
    lg={fixBreakpoint(lg || xl || 12)}
  />
);

export const Row: RsRefForwardingComponent<'div', RowProps> = ({
  gutter,
  ...props
  // @ts-ignore
}) => <Row_ gutter={gutter || 8} {...props} />;

export const Image: React.FC<ImgHTMLAttributes<HTMLImageElement>> = props => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <img {...props} />
);
