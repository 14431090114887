import { all, call, put, takeLatest } from "redux-saga/effects";
import { Message, toaster } from "rsuite";
import * as actions from "../actions";
import types from "../actions/ActionTypes";
import api from "../api/SignupApi";
import * as constants from "../utils/Constants";
import { SIGNUP_SUCESS } from "../utils/Notifications.js";

function* signupCompany(action) {
  yield put(actions.setShowSpinner(true));
  const response = yield call(api.signupCompany, action.payload);

  if (response) {
    toaster.push(
      <Message
        type="success"
        showIcon
        closable
        duration={constants.SIGNUP_NOTIFICATION_DURATION}
      >
        {SIGNUP_SUCESS}
      </Message>
    );
  }

  yield put(actions.setShowSpinner(false));
}

export default function* () {
  yield all([takeLatest(types.SIGNUP_COMPANY, signupCompany)]);
}
