// eslint-disable-next-line no-throw-literal
export const stop = () => {
  throw 'STOP';
};

function combineWith<T, U>(
  destination: T[],
  source: U[],
  combiner: (destination: T, source: U) => void,
): T[] {
  return destination.reduce((out, l) => {
    try {
      source.forEach(r => combiner(l, r));
    } catch (e) {
      if (e !== 'STOP') throw e;
    }

    return [...out, l];
  }, []);
}

export default combineWith;
