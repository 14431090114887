import { selectIsAuthorized } from '@selectors/AuthorizationSelector';
import { styled } from 'goober';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { BaseRouteProps } from './types';

const Layout = styled('div')`
  position: absolute;
  inset: 0 0 auto;
  min-height: 100vh;
  --transition: all 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);

  &.push-enter {
    transform: translateX(-100%);
    opacity: 0;
  }
  &.push-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: var(--transition);
  }
  &.push-exit {
    pointer-events: none;
    transform: translateX(0%);
    opacity: 1;
  }
  &.push-exit-active {
    pointer-events: none;
    opacity: 0;
    transform: translateX(100%);
    transition: var(--transition);
  }

  &.pop-enter {
    transform: translateX(100%);
    opacity: 0;
  }
  &.pop-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: var(--transition);
  }
  &.pop-exit {
    pointer-events: none;
    transform: translateX(0);
    opacity: 1;
  }
  &.pop-exit-active {
    pointer-events: none;
    opacity: 0;
    transform: translateX(-100%);
    transition: var(--transition);
  }
`;

const AppRoute: React.FC<BaseRouteProps> = ({
  private: private_,
  Component,
  ...props
}) => {
  const isAuthorized = useSelector(selectIsAuthorized);

  const routeIsAllowed = private_ ? isAuthorized : true;

  return (
    <Route {...props}>
      {routeProps => {
        const {
          match,
          history: { action },
        } = routeProps;

        return (
          <CSSTransition
            in={!!match}
            classNames={action === 'PUSH' ? 'pop' : 'push'}
            timeout={500}
            unmountOnExit>
            <Layout>
              {routeIsAllowed && Component && <Component />}
              {!routeIsAllowed && <Redirect to="/" push={false} />}
            </Layout>
          </CSSTransition>
        );
      }}
    </Route>
  );
};

export default AppRoute;
