
import { useFindTaxesQuery } from '@api/query/taxApi';
import DrawerModal from '@components/ui/DrawerModal';
import FormattedCurrency from '@components/ui/FormattedCurrency';
import { Close as CloseIcon, Edit as EditIcon } from '@rsuite/icons';
import SearchIcon from '@rsuite/icons/Search';
import { selectCompany } from '@selectors/SystemSelector';
import combineWith, { stop } from '@utils/combine';
import filterOne from '@utils/filterOne';
import useFuzzySearch, {
  getMatchIndices,
  RangeTuples,
} from '@utils/useFuzzySearch';
import useViewport from '@utils/useViewport';
import { styled } from 'goober';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { GroupedVirtuoso, Virtuoso } from 'react-virtuoso';
import {
  Button,
  Form,
  IconButton,
  Input,
  InputGroup,
  Loader,
  Panel,
  SelectPicker,
} from 'rsuite';
import HighlightMatchText from '../ui/HighlightMatchText';
import InputNumber from '../ui/InputNumber';
import { FindProductRequest, Product } from '@api/query/productApi/types';
import { useLazyFindListPriceProductQuery, useLazyFindProductQuery } from '@api/query/productApi/productApi';
import { roundResult } from '@utils/configERP';
import { useLazyPagination } from '@containers/PriceList/UseLazyPagination';
import { useLazyFindInventoryQuery } from '@api/query/inventoryApi/inventory';

Object.defineProperty(GroupedVirtuoso, 'displayName', { value: 'Virtuoso' });

export type ProductBrowserProps = {
  className?: string;
  listPriceId?: string;
  customerId?: string;
  locationId?: string;
  controlsInventory?: boolean;
  allowsNegativeSale?: boolean;
  selected: Product[];
  onSelectedChange(
    items: Product[],
    change: Product | undefined,
    checked: boolean,
  ): void;
};

const keys = ['name'];

const ProductBrowser: React.FC<ProductBrowserProps> = ({
  className,
  onSelectedChange,
  selected,
  listPriceId,
  customerId,
  locationId,
  controlsInventory,
  allowsNegativeSale,
}) => {
  const viewport = useViewport();

  const [triggerListPrice, listPriceQuery] = useLazyFindListPriceProductQuery();
  const [triggerInventory, inventory] = useLazyFindInventoryQuery();
  const [valueSearch, setValueSearch] = useState('');
  const [productsFinal, setProductsFinal] = useState<Product[]>([]);
  const [triggerProducts, result] = useLazyFindProductQuery();
  const trigger = useCallback(
    (value: FindProductRequest) => {
      return triggerProducts({
        ...value
      });
    },
    [triggerProducts],
  );
  const { setExtraQuery } =
    useLazyPagination({
      trigger,
      result,
      initialLimit: 10,
    });

  useEffect(() => {
  if (inventory?.data?.items && result?.data?.items && controlsInventory) {
    const updatedProducts = result.data.items.map(product => {
      const inventoryProduct = inventory.data.items.find(item => item.product.id === product.id);

      if (inventoryProduct) {
        return {
          ...product,
          availableQuantity: inventoryProduct.availableQuantity,
          disabled: true
        };
      } else {
        return {
          ...product,
          availableQuantity: 0,
          disabled: false
        };
      }
    });
    const filteredProducts = updatedProducts.filter(product => product.disabled === true);

    setProductsFinal(filteredProducts);
  }
  
}, [inventory?.data, result?.data, controlsInventory]);
  useEffect(() => {
  if (result?.data?.items?.length > 0 && locationId && controlsInventory) {
    const productIds = result.data.items.map(item => item.id).join(',');
    triggerInventory({ productId: productIds, locationId: locationId });
  } else {
    if (result?.data?.items?.length > 0 && !controlsInventory) {
      setProductsFinal(result?.data?.items);
    }
  }
}, [result?.data, triggerInventory, locationId, controlsInventory]);

  useEffect(() => {
    if (listPriceId) {
      search('')
      triggerListPrice({ listPriceId });
    }
  }, [listPriceId, triggerListPrice]);

  useEffect(() => {
    if (customerId) {
      search('')
      triggerProducts({ active: true });
    }
  }, [customerId, triggerProducts]);

  const products = useMemo<Product[]>(() => {
    let listPrices = []
    const products = productsFinal?.slice() || [];
    if (listPriceId) {
      listPrices = listPriceQuery.data?.items.slice() || [];
    } 

    return combineWith(products, listPrices, (l, r) => {
      if (l.companyId === r.companyId && l.id === r.product?.id) {
        l.priceBeforeTax = r.price;
        l.priceAfterTax = roundResult(r.price + (r.price * l.tax) / 100);
        stop();
      }
    });
  }, [productsFinal, listPriceQuery.data, listPriceQuery]);
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const search = (searchValue) => {
    setValueSearch(searchValue)
    setExtraQuery(value => ({
      ...value,
      active: true,
      keyword: searchValue.trim() ? searchValue : undefined,
    }));
  };

  const context = useMemo(
    () => ({
      products,
      selected,
      onSelectedChange,
    }),
    [products, onSelectedChange, selected],
  );

  const selectedChangeHandler = useCallback(
    (changes: Partial<Product>) => {
      const index = selected.findIndex(({ id }) => id === changes.id);

      if (index !== -1) {
        selected.splice(index, 1, { ...selected[index], ...changes });
        onSelectedChange([...selected], selected[index], true);
      }
    },
    [selected, onSelectedChange],
  );

  const removeHandler = useCallback(
    (product: Product) => {
      const updatedSelected = selected.map((item) => {
        if (item.id === product.id) {
          return { ...item, active: false };
        }
        return item;
      });
      onSelectedChange(updatedSelected, product, false);
    },
    [selected, onSelectedChange],
  );
  return (
    <Panel
      className={className}
      header={
        <div className="flex justify-between">
          <span className="font-medium">
            <FormattedMessage id="products" />
          </span>
          <IconButton
            size="sm"
            appearance="link"
            icon={<SearchIcon />}
            onClick={() => setOpen(true)}>
            {selected.length === 0 ? 'Buscar productos' : undefined}
          </IconButton>
        </div>
      }>
      <ProductList
        products={selected as Product[]}
        onChange={selectedChangeHandler}
        onRemove={removeHandler}
        controlsInventory={controlsInventory}
        allowsNegativeSale={allowsNegativeSale}
      />
      <DrawerModal
        open={open}
        DrawerProps={{ size: 'full' }}
        onClose={onClose}
        placement={viewport.isNarrow ? 'bottom' : 'floating'}
        title={<FormattedMessage id="products" />}
        body={
          <div className="flex flex-col flex-grow">
            {result.isLoading ? (
              <Loader size="md" className="m-auto" />
            ) : (
              <>
                <div className="mx-3 mt-1">
                  <InputGroup inside size="sm">
                    <InputGroup.Addon>
                      <SearchIcon />
                    </InputGroup.Addon>
                    <Input
                      type="search"
                      autoFocus
                      autoComplete="off"
                      inputMode="search"
                      autoCorrect="off"
                      onChange={value => search(value)}
                      value={valueSearch as string}
                    />
                  </InputGroup>
                </div>
                <div className="flex-grow pt-2 relative overflow-hidden md:h-96">
                  <div className="header flex flex-nowrap font-medium py-2 shadow-sm">
                    <span className="ml-4 flex-1">Product</span>
                    <span className="mr-5">Cantidad</span>
                  </div>
                  <Virtuoso
                    className="h-full"
                    totalCount={products.length}
                    context={context}
                    itemContent={(
                      index,
                      _,
                      {
                        products,
                        selected,
                        onSelectedChange,
                      },
                    ) => {

                      const product = products[index]

                      if (!product) return <div className="h-px"></div>;

                      const selectedItem =
                        selected.find(({ id }) => id === product.id) ||
                        product;

                      return (
                        <ProductItemCompact
                          index={(products?.length - 1) === index}
                          product={selectedItem}
                          controlsInventory={controlsInventory}
                          allowsNegativeSale={allowsNegativeSale}
                          checked={selected.some(
                            item => item.id === product.id && item?.active,
                          )}
                          onChange={selectedChangeHandler}
                          onChecked={(product, checked) => {
                            if (checked && !product?.active) {
                              product.active = true
                            }
                            onSelectedChange(
                              checked
                                ? selected.concat(product)
                                : filterOne(
                                  selected,
                                  item => item.id === product.id,
                                ),
                              product,
                              checked,
                            )
                          }
                          }
                        />
                      );
                    }}
                    increaseViewportBy={100}
                  />
                </div>
              </>
            )}
          </div>
        }
        actions={
          <div className="text-slate-800 my-2 mx-4 flex">
            <FormattedMessage
              id="selected"
              values={{
                count: <b className="mr-1">{selected.length}</b>,
              }}
            />
          </div>
        }
        backdrop
      />
    </Panel>
  );
};

export type ProductItemProps = {
  index?: boolean
  product: Product;
  checked?: boolean;
  controlsInventory?: boolean;
  allowsNegativeSale?: boolean;
  onChecked?(product: Product, checked: boolean): void;
  onChange?(changes: Product): void;
  nameMatches?: RangeTuples;
  onRemove?(product: Product): void;
  readonly?: boolean;
};

export const ProductItemCompact: React.FC<ProductItemProps> = ({
  index,
  product,
  checked,
  nameMatches,
  onChecked,
  onChange,
  onRemove,
  readonly,
  controlsInventory,
  allowsNegativeSale
}) => {
  let isAvailable = true
  if (controlsInventory) {
    isAvailable = product.availableQuantity > 0;
  }

  let openOnclick = false
  if (!allowsNegativeSale && isAvailable) {
    openOnclick = true
  }
  if (allowsNegativeSale) {
    openOnclick = true
  }

  return (
    <Form
      className={`flex items-center flex-wrap flex-row px-4 py-2 -mb-px border-y border-gray-200 cursor-pointer
      ${
        checked
          ? 'bg-blue-400 text-white'
          : 'text-slate-700 hover:bg-slate-200 hover:text-slate-700'
      }
      ${
        index
          ? 'pb-10'
          : 'pb-0'
      }
      ${!isAvailable && !openOnclick && 'opacity-50'}`}
      onClick={() => openOnclick && onChecked(product, !checked)}
      fluid
      formDefaultValue={{}}>
      <span className="flex-1 basis-full">
        <HighlightMatchText text={product.name} matches={nameMatches} />
      </span>
      {!isAvailable && controlsInventory && (
        <span className="flex-1 basis-full">
          <div style={{fontSize: '10px'}} className="text-red-600">Agotado</div>
        </span>
      )}
      {isAvailable && controlsInventory && (
        <span className="flex-1 basis-full">
          <div style={{fontSize: '10px'}} className="text-sky-600">{`${product.availableQuantity} disponibles`}</div>
        </span>
      )}

      <div className="flex-shrink basis-36 text-left">
        <FormattedCurrency
          className="text-base font-bold whitespace-nowrap"
          value={product.priceBeforeTax}
        />
      </div>

      <div
        className={`my-1 ml-auto basis-20 flex-shrink ${
          (!checked || (!isAvailable && !openOnclick)) && 'invisible'
        }`}
        onClick={ev => ev.stopPropagation()}>
        <InputNumber
          key={Number(checked)}
          disabled={!isAvailable && !openOnclick}
          name="quantity"
          unit={product.unit}
          value={product.quantity}
          min={product.unit === 'uds' ? 1 : 1}
          max={controlsInventory && !allowsNegativeSale ? product.availableQuantity : 1000000}
          // scale={product.unit === 'uds' ? 0 : 2}
          onChange={quantity => onChange({ ...product, quantity })}
        />
      </div>
    </Form>
  );
};

const ProductListStyled = styled('div')`
  & .rs-input {
    width: 100%;
  }
  & form > * {
    margin-right: 8px;
  }
`;

type ProductListProps = {
  products: Product[];
  onChange?(changes: Partial<Product>): void;
  onRemove?(product: Product): void;
  controlsInventory?: boolean;
  allowsNegativeSale?: boolean;
};

export const ProductList: React.FC<ProductListProps> = ({
  products,
  onChange,
  onRemove,
  controlsInventory,
  allowsNegativeSale,
}) => {
  return (
    <ProductListStyled className="">
      {products
        .filter(product => product.active)
        .map(product => (
          <ProductItem
            key={product.id}
            product={product}
            onChange={onChange}
            onRemove={onRemove}
            controlsInventory={controlsInventory}
            allowsNegativeSale={allowsNegativeSale}
          />
        ))}
    </ProductListStyled>
  );
};

export const ProductItem: React.FC<ProductItemProps> = ({
  product,
  onChange,
  onRemove,
  controlsInventory,
  allowsNegativeSale,
}) => {
  const [open, setOpen] = useState(false);
  let isAvailable = true
  if (controlsInventory) {
    isAvailable = product.availableQuantity > 0;
  }

  let openOnclick = false
  if (!allowsNegativeSale && isAvailable) {
    openOnclick = true
  }
  if (allowsNegativeSale) {
    openOnclick = true
  }

  return (
    <>
      <div className="basis-full border-t border-gray-200 -mx-5" />
      <div
        className={`flex flex-wrap justify-end items-end py-3 -mb-px cursor-pointer
      text-slate-700 hover:bg-slate-50 hover:text-slate-700`}>
        <div className="flex-1 basis-full flex flex-nowrap items-start">
          <span className="flex-1 font-medium">{product.name}</span>
          {(openOnclick) && (
            <IconButton
            className="mx-1 -mt-1"
            size="sm"
            icon={<EditIcon />}
            onClick={() => setOpen(true)}
          />
          )}
          <IconButton
            className="ml-2"
            size="xs"
            circle
            appearance="subtle"
            icon={<CloseIcon />}
            onClick={() => onRemove(product)}
          />
        </div>
         {!isAvailable && controlsInventory && (
            <span className="flex-1 basis-full">
              <div style={{fontSize: '10px'}} className="text-red-600">Agotado</div>
            </span>
          )}
          {isAvailable && controlsInventory && (
            <span className="flex-1 basis-full">
              <div style={{fontSize: '10px'}} className="text-sky-600">{`${product.availableQuantity} disponibles`}</div>
            </span>
          )}

        <FormattedCurrency
          className="flex-1 text-left text-gray-500"
          value={product.priceBeforeTax}
        />

        <span className="basis-10">
          <div
            className={`my-1 ml-auto basis-20 flex-shrink ${
              (!isAvailable && !openOnclick) && 'invisible'
            }`}
            onClick={ev => ev.stopPropagation()}>
            <InputNumber
                value={product.quantity}
                unit={product.unit?.slice(0, 3)}
                // scale={product.unit === 'uds' ? 0 : 2}
              min={product.unit === 'uds' ? 1 : 0.001}
              max={controlsInventory && !allowsNegativeSale ? product.availableQuantity : 1000000}
                onChange={value =>
                  onChange({ ...product, quantity: Number(value) })
                }
              />
          </div>
        </span>

        <span className="flex-shrink basis-32 font-bold text-right mx-2">
          <FormattedCurrency
            value={product.priceBeforeTax * product.quantity}
          />
        </span>
        <ProductInlineEditor
          product={product}
          onChange={onChange}
          open={open}
          onClose={() => setOpen(false)}
        />
      </div>
    </>
  );
};

const ProductInlineEditor: React.FC<
  ProductItemProps & { open: boolean; onClose(): void }
> = ({ product, onChange, open, onClose }) => {
  const { $t } = useIntl();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const productDefault = useMemo(() => open && cloneDeep(product), [open]);

  const taxesOptions = useFindTaxesQuery({});

  const reset = () => {
    onChange(productDefault);
  };

  const save = () => {
    onClose();
  };

  const discard = () => {
    reset();
    onClose();
  };
  
  return (
    <DrawerModal
      open={open}
      onClose={discard}
      placement="floating"
      header={
        <span className="flex-1 flex flex-col text-gray-700">
          <span className="font-medium">{product.name}</span>
        </span>
      }
      body={
        <form className={`p-4 grid grid-cols-2 gap-3 text-slate-700`}>
          <span className="">
            <label htmlFor="price" className="font-medium">
              Precio
            </label>
            <InputNumber
              id="price"
              name="price"
              unit="$"
              min={0}
              max={100_000_000}
              scale={2}
              value={product.priceBeforeTax}
              spinButtons={false}
              onChange={price => {
                onChange({
                  ...product,
                  priceBeforeTax: price,
                  priceAfterTax: price + (price * product.tax) / 100,
                });
              }}
            />
          </span>

          <span className="">
            <label htmlFor="quantity" className="font-medium">
              Cantidad
            </label>
            <InputNumber
              id="quantity"
              name="quantity"
              value={product.quantity}
              unit={product.unit?.slice(0, 3)}
              scale={product.unit === 'uds' ? 0 : 2}
              min={product.unit === 'uds' ? 1 : 0.001}
              onChange={value =>
                onChange({ ...product, quantity: Number(value) })
              }
            />
          </span>

          <span className="">
            <label htmlFor="discount" className="font-medium">
              Descuento
            </label>
            <InputNumber
              id="discount"
              name="discount"
              value={product.discount}
              unit="%"
              scale={3}
              min={0}
              max={100}
              spinButtons={false}
              onChange={discount => onChange({ ...product, discount })}
            />
          </span>

          <span className="">
            <label htmlFor="tax" className="font-medium">
              Impuesto
            </label>
            <SelectPicker
              style={{ width: '100%' }}
              size="sm"
              cleanable={false}
              searchable={false}
              value={product.tax}
              data={
                taxesOptions.data?.items.map(({ description, percentage }) => ({
                  label: `${description} - ${percentage}%`,
                  value: percentage,
                })) || []
              }
              onChange={tax =>
                onChange({
                  ...product,
                  tax: Number(tax),
                  taxIdERP: taxesOptions.data?.items.find(
                    ({ percentage }) => percentage === tax,
                  )?.idERP,
                  priceAfterTax:
                    product.priceBeforeTax +
                    (product.priceBeforeTax * tax) / 100,
                })
              }
            />
          </span>

          <span>
            <label htmlFor="notes" className="font-medium">
              {$t({ id: 'product-notes' })}
            </label>
            <Input
              size="sm"
              className="basis-full mr-2"
              maxLength={30}
              value={product.notes || ''}
              onChange={notes => onChange({ ...product, notes })}
            />
          </span>
          <span className="font-bold text-right">
            <label className="font-medium">Subtotal</label>

            <FormattedCurrency
              className="block mt-2"
              value={
                product.priceBeforeTax * product.quantity -
                product.priceBeforeTax * ((product.discount || 0) / 100)
              }
            />
          </span>
        </form>
      }
      actions={
        <div className="flex justify-end p-2">
          <Button onClick={reset}>Deshacer cambios</Button>
          <Button className="mr-2" appearance="primary" onClick={save}>
            Guardar
          </Button>
        </div>
      }
    />
  );
};

export default ProductBrowser;

/** TODO: Notas individuales por producto 30char */
/** TODO: Cambiar resultados por Numero de seleccionados */
/** TODO:
 * Lista producto: Nombre, precio(modal), cantidad(ambas),
 * precio * cantidad(computado) + impuesto
 * notas(modal) impuestos(modal) */
