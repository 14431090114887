import React from 'react';

const TagFigure = ({ tags }) => {
  // return <div></div>;
  if (tags.length > 0) {
    return (
      <div>
        {tags.map(tag => (
          <span
            title={tag.text}
            className="inline-block py-1 px-2 rounded-full last:mr-0 mr-1"
            style={{ backgroundColor: tag.color }}
          ></span>
        ))}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default TagFigure;
