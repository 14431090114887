import { useUpdateDeliverySlotsMutation } from "@api/query/deliverySlot/deliverySlot";
import { DateTimePickerField } from "@components/Forms/DateTimePickerField";
import { TextField } from "@components/Forms/TextField";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonToolbar,
  DatePicker,
  Form,
  InputNumber,
  Message,
  Modal,
  useToaster,
} from "rsuite";
import { getModelDeliverySlot } from "../helpers/deliverySlot";
import { DeliverySlot } from "@api/query/deliverySlot/types";
import { NOTIFICATION_DURATION } from "@utils/Constants";
import { FormattedMessage } from "react-intl";


export function UpdateDeliverySlot(props) {

  const {
    visible,
    deliverySlot,
    onClose,
  } = props;

  const formRef = useRef();
  const [formValue, setFormValue] = useState<DeliverySlot>()
  const [editDeliverySlot] = useUpdateDeliverySlotsMutation()
  const model = getModelDeliverySlot()
  const toaster = useToaster();

  useEffect(() => {
    if (visible && deliverySlot) {
      setFormValue(deliverySlot)
    }
  }, [visible])

  const onSubmit = () => {
    if (!(formRef.current as any)?.check()) {
      return
    }
    const { companyId, createdAt, updatedAt, id, ...restDeliverySlot } = formValue
    editDeliverySlot({ id: id, data: restDeliverySlot })
      .then(res => {
        toaster.push(
          <Message
            type="success"
            showIcon
            closable
            duration={NOTIFICATION_DURATION}
          >
            <FormattedMessage id="edit-deliverySlot-success" />
          </Message>
        );
        onClose()
      })
  }
  


  return (
    <div>
      <Modal
        className="modal-dialog-container"
        open={visible}
        size="xs"
        keyboard
        onClose={onClose}
      >
        <Modal.Header>
          <Modal.Title className="modal-title-center">
            Editar Franja Horaria
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            ref={formRef}
            formValue={formValue}
            onChange={(formValue) => setFormValue(formValue as DeliverySlot)}
            model={model}
            fluid
          >
            <DateTimePickerField name="startDateTime" label="Inicio" />
            <DateTimePickerField name="endDateTime" label="Fin" />
            <TextField
              max={1000}
              min={1}
              accepter={InputNumber}
              name="capacity"
              label="Capacidad"
            />

            <ButtonToolbar>
              <Button
                appearance="primary"
                onClick={() => onSubmit()}
              >
                Guardar
              </Button>
              <Button onClick={onClose}>Cancelar</Button>
            </ButtonToolbar>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

