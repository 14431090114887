import React from 'react';
import { Modal, Button } from 'rsuite';
import LegacyLinodeIcon from "@rsuite/icons/legacy/Linode";
import '../../styles/General.css';

const NewVersionFound = ({visible,handleOnClick}) =>
    <div>
        <Modal
          size="xs"
          className="modal-dialog-container"
          show={visible}
          backdrop="static"
        >
          <Modal.Body>
                <LegacyLinodeIcon style={{color: '#003d7a', fontSize:24}} />

                {'   '}
                Ordox continúa evolucionando. Haz clic aquí para cerrar sesión y acceder a la última versión.
           
            <div className="pt-6 text-center">
              <br/>
              <Button color="cyan" onClick={handleOnClick}>Ir a la última versión</Button>
            </div>
          </Modal.Body>

        </Modal>
    </div>

export default NewVersionFound;