import axios from '../libs/axios';

const api = {
  async signupCompany(data) {
    return await axios
      .post('auth/signup', data)
      .then((response) => {
        return {
          status: response.status,
          data: response.data.result,
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          return {};
        }
      });
  },
};

export default api;
