import React from 'react'

export function Traceability({ event }) {
  return <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table className="w-full text-sm text-left text-gray-500">
      <tbody>
        <tr className="border-b border-gray-200 ">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
            Nombre de usuario
          </th>
          <td className="px-6 py-2">
            {event.tracking?.userName}
          </td>

        </tr>
        <tr className="border-b border-gray-200 ">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
            Usuario id
          </th>
          <td className="px-6 py-2">
            {event.tracking?.userId}
          </td>

        </tr>
        <tr className="border-b border-gray-200 ">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
            Creada en
          </th>
          <td className="px-6 py-2">
            {event.tracking?.loggedTime}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
}