import * as constants from "../Constants";
import { CONFIRM_ORDER_SLOT1, CONFIRM_ORDER_SLOT2 } from "../Notifications";

export const getOrderSameSlotValidationMessage = (capacity) => {
  const message =
    CONFIRM_ORDER_SLOT1 + "(" + capacity + ")" + CONFIRM_ORDER_SLOT2;
  return message;
};

export const validateSelectedSlot = (ordersList, selectedSlot, orderId) => {
  let slotValidation = { totalOrders: 0, orders: [] };
  let filteredOrders = ordersList.filter(
    (x) => x.StatusId !== constants.ENTREGADA
  );

  let sameSlotOrders = filteredOrders.filter(
    (order) =>
      order.deliverySlotId === selectedSlot.value && orderId !== order.Id
  );

  if (sameSlotOrders.length < selectedSlot.ordersCapacity) {
    sameSlotOrders = [];
  }

  slotValidation.totalOrders = sameSlotOrders.length;
  const orderIdsList = sameSlotOrders.map((order) => order.Id);
  slotValidation.orders = orderIdsList.join(",");
  return slotValidation;
};
