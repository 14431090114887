import history from "@router/history";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { Message, toaster } from "rsuite";
import * as actions from "../actions";
import types from "../actions/ActionTypes";
import api from "../api/ProductApi";
import * as constants from "../utils/Constants";
import { mapProductList } from "../utils/Mappers.js";
import { mapCreateProductDto, mapEditProductDto } from "../utils/Mappers/ProductMapper";
import {
  PRODUCT_CREATED_FAILURE,
  PRODUCT_CREATED_SUCCESS,
  PRODUCT_EDIT_SUCCESS,
  PRODUCT_EDIT_FAILURE,
} from "../utils/Notifications";
import { getNotificationMessage } from "../utils/Notifications.js";

function* getProducts(action) {
  let notificationMessage;

  try {
    const products = yield call(api.getProducts, action.payload);

    const mappedProducts = mapProductList(products.data.items);

    yield put(actions.updateStateProducts(mappedProducts));
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      notificationMessage = getNotificationMessage(
        constants.MASTER_DATA_LOADING_FAILURE
      );
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
          {notificationMessage}
        </Message>
      );
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

function* redirectCreateProduct(action) {
  yield put(actions.updateFormAction({ form: "PRODUCT", action: "CREATE" }));
  yield call(history.push, "addEdit-product");
}

function* searchProducts(action) {
  try {
    yield put(actions.setShowSpinner(true));

    const response = yield call(api.searchProducts, action.payload);
    yield put(
      actions.updateProductsPaging({ name: "totalRows", value: response.data.total })
    );

    yield put(actions.updateProductsGrid(mapProductList(response.data.items)));
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

function* createProduct(action) {
  let notificationMessage;
  try {
    yield put(actions.setShowSpinner(true));
    const newProduct = mapCreateProductDto(
      action.payload
    );

    yield call(api.createProduct, newProduct);

    notificationMessage = PRODUCT_CREATED_SUCCESS;
    toaster.push(
      <Message
        type="success"
        showIcon
        closable
        duration={constants.NOTIFICATION_DURATION}
      >
        {notificationMessage}
      </Message>
    );
  } catch (err) {
    if (err?.response?.status === 400) {
      console.log(err.response.data.message);
    } else if (err?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      notificationMessage = PRODUCT_CREATED_FAILURE;
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
          {`${notificationMessage}: ${err?.response.data.message} `}
        </Message>
      );
    }
  } finally {
    yield put(actions.setShowSpinner(false));

    if (action.callback) {
      action.callback();
    }
  }
}
function* editProduct(action) {
  let notificationMessage;
  try {
    yield put(actions.setShowSpinner(true));
    const newProduct = mapEditProductDto(
      action.payload
    );

    yield call(api.editProduct, { id: action.payload?.productId, data: newProduct });
    

    notificationMessage = PRODUCT_EDIT_SUCCESS;
    toaster.push(
      <Message
        type="success"
        showIcon
        closable
        duration={constants.NOTIFICATION_DURATION}
      >
        {notificationMessage}
      </Message>
    );
  } catch (err) {
    if (err?.response?.status === 400) {
      console.log(err.response.data.message);
    } else if (err?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      notificationMessage = PRODUCT_EDIT_FAILURE;
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
          {`${notificationMessage}: ${err?.response.data.message} `}
        </Message>
      );
    }
  } finally {
    yield put(actions.setShowSpinner(false));

    if (action.callback) {
      action.callback();
    }
  }
}

export default function* () {
  yield all([
    takeLatest(types.GET_PRODUCTS, getProducts),
    takeLatest(types.REDIRECT_CREATE_PRODUCT, redirectCreateProduct),
    takeLatest(types.SEARCH_PRODUCTS, searchProducts),
    takeLatest(types.CREATE_PRODUCT, createProduct),
    takeLatest(types.EDIT_PRODUCT, editProduct),
  ]);
}
