import { useCreatePriceListMutation } from '@api/query/priceListApi';
import { FloatingActionContainer } from '@components/ui/FloatingActionButton';
import { selectCompanyId } from '@selectors/SystemSelector';
import { rollbarErrors } from '@utils/rollbarErrors';
import { createGlobalStyles } from 'goober/global';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useKey } from 'rooks';
import { Button, IconButton, Input, InputGroup, Loader, Modal } from 'rsuite';
import { getMainLoggingParams } from '@selectors/SystemSelector';
export const GlobalStyles = createGlobalStyles`
  .rs-modal-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 5rem;
  }
`;
type FABCreatePriceListProps = {
  onNewPriceListItem?(id: string): void;
};

const FABCreatePriceList: React.FCC<FABCreatePriceListProps> = ({
  onNewPriceListItem,
}) => {
  const { $t } = useIntl();
  const companyId = useSelector(selectCompanyId);
    const { user } = useSelector(getMainLoggingParams);

  const [description, setDescription] = useState('');
  const [open, setOpen] = useState(false);
  const isValid = useMemo(() => description.trim().length > 2, [description]);

  const [createPriceListTrigger, createPriceListResult] =
    useCreatePriceListMutation();
  
  useEffect(() => {
    if (createPriceListResult?.isError) {
      rollbarErrors(createPriceListResult?.error, description.trim(), 'POST/prices', companyId, user)
    }
  }, [createPriceListResult?.isError]);

  const openModal = useCallback(() => {
    setDescription('');
    setOpen(true);
    createPriceListResult.reset();
  }, [createPriceListResult]);

  const closeModal = useCallback(() => {
    if (createPriceListResult.isLoading) return;

    setOpen(false);
    setDescription('');
  }, [createPriceListResult]);

  const createPriceList = useCallback(async () => {
    try {
      if (!isValid) return;
      const response = await createPriceListTrigger({
        description: description.trim(),
      }).unwrap();

      onNewPriceListItem?.(response.id);
      closeModal();
    } catch (error) {}
  }, [isValid, createPriceListTrigger, companyId, description, closeModal]);

  const inputRef = useRef<HTMLInputElement>(null);

  useKey('Enter', () => createPriceList(), {
    target: inputRef,
    eventTypes: ['keydown'],
  });

  return (
    <>
      <FloatingActionContainer>
        <IconButton
          className="shadow-md shadow-gray-500"
          appearance="primary"
          circle
          icon={<BiPlus className="text-2xl" />}
          onClick={openModal}
        />
      </FloatingActionContainer>
      <GlobalStyles />
      <Modal open={open} onClose={closeModal} size="xs">
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="create-price-list" />
          </Modal.Title>
        </Modal.Header>
        {(createPriceListResult.isLoading ||
          createPriceListResult.isSuccess) && (
          <Modal.Body className="flex justify-center">
            <Loader className="my-2" size="md" />
          </Modal.Body>
        )}
        {!createPriceListResult.isSuccess &&
          !createPriceListResult.isLoading && (
            <>
              <Modal.Body>
                <div className="m-1">
                  <InputGroup size="md" inside>
                    <Input
                      inputRef={inputRef}
                      autoFocus
                      placeholder={$t({ id: 'name' })}
                      value={description}
                      onChange={value =>
                        setDescription(value.trimStart().normalize('NFC'))
                      }
                      inputMode="text"
                      type="text"
                      autoComplete="off"
                      maxLength={100}
                    />
                  </InputGroup>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={closeModal}>
                  <FormattedMessage id="cancel" />
                </Button>
                <Button
                  appearance="primary"
                  disabled={!isValid}
                  onClick={createPriceList}>
                  <FormattedMessage id="create" />
                </Button>
              </Modal.Footer>
            </>
          )}
      </Modal>
    </>
  );
};

export default FABCreatePriceList;
