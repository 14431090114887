import queryString from 'query-string';

import axios from '../libs/axios';

const api = {
  getListPrice(filters) {
    const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
    return axios.get(`products/prices${params && `?${params}`}`)
  },
};

export default api;
