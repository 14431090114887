import React from 'react';
import FormattedNumber, { FormattedNumberProps } from './FormattedNumber';

const FormattedCurrency: React.FC<FormattedNumberProps> = ({
  value,
  ...props
}) => (
  <FormattedNumber
    minimumFractionDigits={0}
    maximumFractionDigits={2}
    numberStyle="currency"
    currency="COP"
    currencyDisplay="narrowSymbol"
    useGrouping
    value={value ? value.toString() : ''}
    {...props}
  />
);

export default FormattedCurrency;
