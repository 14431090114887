import React, {Component} from 'react';
import Chart from 'chart.js';
import {formatNumber,formatCurrencySpanishCO} from '../../utils/DataFormat';
import '../../styles/Dashboard.css';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.defaults.global.elements.line.tension = 0;


export default class HorizontalLinesChart extends Component{

    chartRef = React.createRef();

    componentDidMount(){
        
        const myChartRef = this.chartRef.current.getContext("2d");
        const graphType = this.props.type;
        let padding = {};
        
        if(this.props.long == "large")
        {
            padding = {
                left: 2,
                right:45,
                top:2,
                bottom:2
            }
        }
        else
        {
            padding = {
                left: 2,
                right:20,
                top:2,
                bottom:2
            }
        }

        this.myChart = new Chart(myChartRef, {
            type: "horizontalBar",
            data: {
                labels: this.props.labels,
                datasets: [
                    {
                        data: this.props.data,
                        fill: false,
                        backgroundColor: [
                            '#7CB1CF',
                            '#008000',
                            '#ff0000',
                            '#D3D3D3'
                        ],
                        borderColor:  [
                            '#7CB1CF',
                            '#008000',
                            '#ff0000',
                            '#D3D3D3'
                        ],
                        datalabels: {
                            color: '#586d81',
                            formatter: function(value){
                                if(graphType == 'orders')
                                    {return formatNumber(value)}
                                else if(graphType == 'units')
                                    {return formatNumber(value)}
                                else
                                    {return formatCurrencySpanishCO((value/1000000)) + 'M'}
                            },
                            anchor: 'end',
                            align: 'end',
                            offset:-3
                        }
                    }
                ]
            },
            options: {
                title:{
                    display: true,
                    text: this.props.title
                },
                responsive: true,

                layout:{
                    padding: padding
                },
                legend: {display: false},
                scales: {
                    xAxes: [{
                        ticks: { 
                            display: false,
                        },
                        gridLines: {
                            display: false,
                            drawBorder: false
                        }
                    }],
                    yAxes: [{
                        ticks: { 
                            display: this.props.showYAxes,
                        },
                        gridLines: {
                            display: false,
                            drawBorder: true,
                        }
                    }]
                }
            }
        });
    }

    componentDidUpdate(){
        this.myChart.data.labels = this.props.labels;
        this.myChart.data.datasets[0].data = this.props.data;
        this.myChart.update();
    }

    render(){
        return(
            <div>
                <canvas
                    id="myChart"
                    ref={this.chartRef}
                />
            </div>
        );
    }
}

// https://chartjs-plugin-datalabels.netlify.app/guide/getting-started.html#configuration