import { GetUsersRequest } from "@api/query/userApi/types";
import { DataGrid, DataGridRowContent, DataGridToolbar, GlobalFilterCallback, HighlightText, cellsById } from "@components/DataGrid";
import PageHeader from "@components/PageHeader";
import { useLazyPagination } from "@containers/PriceList/UseLazyPagination";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CellProps, Column } from "react-table";
import { CheckPicker, Checkbox, Divider, IconButton } from "rsuite";
import PlusIcon from '@rsuite/icons/Plus';
import { Close as CloseIcon, Edit as EditIcon } from '@rsuite/icons';
import { ModalCustomFields } from "./components/ModalCustomFields";
import { useDispatch } from "react-redux";
import { setOpenAuthModal } from "@actions/index";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useLazyFindCustomFieldsQuery } from "@api/query/customFieldsApi/customFields";
import { CustomFieldsType } from "@api/query/customFieldsApi/types";
import { dataEntity } from "./constants/customFields";

export default function CustomFieldsContainer() {
	const dispatch = useDispatch();
	const [customFieldEdit, setCustomFieldEdit] = useState({})
	const [openEditModal, setOpenEditModal] = useState(false);
	const [openCreateModal, setOpenCreateModal] = useState(false);
	const [triggerGetCustomFields, result] = useLazyFindCustomFieldsQuery()

	const trigger = useCallback(
		(value: GetUsersRequest) => {
			return triggerGetCustomFields({
				...value,
			})
				.then(res => {
					if ((res?.error as FetchBaseQueryError)?.status === 401) {
						dispatch(setOpenAuthModal({ open: true }))
					}
				})
				.catch(err => {
					dispatch(setOpenAuthModal({ open: true }))
				})
		},
		[triggerGetCustomFields],
	);

	const { virtualArray, setViewRange, setExtraQuery, resetPagination } =
		useLazyPagination({
			trigger,
			result,
			initialLimit: 100,
		});

	const handleEditCustomField = (customFields: CustomFieldsType) => {
		setCustomFieldEdit(customFields)
		setOpenEditModal(true)
	}
	const columns = useMemo<Column<CustomFieldsType>[]>(
		(): Column<CustomFieldsType>[] => [
			{
				id: 'entity',
				Header: <FormattedMessage id="entity" />,
				accessor: row => row.entity,
				width: 150,
				maxWidth: 150,
				minWidth: 150,
				sticky: true,
				Filter({ column }) {
					const { id, filterValue, setFilter } = column;

					return (
						<CheckPicker
							id={id}
							name={id}
							placeholder="-"
							size="xs"
							data={dataEntity}
							block
							searchable={false}
							cleanable={false}
							value={filterValue || []}
							onChange={setFilter}
						/>
					);
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						entity: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'defaultValue',
				Header: <FormattedMessage id="defaultValue" />,
				accessor: row => row.defaultValue,
				width: 150,
				maxWidth: 150,
				minWidth: 150,
				sticky: true,
			},
			{
				id: 'fieldType',
				Header: <FormattedMessage id="fieldType" />,
				accessor: row => row.fieldType,
				width: 150,
				maxWidth: 150,
				minWidth: 150,
				sticky: true,
			},
			{
				id: 'name',
				Header: <FormattedMessage id="name" />,
				accessor: row => row.name,
				width: 150,
				maxWidth: 150,
				minWidth: 150,
				sticky: true,
			},
			{
				id: 'title',
				Header: <FormattedMessage id="title" />,
				accessor: row => row.title,
				width: 100,
				maxWidth: 150,
				minWidth: 150,
				sticky: true,
			},
			{
				id: 'length',
				Header: <FormattedMessage id="length" />,
				accessor: row => row.length,
				width: 80,
				maxWidth: 80,
				minWidth: 80,
				sticky: true,
				Cell: ({ value }) => {
					return (
					<div className="font-light text-center">
						<HighlightText text={value} />
					</div>
					)
				},
			},
			{
				id: 'mandatory',
				Header: <FormattedMessage id="mandatory" />,
				accessor: row => row.mandatory,
				sticky: true,
				width: 70,
				maxWidth: 100,
				minWidth: 70,
				
				Cell: ({ value, state }) => (
					<div>{<Checkbox checked={value} />}</div>
				),
			},
			{
				id: 'order',
				Header: <FormattedMessage id="order" />,
				accessor: row => row.order,
				width: 80,
				maxWidth: 80,
				minWidth: 80,
				sticky: true,
				Cell: ({ value }) => {
					return (
					<div className="font-light text-center">
						<HighlightText text={value} />
					</div>
					)
				},
			},
			{
				id: 'actions',
				Header: <FormattedMessage id="action" />,
				// accessor: row => row.id,
				width: 100,
				maxWidth: 100,
				minWidth: 100,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
					}));
				},
				Cell: ({ value, row }) => (
					<IconButton
						className="mx-1 -mt-1"
						size="sm"
						icon={<EditIcon />}
						onClick={() => {
							if (!openEditModal) {
								handleEditCustomField(row.original)
							}
						}}
					/>

				),
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;
					setExtraQuery(value => ({
						...value,
						actions: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},


		], []
	)


	const rowContent = useCallback<DataGridRowContent<CustomFieldsType>>(
		(index, row, { viewport }) => {
			if (viewport.isWide) return;

			const cells = cellsById(row.cells);

			return (
				<div
					className={`flex flex-row justify-between items-stretch py-2 px-4 border-y -mb-px border-gray-200`}>
					<div className="flex flex-col flex-nowrap flex-1">
						<div className="mb-1 flex items-center text-xs text-gray-500">
							<span className="flex flex-row">
								{cells.entity.render('Cell')}
							</span>
							<span className="middle-dot w-1 h-1 mx-2 bg-current rounded" />
							<span className="flex flex-row">
								{cells.fieldType.render('Cell', { inline: true })}
							</span>

						</div>

						<span className="mb-0.5 text-lg font-normal text-gray-700">
							{cells.defaultValue.render('Cell')}
						</span>

						<div className="mb-0.5 text-gray-500">

							<span className="flex flex-row">
								{cells.order.render('Cell')}
							</span>
							<span className="flex flex-row">
								{cells.length.render('Cell')}
							</span>
						</div>
					</div>

					<div className="flex flex-col flex-nowrap items-end">
						<div className="mb-1">
							<span className="text-sm">
								{cells.mandatory.render('Cell')}
							</span>
						</div>
						<span>{cells.actions.render('Cell')}</span>
					</div>
				</div>
			);
		},
		[],
	);

	const topToolbar = useCallback<DataGridToolbar<CustomFieldsType>>(
		({ filteredFlatRows, selectedData, rows, columns }) => {
			const statusColumn = columns.find(({ id }) => id === 'status');

			return (
				<>
					<IconButton
						className="ml-auto mx-1 bg-gray-100 hover:shadow-md"
						size="sm"
						icon={<PlusIcon />}
						title="Crear Usuario"
						onClick={() => setOpenCreateModal(true)}
					/>
					<Divider vertical className="rs-divider-vertical" />
				</>
			);
		},
		[],
	);


	const globalFilter = useCallback<GlobalFilterCallback<CustomFieldsType>>(
		(rows, columnIds, filterValue: string) => {
			setExtraQuery(value => ({
				...value,
				keyword: filterValue.trim() ? filterValue : undefined,
			}));
			return rows;
		},
		[],
	);

	return (<div className="h-screen flex flex-col pb-1">
		<PageHeader title={<FormattedMessage id="custom-fields" />} />
		<DataGrid
			className="flex-1"
			data={virtualArray.ref}
			totalCount={virtualArray.ref.length}
			rangeChanged={setViewRange}
			columns={columns}
			selectable
			rowContent={rowContent}
			topToolbar={topToolbar}
			globalFilter={globalFilter}
			overscan={0}
			increaseViewportBy={500}
			autoResetFilters={false}
			autoResetSelectedRows={false}
			autoResetSortBy={false}
			loading={result.isFetching}
		/>
		{openEditModal && (
			<ModalCustomFields
				customFieldInfo={customFieldEdit}
				isEditing={true}
				title="Editar campos dinámicos"
				visible={openEditModal}
				onClose={() => {
					setOpenEditModal(false)
				}}
			/>
		)}
		{openCreateModal && (
			<ModalCustomFields
				isEditing={false}
				title="Crear campos dinámicos"
				visible={openCreateModal}
				onClose={() => {
					setOpenCreateModal(false)
				}}
			/>
		)}
	</div>)
}
