import { GetInventoryRequest, Channel } from "@api/query/inventoryApi/types";
import { useLazyGetChannelsQuery } from "@api/query/inventoryApi/inventory";
import { DataGrid, DataGridRowContent, DataGridToolbar, GlobalFilterCallback, HighlightText, cellsById } from "@components/DataGrid";
import PageHeader from "@components/PageHeader";
import { useLazyPagination } from "@containers/PriceList/UseLazyPagination";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CellProps, Column } from "react-table";
import { CheckPicker, DateRangePicker, Tooltip, Whisper, Tag, Checkbox, IconButton, Divider } from "rsuite";
import dayjs, { dayjsCalendar, dayjsRanges, formats } from "@utils/dayjs";
import { useDispatch } from "react-redux";
import { setOpenAuthModal } from "@actions/index";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { ACTIVE, TYPES_CHANNELS } from "@utils/Constants";
import { Edit as EditIcon } from '@rsuite/icons';
import { ModalChannelsDetail } from "./components/ModalChannelsDetail";
import PlusIcon from '@rsuite/icons/Plus';

export default function ListChannelsContainer() {
	const dispatch = useDispatch();
	const [triggerGetChannels, result] = useLazyGetChannelsQuery()

	const [channelDetail, setChannelsDetail] = useState<Channel | undefined>(undefined)
	const [openDetailModal, setOpenDetailModal] = useState(false);
	const [openEditDetailModal, setOpenEditDetailModal] = useState(false);

	const trigger = useCallback(
		(value: GetInventoryRequest) => {
			return triggerGetChannels({
				...value,
			})
				.then(res => {
					if ((res?.error as FetchBaseQueryError)?.status === 401) {
						dispatch(setOpenAuthModal({ open: true }))
					}
				})
		},
		[triggerGetChannels],
	);

	const defaultEndtDate = new Date();
	const defaultStartDate = new Date();
	defaultStartDate.setDate(defaultStartDate.getDate() - 30);
	
	const { virtualArray, setViewRange, setExtraQuery } =
		useLazyPagination({
			trigger,
			result,
			initialLimit: 100,
		});

	const columns = useMemo<Column<Channel>[]>(
		(): Column<Channel>[] => [
			{
				id: 'name',
				Header: <FormattedMessage id="name" />,
				accessor: row => row?.description,
				width: 280,
				maxWidth: 280,
				minWidth: 280,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `description:${kind.toUpperCase()}`,
          }));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						description: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'type',
				Header: <FormattedMessage id="Type" />,
				accessor: row => row.type,
				sticky: true,
				width: 180,
				maxWidth: 210,
				minWidth: 180,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
					}));
				},
				Filter({ column }) {
					const { id, filterValue, setFilter } = column;

					return (
						<CheckPicker
							id={id}
							name={id}
							placeholder="-"
							size="xs"
							data={TYPES_CHANNELS}
							block
							searchable={false}
							cleanable={false}
							value={filterValue || []}
							onChange={setFilter}
						/>
					);
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						type: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'active',
				Header: <FormattedMessage id="status" />,
				accessor: row => row.active,
				sticky: true,
				width: 100,
				maxWidth: 130,
				minWidth: 100,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
					}));
				},
				Filter({ column }) {
					const { id, filterValue, setFilter } = column;

					return (
						<CheckPicker
							id={id}
							name={id}
							placeholder="-"
							size="xs"
							data={ACTIVE}
							block
							searchable={false}
							cleanable={false}
							value={filterValue || []}
							onChange={setFilter}
						/>
					);
				},
				Cell: ({ value, state }) => (
					<div>{<Checkbox checked={value} />}</div>
				),
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						active: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'createdAt',
				Header: <FormattedMessage id="createdAt" />,
				accessor: row => row.createdAt,
				minWidth: 200,
				width: 250,
				maxWidth: 250,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `createdAt:${kind.toUpperCase()}`,
          }));
				},
				Cell: ({ value, inline, state }: CellProps<Channel, Date>) => {
					if (value?.toString() === "Invalid Date") {
						return null
					}
					const date = dayjs(value);
					if (inline) {
						return (
							<HighlightText
								text={dayjsCalendar(date)}
								subtext={state.globalFilter}
							/>
						);
					}

					return (
						<>
							<Whisper
								trigger={['click', 'hover', 'active']}
								placement="bottomStart"
								speaker={<Tooltip>{date.format(formats.datetime)}</Tooltip>}>
								<div className="text-sm font-light first-letter:capitalize">
									<HighlightText
										text={dayjsCalendar(date)}
										subtext={state.globalFilter}></HighlightText>
								</div>
							</Whisper>
						</>
					);
				},
				Filter({ column }) {
				const { id, filterValue, setFilter } = column;
				return (
					<DateRangePicker
						id={id}
						name={id}
						size="xs"
						block
						placement="auto"
						value={filterValue}
						defaultValue={[defaultStartDate, defaultEndtDate]}
						onChange={dates => {
							setFilter(dates);
						}}
						format="dd/MM/yyyy HH:mm:ss"
						ranges={dayjsRanges([
							'today',
							'tomorrow',
							'yesterday',
							'last7Days',
							'next7Days',
						])}
					/>
				);
			},
				filter: (rows, _, filterValue) => {
					setExtraQuery(value => ({
						...value,
						fromCreatedAt: filterValue?.[0],
						toCreatedAt: filterValue?.[1],
					}));

					return rows;
				},
			},
			{
				id: 'actions',
				Header: <FormattedMessage id="action" />,
				width: 100,
				maxWidth: 100,
				minWidth: 100,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
					}));
				},
				Cell: ({ value, row }) => {
					const id = row.original.id
					return (
						<div>
							<IconButton
								title="Editar"
								size="sm"
								icon={<EditIcon />}
								onClick={() => {
									setOpenEditDetailModal(true)
									setChannelsDetail(row.original as Channel)
								}}
							/>
						</div>
					)

				}
				,
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;
					setExtraQuery(value => ({
						...value,
						actions: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
		], []
	)


	const rowContent = useCallback<DataGridRowContent<Channel>>(
		(index, row, { viewport }) => {
			if (viewport.isWide) return;

			const cells = cellsById(row.cells);

			return (
				<div
          className={`flex flex-row justify-between items-stretch py-2 px-4 border-y -mb-px border-gray-200`}>
          <div className="flex flex-col flex-nowrap flex-1">
            <div className="mb-1 flex items-center font-normal text-gray-700">
							<span className="mb-0.5 text-lg font-normal text-gray-700">
								{cells.product.render('Cell')}
							</span>
							<span className="middle-dot w-1 h-1 mx-2 bg-current rounded" />
							<span className="mb-0.5 text-lg font-normal text-gray-700">
								{cells.locationName.render('Cell')}
							</span>
						</div>
						<div className="flex mb-0.5 text-gray-500">
							<span className="flex flex-row">
								{cells.recordType.render('Cell')}&nbsp;<span className="middle-dot w-1 h-1 mx-2 mt-2 bg-current rounded" />&nbsp;&nbsp;
								{cells.quantity.render('Cell')}&nbsp;unidades
							</span>
						</div>
						<div className="mb-1 flex items-center text-gray-500">
              <span className="mr-2">
								{cells.user.render('Cell')}
							</span>
              <span className="middle-dot w-1 h-1 mx-2 bg-current rounded" />
              <span className="flex flex-row">
                {cells.createdAt.render('Cell')}
              </span>
            </div>
          </div>
          
        </div>
			);
		},
		[],
	);

	const topToolbar = useCallback<DataGridToolbar<Channel>>(
		({ filteredFlatRows, selectedData, rows, columns }) => {

			return (
				<>
					<Divider vertical className="rs-divider-vertical" />
					<IconButton
						className="ml-0 mx-1 bg-gray-100 hover:shadow-md"
						size="sm"
						icon={<PlusIcon />}
						title="Crear Cliente"
						onClick={() => {
							setOpenDetailModal(true)
						}}

					/>
					<Divider vertical className="rs-divider-vertical" />
				</>
			);
		},
		[],
	);


	const globalFilter = useCallback<GlobalFilterCallback<Channel>>(
		(rows, columnIds, filterValue: string) => {
			setExtraQuery(value => ({
				...value,
				keyword: filterValue.trim() ? filterValue : undefined,
			}));
			return rows;
		},
		[],
	);
	return (<div className="h-screen flex flex-col pb-1">
		<PageHeader title={<FormattedMessage id="channel" />} />
		{openEditDetailModal
			? <ModalChannelsDetail
				isOpenDetailModal={openEditDetailModal}
				oncloseModal={() => setOpenEditDetailModal(false)}
				channel={channelDetail}
				edit={true}
				data={result?.data?.items}
			/>
			: null}
		{openDetailModal
			? <ModalChannelsDetail
				isOpenDetailModal={openDetailModal}
				oncloseModal={() => setOpenDetailModal(false)}
				channel={channelDetail}
				edit={false}
				data={result?.data?.items}
			/>
			: null}
		<DataGrid
			className="flex-1"
			data={virtualArray.ref}
			totalCount={virtualArray.ref.length}
			rangeChanged={setViewRange}
			columns={columns}
			selectable
			rowContent={rowContent}
			topToolbar={topToolbar}
			globalFilter={globalFilter}
			overscan={0}
			increaseViewportBy={700}
			autoResetFilters={false}
			autoResetSelectedRows={false}
			autoResetSortBy={false}
			loading={result.isFetching}
		/>
	</div>)
}
