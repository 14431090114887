import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import types from '../actions/ActionTypes';
import api from '../api/ListPriceApi';
import { mapListPrice } from '../utils/Mappers/MasterDataMappers';

function* getListPrice(action) {
  try {
    const listPrice = yield call(api.getListPrice, action.payload);

    yield put(actions.updateFullListPrice(mapListPrice(listPrice.data.items)));
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

export default function* () {
  yield all([takeLatest(types.GET_LIST_PRICE, getListPrice)]);
}
