import Chart from "chart.js";
import React, { Component } from "react";
import "../../styles/Dashboard.css";

export default class DoughnutChart extends Component {
  chartRef = React.createRef();

  componentDidMount() {
    const myChartRef = this.chartRef.current.getContext("2d");

    this.myChart = new Chart(myChartRef, {
      type: "doughnut",
      data: {
        datasets: [
          {
            label: this.props.description,
            data: this.props.data,
            backgroundColor: [
              "#4D9DE0",
              "#97799A",
              "#E15554",
              "#E1893F",
              "#E1A334",
              "#E1BC29",
              "#8EB74E",
              "#3BB273",
              "#598D91",
              "#7768AE",
            ],
            borderColor: [
              "#4D9DE0",
              "#97799A",
              "#E15554",
              "#E1893F",
              "#E1A334",
              "#E1BC29",
              "#8EB74E",
              "#3BB273",
              "#598D91",
              "#7768AE",
            ],
            borderAlign: "center",
            borderWidth: 2,
            datalabels: {
              display: false,
            },
          },
        ],
        labels: this.props.labels,
      },
      options: {
        legend: {
          display: true,
          position: "right",
          maintainAspectRatio: true,
        },
        title: {
          display: true,
          text: this.props.title,
        },
        layout: {
          padding: {
            left: 2,
            right: 2,
            top: 2,
            bottom: 5,
          },
        },
      },
    });
  }

  componentDidUpdate() {
    this.myChart.data.labels = this.props.labels;
    this.myChart.data.datasets[0].data = this.props.data;
    this.myChart.update();
  }

  render() {
    return (
      // <div class="chart-container">
      <div>
        {/* <div className="chart-container"> */}
        <canvas id="myChart" ref={this.chartRef} />
      </div>
    );
  }
}
