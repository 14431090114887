import React, { useDeferredValue, useEffect, useMemo, useRef, useState } from "react";
import { Button, ButtonToolbar, Form, Input, Message, Modal, useToaster } from "rsuite";
import { validateUpdateInventory } from "../helpers/inventory";
import { DropDownField, TextField } from "@components/Customers/AddEditCustomer";
import { DEFAULT_INVENTORY_DATA } from "../constants/inventory";
import { InputPickerField } from "@components/Forms/InputPickerField";
import { useCreateInventoryMutation } from "@api/query/inventoryApi/inventory";
import { NOTIFICATION_DURATION } from "@utils/Constants";
import { FormattedMessage } from "react-intl";
import { useFindInventoryTypesQuery } from "@api/query/inventoryApi/inventory";
import { useFindProductQuery } from "@api/query/productApi/productApi";
import { useFindLocationsQuery } from "@api/query/customerApi/customerApi";


export function ModalCreateEditInventory(props) {
  const {
    visible,
    onClose,
    title,
  } = props;
  const [createInventory] = useCreateInventoryMutation()
  const [searchClient, setSearchClient] = useState('')
  const [searchLocation, setSearchLocation] = useState('')
  const [comment, setComment] = React.useState('');
  const [productsFinal, setProductsFinal] = useState([])
  const [productsData, setProductsData] = useState([])
  const formRef = useRef();
  const [formValue, setFormValue] = useState({})
  const searchDeferred = useDeferredValue(searchClient);
  const searchLocationDeferred = useDeferredValue(searchLocation);
  const model = validateUpdateInventory()
  const toaster = useToaster();

  const locationsData = useFindLocationsQuery({
    keyword: searchLocationDeferred,
  });

	const locationsOptions = useMemo(
    () => {
      const result = locationsData?.data?.items.map(value => ({
        value: value.name,
        label: value.name,
      }))
      if (result?.length) {
        formValue.locations = result[0]?.value
      }
      formValue.quantity = '0'
      
      return result
    },
    [locationsData?.data],
	);

  const productQuery = useFindProductQuery({
    keyword: searchDeferred,
    active: true,
    limit: 100,
    offset: 0,
  });

  const inventoryTypesData = useFindInventoryTypesQuery({ orderBy: 'description:ASC' });

	const inventoryTypesOptions = useMemo(
    () => {
      const result = inventoryTypesData?.data?.items.map(value => ({
        value: value.id,
        label: value.description,
      }))
      if (result?.length) {
        formValue.recordType = result[0]?.value
      }
      return result
    },
    [inventoryTypesData?.data],
	);

  useEffect(() => {
    if (productQuery?.data) {
      const result = productQuery.data?.items?.map(product => {
        return { label: product.name, value: product.id }
      })
      setProductsData(result)
    }
  }, [searchClient, productQuery])

  useEffect(() => {
    if (productsData) {
      setProductsFinal(productsData)
    }
  }, [productsData])

  const changeComments = value => {
    setComment(value)
    formValue.comments = value
  }

  const submit = (type) => {
    if (!formRef.current.check()) {
      return
    }
    const product = productQuery.data?.items.find(product => product.id === formValue?.productId);
    const location = locationsData?.data?.items.find(location => location.name === formValue?.locations);
    const recordType = inventoryTypesData?.data?.items.find(recordType => recordType.id === formValue?.recordType);
    const data = {
      product: {
        id: product?.id,
        name: product?.name,
        idERP: product?.idERP,
        sku: product?.sku,
        externalIds: product?.externalIds ? product?.externalIds : undefined
      },
      location: {
        id: location?.id,
        name: location?.name,
        externalIds: location?.externalIds ? location?.externalIds : undefined
      },
      quantity: parseFloat(formValue?.quantity) ? parseFloat(formValue?.quantity) : 0,
      comments: formValue?.comments,
      recordType: {
        id: recordType?.id,
        description: recordType?.description,
        role: recordType?.role,
      },
    }
        createInventory(data)
        .then(resp => {
          toaster.push(
            <Message
              type="success"
              showIcon
              closable
              duration={NOTIFICATION_DURATION}
            >
              <FormattedMessage id="create-inventory.success" />
            </Message>
          );
        })
        .catch(_error => {
          toaster.push(
            <Message
              type="error"
              showIcon
              closable
              duration={NOTIFICATION_DURATION}
            >
              <FormattedMessage id="create-inventory.error" />
            </Message>
          );
        })

    setFormValue(DEFAULT_INVENTORY_DATA)
    if (type === 1) {
      onCloseModal()
      setComment('')
    }
  }


  const onCloseModal = () => {
    setComment('')
    setFormValue(DEFAULT_INVENTORY_DATA)
    onClose()
  }
  return (
    <div>
      <Modal
        className="modal-dialog-container"
        open={visible}
        size="xs"
        keyboard
        onClose={onCloseModal}
      >
        <Modal.Header>
          <Modal.Title style={{ marginBottom: '10px' }}> {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form
              fluid
              ref={formRef}
              onChange={setFormValue}
              formValue={formValue}
              model={model}
            >
              <div className="grid grid-cols-1 gap-x-4 gap-y-2">
                <div>
                  {" "}
                  {productsFinal ? <>
                    <InputPickerField
                      onSearch={(text) => setSearchClient(text)}
                      onClean={() => setSearchClient('')}
                      label={<>{<FormattedMessage id="productName" />} *</>}
                      data={productsFinal ?? []} className='w-full' name='productId' />
                      
                  </> : null}
                  {locationsOptions?.length > 0 && (
                    <DropDownField
                      style={{ width: '100%' }}
                      name="locations"
                      label={<>{<FormattedMessage id="locationName" />} *</>}
                      defaultValue={locationsOptions[0]?.value}
                      data={locationsOptions}
                    />
                  )}
                  {inventoryTypesOptions?.length > 0 && (
                    <DropDownField
                      style={{ width: '100%' }}
                      name="recordType"
                      label={<>{<FormattedMessage id="movementType" />} *</>}
                      defaultValue={inventoryTypesOptions[0]?.value}
                      data={inventoryTypesOptions}
                    />
                  )}
                  <TextField name="quantity" label="Cantidad *" defaultValue={'0'} />{" "}
                  <div className='pt-2 pr-9'>
                    Comentarios:
                  </div>
                  <Input
                    as="textarea"
                    name="comment"
                    label="Comentario"
                    rows="2"
                    onChange={changeComments}
                    value={comment}
                  />                  
                </div>
              </div>
            </Form>
          </div>
          <div style={{ paddingTop: '20px' }}>
            <ButtonToolbar>
              <Button
                appearance="primary"
                onClick={() => submit(1)}
              >
                Guardar
              </Button>
              <Button onClick={onCloseModal}>Cancelar</Button>
            </ButtonToolbar>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}