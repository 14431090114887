export const getProducts = state => state.ordox.items;
export const getProductsGridRows = state =>
  state.ordox.masterData.products.list;
export const getProductsGridColumns = () => {
  const columns = [
    {
      key: 'value',
      headerAlign: 'center',
      headerText: 'Producto',
      flexGrow: 2,
      width: 500,
      headerClassName: 'grid-header',
      contentClassName: 'grid-row-left',
    },
    {
      key: 'sku',
      headerAlign: 'center',
      headerText: 'SKU',
      flexGrow: 2,
      width: 80,
      headerClassName: 'grid-header',
      contentClassName: 'grid-row-left',
    },
    {
      key: 'formattedPrice',
      headerAlign: 'center',
      headerText: 'Precio de venta',
      flexGrow: 2,
      width: 145,
      headerClassName: 'grid-header',
      contentClassName: 'grid-row-center',
    },
    {
      key: 'formattedTax',
      headerAlign: 'center',
      headerText: 'Impuesto',
      flexGrow: 2,
      width: 130,
      headerClassName: 'grid-header',
      contentClassName: 'grid-row-center',
    },
    {
      key: 'idERP',
      headerAlign: 'center',
      headerText: 'Id Ext.',
      flexGrow: 2,
      width: 100,
      headerClassName: 'grid-header',
      contentClassName: 'grid-row-center',
    },
  ];
  return columns;
};

export const getProductsPaging = (state, params) =>
  state.ordox.masterData.products.paging[params];
export const getProductsFilters = state =>
  state.ordox.masterData.products.filters;
export const getProductsFormValue = state =>
  state.ordox.masterData.products.formValue;
export const getProductsFormError = state =>
  state.ordox.masterData.products.formError;
  export const getProductTaxAccountable = state =>
  state.ordox.company.taxAccountable;
