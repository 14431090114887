import { useUpdateStatusOrdersMutation } from '@api/query/orderApi/orderApi';
import { useInvoiceOrdersMutation } from '@api/query/orderEventApi/orderEventApi';
import React, { useEffect } from "react";
import { useIntl } from 'react-intl';
import { Button, ButtonToolbar, Checkbox, InputPicker, Message, Modal, toaster } from "rsuite";
import * as constants from "../../../utils/Constants";
import { rollbarErrors } from '@utils/rollbarErrors';
import { getMainLoggingParams } from '@selectors/SystemSelector';
import { useSelector } from 'react-redux';

const ModalMultipleBilling = ({
  visible,
  statusList,
  status,
  onClose,
  ordersSelected
}) => {
  const { companyId, user } = useSelector(getMainLoggingParams);
  const [valueStatus, setValueStatus] = React.useState(null);
  const [dataStatus, setDataStatus] = React.useState(null);
  const [bodyStatus, setBodyStatus] = React.useState({});
  const [active, setActive] = React.useState(false);
  const [triggerInvoiceOrders, invoiceOrdersState] = useInvoiceOrdersMutation({});
  const [triggerUpdateStatusOrders, UpdateStatusOrdersResponse] = useUpdateStatusOrdersMutation({});


  const onCloseModal = () => {
    setValueStatus(null)
    onClose()
  }

  useEffect(() => {
    if (UpdateStatusOrdersResponse?.isError) {
      rollbarErrors(UpdateStatusOrdersResponse?.error, bodyStatus, 'PATCH/orders/status/_bulk', companyId, user)
    }
  }, [UpdateStatusOrdersResponse?.isError]);

  useEffect(() => {
    if (invoiceOrdersState?.data && UpdateStatusOrdersResponse?.data) {
      toaster.push(
        <Message
          type="info"
          showIcon
          closable
          duration={constants.NOTIFICATION_SUCESS_QUICK_DURATION}
        >
          {$t({ id: 'TheBillingRequestHasBeenSentSuccessfully' })}
        </Message>,
      );
    } else {
      if (invoiceOrdersState?.data) {
        toaster.push(
          <Message
            type="info"
            showIcon
            closable
            duration={constants.NOTIFICATION_SUCESS_QUICK_DURATION}
          >
            {$t({ id: 'TheBillingRequestHasBeenSentSuccessfully' })}
          </Message>,
        );
      }
      if (UpdateStatusOrdersResponse?.data) {
        toaster.push(
          <Message
            type="info"
            showIcon
            closable
            duration={constants.NOTIFICATION_SUCESS_QUICK_DURATION}
          >
            {$t({ id: 'successUpdateStatus' })}
          </Message>,
        );
      }
    }
    
  }, [invoiceOrdersState, UpdateStatusOrdersResponse]);

    const onChageStatus = value => {
    const result = status.find(
      status => status.value === value,
    )
    setValueStatus(value)
    setDataStatus(result)
  }

  const orders = [];

  ordersSelected.forEach((key) => {
    orders.push(key?.id);
  });

  const getDocumentCreationStatus = type => {
    const status = statusList.find(status => status.role == type);
    return status.id;
  };

  const handleCheckAllCustomer = (value, checked) => {
    setActive(checked)
  }

  const trigerInvoicing = async () => {
    if (active) {
      try {
        triggerInvoiceOrders({
          orders: orders,
          statusId: getDocumentCreationStatus('INVOICE'),
        });
      } catch (error) {
        console.log(error);
      }
    }
    toaster.push(
      <Message
        type="info"
        showIcon
        closable
        duration={constants.NOTIFICATION_SUCESS_QUICK_DURATION}
      >
        {$t({ id: 'TheBillingRequestHasBeenSentSuccessfully' })}
      </Message>,
    );
    if (dataStatus) {
      try {
        setBodyStatus({
          status: {
            id: dataStatus.value,
            role: dataStatus.role,
            description: dataStatus.label,
            listingOrder: dataStatus.listingOrder,
          },
          orders: orders,
        })
        triggerUpdateStatusOrders({
          status: {
            id: dataStatus.value,
            role: dataStatus.role,
            description: dataStatus.label,
            listingOrder: dataStatus.listingOrder,
          },
          orders: orders,
        });
      } catch (error) {
        console.log(error);
      }
    }
    setValueStatus(null)
    setDataStatus(null)
    onClose()
  }

  const { $t } = useIntl();

  return (
    <div>
      <Modal
        className="modal-dialog-container"
        open={visible}
        size="xs"
        keyboard
        onClose={onCloseModal}
      >
        <Modal.Header>
          <Modal.Title style={{ marginBottom: '15px' }}>{$t({ id: 'route.multiple-billing' })}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className="pb-2 pt-0">
            <InputPicker
              // placeholder="Estado de la orden"
              data={status}
              cleanable={false}
              onChange={onChageStatus}
              value={valueStatus || []}
              block
              placeholder={$t({ id: 'action.change-state' })}
            />
          </div>
          <div>
            <Checkbox onChange={handleCheckAllCustomer}> Facturar ordenes seleccionadas</Checkbox>
          </div>
          <div>
            <ButtonToolbar className="pt-4">
              <Button appearance="primary" onClick={trigerInvoicing} >
                Aceptar
              </Button>
              <Button appearance="ghost" onClick={onCloseModal}>
                {$t({ id: 'cancel' })}
              </Button>
            </ButtonToolbar>
          </div>
          <div style={{ marginTop: '8px', fontSize: 'smaller' }}>{ordersSelected?.length} {' '}orden(es) selecionada(s)</div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalMultipleBilling;
