import { State, City } from '@api/query/types';
import AddressLink from '@components/ui/AddressLink';
import DrawerModal from '@components/ui/DrawerModal';
import FormattedPhoneNumber from '@components/ui/FormattedPhoneNumber';
import SearchIcon from '@rsuite/icons/Search';
import { selectCompany } from '@selectors/SystemSelector';
import preventDefault from '@utils/preventDefault';
import useViewport from '@utils/useViewport';
import React, { useDeferredValue, useEffect, useMemo, useState } from 'react';
import { BiCurrentLocation } from 'react-icons/bi';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import { Components, Virtuoso } from 'react-virtuoso';
import { useLocalstorageState } from 'rooks';
import { Button, IconButton, Input, InputGroup, Loader, Modal, Panel } from 'rsuite';
import FormattedEmail from '../ui/FormattedEmail';
import FormattedIndentification from '../ui/FormattedIndentification';
import { ModalCreateOrEditCustomer } from '../../components/Modals/Customer/ModalCreateOrEditCustomer';
import { FaPen, FaPlusCircle } from 'react-icons/fa';
import { Contact, Customer, Model } from '@api/query/customerApi/types';
import { useFindCustomerQuery } from '@api/query/customerApi/customerApi';
import AddEditCustomerContainer from '@containers/Customers/AddEditCustomerContainer';

export type CustomerBrowserProps = {
  className?: string;
  customer?: Customer;
  contact?: Contact;
  contactModel?: Model;
  states?: [State];
  cities?: [City];
  citiesDisabled?: boolean;
  formValuesContact?: Contact;
  onCustomerChange(value: Customer): void;
  onContactChange(value: Contact): void;
  onStatesSearch(value: string): void;
  onChangeContact(value: string): void;
  defaultAdress(value: string): void;
  onStatesChangeContact(value: string): void;
  onCitiesChangeContact(value: string): void;
  onSubmit(form: void, saveAndCreateNew: string, byContact: boolean): void;
  onErrorValidationContact(value: string): void;
  contactFormModel(): void;
  setValuesForm(): void;
};

const CustomerBrowser: React.FC<CustomerBrowserProps> = ({
  className,
  customer,
  contact,
  onCustomerChange,
  onContactChange,
  onStatesSearch,
  onStatesChangeContact,
  onCitiesChangeContact,
  states,
  cities,
  citiesDisabled,
  formValuesContact,
  contactModel,
  onChangeContact,
  onErrorValidationContact,
  onSubmit,
  contactFormModel,
  defaultAdress,
  setValuesForm,
}) => {
  const viewport = useViewport();

  const [open, setOpen] = useState(false);
  const [openCustomerCreate, setOpenCustomerCreate] = useState(false);
  const [customerNew, setCustomerNew] = useState<Customer | undefined>();

  useEffect(() => {
    if (customerNew) {
      onCustomerChange(customerNew)
      setOpenCustomerCreate(false)
      setOpen(false)
    }
  }, [customerNew]);

  const onClose = () => {
    setOpen(false);
  };

  const onCloseCustomerCreate = () => {
    setOpenCustomerCreate(false);
  };

  const [search, setSearch] = useLocalstorageState('customer:search', '');

  const searchDeferred = useDeferredValue(search);

  const customerQuery = useFindCustomerQuery({
    keyword: searchDeferred,
    active: true,
    limit: 100,
    offset: 0,
  });

  const context = useMemo(
    () => ({ data: customerQuery.data?.items }),
    [customerQuery.data],
  );

  type Context = typeof context;

  const components = useMemo<Components<Context>>(() => {
    return {
      EmptyPlaceholder: ({ context: { data } }) => (
        <div className="text-center py-4 font-semibold">
          {data?.length === 0 && <span>No se encontró ningún cliente</span>}
        </div>
      ),
    };
  }, []);

  return (
    <>
      <Panel
        className={className}
        header={
          <div className="flex justify-between">
            <span className="font-medium w-full">
              <FormattedMessage id="customer" />
            </span>
            <span>
              <IconButton
                icon={<SearchIcon />}
                size="sm"
                appearance="link"
                onClick={() => setOpen(true)}>
                {!customer ? 'Buscar cliente' : undefined}
              </IconButton>
            </span>
          </div>
        }>
        {customer && (
          <CustomerSummary
            value={customer}
            onContactsChange={onContactChange}
            contact={contact}
            onStatesSearch={onStatesSearch}
            onStatesChangeContact={onStatesChangeContact}
            states={states}
            cities={cities}
            citiesDisabled={citiesDisabled}
            onCitiesChangeContact={onCitiesChangeContact}
            formValuesContact={formValuesContact}
            contactModel={contactModel}
            onChangeContact={onChangeContact}
            onErrorValidationContact={onErrorValidationContact}
            onSubmit={onSubmit}
            contactFormModel={contactFormModel}
            defaultAdress={defaultAdress}
            setValuesForm={setValuesForm}
          />
        )}
      </Panel>
      <DrawerModal
        open={open}
        DrawerProps={{ size: 'full' }}
        onClose={onClose}
        placement={viewport.isNarrow ? 'bottom' : 'floating'}
        title={<FormattedMessage id="customer.select-customer" />}
        body={
          <div className="flex flex-col flex-grow">
            <div className="flex-row mx-2 mt-1">
              <InputGroup inside size="sm">
                <InputGroup.Addon>
                  <SearchIcon />
                </InputGroup.Addon>
                <Input
                  type="search"
                  autoFocus
                  autoComplete="off"
                  inputMode="search"
                  autoCorrect="off"
                  onChange={value => setSearch(value)}
                  value={search}
                />
                {customerQuery.isFetching && (
                  <InputGroup.Addon>
                    <Loader />
                  </InputGroup.Addon>
                )}
              </InputGroup>
            </div>
            <Button
              className="mt-2"
              style={{alignSelf: 'center'}}
              size="sm"
              onClick={() => {
                  setOpenCustomerCreate(true)
                  setOpen(false)
                }
              }
            >
              Crear un nuevo cliente
            </Button>
            <div className="flex-grow mt-2 relative overflow-hidden md:h-96 shadow-inner">
              <Virtuoso
                increaseViewportBy={200}
                data={customerQuery.data?.items}
                components={components}
                context={context}
                itemContent={(_, value) => (
                  <CustomerItem
                    value={value}
                    active={customer?.id === value.id}
                    onClick={() => {
                      onCustomerChange(value);
                      setOpen(false);
                    }}
                  />
                )}
              />
            </div>
          </div>
        }>
      </DrawerModal>
      <Modal
        open={openCustomerCreate}
        onClose={onCloseCustomerCreate}
        title={'Crear cliente'}
        style={{ width: '98%'}}
      >
        <Modal.Header>
          <Modal.Title >{'Crear cliente'}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div >
            <AddEditCustomerContainer
              setCustomerNew={setCustomerNew}
              createCustomerOrder={true}
            />
          </div>
          </Modal.Body>
      </Modal>
    </>
  );
};

type CustomerItemProps = {
  value: Customer;
  active?: boolean;
  onClick?(
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
    value: Customer,
  ): void;
};

const CustomerItem: React.FC<CustomerItemProps> = ({
  value,
  active,
  onClick,
}) => {
  return (
    <div
      className={`flex flex-row flex-wrap px-4 py-2 border-y -mb-px cursor-pointer transition-colors ${
        active
          ? 'bg-blue-500 text-white'
          : 'text-slate-700 hover:bg-slate-200 hover:text-slate-700'
      } `}
      onClick={ev => onClick(ev, value)}>
      <span className="basis-1/2 font-medium">{value.fullName}</span>
      <FormattedEmail
        className="basis-1/2 text-end text-current visited:text-current hover:text-current"
        value={value.primaryEmail}
        onClick={ev => ev.preventDefault()}
      />
      <span className="basis-full text-end mt-1">
        <b className="mr-1 text-sm">CC:</b>
        <FormattedIndentification
          value={value.identification}
          onClick={ev => ev.preventDefault()}
        />
      </span>
    </div>
  );
};

export default CustomerBrowser;

export type CustomerSummaryProps = {
  value: Customer;
  onContactsChange(value: Contact): void;
  onStatesSearch(value: string): void;
  onChangeContact(value: string): void;
  defaultAdress(value: string): void;
  onStatesChangeContact(value: string): void;
  onCitiesChangeContact(value: string): void;
  onErrorValidationContact(value: string): void;
  onSubmit(form: void, saveAndCreateNew: string, byContact: boolean): void;
  contactFormModel(): void;
  setValuesForm(): void;
  contact: Contact;
  states: [State];
  cities: [City];
  citiesDisabled: boolean;
  formValuesContact: Contact;
  contactModel: Model;
};

const CustomerSummary: React.FC<CustomerSummaryProps> = ({
  value: {
    fullName,
    brandName,
    primaryEmail,
    phone,
    mobile,
    address,
    state,
    identification,
    idType,
    city,
    contacts,
  },
  onContactsChange,
  contact,
  onStatesSearch,
  onChangeContact,
  defaultAdress,
  onStatesChangeContact,
  states,
  cities,
  citiesDisabled,
  onCitiesChangeContact,
  onErrorValidationContact,
  formValuesContact,
  contactModel,
  onSubmit,
  contactFormModel,
  setValuesForm,
}) => {
  const [openContact, setOpenContact] = useState(false);
  const [openAddEdit, setOpenAddEdit] = useState(false);

  const onClose = () => {
    setOpenContact(false);
  };
  const { $t } = useIntl();

  const onCloseContact = () => {
    setValuesForm();
    setOpenAddEdit(false);
  };
  const viewport = useViewport();

  return (
    <div className="mb-2">
      <div className="flex flex-col">
        <h4 className="text-base">{brandName ? brandName : fullName}</h4>
        <span>
          <span className="font-semibold mr-1">{idType}</span>
          <FormattedIndentification value={identification} />
        </span>
      </div>

      <div className="my-4 border-t border-gray-300 -mx-8" />
      <div className="flex flex-col">
        <div className="flex justify-between">
          <span className="font-bold w-full decoration-black text-base">
            <FormattedMessage id="info-contact" />
          </span>
          <span className="mr-1.5">
            <IconButton
              icon={<FaPlusCircle />}
              size="sm"
              title={$t({ id: 'add-a-new-contact' })}
              onClick={() => {
                contactFormModel();
                setOpenAddEdit(true);
              }}></IconButton>
          </span>
          <span>
            <IconButton
              icon={<FaPen />}
              size="sm"
              title={$t({ id: 'modify-contact' })}
              onClick={() => setOpenContact(true)}></IconButton>
          </span>
        </div>
        <DrawerModal
          className="md:w-96"
          open={openContact}
          DrawerProps={{ size: 'full' }}
          onClose={onClose}
          placement={viewport.isNarrow ? 'bottom' : 'floating'}
          title={<FormattedMessage id="customer.select-contact" />}
          body={
            <div className="flex flex-col flex-grow">
              <div className="flex-grow mt-2 relative overflow-hidden md:h-44 shadow-inner">
                <Virtuoso
                  increaseViewportBy={200}
                  data={contacts}
                  // components={components}
                  // context={context}
                  itemContent={(_, value) => (
                    <ContactItem
                      value={value}
                      // active={customer?.id === value.id}
                      onClick={() => {
                        onContactsChange(value);
                        setOpenContact(false);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          }></DrawerModal>
        {fullName && (
          <div>
            <ModalCreateOrEditCustomer
              visible={openAddEdit}
              onStatesSearch={onStatesSearch}
              onStatesChange={onStatesChangeContact}
              states={states}
              cities={cities}
              // props={props}
              citiesDisabled={citiesDisabled}
              onCitiesChange={onCitiesChangeContact}
              formValues={formValuesContact}
              model={contactModel}
              onChange={onChangeContact}
              onSubmit={onSubmit}
              onErrorValidation={onErrorValidationContact}
              onCloseModal={onCloseContact}
              // actionModal={this.state.actionModal}
              defaultAdress={defaultAdress}
            />
          </div>
        )}
        <span className="mr-1">
          {contact?.name} {contact?.lastName}
        </span>
        <FormattedEmail
          value={contact?.primaryEmail}
          placeholder="No se encontró email"
          onClick={preventDefault()}
        />
        <FormattedPhoneNumber
          value={contact?.mobile}
          placeholder="Movil no proveido"
        />
      </div>

      <div className="my-4 border-t border-gray-300 -mx-8" />
      <div className="mt-4 flex flex-col">
        <h4 className="text-base mb-1">Información de envio</h4>
        {contact?.address ? (
          <span className="flex flex-col select-text">
            <span>
              <span className="mr-1">
                {(contact?.state && contact?.state.name) || ''},
              </span>
              <span className="mr-1">
                {(contact?.city && contact?.city.name) || ''}
              </span>
            </span>
            <span className="flex flex-row items-center">
              <span>{contact?.address}</span>
              <AddressLink query={address} place={(city && city.name) || ''}>
                <BiCurrentLocation className="text-lg mx-2" />
              </AddressLink>
            </span>
          </span>
        ) : (
          'Dirección no proveida'
        )}
      </div>
    </div>
  );
};

type ContactItemProps = {
  value: Contact;
  active?: boolean;
  onClick?(ev: React.MouseEvent<HTMLElement, MouseEvent>, value: Contact): void;
};

const ContactItem: React.FC<ContactItemProps> = ({
  value,
  active,
  onClick,
}) => {
  return (
    <div
      className={`flex flex-row flex-wrap px-4 py-2 border-y -mb-px cursor-pointer transition-colors ${
        active
          ? 'bg-blue-500 text-white'
          : 'text-slate-700 hover:bg-slate-200 hover:text-slate-700'
      } `}
      onClick={ev => onClick(ev, value)}>
      <span className="basis-1/2 font-medium">
        {value.name} {value?.lastName}
      </span>
      <span className="basis-1/2 text-end text-current visited:text-current hover:text-current">
        <span className="basis-1/2 font-medium">
          {value?.state?.name}
          {', '}
          {value?.city?.name}
        </span>
      </span>
    </div>
  );
};