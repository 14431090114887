import React from 'react';

export type FormattedEmailProps = Omit<
  React.HTMLAttributes<HTMLAnchorElement>,
  'placeholder'
> & {
  value?: string;
  placeholder?: React.ReactNode;
};

const FormattedEmail: React.FC<FormattedEmailProps> = ({
  value = '',
  placeholder,
  ...props
}) => {
  return (
    value && (
      <a {...props} href={`mailto:${value}`}>
        {value ? value : placeholder}
      </a>
    )
  );
};

export default FormattedEmail;
