import React from 'react'
import { FormattedMessage } from "react-intl";

export function GeneralInformation({ customer }) {
  return <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table className="w-full text-sm text-left text-gray-500">
      <tbody>
        <tr className="border-b border-gray-200">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
            <FormattedMessage id="name" />
          </th>
          <td className="px-6 py-2">
            {customer.fullName}
          </td>

        </tr>
        <tr className="border-b border-gray-200">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
            <FormattedMessage id="identification" />
          </th>
          <td className="px-6 py-2">
            {customer.idType} - {customer.identification}
          </td>
        </tr>
        <tr className="border-b border-gray-200">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
            <FormattedMessage id="email" />
          </th>
          <td className="px-6 py-2">
            {customer.primaryEmail}
          </td>
        </tr>
        <tr className="border-b border-gray-200">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
            <FormattedMessage id="phone" />
          </th>
          <td className="px-6 py-2">
            {customer.mobile}
          </td>
        </tr>
        <tr className="border-b border-gray-200">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
            <FormattedMessage id="sales-channel" />
          </th>
          <td className="px-6 py-2">
            {customer.channel?.description}
          </td>
        </tr>
        <tr className="border-b border-gray-200">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
            <FormattedMessage id="price-description" />
          </th>
          <td className="px-6 py-2">
            {customer.listPrice?.description}
          </td>
        </tr>
        <tr>
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
            <FormattedMessage id="state" />
          </th>
          <td className="px-6 py-2">
            {customer.active ? "Activo" : "Inactivo"}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
}