import { createApi } from '@reduxjs/toolkit/query/react';

import { ordoxBaseQuery } from "../baseQuery";
import {
  CreateProductRequest,
  FindListPriceProductRequest,
  FindListPriceProductResponse,
  FindProductRequest,
  FindProductResponse,
  Product,
  UpdateProductRequest,
  synchronizationsRequest,
} from "./types";
import queryString from "query-string";
import { Message, downloadRequest } from '../customerApi/types';

const productApi = createApi({
  reducerPath: 'product-api',
  tagTypes: ['product'],
  baseQuery: ordoxBaseQuery(),
  endpoints: ({ query, mutation }) => ({
    findProduct: query({
      providesTags: ['product'],
      query: (filters: FindProductRequest) => {
        const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
        return {
          url: `products${(params && `?${params}`) || ''}`,
          method: 'GET'
        }
      },
      transformResponse: (response: FindProductResponse, _, request) => {
        response.items = response.items.map(item => ({
          ...item,
          unit: (item.unit as string) === 'unit' ? 'uds' : item.unit,
        }))

        return {
          ...response,
          offset: request.offset,
          limit: request.limit,
        };
      },
    }),
    findListPriceProduct: query<FindListPriceProductResponse, FindListPriceProductRequest>({
      providesTags: ['product'],
      query: (filters) => {
        const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
        return {
          url: `products/prices${(params && `?${params}`) || ''}`,
          method: 'GET'
        }
      },
    }),
    createProduct: mutation<Product, CreateProductRequest>({
      invalidatesTags: ['product'],
      query: data => ({
        url: 'products',
        method: 'POST',
        body: data,
      }),
      transformResponse(baseQueryReturnValue, meta, arg): Product {
        return baseQueryReturnValue as Product
      },
    }),
    updateProduct: mutation<Product, UpdateProductRequest>({
      invalidatesTags: ['product'],
      query: ({ id, data }) => {
        return {
          url: `/products/${id}`,
          method: 'PATCH',
          body: data,
        }
      },
    }),
    synchronizationsProduct: mutation<Message, synchronizationsRequest>({
      invalidatesTags: ['product'],
      query: ({ ids, groupIntoSingleProduct }) => ({
        url: `/products/synchronizations`,
        method: 'PATCH',
        body: { ids, groupIntoSingleProduct },
      }),
    }),
    downloadData: mutation<Product, downloadRequest>({
      invalidatesTags: ['product'],
      query: data => ({
        url: '/products/downloads',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useUpdateProductMutation,
  useCreateProductMutation,
  useFindProductQuery,
  useLazyFindProductQuery,
  useDownloadDataMutation,
  useFindListPriceProductQuery,
  useLazyFindListPriceProductQuery,
  useSynchronizationsProductMutation,
  util: { invalidateTags: productApiInvalidateTagAction },
} = productApi;

export default productApi;
