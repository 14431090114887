import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import types from '../actions/ActionTypes';
import api from '../api/DashboardApi';
import orderApi from '../api/_OrderApi'
import * as Mappers from '../utils/Mappers/DashboardMappers';
import dayjs, { dayjsCalendar, dayjsRanges, formats } from '@utils/dayjs';

function* getDashboardData(action) {
  let response = null;

  try {
    const companyId = action.payload.loggingParams.companyId;
    let daysRange = 0;
    let dashboardController = {};
    let buttonGroupStatus = [
      { button: 'lastMonth', active: false },
      { button: 'currentMonth', active: false },
    ];
    const dashboardData = {};
    const selection = action.payload.selection;
    let fromDate, toDate;

    yield put(actions.setShowSpinner(true));
    try {
      if (selection === 'PREVIOUS_MONTH') {
        fromDate = dayjs().subtract(1, 'month').startOf('month').toDate()
        toDate = dayjs().subtract(1, 'month').endOf('month').toDate()
        response = yield call(orderApi.find, { fromCreatedAt: fromDate, toCreatedAt: toDate, actives: false, offset: 0 })
        buttonGroupStatus[1].active = true;

      } else if (selection === 'CURRENT_MONTH' || selection === undefined) {
        fromDate = dayjs().startOf('month').toDate()
        toDate = dayjs().endOf('month').toDate()
        response = yield call(orderApi.find, { fromCreatedAt: fromDate, toCreatedAt: toDate, actives: false, offset: 0 })
        buttonGroupStatus[1].active = true;

      } else {
        buttonGroupStatus[2].active = true;
        response = yield call(api.getDailyLiveStatus);
      }
    
      if (response && response.status === 200) {
        // set dashboard controllers
        dashboardController.currentSelection = selection;
        dashboardController.buttonGroupStatus = buttonGroupStatus;
        dashboardController.lastUpdate = Date.now();

        const localTimeData = Mappers.getLocalTimeData(
          response.data.items,
          'createdAt',
        );
        const currentMonthOnTime = Mappers.getOnTimeData(
          localTimeData,
          action.payload.deliveryStatusId,
          action.payload.cancelStatusId,
        );

        const customerData = Mappers.getDataByCustomer(
          localTimeData,
          action.payload.topTenDimension,
          action.payload.deliveryStatusId,
          action.payload.cancelStatusId,
        );
        dashboardData.currentMonthOnTime = currentMonthOnTime;
        dashboardData.customerData = customerData;

        yield put(actions.setDashboardData(dashboardData));


        yield put(actions.setDashboardControllers(dashboardController));
      } else if (response.status === 401) {
        yield put(actions.setOpenAuthModal({ open: true }));
      } else {
        console.log('oups! something went wrong');
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        console.log(error.response.data.message);
      } else if (error?.response?.status === 401) {
        yield put(actions.setOpenAuthModal({ open: true }));
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

export default function* () {
  yield all([takeLatest(types.GET_DASHBOARD_DATA, getDashboardData)]);
}
