import queryString from 'query-string';

import axios from '../libs/axios';

const api = {
  async getOrderTrackingInformation(companyId, orderId) {
    return await axios
      .get('OrderTracking?companyId=' + companyId + '&orderId=' + orderId)

      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })

      .catch(error => {
        if (error.response) {
          console.log('an error was found');
          console.log(error.response.data);
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
      });
  },

  async getTrackingFullData(orderId, filters = {}) {
    const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
    return await axios.get(`orders-tracking/orders/${orderId}${params && `?${params}`}`)
  },

  async updateOrderStatus(id, data) {
    return await axios.patch(`orders/${id}/status`, data)
  },

  triggerOrderEvent(id, data) {
    return axios.patch(`/orders/${id}/invoices`, data)
  },
};

export default api;
