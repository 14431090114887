import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ordoxBaseQuery } from './baseQuery';

export type Channel = {
  id: string;
  description: string;
  priority: number;
  companyId: string;
  createdAt: string;
  updatedAt: string;
  origin: string[];
};

type FindChannelRequest = {};

type FindChannelResponse = {
  total: number;
  items: Channel[];
};

export const channelApi = createApi({
  reducerPath: 'channel-api',
  baseQuery: ordoxBaseQuery(),
  endpoints: builder => ({
    findChannel: builder.query<FindChannelResponse, FindChannelRequest>({
      query: () => ({
        url: '/channels',
        method: 'GET',
      }),
    }),
  }),
});

export const { useFindChannelQuery } = channelApi;

export default channelApi;
