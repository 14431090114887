import { createApi } from '@reduxjs/toolkit/query/react';
import { ordoxBaseQuery } from "../baseQuery";
import { CustomFieldsRequest, CustomFieldsResponse } from "./types";
import queryString from 'query-string';


const customFieldApi = createApi({
    reducerPath: 'custom-fields-api',
    tagTypes: ['custom-fields'],
    baseQuery: ordoxBaseQuery(),
    endpoints: ({ query, mutation }) => ({
      findCustomFields: query<CustomFieldsResponse, {}>({
        providesTags: ['custom-fields'],
        query: filters => {
          const params = queryString.stringify(filters, {
            skipNull: true,
            skipEmptyString: true,
          });
  
          return {
            url: `/custom-fields${(params && `?${params}`) || ''}`,
            method: 'GET',
          };
        },
      }),
      createCustomField: mutation<CustomFieldsResponse, CustomFieldsResponse>({
        invalidatesTags: ['custom-fields'],
        query: (data) => ({
          url: `/custom-fields`,
          method: 'POST',
          body: data,
        }),
      }),
      updateCustomField: mutation<CustomFieldsRequest, CustomFieldsRequest>({
        invalidatesTags: ['custom-fields'],
        query: ({ id, data }) => ({
          url: `/custom-fields/${id}`,
          method: 'PATCH',
          body: data,
        }),
      }),
    }),
  });
  
  export const {
    useFindCustomFieldsQuery,
    useLazyFindCustomFieldsQuery,
    useCreateCustomFieldMutation,
    useUpdateCustomFieldMutation,
  } = customFieldApi;
  
export default customFieldApi;
  