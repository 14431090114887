import queryString from 'query-string';

import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ordoxBaseQuery } from './baseQuery';

export type Tax = {
  id: string;
  idERP: string;
  description: string;
  percentage: number;
  companyId: string;
};

type FindTaxesRequest = {};
type FindTaxesResponse = {
  total: number;
  items: Tax[];
};

export const taxApi = createApi({
  reducerPath: 'tax-api',
  baseQuery: ordoxBaseQuery(),
  endpoints: builder => ({
    findTaxes: builder.query<FindTaxesResponse, FindTaxesRequest>({
      query: (filters = {}) => {
        const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
        return {
          url: `taxes${params ? `?${params}` : ''}`,
          method: 'GET'
        }
      }
    }),
  }),
});

export const { useFindTaxesQuery } = taxApi;

export default taxApi;
