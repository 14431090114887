import CustomerInfo from '@components/CustomerInfo';
import OrderItems from '@components/OrderItems/OrderItems';
import OrderTracking from '@components/OrderTracking';
import GeneralInfo from '@components/OrderTracking/GeneralInfo';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer, Modal, Panel, Tag } from 'rsuite';
import * as actions from '../../actions';
import * as SelectorOrderTracking from '../../selectors/OrderTrackingSelector';
import {
  getMainLoggingParams,
  getUserDevice
} from '../../selectors/SystemSelector';
import { FormattedMessage } from 'react-intl';

class OrderTrackingContainer extends Component {
  constructor(props) {
    super(props);
    this.closeOrderTracking = this.closeOrderTracking.bind(this);
    this.bodyModalRef = React.createRef();
    this.state = { scrollTop: 0 };
  }

  componentDidMount() {
    this.bodyModalRef.current.addEventListener('scroll', this.handleScrollEvent)
  }
  componentWillUnmount() {
    this.bodyModalRef.current.removeEventListener('scroll', this.handleScrollEvent)
  }

  closeOrderTracking() {
    this.props.hideOrderTracking('orderTracking');
  }

  handleScrollEvent = (event) => {
    const scrollTop = this.bodyModalRef?.current.scrollTop
    this.setState({ scrollTop })
  }

  render() {
    const orderTracking = this.props.orderTracking[0];


    if (orderTracking) {
      return (
        <div>
          <Modal
            size="md"
            placement="top"
            open={this.props.orderTrackingVisible}
            onClose={this.closeOrderTracking}
            autoFocus
            keyboard
          >
            <Modal.Header>
              <Drawer.Title className="font-medium">
                <div className='flex items-center '>
                  <div className='mr-5'>Savia #{orderTracking.order.orderNumber}</div>
                  {orderTracking.order.isPaid === false ?
                    <div className='mr-5 flex items-center'><Tag color="red"> <FormattedMessage id="pending-payment" /></Tag></div>
                    : null}
                </div>
              </Drawer.Title>
            </Modal.Header>

            <Modal.Body ref={this.bodyModalRef} >
              <div className="container">
                <div className="grid grid-cols-1 md:grid-cols-5 gap-1">
                  <div className="col-span-3 md:self-stretch flex">
                    <Panel bordered className="flex-1">
                      <CustomerInfo customer={orderTracking.order.customer} shippingAddress={orderTracking.order.shippingAddress} />
                    </Panel>
                  </div>
                  <div className="row-span-2 col-span-2 flex">
                    <Panel bordered className="flex-1 lg:h-80 lg:overflow-y-scroll">
                      <OrderTracking
                        trackingInfo={orderTracking.orderTracking}
                      />
                    </Panel>
                  </div>
                  <div className="col-span-3 md:self-stretch flex">
                    <Panel bordered className="flex-1">
                      <GeneralInfo
                        order={orderTracking.order}
                        ecomSite={this.props.ecomSite}
                      />
                    </Panel>
                  </div>
                </div>
              </div>
              <div className="bg-red container">
                <OrderItems
                  orderItems={orderTracking.orderItems}
                  subTotal={orderTracking.order.totalBeforeTax}
                  discount={orderTracking.order.discount}
                  isMobile={this.props.isMobile}
                  totalAmount={orderTracking.order.totalAfterTax}
                  totalUnits={orderTracking.order.totalUnits}
                  scrollTop={this.state.scrollTop}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    orderTracking: SelectorOrderTracking.getOrderTrackingData(state),
    orderTrackingVisible: SelectorOrderTracking.getOrderTrackingVisible(state),
    // orderItems: SelectorOrderTracking.getOrderItems(state),
    isMobile: getUserDevice(state),
    ecomSite: getMainLoggingParams(state).ecomSite,
  };
};

export default connect(mapStateToProps, actions)(OrderTrackingContainer);
