import React, { useRef } from "react";
import { Button, ButtonToolbar, Col, Container, Message, Modal, Row, useToaster } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { ExcelRenderer } from "react-excel-renderer";

import { FileUploader } from "@containers/FileUploader/FileUploader";
import * as Constants from "../../../utils/Constants";
import { FILE_UPLOADING_ERROR, FILE_UPLOADING_INVALID_FILETYPE } from "@utils/Notifications";
import { getProcessEnabled, getSlots, getUploadedFileName } from "@selectors/DeliverySlotsSelector";
import { createDeliverySlots, setFileUploaderData, updateProcessEnabled } from "@actions/index";

export function CreateDeliverySlot(props) {
  const {
    visible,
    onClose,
    title,
    onDeliverySlotLoaded,
    
  } = props;

  const toaster = useToaster();
  const fileInput = useRef();
  const uploadedFileName = useSelector(getUploadedFileName)
  const processEnabled = useSelector(getProcessEnabled)
  const slots = useSelector(getSlots)
  const dispatch = useDispatch()

  const fileHandler = (value) => {
    if (value.length) {
      const fileName = value[0].name;
      const extension = fileName.slice(fileName.lastIndexOf(".") + 1);

      if (extension === "xlsx" || extension === "xls") {
        dispatch(setFileUploaderData({
          context: Constants.DELIVERY_SLOTS_CONTEXT,
          data: { key: "uploadedFileName", value: fileName },
        }))
        dispatch(setFileUploaderData({
          context: Constants.DELIVERY_SLOTS_CONTEXT,
          data: { key: "isFormInvalid", value: false },
        }))
        renderFile(value[0].blobFile);
      } else {
        toaster.push(
          <Message
            type="error"
            showIcon
            closable
            duration={Constants.NOTIFICATION_DURATION}
          >
            {FILE_UPLOADING_INVALID_FILETYPE}
          </Message>
        );
        dispatch(setFileUploaderData({
          context: Constants.DELIVERY_SLOTS_CONTEXT,
          data: { key: "uploadedFileName", value: "" },
        }))
        dispatch(setFileUploaderData({
          context: Constants.DELIVERY_SLOTS_CONTEXT,
          data: { key: "isFormInvalid", value: true },
        }))
      }
    }
  };

  const handleOpenFileBrowser = () => {
    (fileInput.current as any)?.click();
  };

  const renderFile = (fileObject) => {
    ExcelRenderer(fileObject, (err, resp) => {
      if (err) {
        toaster.push(
          <Message
            type="error"
            showIcon
            closable
            duration={Constants.NOTIFICATION_DURATION}
          >
            {FILE_UPLOADING_ERROR}
          </Message>
        );
        console.error(err);
      } else {
        // Alert.success(FILE_UPLOADING_SUCESS,Constants.NOTIFICATION_SUCESS_QUICK_DURATION);
        dispatch(setFileUploaderData({
          context: Constants.DELIVERY_SLOTS_CONTEXT,
          data: { key: "dataLoaded", value: true },
        }))
        dispatch(setFileUploaderData({
          context: Constants.DELIVERY_SLOTS_CONTEXT,
          data: { key: "cols", value: resp.cols },
        }))
        dispatch(setFileUploaderData({
          context: Constants.DELIVERY_SLOTS_CONTEXT,
          data: { key: "rows", value: resp.rows },
        }))
        dispatch(updateProcessEnabled(true))
      }
    });
  };

  const handleCreateSlots = () => {
    dispatch(createDeliverySlots(
      { totalSlots: slots },
      redirectListDeliverySlots
    ))
    dispatch(setFileUploaderData({
      context: Constants.DELIVERY_SLOTS_CONTEXT,
      data: { key: "uploadedFileName", value: "" },
    }))
    dispatch(setFileUploaderData({
      context: Constants.DELIVERY_SLOTS_CONTEXT,
      data: { key: "cols", value: null },
    }))
    dispatch(setFileUploaderData({
      context: Constants.DELIVERY_SLOTS_CONTEXT,
      data: { key: "rows", value: null },
    }))
    dispatch(updateProcessEnabled(false))
    onClose()
  };

  const redirectListDeliverySlots = (info) => {
    if(info?.finish){
      onDeliverySlotLoaded()
    }
  }

  return (
    <div>
      <Modal
        className="modal-dialog-container"
        open={visible}
        size="xs"
        keyboard
        onClose={onClose}
      >
        <Modal.Header>
          <Modal.Title style={{ marginBottom: '10px' }}> {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: 'hidden' }}>
          <Container>
            <Row className="pt-4">
              <Col style={{ maxWidth: '100%' }}>
                <FileUploader
                  fileHandler={fileHandler}
                  handleOpenFileBrowser={handleOpenFileBrowser}
                  uploadedFileName={uploadedFileName}
                  fileInput={fileInput}
                />

              </Col>
            </Row>
            <Row className="pb-2">
              <Col>
                <ButtonToolbar >
                  <Button
                    appearance="primary"
                    disabled={!processEnabled}
                    className="mr-2"
                    onClick={handleCreateSlots}
                  >
                    Procesar
                  </Button>
                  <Button onClick={onClose}>Cancelar</Button>
                </ButtonToolbar>

              </Col>
            </Row>

          </Container>

        </Modal.Body>
      </Modal>
    </div>
  )
}