import axios from '../libs/axios';

const api = {
  async getDashboardData(filter) {
    return await axios
      .get('order/findfulldatadashboard/', { params: filter })
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
        console.log('problem on getting products!!');
        console.log(error);
      });
  },
};

export default api;
