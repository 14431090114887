import PageHeader, { PageHeaderProvider } from '@components/PageHeader';
import PageLayout from '@components/PageLayout';
import { getMainLoggingParams, validatepermissions } from '@selectors/SystemSelector';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PriceListView from './PriceListView';

const PriceListSettings: React.FC = () => {
  const {
    permissions,
    ...loggingParams
  } = useSelector(getMainLoggingParams);

  const history = useHistory();
  useEffect(() => {
    if (validatepermissions(permissions, 'MENU_LISTPRICE')) {
      history.push({ pathname: '/home' });
    }
  }, []);

  return (
    <PageHeaderProvider>
      <PageLayout
        header={
          <PageHeader
            title={
              <span>
                <FormattedMessage id="price-lists" />
              </span>
            }
          />
        }>
        <PriceListView />
      </PageLayout>
    </PageHeaderProvider>
  );
};

export default PriceListSettings;
