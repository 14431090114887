import queryString from 'query-string';

import axios from '../libs/axios';

const api = {
  async getAllStates() {
    return await axios
      .get('MasterData/GetAllStates')

      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
      });
  },

  async getAllStateCities(filters = {}) {
    const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
    return await axios.get(`cities${params && `?${params}`}`)
  },

  getAllChannels(filters) {
    return axios.get('channels')
  },

  getAllInvoicingSettings(filters = {}) {
    const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
    return axios.get(`invoicing-settings${params && `?${params}`}`)
  },

  async getAllTaxes(filter) {
    return await axios
      .get('taxes', filter)

      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
      });
  },
  async getAllFulFillmentTypes(filter) {
    return await axios
      .get('fulfillment-types', filter)

      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
      });
  },

  searchStates(filters = {}) {
    const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
    return axios.get(`states${params && `?${params}`}`)
  },

  async countStates(companyId, columns, filters) {
    return await axios
      .get(
        'Geo/CountStates?companyId= ' +
          companyId +
          'columns=' +
          columns +
          '&filters=' +
          filters,
      )
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
      });
  },

  async searchCities(companyId, columns, filters, pageSize, page) {
    const URL =
      'Geo/SearchCities?companyId=' +
      companyId +
      '&columns=' +
      columns +
      '&filters=' +
      filters +
      '&pageSize=' +
      pageSize +
      '&page=' +
      page;

    return await axios
      .get(URL)
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
      });
  },

  async countCities(companyId, columns, filters) {
    return await axios
      .get(
        'Geo/CountCities?companyId=' +
          companyId +
          'columns=' +
          columns +
          '&filters=' +
          filters,
      )
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
      });
  },

  getAllTags(filters) {
    return axios.get('tags')
  },
};
export default api;
