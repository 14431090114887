import React from "react";
import { Button, ButtonToolbar, Form, Input, InputPicker, Modal } from "rsuite";
import "../../styles/Login.css";
import { SignupModel } from "../../utils/Validations/SignupModel";

export class Signup extends React.PureComponent {
  render() {
    const {
      visible,
      formValues,
      formAction,
      onChangedForm,
      onSubmit,
      onClose,
      model,
    } = this.props;

    return (
      <div>
        <Modal size="xs" open={visible} onClose={onClose} keyboard>
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <Form
              //   layout="horizontal"
              // fluid
              ref={(ref) => (this.form = ref)}
              model={SignupModel}
              onChange={(formValues) => onChangedForm(formValues)}
              formValue={formValues}
            >
              <TextField name="fullName" label="Tu nombre"></TextField>
              <TextField
                name="company"
                label="Empresa"
                tooltip
                tooltipText="Nombre de tu empresa o marca"
              ></TextField>
              <TextField
                name="primaryEmail"
                label="Email"
                tooltip
                tooltipText="Ingresa una cuenta de Google"
              ></TextField>

              <Form.Group controlId="inputPicker">
                <Form.ControlLabel>Sistema contable</Form.ControlLabel>
                <Form.Control
                  name="accounting"
                  accepter={InputPicker}
                  data={["Alegra", "Siigo", "Otro"].map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              </Form.Group>

              <Form.Group controlId="inputPicker">
                <Form.ControlLabel>e-commerce</Form.ControlLabel>
                <Form.Control
                  name="ecommerce"
                  accepter={InputPicker}
                  data={["Shopify", "Wocommerce", "Prestashop", "Otro"].map(
                    (item) => ({
                      label: item,
                      value: item,
                    })
                  )}
                />
              </Form.Group>

              <Form.Group>
                <ButtonToolbar>
                  <Button
                    appearance="primary"
                    type="submit"
                    onClick={() => onSubmit(this.form)}
                  >
                    Crear cuenta
                  </Button>
                  <Button onClick={onClose}>Cancelar</Button>
                </ButtonToolbar>
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export class TextField extends React.PureComponent {
  render() {
    const {
      name,
      label,
      accepter,
      tooltip,
      tooltipText,
      ...props
    } = this.props;
    return (
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Form.Control
          name={name}
          accepter={Input}
          //   style={{ width: 200 }}
          {...props}
        />
        {tooltip && <Form.HelpText tooltip>{tooltipText}</Form.HelpText>}
      </Form.Group>
    );
  }
}
