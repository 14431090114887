import React from "react"
import { Event } from "@api/query/eventApi/types";
import { FormattedMessage } from "react-intl";
import { Drawer, Modal } from "rsuite";

import { TabsComponent } from '../../../components/Tabs'
import { TabContent } from '../../../components/Tabs/TabContent'
import { ModifiedData } from "./ModifiedData";
import { Traceability } from "./Traceability";


interface Props {
	event: Event;
	oncloseModal: () => void;
	isOpenDetailModal: boolean;
}
const tabs = [
	{
		id: 0,
		label: <FormattedMessage id="modified-data" />
	},
	{
		id: 1,
		label: <FormattedMessage id="traceability" />
	},
]

export function ModalEventDetail({ isOpenDetailModal, oncloseModal, event }: Props) {
	return (
		<div>
			<Modal
				size="md"
				// placement="top"
				open={isOpenDetailModal}
				onClose={() => oncloseModal()}
				autoFocus
				keyboard
			>
				<Modal.Header>
					<Drawer.Title className="font-medium  text-xl">
						{`${event?.event}: ${event?.shortDescription}`}
					</Drawer.Title>
				</Modal.Header>
				<Modal.Body  >

					{/* <!--Tabs navigation--> */}
					<TabsComponent tabs={tabs}>
						<TabContent id={0}>
							<ModifiedData event={event}/>
						</TabContent>
						<TabContent id={1}>
							<Traceability event={event}/>
						</TabContent>
					</TabsComponent>
					<div className="bg-red container">
					</div>
				</Modal.Body>
			</Modal>
		</div>
	)
};