import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, ButtonToolbar, InputPicker, Message, Modal, toaster } from 'rsuite';
import TrashIcon from '@rsuite/icons/Trash';
import { dataEntity, dataFieldsType } from '../constants/customFields';
import { useCreateCustomFieldMutation, useUpdateCustomFieldMutation } from '@api/query/customFieldsApi/customFields';
import { NOTIFICATION_DURATION } from '@utils/Constants';
import { FormattedMessage } from 'react-intl';


export function ModalCustomFields(props) {
  const {
    visible,
    onClose,
    customFieldInfo,
    title,
    isEditing
  } = props;
  const { control, handleSubmit, setValue, formState: { errors } } = useForm();

  const [setCreateCustomField] = useCreateCustomFieldMutation();
  const [setUpdateCustomField] = useUpdateCustomFieldMutation();
  const [listValues, setListValues] = useState([]);
  const [fieldType, setFieldType] = useState(undefined);
  const [entityFocused, setEntityFocused] = useState(false);
  const [fieldTypeFocused, setFieldTypeFocused] = useState(false);

  useEffect(() => {
    if (fieldType === 'LIST') {
      setValue('values', []);
    } else {
      setValue('values', undefined);
    }
  }, [fieldType, setValue]);

  useEffect(() => {
    if (isEditing && customFieldInfo) {
      setValue('entity', customFieldInfo.entity);
      setValue('fieldType', customFieldInfo.fieldType);
      setFieldType(customFieldInfo.fieldType);
      setValue('length', customFieldInfo.length);
      setValue('order', customFieldInfo.order);
      setValue('defaultValue', customFieldInfo.defaultValue);
      setValue('name', customFieldInfo.name);
      setValue('title', customFieldInfo.title);
      setValue('mandatory', customFieldInfo.mandatory);
      if (customFieldInfo.fieldType === 'LIST') {
        setListValues(customFieldInfo.values || []);
        setValue('values', customFieldInfo.values || []);
      }
    } else {
      setValue('entity', dataEntity[0]?.value);
      setValue('fieldType', dataFieldsType[0]?.value);
      setFieldType(dataFieldsType[0]?.value);
    }
  }, [isEditing, customFieldInfo, setValue]);

  const onSubmit = (data) => {
    const finalData = {
    ...data,
    values: listValues.map(item => ({ label: item.label, value: item.value })),
    };
    if (isEditing) {
      const newObject = {};
      let valuesChanged = false;

      for (const prop in finalData) {
        if (finalData.hasOwnProperty(prop)) {
          if (Array.isArray(finalData[prop]) && Array.isArray(customFieldInfo[prop])) {
            if (!valuesChanged) {
              valuesChanged = JSON.stringify(finalData[prop]) !== JSON.stringify(customFieldInfo[prop]);
            }
            
            if (valuesChanged) {
              newObject[prop] = finalData[prop];
            }
          } else {
            if (finalData[prop] !== customFieldInfo[prop]) {
              newObject[prop] = finalData[prop];
            }
          }
        }
      }
      setUpdateCustomField({ id: customFieldInfo?.id, data: newObject })
        .then(resp => {
          try {
            const menssageError = resp?.error?.data?.message
            if (menssageError) {
              toaster.push(
                <Message
                  type="error"
                  showIcon
                  closable
                  duration={NOTIFICATION_DURATION}
                >
                  {menssageError}
                </Message>
              );
            } else {
              toaster.push(
                <Message
                  type="success"
                  showIcon
                  closable
                  duration={NOTIFICATION_DURATION}
                >
                  {'El campo dinamico ha sido actualizado correctamente'}
                </Message>
              );
            }
          } catch (error) {
            console.error('Error en la promesa:', error);
          }
        })
        .catch(_error => {
          toaster.push(
            <Message
              type="error"
              showIcon
              closable
              duration={NOTIFICATION_DURATION}
            >
              <FormattedMessage id="create-fields.error" />
            </Message>
          );
        })
    } else {
      setCreateCustomField(finalData)
        .then(resp => {
          try {
            const menssageError = resp?.error?.data?.message
            if (menssageError) {
              toaster.push(
                <Message
                  type="error"
                  showIcon
                  closable
                  duration={NOTIFICATION_DURATION}
                >
                  {menssageError}
                </Message>
              );
            } else {
              toaster.push(
                <Message
                  type="success"
                  showIcon
                  closable
                  duration={NOTIFICATION_DURATION}
                >
                  {'El campo dinamico ha sido creado correctamente'}
                </Message>
              );
            }
          } catch (error) {
            console.error('Error en la promesa:', error);
          }
        })
        .catch(_error => {
          toaster.push(
            <Message
              type="error"
              showIcon
              closable
              duration={NOTIFICATION_DURATION}
            >
              <FormattedMessage id="create-fields.error" />
            </Message>
          );
        })
    }
    
    onCloseModal()
  };

  const onCloseModal = () => {
    setListValues([]);
    onClose()
  }

  return (
    <div>
      <Modal
        className="modal-dialog-container"
        open={visible}
        size="xs"
        keyboard
        onClose={onCloseModal}
      >
        <Modal.Header>
          <Modal.Title style={{ marginBottom: '10px' }}> {title}</Modal.Title>
        </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 gap-x-4 gap-y-2">
              <div >
                <div className="mb-2">
                <label className="block text-sm text-gray-700"><FormattedMessage id="entity" />*</label>
                <Controller
                  control={control}
                  name="entity"
                  render={({ field }) => (
                    <InputPicker
                      {...field}
                      onFocus={() => setEntityFocused(true)}
                      onBlur={() => setEntityFocused(false)}
                      data={dataEntity}
                      defaultValue={dataEntity[0]?.value}
                      cleanable={false}
                      searchable={false}
                      className={`mt-1 border rounded-md w-full ${entityFocused ? 'text-blue-500' : ''}`}
                    />
                  )}
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm text-gray-700"><FormattedMessage id="name" /></label>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => <input type="text" {...field} className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500" />}
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm text-gray-700"><FormattedMessage id="label" />*</label>
                <Controller
                  control={control}
                    name="title"
                    rules={{ required: 'title is required' }}
                    render={({ field }) => (
                      <div>
                        <input type="text" {...field} className={`mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500 ${errors.label ? 'border-red-500' : ''}`} />
                        {errors.title && <span className="text-red-500 text-sm">{errors.title.message}</span>}
                      </div>
                    )}
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm text-gray-700"><FormattedMessage id="fieldType" />*</label>
                <Controller
                  control={control}
                  name="fieldType"
                  render={({ field }) => (
                    <InputPicker
                      {...field}
                      onFocus={() => setFieldTypeFocused(true)}
                      onBlur={() => setFieldTypeFocused(false)}
                      data={dataFieldsType}
                      cleanable={false}
                      defaultValue={dataFieldsType[0]?.value}
                      searchable={false}
                      onChange={(e) => {
                        field.onChange(e);
                        setFieldType(e);
                      }}
                      className={`mt-1 border rounded-md w-full ${fieldTypeFocused ? 'text-blue-500' : ''}`}
                    />
                  )}
                />
              </div>
              {fieldType === 'LIST' && (
                <div className="mb-2">
                  <label className="block text-sm text-gray-700"><FormattedMessage id="values" /></label>
                  <Controller
                    control={control}
                    name="values"
                    render={({ field }) => (
                      <div>
                        {listValues.map((item, index) => (
                          <div key={index} className="mb-2 flex items-center">
                            <span className="mr-2 w-80 ml-4">{item.label}: {item.value}</span>
                            <TrashIcon
                              type="button"
                              onClick={() => {
                                const updatedList = [...listValues];
                                updatedList.splice(index, 1);
                                setListValues(updatedList);
                              }}
                              className='ml-8 mr-8 text-xl' 
                            >
                            </TrashIcon>
                          </div>
                        ))}
                        <input
                          type="text"
                          placeholder="Etiqueta"
                          value={field.value && field.value[0]?.key || ''}
                          onChange={(e) => setValue('values', [{ key: e.target.value, value: field.value[0]?.value }])}
                          className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500"
                        />
                        <input
                          type="text"
                          placeholder="Valor"
                          value={field.value && field.value[0]?.value || ''}
                          onChange={(e) => setValue('values', [{ key: field.value[0]?.key, value: e.target.value }])}
                          className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500"
                        />
                        <button
                          type="button"
                          onClick={() => {
                            setListValues([...listValues, { label: field.value[0]?.key, value: field.value[0]?.value }]);
                            field.onChange([{ label: '', value: '' }]);
                          }}
                          className="mt-2 bg-blue-500 text-white p-2 rounded-md w-full focus:outline-none"
                        >
                          Agregar Value
                        </button>
                      </div>
                    )}
                  />
                </div>
              )}
              <div className="mb-2">
                <label className="block text-sm text-gray-700"><FormattedMessage id="defaultValue" /></label>
                <Controller
                  control={control}
                  name="defaultValue"
                  render={({ field }) => <input type="text" {...field} className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500" />}
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm text-gray-700"><FormattedMessage id="length" /></label>
                <Controller
                  control={control}
                  name="length"
                  render={({ field }) => (
                    <div>
                      <input
                        type="number"
                        {...field}
                        min={0}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                        className={`mt-1 p-2 border rounded-md w-full focus:outline-none `}
                      />
                    </div>
                  )}
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm text-gray-700"><FormattedMessage id="order" />*</label>
                <Controller
                  control={control}
                  name="order"
                  rules={{ required: 'order is required' }}
                    render={({ field }) => (
                      <div>
                        <input
                          type="number"
                          min={0}
                          onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e' || e.key === '.') {
                              e.preventDefault();
                            }
                          }}
                          {...field}
                          className={`mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500 ${errors.order ? 'border-red-500' : ''}`}
                        />
                        {errors.order && <span className="text-red-500 text-sm">{errors.order.message}</span>}
                      </div>
                    )}
                />
              </div>
              <div className="mb-2 flex items-center">
                <label className="block text-sm text-gray-700 mr-2"><FormattedMessage id="mandatory" /></label>
                <Controller
                  control={control}
                  name="mandatory"
                  render={({ field }) => (
                    <input
                      type="checkbox"
                      {...field}
                      checked={field.value}
                      className="mt-1" 
                    />
                  )}
                />
              </div>
            </div>
            <ButtonToolbar>
              <Button
                appearance="primary"
                type="submit"
              >
                Guardar
              </Button>
              <Button onClick={onCloseModal}>Cancelar</Button>
            </ButtonToolbar>
          </form>
        </div>
      </Modal.Body>
      </Modal>
    </div>
  );
};