import { useUpdateSubscribersMutation } from '@api/query/inventoryApi/inventory';
import { NOTIFICATION_DURATION } from '@utils/Constants';
import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import { Button, ButtonToolbar, Message, toaster } from 'rsuite';

export function Subscribers({ channel, onClose, data }) {
  const updatedData = data.filter(c => c.id !== channel.id);

  const initialData = updatedData.map(item => ({ ...item, isSelected: false }));
  const [editSubscribers] = useUpdateSubscribersMutation()

  const [editableData, setEditableData] = useState(initialData);

  const submit = () => {
    const ids = editableData
    .filter(obj => obj.isSelected)
    .map(obj => obj.id)
    .join(','); 
    editSubscribers({ id: channel?.id, subscribers: ids })
        .then(_resp => {

          toaster.push(
            <Message
              type="success"
              showIcon
              closable
              duration={NOTIFICATION_DURATION}
            >
              <FormattedMessage id="edit-channel-success" />
            </Message>
          );

        })
      onCloseModal()
  }

  const onCloseModal = () => {
    onClose()
  }

  const handleCheckboxChange = (id) => {
    setEditableData(prevData =>
      prevData.map(item =>
        item?.id === id ? { ...item, isSelected: !item.isSelected } : item
      )
    );
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500">
        <thead>
          <tr className="border-b border-gray-200 bg-gray-50">
            <th scope="col" className="px-6 py-2 font-medium text-gray-900">
              <FormattedMessage id="description" defaultMessage="Description" />
            </th>
            <th scope="col" className="px-6 py-2 font-medium text-gray-900">
              <FormattedMessage id="Type" defaultMessage="Type" />
            </th>
            <th scope="col" className="px-6 py-2 font-medium text-gray-900">
              <FormattedMessage id="isSubs" defaultMessage="Conectar" />
            </th>
          </tr>
        </thead>
        <tbody>
          {editableData.map((item) => (
            <tr key={item.id} className="border-b border-gray-200">
              <td className="px-6 py-2">{item.description}</td>
              <td className="px-6 py-2">{item.type}</td>
              <td className="px-6 py-2" >
                <input
                  type="checkbox"
                  checked={item.isSelected}
                  onChange={() => handleCheckboxChange(item.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ paddingTop: '20px', textAlign: 'left' }}>
        <ButtonToolbar>
          <Button
            appearance="primary"
            onClick={() => submit()}
          >
            Guardar
          </Button>

          <Button onClick={onCloseModal}>Cancelar</Button>
        </ButtonToolbar>
      </div>
    </div>
  );
}