import React from 'react';
import { Form } from "rsuite";

export function TextField (props) {
    const { name, label, accepter, tooltip, tooltipText, ...restProps} =
      props;
    return (
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <div className="flex items-center">
          <Form.Control name={name} accepter={accepter} {...restProps} />
          {tooltip && <Form.HelpText tooltip className='mt-0 mr-2'>{tooltipText}</Form.HelpText>}
        </div>
      </Form.Group>
    );
  }