import React from "react";
import { Button, ButtonGroup, ButtonToolbar } from "rsuite";
import "../../styles/Dashboard.css";

const DashboardHeader = ({
  appearance,
  currentMonthActive,
  lastMonthActive,
  filterClicked,
}) => (
  <div>
    <ButtonToolbar>
      <ButtonGroup>
        <Button
          className="header-links"
          id="PREVIOUS_MONTH"
          appearance={appearance}
          active={lastMonthActive}
          onClick={filterClicked}
        >
          Mes anterior
        </Button>
        <Button
          className="header-links"
          id="CURRENT_MONTH"
          appearance={appearance}
          active={currentMonthActive}
          onClick={filterClicked}
        >
          Mes actual
        </Button>
        {/* <Button className="header-links" id="liveStatus" appearance={appearance} active={liveStatusActive} onClick={filterClicked}>Live status</Button> */}
      </ButtonGroup>
    </ButtonToolbar>
  </div>
);

export default DashboardHeader;
