import RemindIcon from '@rsuite/icons/legacy/Remind';
import React from 'react';
import { Button, Modal } from 'rsuite';

const AuthModalComponent = ({ open, handleClose }) => {
  return (
    <Modal
      backdrop="static"
      role="alertdialog"
      open={open}
      onClose={handleClose}
      size="xs"
    >
      <Modal.Body>
        <RemindIcon style={{ color: '#ffb300', fontSize: 20 }} />
        Tu sesión en Savia ha expirado. Haz clic aquí para renovar tus
        credenciales!
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} appearance="primary">
          Volver a Savia
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AuthModalComponent;
