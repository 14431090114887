import React from 'react';
// import { Col, Row } from "rsuite-migration";
import '../styles/General.css';
import {
  getCustomerIdentification,
  getStateCityData
} from '../utils/DataFormat';

const CustomerInfo = ({ customer, shippingAddress }) => {

  const customerName = customer.fullName.trim()

  return (
    <div>
      <b>{customerName}</b>

      {customer.identification !== 1 && (
        <div>
          {getCustomerIdentification(customer.idType, customer.identification)}
        </div>
      )}

      <div>{shippingAddress.address}</div>

      <div>
        {getStateCityData(shippingAddress?.state?.name  || '', shippingAddress?.city?.name || '')}
      </div>

      {shippingAddress.primaryEmail !== 'a@abc.com' && (
        <div>{shippingAddress.primaryEmail}</div>
      )}

      <div>{shippingAddress.mobile}</div>
    </div>
  );
};

export default CustomerInfo;
