import DoughnutChart from '@components/Charts/DoughnutChart';
import HorizontalLinesChart from '@components/Charts/HorizontalLinesChart';
import LineGraph from '@components/Charts/LineGraph';
import DashboardHeader from '@components/Dashboards/DashboardHeader';
import SLA from '@components/Dashboards/SLA';
import TotalOrders from '@components/Dashboards/TotalOrders';
import AuthModalComponent from '@components/Modals/Auth';
import PageHeader from '@components/PageHeader';
import * as DashboardSelector from '@selectors/DashboardSelector';
import {
  getCancelStatusId,
  getDeliveryStatusId,
} from '@selectors/StatusSelector';
import {
  getAuthModalData,
  getMainLoggingParams,
} from '@selectors/SystemSelector';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Radio, RadioGroup } from 'rsuite';
import * as actions from '../actions';
import '../styles/Dashboard.css';
import * as Constants from '../utils/Constants';

class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.onClickFilter = this.onClickFilter.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleTopTenDimensionChanged = this.handleTopTenDimensionChanged.bind(
      this,
    );
  }

  componentDidMount() {
    const deliveryStatusId = this.props.deliveryStatusId;
    const cancelStatusId = this.props.cancelStatusId;

    this.props.redirect({ editForm: false, home: false, payload: null });
    this.props.getDashboardData({
      selection: this.props.currentSelection,
      loggingParams: this.props.loggingParams,
      topTenDimension: this.props.topTenDimension,
      deliveryStatusId,
      cancelStatusId,
      handleCloseModal: this.handleCloseModal,
    });
    window.addEventListener('focus', this.onFocus);
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onFocus);
  }

  onClickFilter(ev) {
    const selection = ev.target.id;
    this.props.getDashboardData({
      selection: selection,
      loggingParams: this.props.loggingParams,
      topTenDimension: this.props.topTenDimension,
      deliveryStatusId: this.props.deliveryStatusId,
      cancelStatusId: this.props.cancelStatusId,
      handleCloseModal: this.handleCloseModal,
    });
  }

  onFocus = () => {
    let timeElapsed = Date.now() - this.props.lastUpdate;
    if (timeElapsed > Constants.UPDATE_MAXIMUM_ELAPSED_TIME) {
      this.props.getDashboardData(this.props.currentSelection);
    }
  };

  handleTopTenDimensionChanged(value) {
    const deliveryStatusId = this.props.deliveryStatusId;
    const cancelStatusId = this.props.cancelStatusId;
    this.props.updateTopTenDimension(value);
    this.props.getDashboardData({
      selection: this.props.currentSelection,
      loggingParams: this.props.loggingParams,
      topTenDimension: value,
      deliveryStatusId,
      cancelStatusId,
    });
  }

  handleCloseModal() {
    this.props.closeAuthModal();
    this.props.history.push('/logout');
  }

  render() {
    return (
      <div className="flex flex-col flex-nowrap min-h-screen">
        <PageHeader
          title="Torre de Control"
          // subtitle={
          //   <label className="last-update">
          //     Última act.{" "}
          //     {formatDateAndHourSpanishCOShort(this.props.lastUpdate)}
          //   </label>
          // }
        />

        <div className="mx-1 md:mx-20">
          <DashboardHeader
            appearance="subtle"
            filterClicked={this.onClickFilter}
            lastMonthActive={this.props.lastMonthStatus}
            currentMonthActive={this.props.currentMonthStatus}
            liveStatusActive={this.props.liveStatusStatus}
          ></DashboardHeader>
        </div>

        {(this.props.currentMonthStatus || this.props.lastMonthStatus) && (
          <div className="flex-1 relative overflow-auto bg-slate-100">
            <div className="absolute top-0 left-0 right-0 grid grid-cols-6 gap-1  p-1 max-w-7xl mx-auto">
              <div className="col-span-2 bg-white rounded-md px-1 py-2 shadow-md">
                <SLA currentMonthOnTime={this.props.currentMonthOnTime} />
              </div>

              <div className="col-span-2 bg-white rounded-md px-1 py-2 shadow-md">
                <TotalOrders
                  status="ON_TIME"
                  totalOrders={this.props.currentMonthOnTimeOrders}
                ></TotalOrders>
              </div>

              <div className="col-span-2 bg-white rounded-md px-1 py-2 shadow-md">
                <TotalOrders
                  status="delayed"
                  totalOrders={this.props.currentMonthOffTimeOrders}
                ></TotalOrders>
              </div>

              <div className="col-span-6 md:col-span-2 bg-white rounded-md px-1 py-2 shadow-md">
                <HorizontalLinesChart
                  data={this.props.totalOrders}
                  labels={Constants.TOTAL_ROW_LABELS}
                  title="Ordenes"
                  type="orders"
                  showYAxes={true}
                  long="large"
                />
              </div>

              <div className="col-span-6 md:col-span-2 bg-white rounded-md px-1 py-2 shadow-md">
                <HorizontalLinesChart
                  data={this.props.totalAmount}
                  labels={Constants.TOTAL_ROW_LABELS}
                  title="Ventas COP"
                  type="amount"
                  showYAxes={false}
                  long="large"
                />
              </div>

              <div className="col-span-6 md:col-span-2 bg-white rounded-md px-1 py-2 shadow-md">
                <HorizontalLinesChart
                  data={this.props.totalUnits}
                  labels={Constants.TOTAL_ROW_LABELS}
                  title="Unidades"
                  type="units"
                  showYAxes={false}
                  long="large"
                />
              </div>

              <div className="col-span-6 md:col-span-3 bg-white rounded-md px-1 py-2 shadow-md ">
                <LineGraph
                  labels={this.props.dailyOrdersSeries}
                  data={this.props.dailyOrdersData}
                  description="# ordenes día"
                  title="Total ordenes / día"
                />
              </div>

              <div className="col-span-6 md:col-span-3 bg-white rounded-md px-1 py-2 shadow-md">
                <RadioGroup
                  inline
                  value={this.props.topTenDimension}
                  onChange={value => {
                    this.handleTopTenDimensionChanged(value);
                  }}
                  defaultValue="cop"
                >
                  <Radio value="cop">Facturación</Radio>
                  <Radio value="uds">Unidades</Radio>
                </RadioGroup>
                <DoughnutChart
                  labels={this.props.topCustomersSeries}
                  data={this.props.topCustomersData}
                  title="Top 10 Clientes"
                  description="Unidades despachadas"
                />
              </div>
            </div>
          </div>
        )}
        <AuthModalComponent
          open={this.props.modal.open}
          handleClose={this.handleCloseModal}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    deliveryStatusId: getDeliveryStatusId(state),
    cancelStatusId: getCancelStatusId(state),
    currentMonthOnTime: DashboardSelector.getCurrentMonthOnTime(state),
    currentMonthOnTimeOrders: DashboardSelector.getCurrentMonthOnTimeOrders(
      state,
    ),
    currentMonthOffTimeOrders: DashboardSelector.getCurrentMonthOffTimeOrders(
      state,
    ),

    totalOrders: DashboardSelector.getTotalOrders(state),
    totalUnits: DashboardSelector.getTotalUnits(state),
    totalAmount: DashboardSelector.getTotalAmount(state),

    dailyOrdersSeries: DashboardSelector.getDailyOrdersSeries(state),
    dailyOrdersData: DashboardSelector.getDailyOrdersData(state),
    topCustomersData: DashboardSelector.getTopCustomersData(state),
    topCustomersSeries: DashboardSelector.getTopCustomersSeries(state),
    lastMonthStatus: DashboardSelector.getLastMonthStatus(state),
    currentMonthStatus: DashboardSelector.getCurrentMonthStatus(state),
    lastUpdate: DashboardSelector.getLastUpdate(state),
    currentSelection: DashboardSelector.getCurrentSelection(state),
    topTenDimension: DashboardSelector.getTopTenDimension(state),
    loggingParams: getMainLoggingParams(state),
    modal: getAuthModalData(state),
  };
};
export default withRouter(connect(mapStateToProps, actions)(HomeContainer));

// responsive charts https://codepen.io/chartjs/pen/YVWZbz
