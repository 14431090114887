import React from "react"
import { FormattedMessage } from "react-intl";
import { Modal } from "rsuite";

import { TabsComponent } from '../../../components/Tabs'
import { TabContent } from '../../../components/Tabs/TabContent'
import { GeneralInformation } from "./GeneralInformation";
import { Subscribers } from "./Subscribers";
import { Channel } from "@api/query/inventoryApi/types";


interface Props {
	channel: Channel;
	oncloseModal: () => void;
	isOpenDetailModal: boolean;
	edit: boolean;
	data: Channel[];
}
const getTabs = (edit): any[] => {
    const tabs = [
			{
				id: 0,
				label: <FormattedMessage id="Información General" defaultMessage="General Information" />
			}
		];

		if (edit) {
			tabs.push({
				id: 1,
				label: <FormattedMessage id="Suscriptores" defaultMessage="Subscribers" />
			});
		}

    return tabs;
  };

export function ModalChannelsDetail({ isOpenDetailModal, oncloseModal, channel, edit, data }: Props) {
	return (
		<div>
			<Modal
				size="sm"
				open={isOpenDetailModal}
				onClose={() => oncloseModal()}
				autoFocus
				keyboard
			>
				<Modal.Header>
				</Modal.Header>
				<Modal.Body  >
					<TabsComponent
						tabs={getTabs(edit)}
					>
						<TabContent id={0}>
							<GeneralInformation channel={channel} onClose={oncloseModal} edit={edit} />
						</TabContent>
						<TabContent id={1}>
							<Subscribers channel={channel} onClose={oncloseModal} data={data} />
						</TabContent>
					</TabsComponent>
					<div className="bg-red container">
					</div>
				</Modal.Body>
			</Modal>
		</div>
	)
};