import React from 'react'
import { FormattedMessage } from "react-intl";

export function Configuration({ customer }) {
  return <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table className="w-full text-sm text-left text-gray-500">
      <tbody>
        <tr className="border-b border-gray-200">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50 ">
          <FormattedMessage id="dispatch-action" />
          </th>
          <td className="px-6 py-2">
            {customer.actionOnShipment}
          </td>
        </tr>
        <tr>
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50 ">
          <FormattedMessage id="type-logistics" />
          </th>
          <td className="px-6 py-2">
            {customer.fulfillmentType?.description}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
}