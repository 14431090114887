import React from 'react'
import { useTabsContext } from './useTabsContext'

const classSelected = 'inline-block p-4 text-blue-600 border-b-2 border-blue-600 w-full rounded-t-lg active '
const classDefault = 'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 cursor-pointer w-full'

export function Tabs({ tabs, children }) {
  const { tabSelected, onChangeTabs } = useTabsContext()
  return (
  <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 overflow-x-auto">
    <ul className="flex flex-no-wrap -mb-px">
      {tabs.map(tab => (
        <li key={tab.id} className="w-full md:w-fit text-left md:text-center">
          <span
            className={tabSelected === tab.id ? classSelected : classDefault}
            onClick={() => onChangeTabs(tab.id)}
          >
            {tab.label}
          </span>
        </li>
      ))}
    </ul>
    {children}
  </div>
  )
}