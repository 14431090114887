import { Schema } from "rsuite";

export const validateUpdateInventory = () => {
  const { StringType } = Schema.Types;
  let schema = Schema.Model({
    recordType: StringType().isRequired("Requerido"),
    locations: StringType().isRequired("Requerido"),
    quantity: StringType().isRequired("Requerido"),
    productId: StringType().isRequired('Requerido'),
  });
  return schema;
};