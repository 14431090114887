import { AppState } from '@src/store';
import { MILLISECONDS_TO_HOURS } from '@utils/Constants';
import dayjs from '@utils/dayjs';


export const getActiveUser = state => state.ordox.activeUser;
export const getLoadingVisible = state => state.ordox.loadingVisible;
export const setButtonDisabled = state => state.ordox.buttonDisabled;
export const getFormActions = state => state.ordox.formActions;
export const redirectToHome = state => state.ordox.redirectTo.home;
export const redirectToListOrder = state => state.ordox.redirectTo.listOrder;
export const getShowSpinner = state => state.ordox.showSpinner;
export const getShowNotification = state => state.ordox.showNotification;
export const getLastUpdatedOrdersGrid = state =>
  state.ordox.lastUpdateOrdersGrid;
export const getUserDevice = state => state.ordox.isMobile;

export const getMainLoggingParams = (state: AppState) => ({
  company: state.ordox.overallParams.company,
  companyData: state.ordox.activeUser?.company,
  version: state.ordox.overallParams.version,
  fulfillmentLocationId: state.ordox.activeUser.fulfillmentLocationId,
  permissions: state.ordox.activeUser.permissions,
  maxNumberDownloadAllowed: state.ordox.company.maxNumberDownloadAllowed ? state.ordox.company.maxNumberDownloadAllowed : 3500,
  user: state.ordox.activeUser.email,
  userId: state.ordox.activeUser.id,
  companyId: state.ordox.overallParams.companyId,
  erp: state.ordox.company.erp,
  taxAccountable: state.ordox.company.taxAccountable,
  rounding: state.ordox.company.rounding,
  ecomSite: state.ordox.company.ecomSite,
  deliveryHoursAhead: state.ordox.company.deliveryHoursAhead
});

export const selectCompany = (state: AppState) => state.ordox.company;
export const selectCompanyId = (state: AppState) => state.ordox.company.id;
export const getShowSignUp = state => state.ordox.signUpVisible;
export const getSignupForm = state => state.ordox.signupForm;
export const getShowModalTokenExpired = state =>
  state.ordox.showModalTokenExpired;
export const getAuthModalData = state => state.ordox.authModal;

export const ROLES = {
    UPDATE_ORDERS: 'UPDATE_ORDERS',
    UPDATE_ORDERS_PERMANENTLY: 'UPDATE_ORDERS_PERMANENTLY',
    UPDATE_ORDERS_STATUS_PERMANENTLY: 'UPDATE_ORDERS_STATUS_PERMANENTLY'
}

export const validateUpdateOrders = (permissions,deliveryHoursAhead,promisedDeliveryDateTime) => {

  if(permissions === undefined) return true // temp, wait for all users to load this from state
  if(permissions.UPDATE_ORDERS === false) return false
  if(permissions.UPDATE_ORDERS_PERMANENTLY === true) return true

  const diff = dayjs(promisedDeliveryDateTime).diff(dayjs())
  if((diff / MILLISECONDS_TO_HOURS) < deliveryHoursAhead) return false

  return true
}
export const validatepermissions = (permissions, option) => {
  if (option === 'MENU_ORDERS') {
    if (!(permissions.MENU_ORDERS !== false)) {
      return true
    } else {
      return false
    }
  } else if (option === 'MENU_LISTPRICE') {
    if (!(permissions.MENU_LISTPRICE !== false)) {
      return true
    } else {
      return false
    }
  } else {
    if (!(permissions.MENU_ORDERS_HISTORY !== false)) {
      return true
    } else {
      return false
    }
  }
}