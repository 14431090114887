import React, { useEffect, useRef } from 'react';
import './OrderItems.css';
import { formatCurrencySpanishCO } from '../../utils/DataFormat';
import { OrderItemCard } from './components/OrderItemCard';
import { OrderItemTable } from './components/OrderItemTable';

const OrderItems = ({ orderItems, isMobile, totalAmount, totalUnits, subTotal, discount, scrollTop }) => {
	const totalsDivRef = useRef();
	const cardsRef = useRef();
	let flexGrow = { col1: 2, col2: 0, col3: 1 };
	if (isMobile) {
		flexGrow = { col1: 0, col2: 0, col3: 0 };
	}

	useEffect(() => {
		const divPosition = cardsRef?.current?.offsetTop
		if (scrollTop > divPosition) {
			totalsDivRef.current.classList.add('fix-div-totals')
		} else {
			totalsDivRef.current.classList.remove('fix-div-totals')
		}
	}, [scrollTop])

	return (
		<div className="flex flex-col">
			<div className="overflow-x-auto">
				<div className="py-2 inline-block min-w-full">
					<div className="overflow-auto rounded-lg shadow">
						<div className='hidden md:block'>
							<table className="min-w-full border ">
								<thead className="bg-gray-50 border-b-2 border-gray-200">
									<tr>
										<th
											scope="col"
											className="text-sm font-medium text-gray-600 px-6 py-2 text-left"
										>
											Producto
										</th>
										<th
											scope="col"
											class="text-sm font-medium text-gray-600 px-6 py-2 text-left"
										>
											Cant.
										</th>
										{!isMobile &&
											<th
												scope="col"
												className="text-sm font-medium text-gray-600 px-6 py-2 text-center"
											>
												Precio
											</th>
										}
										{!isMobile &&
											<th
												scope="col"
												className="text-sm font-medium text-gray-600 px-6 py-2 text-left"
											>
												Impuesto
											</th>
										}
										<th
											scope="col"
											className="text-sm font-medium text-gray-600 px-6 py-2 text-left"
										>
											Total
										</th>
									</tr>
								</thead>
								<tbody>
									{orderItems.map(item => {
										if (item.active === true) {
											return (
												<OrderItemTable key={item.id} item={item} isMobile={isMobile} />
											);
										}
									})}
								</tbody>
							</table>
						</div>


						<div className="min-w-full border block md:hidden " ref={cardsRef}>
							<div className='fix-div-totals p-3' ref={totalsDivRef}>

								<div>
									<strong>Total: </strong>
									{formatCurrencySpanishCO(totalAmount)}
								</div>
								<div >
									<strong>Subtotal: </strong>
									{formatCurrencySpanishCO(subTotal)}
								</div>
								{discount > 0
									?
									<div >
										<strong>Descuento: </strong>
										{(formatCurrencySpanishCO(discount))}
									</div>
									: null
								}
							</div>



							{orderItems.map(value => {
								return (
									<OrderItemCard key={value.id} item={value} />
								);
							})}

						</div>
					</div>
				</div>
			</div>
		</div>
		// <Table
		//   data={orderItems}
		//   virtualized
		//   rowHeight={30}
		//   cellBordered
		//   bordered
		//   wordWrap
		//   headerHeight={60}
		//   height={getTableHeight(orderItems.length)}
		// >
		//   <Column flexGrow={flexGrow.col1} minwidth={350} align="center">
		//     <HeaderCell className="grid-header">Producto</HeaderCell>
		//     <Cell dataKey="productName">
		//       {(rowData) => <OrderItemRow rowData={rowData} value="productName" />}
		//     </Cell>
		//   </Column>
		//   <Column flexGrow={flexGrow.col2} minwidth={10} align="center">
		//     <HeaderCell className="grid-header">
		//       Cantidad
		//       <HeaderSummary summary={units} />
		//     </HeaderCell>
		//     <Cell dataKey="product.quantity">
		//       {(rowData) => <OrderItemRow rowData={rowData} value="quantity" />}
		//     </Cell>
		//   </Column>
		//   <Column flexGrow={flexGrow.col3} align="center" minwidth={35}>
		//     <HeaderCell className="grid-header">
		//       Valor Total
		//       <HeaderSummary summary={formatCurrencySpanishCO(amount)} />
		//     </HeaderCell>
		//     <Cell dataKey="product.priceAfterTax">
		//       {(rowData) => (
		//         <OrderItemRow rowData={rowData} value="priceAfterTax" />
		//       )}
		//     </Cell>
		//   </Column>
		// </Table>
	);
};

export default OrderItems;

const getTableHeight = items => {
	const height = 60 + items * 30;
	return height;
};

const OrderItemRow = ({ rowData, value }) => {
	let column;
	switch (value) {
		case 'productName':
			column = (
				<div>
					<p className="grid-row-left"> {rowData[value]} </p>
				</div>
			);
			break;
		case 'quantity':
			column = (
				<div>
					<p className="grid-row-right"> {rowData[value]} </p>
				</div>
			);
			break;
		case 'priceAfterTax':
			column = (
				<div>
					<p className="grid-row-right">
						{' '}
						{formatCurrencySpanishCO(rowData[value])}{' '}
					</p>
				</div>
			);
			break;
	}
	return column;
};

const HeaderSummary = ({ title, summary }) => (
	<div>
		<div
			style={{
				fontSize: 18,
				color: '#2eabdf',
			}}
		>
			{summary}
		</div>
	</div>
);
