import React from 'react';
// import {Form, InputGroup, Button} from 'react-bootstrap';
import { InputGroup, Input, Form, Uploader, Button } from 'rsuite';
import '../../styles/General.css';


export const FileUploader = ({fileHandler,fileInput,handleOpenFileBrowser,uploadedFileName}) => {

    return(

        // <Form>
        //     <Form.Group controlId="fileUpload">
        //         <Form.Label></Form.Label>
                
        //             <InputGroup className="mb-3">
        //                 <InputGroup.Prepend>
        //                     <Button color="info" 
        //                         style={{color: "white",height:38}} 
        //                         onClick={handleOpenFileBrowser}>
        //                         <i className="cui-file"></i> Buscar&hellip;
        //                     </Button> 
        //                 </InputGroup.Prepend>
                
        //                 <input 
        //                     type="file" hidden 
        //                     onChange={fileHandler} 
        //                     ref={fileInput} 
        //                     style={{"padding":"10px"}} 
        //                 />                                 
                    
        //             <input 
        //                 type="text" 
        //                 className="form-control" 
        //                 value={uploadedFileName} 
        //                 readOnly 
        //             />                                              
        //             </InputGroup>
        //     </Form.Group>
        // </Form>

        // <Form>
        //     <FormGroup controlId="fileUpload">
        //         <InputGroup>
        //             <InputGroup.Button>
        //                 <Icon onClick={handleOpenFileBrowser} icon="search" />
        //             </InputGroup.Button>
        //             <Input type="file" hidden onChange={fileHandler} ref={fileInput} />
        //         </InputGroup>
        //     </FormGroup>
        // </Form>

        <Uploader  
            accept=".xls,.xlsx"
            onChange={fileHandler}
            autoUpload={false}
            ref={fileInput}
            onClick={handleOpenFileBrowser}
            multiple={false}
            name={uploadedFileName}
        />
    );   
}