import {
  getFormattedStatusList,
  getStatusList,
} from '@selectors/StatusSelector';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import UpdateOrderStatus from '../../components/OrderTracking/UpdateOrderStatus';
import * as selectorOrderTracking from '../../selectors/OrderTrackingSelector';
import * as SystemSelector from '../../selectors/SystemSelector';
import { thru } from 'lodash';

class UpdateOrderStatusContainer extends Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.updateOrderStatus = this.updateOrderStatus.bind(this);
    this.handleCommentsChange = this.handleCommentsChange.bind(this);
  }

  componentDidMount() {
    if (this.props.fullStatusList.length == 0) {
      this.props.getStatus(this.props.companyId);
    }

    // this.props.updateOrderStatusStatusChange(this.props.nextStatus?.value);
    this.props.updateOrderStatusStatusChange(
      this.props.fullStatusList.find(
        item => item.id === this.props.nextStatus?.value,
      ),
    );
  }

  onClose() {
    this.props.hideUpdateOrderStatus();
  }

  changeStatus(status) {
    this.props.updateOrderStatusStatusChange(
      this.props.fullStatusList.find(item => item.id === status),
    );
  }

  handleCommentsChange(comments) {
    this.props.updateTrackingComments(comments);
  }

  updateOrderStatus() {
    this.props.disableButton(true);
    const status = this.props.newOrderStatus;
    const data = {
      status: {
        id: status.id,
        role: status.role,
        description: status.description,
        listingOrder: status.listingOrder,
      },
      orderEvent: {
        data: this.getOrderEventData(),
        statusRole: status.role,
        newStatus: this.props.orderStatusWillUpdate.actionOnShipment,
      },
      orderId: this.props.orderStatusWillUpdate.id,
      originalData: this.props.orderStatusWillUpdate,
    };

    if (this.props.statusNotes) {
      data.notes = this.props.statusNotes;
    }

    this.props.updateOrderStatus(data);
    this.props.hideUpdateOrderStatus();

    // if (
    //   ['DELIVERY', 'SHIPMENT'].includes(status.role) &&
    //   ['INVOICE', 'PURCHASE_ORDER'].includes(this.props.orderStatusWillUpdate.actionOnShipment)
    // ) {
    //   this.triggerOrderEvent(this.props.newOrderStatus);
    // }

    this.props.updateTrackingComments('');
  }

  getOrderEventData() {
    let data = {};

    if (this.props.orderStatusWillUpdate.actionOnShipment === 'INVOICE') {
      data.action = 'invoice';
      data.statusId = this.getDocumentCreationStatus('INVOICE');
      data.orderId = this.props.orderStatusWillUpdate.id;
    } else {
      data.action = 'purchase';
      data.statusId = this.getDocumentCreationStatus('PO_CREATED');
      data.orderId = this.props.orderStatusWillUpdate.id;
    }
    return data;
  }

  triggerOrderEvent(status) {
    const statusIdInvoiced = this.getDocumentCreationStatus('INVOICE');
    const statusIdPOCreated = this.getDocumentCreationStatus('PO_CREATED');

    const data = {
      action: 'purchase',
      statusId: statusIdPOCreated,
      orderId: this.props.orderStatusWillUpdate.id,
      originalData: this.props.orderStatusWillUpdate,
    };

    if (this.props.orderStatusWillUpdate.actionOnShipment === 'INVOICE') {
      data.action = 'invoice';
      data.statusId = statusIdInvoiced;
    }

    this.props.triggerOrderEvent(data);
  }

  getDocumentCreationStatus(type) {
    const status = this.props.fullStatusList.find(
      status => status.role === type,
    );
    return status.id;
  }

  render() {
    let statusList = this.props.manualStatusList;
    if (this.props.permissions?.UPDATE_ORDERS_STATUS_PERMANENTLY === false) {
      statusList = this.props.manualStatusList.filter(
        status => status.listingOrder >= this.props.nextStatus.listingOrder,
      );
    }
    return (
      <div>
        <UpdateOrderStatus
          visible={this.props.visible}
          orderNumber={this.props.orderStatusWillUpdate.orderNumber}
          isPaid={this.props.orderStatusWillUpdate.isPaid}
          newOrderStatus={this.props.newOrderStatus}
          statusList={statusList}
          onClose={this.onClose}
          changeStatus={this.changeStatus}
          updateOrderStatus={this.updateOrderStatus}
          buttonDisabled={this.props.buttonDisabled}
          comments={this.props.comments}
          changeComments={this.handleCommentsChange}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    manualStatusList: getFormattedStatusList(state),
    fullStatusList: getStatusList(state),
    companyId: SystemSelector.getMainLoggingParams(state).companyId,
    permissions: SystemSelector.getMainLoggingParams(state).permissions,
    nextStatus: selectorOrderTracking.getNextStatus(state),
    loggingParams: SystemSelector.getMainLoggingParams(state),
    statusNotes: selectorOrderTracking.getTrackingComments(state),
    newOrderStatus: selectorOrderTracking.getNewOrderStatus(state),
    orderStatusWillUpdate:
      selectorOrderTracking.getOrderStatusWillUpdate(state),
  };
};
export default connect(mapStateToProps, actions)(UpdateOrderStatusContainer);
