import { setOpenAuthModal } from '@actions/index';
import { PriceListItem, useFindPriceListQuery } from '@api/query/priceListApi';
import { PageHeaderPortal } from '@components/PageHeader';
import HighlightMatchText from '@components/ui/HighlightMatchText';
import { getAuthModalData } from '@selectors/SystemSelector';
import { isFetchBaseQueryError } from '@utils/queryUtils';
import useFuzzySearch, {
  FuzzyResultMatches,
  getMatchIndices,
} from '@utils/useFuzzySearch';
import React, { useCallback, useRef, useState } from 'react';
import { BiEditAlt, BiSearch, BiX } from 'react-icons/bi';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Animation, IconButton, Input, InputGroup, List, Loader } from 'rsuite';
import FABCreatePriceList from './FABCreatePriceList';

const searchKeys = ['description'];

// TODO: Crear alerta La lista "Precios nuevos de Enero" ha sido creada

const PriceListView = () => {
  const { $t } = useIntl();
  const authModalVisible = useSelector(getAuthModalData);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const priceList = useFindPriceListQuery({ active: true });

  if (priceList?.isError && !authModalVisible.open) {
    if (
      isFetchBaseQueryError(priceList?.error) &&
      priceList?.error.status === 401
    ) {
      dispatch(setOpenAuthModal({ open: true }))
    }
  }

  const [searchVisible, setSearchVisible] = useState(false);
  const { pattern, search, searchResult, isSearchFound, data } = useFuzzySearch(
    {
      data: priceList.data?.items,
      keys: searchKeys,
      isCaseSensitive: false,
      defaultSearchResult: 'originalData',
      threshold: 0.4,
    },
  );

  const listRef = useRef<HTMLDivElement>();

  const scrollToTop = useCallback(() => {
    listRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  const handlePriceListClick = useCallback(
    (item: PriceListItem) => {
      push({ pathname: '/price-lists/edit', state: item });
    },
    [push],
  );

  return (
    <>
      <PageHeaderPortal
        title={
          searchVisible && (
            <InputGroup className="flex-1" size="md" inside>
              <Input
                autoFocus
                placeholder={$t({ id: 'search' })}
                value={pattern as string}
                onChange={value => search(value.trimStart().normalize('NFC'))}
                inputMode="search"
                type="search"
                autoComplete="off"
              />
              <InputGroup.Addon
                className="cursor-pointer"
                onClick={() => {
                  search('');
                  setSearchVisible(false);
                }}>
                <BiX className="text-base" />
              </InputGroup.Addon>
            </InputGroup>
          )
        }
        right={
          !searchVisible && (
            <span className="flex-1 flex justify-end">
              <IconButton
                circle
                icon={<BiSearch className="text-base" />}
                onClick={() => setSearchVisible(v => !v)}
              />
            </span>
          )
        }
      />
      {priceList.isLoading && (
        <>
          <Loader backdrop className="absolute inset-0" size="md" />
        </>
      )}
      {!isSearchFound && (
        <div className="p-1 text-center font-medium text-red-500">
          <FormattedMessage id="search-not-match" />
        </div>
      )}
      {priceList.isSuccess && (
        <>
          <List hover ref={listRef as React.MutableRefObject<HTMLDivElement>}>
            {searchResult.map(({ item, matches }) => (
              <PriceListItemView
                key={item.id}
                item={item}
                matches={matches}
                onClick={handlePriceListClick}
              />
            ))}
          </List>
        </>
      )}
      <FABCreatePriceList onNewPriceListItem={scrollToTop} />
    </>
  );
};

export type PriceListItemProps = {
  item: PriceListItem;
  matches?: FuzzyResultMatches;
  onClick?(item: PriceListItem): void;
};

const PriceListItemView: React.FC<PriceListItemProps> = ({
  item,
  matches,
  onClick,
}) => {
  return (
    <Animation.Slide in={true} placement="left">
      {(fadeProps, ref) => (
        <div ref={ref} {...fadeProps} onClick={() => onClick?.(item)}>
          <List.Item
            id={`PriceListItem-${item.id}`}
            className="cursor-pointer text-base px-5 py-4 text-gray-900 flex items-center justify-between">
            <HighlightMatchText
              text={item.description}
              matches={getMatchIndices('description', matches)}
            />
            <span>
              <IconButton circle icon={<BiEditAlt />} />
            </span>
          </List.Item>
        </div>
      )}
    </Animation.Slide>
  );
};

export default PriceListView;
