import queryString from 'query-string';

import axios from '../libs/axios';

const api = {
  getProducts(filters) {
    return axios.get(`products?active=${filters.active}`)
  },

  getProductById({ id }) {
    return axios.get(`products/${id}`)
  },

  searchProducts(filters) {
    const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
    return axios.get(`products${params ? `?${params}` : ''}`)
  },

  createProduct(product) {
    return axios.post("products", product)
  },

  editProduct({id, data}){
    return axios.patch(`products/${id}`, data)
  },
};

export default api;
