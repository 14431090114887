import {put, takeLatest, call, all} from 'redux-saga/effects';
import types from '../actions/ActionTypes';
import api from '../api/PaymentMethodApi';
import * as actions from '../actions';

function* getPaymentMethods(action){
  try {
    const paymentMethodsList = yield call(api.getPaymentMethods, action.payload);
    yield put (actions.updateStatePaymentMethods(paymentMethodsList.data.items));
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

export default  function* () {
    yield all([
        takeLatest(types.GET_PAYMENT_METHODS, getPaymentMethods)
    ]);
}