export const B2BPORTAL = "B2BPORTAL"
export const SAVIA_CORE = "SAVIA_CORE"

export const APP_TYPES = [
    {
        label: SAVIA_CORE,
        value: SAVIA_CORE,
    },
    {
        label: B2BPORTAL,
        value: B2BPORTAL,
    }
]
export const DEFAULT_USER_DATA = {
    fullName: '',
    primaryEmail: '',
    mobile: '',
    appType: SAVIA_CORE,
    listDelimiter: ',',
    decimalSeparator: '.',
    customerId: '',
    active: true,
}