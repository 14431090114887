import { createApi } from '@reduxjs/toolkit/query/react';
import { ordoxBaseQuery } from '../baseQuery';
import { CreateUserRequest, GetUsersRequest, GetUsersResponse, UpdateUserRequest, User } from './types';

export const userApi = createApi({
  reducerPath: 'user-api',
  tagTypes: ['user'],
  baseQuery: ordoxBaseQuery(),
  endpoints: builder => ({
    getUsers: builder.query({
      providesTags: ['user'],
      query: (data?: GetUsersRequest) => {
        return {
          params: data,
          url: 'users',
          method: 'GET'
        };
      },
      transformResponse: (data: GetUsersResponse, _, request) => {
        return {
          limit: request.limit,
          offset: request.offset,
          total: data.total,
          items: data.items,
        };
      },
    }),
    createUser: builder.mutation<User, CreateUserRequest>({
      invalidatesTags: ['user'],
      query: data => ({
        url: 'users',
        method: 'POST',
        body: data,
      }),
    }),
    updateUser: builder.mutation<User, UpdateUserRequest>({
      invalidatesTags: ['user'],
      query: ({ id, data }) => {
        const { companyId, createdAt, updatedAt, id: id2, ...dataUser } = data
        return {
          url: `/users/${id}`,
          method: 'PATCH',
          body: dataUser,
        }
      },
    }),
  }),
});

export const { useGetUsersQuery, useLazyGetUsersQuery, useCreateUserMutation, useUpdateUserMutation } = userApi;

export default userApi;
