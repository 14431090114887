import React, { useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, ButtonToolbar, InputPicker, Message, Modal, toaster } from 'rsuite';
import { NOTIFICATION_DURATION } from '@utils/Constants';
import { FormattedMessage } from 'react-intl';
import { useFindLocationsQuery } from '@api/query/customerApi/customerApi';
import { useCreateInventoryTransferMutation } from '@api/query/inventoryApi/inventory';


export function ModalProducts(props) {
  const {
    inventoryInfo,
    inventoryTypesData,
    visible,
    onClose,
  } = props;
  const { control, handleSubmit, setValue, getValues, formState: { errors } } = useForm();

  const [createInventoryTranfer] = useCreateInventoryTransferMutation()
  const [entityFocused, setEntityFocused] = useState(false);
  const [quantityValue, setQuantityValue] = useState('');

  const handleChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 1) {
      setValue('quantity', value);
      setQuantityValue(value)
    }
  };

  const locationsData = useFindLocationsQuery();

	const locationsOptions = useMemo(
    () => {
      const result = locationsData?.data?.items.map(value => ({
        value: value.name,
        label: value.name,
      }))
      if (result?.length) {
        // formValue.locations = result[0]?.value
      }
      
      return result
    },
    [locationsData?.data],
	);

  useEffect(() => {
  if (inventoryInfo) {    
    const currentQuantity = getValues('quantity');
    if (!currentQuantity) {
      setValue('quantity', 1);
      setQuantityValue(1);
    }
  }
}, [inventoryInfo, setValue, getValues]);
  useEffect(() => {
  if (locationsOptions) {
    setValue('destinationLocation', locationsOptions[0]?.value);
  }
}, [locationsOptions, setValue, getValues]);

  const onSubmit = (data) => {
    const destination = locationsData?.data?.items?.find(location => location.name === data?.destinationLocation);

    const body = {
      product: inventoryInfo?.product,
      source: inventoryInfo?.location,
      target: {
        externalIds: destination?.externalIds,
        id: destination?.id,
        name: destination?.name,
      },
      quantity: data?.quantity,
      recordType: inventoryTypesData?.data?.items[0],
    }

    createInventoryTranfer(body)
      .then(resp => {
        try {
          const menssageError = resp?.error?.data?.message
          if (menssageError) {
            toaster.push(
              <Message
                type="error"
                showIcon
                closable
                duration={NOTIFICATION_DURATION}
              >
                {menssageError}
              </Message>
            );
          }
        } catch (error) {
          console.error('Error en la promesa:', error);
        }
      })
      .catch(_error => {
        toaster.push(
          <Message
            type="error"
            showIcon
            closable
            duration={NOTIFICATION_DURATION}
          >
            {'error'}
          </Message>
        );
      })
    
    onCloseModal()
  };

  const onCloseModal = () => {
    onClose()
  }
  return (
    <div>
      <Modal
        className="modal-dialog-container"
        open={visible}
        size="xs"
        keyboard
        onClose={onCloseModal}
      >
        <Modal.Header>
          <Modal.Title> {inventoryInfo?.product?.name}</Modal.Title>
        </Modal.Header>
      <Modal.Body className="p-0">
        <div className="pt-4 pr-4 pl-4">
          <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 gap-x-4 gap-y-2">
              <div className="mb-2 flex">
                <label className="block text-sm text-gray-700 w-28"><FormattedMessage id="origin-location" />:</label>
                <div>{inventoryInfo?.location?.name}</div>
              </div>
              <div >
                <div className="mb-2 flex">
                  <label className="block text-sm text-gray-700 w-28 mt-2"><FormattedMessage id="destination-location" />:</label>
                  {locationsOptions && (
                    <Controller
                      control={control}
                      name="destinationLocation"
                      render={({ field }) => (
                        <InputPicker
                          {...field}
                          onFocus={() => setEntityFocused(true)}
                          onBlur={() => setEntityFocused(false)}
                          cleanable={false}
                          defaultValue={locationsOptions[0]?.value}
                          data={locationsOptions}
                          searchable={false}
                          className={`w-56 border rounded-md w-full ${entityFocused ? 'text-blue-500' : ''}`}
                        />
                      )}
                    />
                  )}
                
              </div>
              <div className="mb-2 flex">
                <label className="block text-sm text-gray-700 w-28 mt-2"><FormattedMessage id="quantity" />:</label>
                <Controller
                  control={control}
                  name="quantity"
                  rules={{ required: 'quantity is required' }}
                    render={({ field }) => (
                      <div className={`w-56 pl-1`}>
                        <input
                          type="number"
                          value={quantityValue}
                          onChange={handleChange}
                          className={`w-56 p-2 border rounded-md focus:outline-none focus:border-blue-500 ${errors.quantity ? 'border-red-500' : ''}`}
                        />
                        {errors.quantity && <span className="text-red-500 text-sm">{errors.quantity.message}</span>}
                      </div>
                    )}
                />
              </div>
            </div>
            <ButtonToolbar>
              <Button
                appearance="primary"
                type="submit"
              >
                Continuar
              </Button>
              <Button onClick={onCloseModal}>Cancelar</Button>
            </ButtonToolbar>
          </form>
        </div>
      </Modal.Body>
      </Modal>
    </div>
  );
};