import { resetNewOrder } from '@actions/index';
import { useCreateOrderMutation, useUpdateOrderMutation } from '@api/query/orderApi/orderApi';
import CustomerBrowser from '@components/Customers/CustomerBrowser';
import PageHeader from '@components/PageHeader';
import ProductBrowser from '@components/Product/ProductBrowser';
import { CustomFields } from './CustomFields';
import FormattedCurrency from '@components/ui/FormattedCurrency';
import { NewOrderState } from '@reducers/initialState';
import { ArrowDown as ArrowDownIcon } from '@rsuite/icons';
import { getNewEditOrder } from '@selectors/CreateOrderSelector';
import { getStatusList } from '@selectors/StatusSelector';
import * as MasterDataSelector from '../../selectors/MasterDataSelector';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { random } from 'lodash';
import * as Constants from '../../utils/Constants';
import { State, City } from '@api/query/types';

import {
  getMainLoggingParams,
  selectCompanyId,
  selectCompany,
} from '@selectors/SystemSelector';
import { roundResult } from '@utils/configERP';
import dayjs from '@utils/dayjs';
import { isFetchBaseQueryError } from '@utils/queryUtils';
import useViewport from '@utils/useViewport';
import { differenceBy } from 'lodash';
import pick from 'lodash.pick';
import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getCustomerFormAction,
  getContactFormModel,
  getcontactFormValues,
  getEditedCustomer,
} from '../../selectors/CustomerSelector';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Dropdown,
  IconButton,
  Message,
  Panel,
  Popover,
  useToaster,
  Whisper,
} from 'rsuite';
import AdditionalInfo, { AdditionalInfoFormValues } from './AdditionalInfo';
import InvoiceNotes from './InvoiceNotes';
import {
  contactFormInitialValues,
  contactFormValidation,
} from '../../utils/Validations/CustomerValidations';
import { rollbarErrors } from '@utils/rollbarErrors';
import { Contact, Customer, Model } from '@api/query/customerApi/types';
import { useLazyFindCustomerByIdQuery } from '@api/query/customerApi/customerApi';
import { Product } from '@api/query/productApi/types';
import { useFindCustomFieldsQuery } from '@api/query/customFieldsApi/customFields';
import { CustomFieldsDataType, CustomFieldsType } from '@api/query/customFieldsApi/types';

type permissions = {
  MENU_NEW_ORDER?: boolean
}
type inventoryConfig = {
  inventoryConfig?: { controlsInventory: boolean, allowsNegativeSale: boolean }
}

const InitialFormValues: AdditionalInfoFormValues = {
  deliveryDate: new Date(),
  deliverySlot: [],
  deliverySlotId: '',
  invoicingSettingId: '',
  channelId: '',
  paymentMethodId: '',
  fulfillmentTypeId: '',
  fulfillmentType: {
    id: '',
    description: '',
    idDelivery: '',
    deliveryType: '',
    companyId: '',
  },
  paymentMethod: {
    id: '',
    description: '',
    idERP: '',
    accountIdERP: '',
    receiptPaymentMethodIdERP: '',
  },
  channel: {
    id: '',
    description: '',
  },
  actionOnShipment: '',
};

type CreateOrderProps = {
  searchCities(state: any): void;
  searchStates(state: any): void;
  formValuesContact: Contact;
  formModelContact: Model;
  states: [State];
  cities: [City];
  setCitiesDisabled(boolean): void;
  citiesDisabled: boolean;
  loggingParams: { companyId: string, permissions: permissions, companyData: inventoryConfig, user: string };
  fulfillmentTypesList
};

const CreateOrder: React.FC<CreateOrderProps> = ({
  searchCities,
  searchStates,
  formValuesContact,
  states,
  cities,
  setCitiesDisabled,
  citiesDisabled,
  formModelContact,
  loggingParams,
  fulfillmentTypesList,
}) => {
  const history = useHistory();
  useEffect(() => {
    if (!(loggingParams?.permissions?.MENU_NEW_ORDER !== false)) {
      history.push({ pathname: '/home' });
    }
  }, []);
  
  const toaster = useToaster();
  const dispatch = useDispatch();
  const [bodyUpdate, setBodyUpdate] = useState<{}>();
  const [bodyCreate, setBodyCreate] = useState<{}>();
  const [fields, setFields] = useState<CustomFieldsType[]>([]);
  let [customFieldsData, setCustomFieldsData] = useState<CustomFieldsDataType>();
  const [triggerCreateOrder, createOrderState] = useCreateOrderMutation({});
  const [triggerUpdateOrder, updateOrderState] = useUpdateOrderMutation({});
  const customFieldsQuery = useFindCustomFieldsQuery({ entity: 'ORDER' });
  useEffect(() => {
    if (customFieldsQuery?.data) {
      const orderFields = customFieldsQuery?.data?.items?.sort((a, b) => a.order - b.order);
      setFields(orderFields)
    }
  }, [customFieldsQuery]);

  useEffect(() => {
    if (updateOrderState?.isError) {
      rollbarErrors(updateOrderState?.error, bodyUpdate, 'PATCH/orders', loggingParams?.companyId, loggingParams?.user)
    }
  }, [updateOrderState?.isError]);

  useEffect(() => {
    if (createOrderState?.isError) {
      rollbarErrors(createOrderState?.error, bodyCreate, 'POST/orders', loggingParams?.companyId, loggingParams?.user)
    }
  }, [createOrderState?.isError]);

  const orderActive: NewOrderState = useSelector(getNewEditOrder);
  const [orderId, setOrderId] = useState<string>();
  const [orderNumber, setOrderNumber] = useState<number>();
  const [orderActiveData, setOrderActive] = useState<NewOrderState>();
  const [mode, setMode] = useState<'create' | 'edit'>('create');
  const [defaultAdress, setDefaultAdress] = useState<false | true>(false);
  const [initialProduct, setInitialProduct] = useState<Product[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [subTotal, setSubtotal] = useState(0);

  const [customer, setCustomer] = useState<Customer>();
  const [contact, setContact] = useState<Contact>();

  const [getCustomerById, customerData] = useLazyFindCustomerByIdQuery({});

  useEffect(() => {
    if (customerData?.data) {
      setCustomer(customerData?.data)
    }
  }, [customerData]);

  useEffect(() => {
    setCitiesDisabled(true);
    dispatch(actions?.updateContactFormModel(contactFormValidation()));
    loadMasterData(true);
  }, []);

  const handleCustomerChange = useCallback(
    (action: SetStateAction<Customer>) => {
      setCustomer(prevCustomer => {
        const customer =
          typeof action === 'function' ? action(prevCustomer!) : action;
          customer.fullName = customer?.fullName ? customer?.fullName : `${customer?.name} ${customer?.lastName}`
        if (!customer) return customer;
        setAdditionalInfo(values => ({
          ...values,
          paymentMethodId: customer.paymentMethodId,
          invoicingSettingId: customer.invoicingSettingId,
          channelId: customer.channelId,
          actionOnShipment: customer.actionOnShipment,
          fulfillmentType: customer?.fulfillmentType,
          fulfillmentTypeId: customer?.fulfillmentType?.id,
          locationId: customer?.defaultLocationId,
          fullName: customer?.fullName ? customer?.fullName : `${customer?.name} ${customer?.lastName}`,
        }));
        return customer;
      });
    },
    [setCustomer],
  );

  const handleContactChange = value => {
    setContact(value);
  };

  type KeywordsType = {
    filters: NameFilters;
    companyId: string;
  };
  type NameFilters = {
    name: string;
  };

  const companyId = useSelector(selectCompanyId);
  const onStatesSearch = value => {
    if (value.length > 3) {
      let keywords: KeywordsType;
      keywords.filters = { name: value };
      keywords.companyId = companyId;
      searchStates(keywords);
    }
  };

  const loadMasterData = reset => {
    reset && resetData();
  };

  const resetData = () => {
    const initialValues = contactFormInitialValues(states[0]?.value);
    dispatch(actions?.updateContactFormModel(contactFormValidation()));
    setStatesAndCities(initialValues.stateId);
  };

  const setStatesAndCities = stateId => {
    searchStates({ orderBy: 'name:ASC' });
    searchCities({ stateId: stateId });
    setCitiesDisabled(false);
  };

  const onStatesChangeContact = value => {
    if (value) {
      const selectedState = value;
      searchCities({ stateId: selectedState });
      setCitiesDisabled(false);

      // set state description
      const foundState = states.find(item => item.value === value);
      const newFormValues = formValuesContact;
      newFormValues.state = {
        id: foundState.value,
        name: foundState.label,
        idSiigo: foundState.idSiigo,
        label: undefined,
      };
      newFormValues.stateId = foundState.value;
      dispatch(actions?.updateContactFormValues(newFormValues));
    } else {
      setCitiesDisabled(true);
    }
  };

  const randomNumber = () => {
    const hexadecimal = new Array(
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
    );
    let randomColor = '#';
    for (let i = 0; i < 6; i++) {
      const posarray = random(0, hexadecimal.length);
      randomColor += hexadecimal[posarray];
    }
    return randomColor;
  };

  const onChangeContact = value => {
    dispatch(actions.updateContactFormValues(value));
  };

  const setValuesForm = () => {
    dispatch(actions.updateContactFormValues());
  };

  const contactFormModel = () => {
    dispatch(actions?.updateContactFormModel(contactFormValidation()));
  };

  const handleDefaultAdress = value => {
    setDefaultAdress(value);
  };

  const setProductsChange = items => {
    const uniqueItems = items.filter((item, index) =>
      items.findIndex(innerItem => innerItem.id === item.id) === index
    );

    setProducts(uniqueItems);
  };
  const onCitiesChangeContact = value => {
    if (value) {
      const foundCity = cities.find(item => item.value === value);
      const newFormValues = formValuesContact;
      newFormValues.city = {
        id: foundCity.value,
        name: foundCity.label,
        stateId: foundCity.stateId,
        idSiigo: foundCity.idSiigo,
        label: undefined,
      };
      newFormValues.cityId = foundCity.value;
      dispatch(actions?.updateContactFormValues(newFormValues));
    }
  };

  const onErrorValidationContact = value => {
    actions?.updateContactFormErrors(value);
  };

  const onSubmit = (form, saveAndCreateNew, byContact) => {
    if (!form.check()) {
      return;
    } else {
      if (byContact && customer) {
        customer.contacts?.forEach((contact, index) => {
          formValuesContact.color = randomNumber();
          if (defaultAdress) {
            formValuesContact.defaultShippingAddress = true;
            if (contact?.defaultShippingAddress) {
              contact.defaultShippingAddress = false;
            }
          } else {
            formValuesContact.defaultShippingAddress = false;
          }
        });
        const contact = {
          address: formValuesContact.address,
          city: formValuesContact.city,
          lastName: formValuesContact.lastName,
          mobile: formValuesContact.mobile,
          name: formValuesContact.name,
          primaryEmail: formValuesContact.primaryEmail,
          state: formValuesContact.state,
          defaultShippingAddress: formValuesContact?.defaultShippingAddress,
          color: randomNumber(),
        };
        customer.contacts.push(contact);
        setCustomer(customer);
        setContact(contact);
        dispatch(actions?.updateContactFormValues());
        dispatch(
          actions?.editCustomer({
            customer: customer,
            contact: true,
          }),
        );
        toaster.push(
          <Message
            type="success"
            showIcon
            closable
            duration={Constants.NOTIFICATION_DURATION}>
            {`El contacto ha sido agregado al cliente`}
          </Message>,
        );
      }
    }
    if (saveAndCreateNew) {
      loadMasterData(true);
    }
  };

  const [invoiceNotes, setInvoiceNotes] = useState('');
  const [comments, setComments] = useState('');
  const [incomingPurchaseOrder, setIncomingPurchaseOrder] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState(InitialFormValues);
  const [invoicing, setInvoicing] = useState([]);

  useEffect(() => {
    if (customer?.contacts) {
      if (!orderNumber) {
        for (let i = 0; i < customer?.contacts?.length; i++) {
          if (customer?.contacts[i]?.defaultShippingAddress) {
            setContact(customer?.contacts[i]);
            i = customer?.contacts?.length;
          }
        }
      } else {
        for (let i = 0; i < customer?.contacts?.length; i++) {
          if (
            customer?.contacts[i]?.city?.name ===
              orderActiveData?.shippingAddress?.city.name &&
            customer?.contacts[i]?.state?.name ===
              orderActiveData?.shippingAddress?.state.name &&
            customer?.contacts[i]?.address ===
              orderActiveData?.shippingAddress?.address
          ) {
            setContact(customer?.contacts[i]);
            i = customer?.contacts?.length;
          }
        }
      }
    }
  }, [customer, orderNumber, orderActiveData]);

  useEffect(() => {
    if ((orderActive?.items?.length || 0) === 0) return;

    const {
      id,
      orderNumber,
      customer,
      actionOnShipment,
      comments,
      notes,
      incomingPurchaseOrder,
      channelId,
      invoicingSettingsId,
      paymentMethodId,
      paymentMethod,
      channel,
      shippingAddress,
      promisedDeliveryDateTime,
      deliverySlotId,
      fulfillmentType,
      fulfillmentTypeId,
      deliverySlot,
    } = orderActive;
    if (orderActive.action === 'EDIT') {
      setMode('edit');
      setOrderId(id);
      setContact(shippingAddress);
      setOrderNumber(orderNumber);
      setOrderActive(orderActive);
      setCustomFieldsData(orderActive?.customFields);
      setAdditionalInfo({
        actionOnShipment,
        channelId,
        deliveryDate: new Date(promisedDeliveryDateTime),
        deliverySlot: [
          deliverySlot?.startDateTime,
          deliverySlot?.endDateTime,
          deliverySlot?.id,
        ],
        deliverySlotId,
        invoicingSettingId: invoicingSettingsId,
        paymentMethodId,
        fulfillmentTypeId: fulfillmentTypeId,
        paymentMethod,
        channel,
        fulfillmentType: fulfillmentType,
      });
    } else {
      setOrderNumber(orderNumber);
      setOrderActive(orderActive);
      setContact(shippingAddress);
      setAdditionalInfo({
        actionOnShipment,
        channelId,
        deliveryDate: new Date(),
        deliverySlot: [],
        deliverySlotId: '',
        invoicingSettingId: invoicingSettingsId,
        paymentMethodId,
        fulfillmentTypeId: fulfillmentTypeId,
        paymentMethod,
        channel,
        fulfillmentType: fulfillmentType,
      });
    }

    const products: Product[] = orderActive.items.map(item => ({
      id: item.productId,
      orderItemId: item.id,
      sku: item.sku,
      companyId,
      cost: 0,
      createdAt: dayjs().toISOString(),
      idERP: item.idERP,
      inventory: 0,
      name: item.item,
      priceBeforeTax: item.itemPrice,
      priceAfterTax:
        item.itemPrice + (item.itemPrice * item.itemTaxNumeric) / 100,
      tax: item.itemTaxNumeric,
      taxIdERP: item.taxIdERP,
      unit: 'uds' as Product['unit'],
      unitsCount: item.unitsCount,
      updatedAt: dayjs().toISOString(),
      discount: item.discount,
      notes: item.notes,
      productId: item.productId,
      quantity: item.itemQuantity,
      active: item.active,
    }));
    try {
      getCustomerById(customer?.id)
    } catch(err) {
      console.log('err', err)
    }
    setProducts(products);
    setInitialProduct(products);
    setInvoiceNotes(notes);
    setIncomingPurchaseOrder(incomingPurchaseOrder);
    setComments(comments);

    dispatch(resetNewOrder());
  }, [orderActive]);

  const statusList: { id: string; role: string }[] =
    useSelector(getStatusList);

  useEffect(() => {
    createOrderState.reset();
    return () => createOrderState.reset();
  }, []);

  useEffect(() => {
    updateOrderState.reset();
    return () => updateOrderState.reset();
  }, []);

  useEffect(() => {
    if (createOrderState.isUninitialized) return;
    if (createOrderState.isLoading) return;

    if (
      isFetchBaseQueryError(createOrderState.error) &&
      createOrderState.error.status === 409
    ) {
      toaster.push(
        <Message type={'warning'} duration={3000} closable>
          <FormattedMessage id={'slot-no-room'} />
        </Message>,
      );
      return;
    }

    toaster.push(
      <Message
        type={createOrderState.isSuccess ? 'success' : 'error'}
        duration={4000}
        closable>
        <FormattedMessage
          id={`${'create-order'}.${
            createOrderState.isSuccess ? 'success' : 'failure'
          }`}
          values={{
            orderId: (
              <b>#{createOrderState.data?.orderNumber || orderNumber}</b>
            ),
          }}
        />
      </Message>,
    );
    createOrderState.reset();
  }, [createOrderState]);

  useEffect(() => {
    if (updateOrderState.isUninitialized) return;
    if (updateOrderState.isLoading) return;

    if (
      isFetchBaseQueryError(updateOrderState.error) &&
      updateOrderState.error.status === 409
    ) {
      toaster.push(
        <Message type={'warning'} duration={7000} closable>
          <FormattedMessage id={'slot-no-room'} />
        </Message>,
      );
      return;
    }

    toaster.push(
      <Message
        type={updateOrderState.isSuccess ? 'success' : 'error'}
        duration={4000}
        closable>
        <FormattedMessage
          id={`${'edit-order'}.${
            updateOrderState.isSuccess ? 'success' : 'failure'
          }`}
          values={{
            orderId: (
              <b>#{updateOrderState.data?.orderNumber || orderNumber}</b>
            ),
          }}
        />
      </Message>,
    );
    updateOrderState.reset();
  }, [updateOrderState]);

  const handleCreateOrderSave = (hint: 'reset' | 'close') => {
    const fieldsFinal = customFieldsData
    const requiredFields = fields?.filter(field => field?.mandatory);
    const incompleteFields = requiredFields.filter(field => !fieldsFinal?.[field?.title]);

    if (incompleteFields.length > 0) {
      const missingFieldsMessage = `Completa los siguientes campos dinámicos requeridos antes de guardar: ${incompleteFields.map(field => field?.name).join(', ')}`;
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={20000}
        >
          {missingFieldsMessage}
        </Message>,
      );
      return;
    }
    const { deliveryDate, deliverySlot } = additionalInfo;
    const { totalAfterTax, totalBeforeTax, totalUnits, discount } =
      paymentSummaryValues(products);

    const deletedItems =
      mode === 'edit'
        ? products.filter(item => item.active === false).map(
            ({ orderItemId }) => orderItemId as string,
          )
        : [];
    
    if (mode !== 'edit') {
      for (let i = products.length - 1; i >= 0; i--) {
        if (!products[i].active) {
          products.splice(i, 1);
        }
      }
    }

    if (!customer?.id) {
      return toaster.push(
        <Message type="warning" showIcon duration={1000}>
          <FormattedMessage id="create-order.customer.error" />
        </Message>,
      );
    }

    if (
      loggingParams?.companyData?.inventoryConfig?.controlsInventory &&
      !loggingParams?.companyData?.inventoryConfig?.allowsNegativeSale
    ) {
      let showMessage = false;

      products.forEach(product => {
        if (product.availableQuantity <= 0) {
          showMessage = true;
        }
      });

      if (showMessage) {
        toaster.push(
          <Message type="warning" showIcon duration={3000}>
            <FormattedMessage id="create-order.products-acailableQuantity.error" />
          </Message>
        );
        return;
      }
    }

    if (!products.length) {
      return toaster.push(
        <Message type="warning" showIcon duration={1000}>
          <FormattedMessage id="create-order.products.error" />
        </Message>,
      );
    }
    const invoicingSeting = invoicing?.find(
      item => item.id === additionalInfo?.invoicingSettingId,
    );
    if (invoicingSeting?.isPOSNumbering === true && subTotal > 212050) {
      return toaster.push(
        <Message type="warning" showIcon duration={5000}>
          <FormattedMessage id="create-order.invoicing.error" />
        </Message>,
      );
    }

    if (!deliverySlot.length) {
      return toaster.push(
        <Message type="warning" showIcon duration={3000}>
          <FormattedMessage id="create-order.deliverySlot.error" />
        </Message>,
      );
    }

    const customerOrder = pick(customer, [
      'id',
      'idType',
      'mobile',
      'listPriceId',
      'identification',
    ])
    if (customer?.brandName) {
      customerOrder.fullName = customer.brandName
    } else if (customer?.fullName && !customer?.brandName) {
      customerOrder.fullName = customer?.fullName
    }
    let customFieldsBody = []
    if (customFieldsData) {
      customFieldsBody = Object.keys(customFieldsData)
        .filter(key => fieldsFinal[key] !== "")
        .map(key => ({
          title: key,
          value: fieldsFinal[key]
        }));
    }
    const order = {
      source: 'savia',
      data: {
        actionOnShipment: additionalInfo.actionOnShipment,
        channel: {
          id: additionalInfo.channel.id,
          description: additionalInfo.channel.description
        },
        fulfillmentType: {
          id: additionalInfo?.fulfillmentType?.id,
          description: additionalInfo?.fulfillmentType?.description,
          idDelivery: additionalInfo?.fulfillmentType?.idDelivery,
          deliveryType: additionalInfo?.fulfillmentType?.deliveryType,
        },
        fulfillmentLocationId: additionalInfo?.location?.id,
        comments,
        customer: customerOrder,
        shippingAddress: {
          address: contact.address,
          city: contact.city,
          state: contact.state,
          primaryEmail: contact.primaryEmail,
          mobile: contact.mobile,
          name: contact.name || undefined,
          lastName: contact?.lastName || undefined,
        },
        deliverySlotId: additionalInfo.deliverySlot[2],
        discount,
        invoicingSettingsId: additionalInfo.invoicingSettingId,
        items: (products as Required<Product>[]).map(product => ({
          id: mode === "edit" ? product.orderItemId as string : undefined,
          productId: product.productId || product.id,
          discount: product.discount || 0,
          priceAfterTax: product.priceAfterTax,
          priceBeforeTax: product.priceBeforeTax,
          quantity: product.quantity,
          tax: product.tax,
          taxIdERP: product.taxIdERP,
          notes: product.notes
        })),
        notes: invoiceNotes,
        incomingPurchaseOrder: incomingPurchaseOrder,
        paymentMethod: {
          id: additionalInfo.paymentMethodId,
          description: additionalInfo.paymentMethod.description,
          idERP: additionalInfo.paymentMethod.idERP,
          accountIdERP: additionalInfo.paymentMethod.accountIdERP,
          receiptPaymentMethodIdERP:
            additionalInfo.paymentMethod.receiptPaymentMethodIdERP,
        },
        promisedDeliveryDateTime: dayjs(deliveryDate)
          .set('hour', dayjs(deliverySlot[1]).hour())
          .set('minutes', dayjs(deliverySlot[1]).minute())
          .set('second', 0)
          .toISOString(),
        status: pick(
          statusList.find(status => status.role === 'START'),
          ['id', 'description', 'role', 'listingOrder'],
        ),
        totalAfterTax,
        totalBeforeTax,
        totalUnits,
        customFields: customFieldsBody ? customFieldsBody : undefined,
      },
    }

    let promissed = null

    if (mode === 'edit') {
      setBodyUpdate({
        id: orderId,
        data: {
          ...order,
          deletedItems,
          data: {
            ...order.data,
            status: orderActiveData?.status,
            invoicingSettingsId: additionalInfo.invoicingSettingId,
          }
        }
      })
      promissed = triggerUpdateOrder({
        id: orderId,
        data: {
          ...order,
          deletedItems,
          data: {
            ...order.data,
            status: orderActiveData?.status,
            invoicingSettingsId: additionalInfo.invoicingSettingId,
          }
        }
      })
    } else {
      setBodyCreate(order)
      promissed = triggerCreateOrder(order)
    }

    promissed.finally(() => {
      if (hint === 'reset') {
        setOrderId(undefined);
        setCustomer(undefined);
        setProducts([]);
        setInvoiceNotes('');
        setComments('');
        setIncomingPurchaseOrder('');
        setAdditionalInfo(({ deliveryDate, deliverySlot }) => ({
          ...InitialFormValues,
          deliveryDate,
          deliverySlot,
        }));
        setInitialProduct([]);
      } else {
        history.goBack();
      }
    });
  };
  return (
    <div className="flex flex-col h-screen">
      <PageHeader
        title={
          <>
            <FormattedMessage
              id={mode === 'create' ? 'create-order' : 'edit-order'}
            />
            {orderNumber && orderActiveData?.action === 'EDIT' && (
              <span className="mx-3 font-semibold">({orderNumber})</span>
            )}
          </>
        }
        right={
          <>
            <CreateOrderActions
              className="col-span-full text-right"
              onSave={handleCreateOrderSave}
              loading={mode === 'create' ? createOrderState.isLoading : updateOrderState.isLoading}
              onDiscard={() => history.goBack()}
              mode={mode}
            />
          </>
        }
      />
      <div
        className="relative overflow-auto flex-grow w-full mx-auto"
        style={{ maxWidth: 2048 }}>
        <div
          className="absolute top-0 left-0 right-0
        grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-stretch justify-center items-stretch gap-x-2 gap-y-2 px-2">
          <CustomerBrowser
            className="shadow-lg max-w-3xl"
            customer={customer}
            contact={contact}
            onCustomerChange={handleCustomerChange}
            onContactChange={handleContactChange}
            onStatesSearch={onStatesSearch}
            onStatesChangeContact={onStatesChangeContact}
            onCitiesChangeContact={onCitiesChangeContact}
            states={states}
            cities={cities}
            citiesDisabled={citiesDisabled}
            formValuesContact={formValuesContact}
            contactModel={formModelContact}
            onChangeContact={onChangeContact}
            onErrorValidationContact={onErrorValidationContact}
            onSubmit={onSubmit}
            contactFormModel={contactFormModel}
            defaultAdress={handleDefaultAdress}
            setValuesForm={setValuesForm}
          />
          <AdditionalInfo
            className="shadow-lg max-w-3xl"
            customer={customer}
            onChange={setAdditionalInfo}
            value={additionalInfo}
            setInvoicing={setInvoicing}
            fulfillmentTypes={fulfillmentTypesList}
            controlsInventory={loggingParams?.companyData?.inventoryConfig?.controlsInventory}
          />
          <PaymentSummary className="shadow-lg max-w-3xl" products={products} setSubtotal={setSubtotal} />
          <ProductBrowser
            className="shadow-lg md:col-span-2"
            selected={products}
            listPriceId={customer?.listPriceId}
            customerId={customer?.id}
            controlsInventory={loggingParams?.companyData?.inventoryConfig?.controlsInventory}
            allowsNegativeSale={loggingParams?.companyData?.inventoryConfig?.allowsNegativeSale}
            locationId={additionalInfo?.location?.id}
            onSelectedChange={items => setProductsChange(items)}
          />

          <InvoiceNotes
            className="shadow-lg max-w-3xl"
            agentNotes={comments}
            invoiceNotes={invoiceNotes}
            incomingPurchaseOrder={incomingPurchaseOrder}
            onChange={(ev, name) => {
              if (name === 'invoiceNotes') {
                setInvoiceNotes(ev.currentTarget.value);
              } else if (name === 'incomingPurchaseOrder') {
                setIncomingPurchaseOrder(ev.currentTarget.value);
              } else {
                setComments(ev.currentTarget.value);
              }
            }}
          />
          {fields?.length > 0 && (
            <CustomFields
              className="shadow-lg md:col-span-2"
              fields={fields}
              setCustomFieldsData={setCustomFieldsData}
              customFieldsData={customFieldsData}
              action={mode}
            />
          )}
          <CreateOrderActions
            className="col-span-full text-right py-4"
            onSave={handleCreateOrderSave}
            loading={mode === 'create' ? createOrderState.isLoading : updateOrderState.isLoading}
            onDiscard={() => history.goBack()}
            mode={mode}
          />
        </div>
      </div>
    </div>
  );
};

type CreateOrderActionsProps = {
  className?: string;
  onSave(hint: 'reset' | 'close'): void;
  onDiscard(): void;
  loading: boolean;
  mode: String;
};

const CreateOrderActions: React.FC<CreateOrderActionsProps> = ({
  className,
  onSave,
  onDiscard,
  loading,
  mode,
}) => {
  const { isNarrow, isWide } = useViewport();
  return (
    <div className={className}>
      <ButtonToolbar className="flex-nowrap">
        <Button className="m-1" disabled={loading} onClick={onDiscard}>
          <FormattedMessage id="discard" />
        </Button>
        <ButtonGroup className="m-1">
          <Button
            disabled={loading}
            appearance="primary"
            onClick={() => onSave('close')}>
            <FormattedMessage id="save" />
          </Button>
          {isNarrow && mode !== 'edit' && (
            <Whisper
              placement="autoVerticalEnd"
              trigger={['click']}
              speaker={({ onClose, left, top, className }, ref) => (
                <Popover
                  ref={ref}
                  className={className}
                  style={{ left, top }}
                  full>
                  <Dropdown.Menu
                    onSelect={eventKey => {
                      onSave(eventKey as 'reset');
                      onClose();
                    }}>
                    <Dropdown.Item eventKey="reset">
                      Guardar y crear otra
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Popover>
              )}>
              <IconButton appearance="primary" icon={<ArrowDownIcon />} />
            </Whisper>
          )}
        </ButtonGroup>
        {isWide && mode !== 'edit' && (
          <Button
            disabled={loading}
            appearance="primary"
            onClick={() => onSave('reset')}>
            Guardar y crear otra
          </Button>
        )}
      </ButtonToolbar>
    </div>
  );
};

type PaymentSummaryProps = {
  className?: string;
  products: Product[];
  setSubtotal;
};

function paymentSummaryValues(products: Product[]) {
  const activeProducts = products.filter(product => product.active);
  const subtotal = roundResult(
    activeProducts.reduce(
      (result, { priceBeforeTax, quantity }) =>
        result + priceBeforeTax * quantity!,
      0,
    ),
  );

  const discount = roundResult(
    activeProducts.reduce(
      (result, { discount, priceBeforeTax, quantity }) =>
        result + ((discount || 0) / 100) * priceBeforeTax * quantity!,
      0,
    ),
  );

  const taxes = roundResult(
    activeProducts.reduce(
      (result, { tax, priceBeforeTax, quantity, discount }) =>
        result +
        priceBeforeTax * (1 - (discount || 0) / 100) * quantity! * (tax / 100),
      0,
    ),
  );

  const totalBeforeTax = roundResult(subtotal - discount);

  const totalAfterTax = roundResult(totalBeforeTax + taxes);

  const totalUnits = activeProducts
    .filter(item => item.unitsCount)
    .reduce((result, { quantity }) => result + quantity!, 0);

  return {
    subtotal,
    discount,
    taxes,
    totalAfterTax,
    totalBeforeTax,
    totalUnits,
  };
}

const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  className,
  products,
  setSubtotal,
}) => {
  const { discount, subtotal, taxes, totalAfterTax } =
    paymentSummaryValues(products);
  setSubtotal(subtotal)
  return (
    <Panel
      className={className}
      header={
        <div>
          <span className="font-medium">Resumen</span>
        </div>
      }>
      <div className="grid grid-cols-2 gap-2">
        <span>Subtotal</span>
        <span>
          <FormattedCurrency value={subtotal} />
        </span>

        <span>Descuento</span>
        <span>
          <FormattedCurrency value={discount} />
        </span>

        <span>Impuesto</span>
        <span>
          <FormattedCurrency value={taxes} />
        </span>

        <span className="col-span-full border-t border-gray-200 -mx-8"></span>
        <span className="font-bold">Total</span>
        <span className="font-bold">
          <FormattedCurrency className="font-bold" value={totalAfterTax} />
        </span>
      </div>
    </Panel>
  );
};

const mapStateToProps = state => {
  return {
    states: MasterDataSelector.getFoundStates(state),
    cities: MasterDataSelector.getFoundCities(state),
    citiesDisabled: MasterDataSelector.getCitiesDisabled(state),
    formValuesContact: getcontactFormValues(state),
    formModelContact: getContactFormModel(state),
    formAction: getCustomerFormAction(state),
    loggingParams: getMainLoggingParams(state),
    editedCustomer: getEditedCustomer(state),
    company: selectCompany(state),
    fulfillmentTypesList: MasterDataSelector.getFulfillmentTypesList(state),
  };
};

export default connect(mapStateToProps, actions)(CreateOrder);
