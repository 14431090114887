import axios from '../libs/axios';

const api = {
    async getProductionList(companyId,daysRange)
    {
        return await axios.get('Order/GetProductionList?companyId=' + companyId + '&daysRange=' + daysRange)
        .then(response => {
            return{
                status: response.status,
                data: response.data
            }
        })
        .catch(error => {
            return{
                status: error.response.status,
                data: error.response.data
            }
        })
    },
}

export default api;