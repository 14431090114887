function filterOne<T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => unknown
) {
  const array_ = array.slice();

  const index = array_.findIndex(predicate);
  if (index !== -1) array_.splice(index, 1);

  return array_;
}

export default filterOne;
