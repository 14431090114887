import React from "react";
import Select from "react-select";
import { DatePicker, InputPicker } from "rsuite";
import { Col, Container, Row } from "rsuite-migration";
import "../styles/General.css";
import { localeCalendarSpanish } from "../utils/DataFormat";

export const OrderHeader = ({
  props,
  changeCustomer,
  changeDeliverySlot,
  changePaymentMethod,
  changePromisedDeliveryDate,
  cleanDateTimePicker,
  changeInvoicingSetting,
  changeChannel,
  handleOnCustomersSearch,
  changeActionOnShipment,
}) => (
  <div>
    <Container fluid className="border pt-3 pb-3">
      <Row className="pb-2">
        <Col className="pr-6" sm={12} md={6} lg={4}>
          <div>
            <InputPicker
              placeholder="Cliente"
              data={props.customer}
              onChange={changeCustomer}
              onSearch={handleOnCustomersSearch}
              disabled={props.editDisabled}
              value={props.selectedCustomer.value}
              cleanable={false}
              block
            />
          </div>
        </Col>

        {/* <Col sm={1} md={1} lg={1} className="pl-0 pr-0 mr-0 col-form-label">
                   {props.selectedCustomer && 
                        <Whisper placement="bottom" trigger="click" 
                            speaker={
                                <Popover title={props.selectedCustomer.label}>
                                    <p>{props.selectedCustomer.address}</p>
                                    <p>{props.selectedCustomer.phone}</p>
                                    <p>{props.selectedCustomer.email}</p>
                                </Popover>
                            }
                        >
                            <Image src={moreInfoIcon} />
                        </Whisper>
                    }
                </Col> */}

        <Col className="pr-0 mr-0" sm={12} md={6} lg={4}>
          <DatePicker
            format="dd/MM/yyyy"
            placeholder="Fecha de entrega"
            locale={localeCalendarSpanish}
            onChange={changePromisedDeliveryDate}
            onClean={cleanDateTimePicker}
            defaultValue={
              new Date(props.selectedPromisedDeliveryDate || Date.now())
            }
            disabled={props.editDisabled}
            cleanable={false}
            oneTap
            block
            tabIndex={0}
          />
        </Col>

        <Col sm={12} md={6} lg={4}>
          <div>
            <Select
              name="deliverySlot"
              placeholder="Franja horaria"
              options={props.deliverySlots}
              onChange={changeDeliverySlot}
              value={props.selectedDeliverySlot}
              isDisabled={props.editDisabled}
              tabIndex={0}
            />
          </div>
        </Col>
      </Row>

      <Row className="pb-2">
        <Col sm={12} md={6} lg={4}>
          <div>
            <Select
              name="paymentMethod"
              placeholder="Método de pago"
              options={props.paymentMethods}
              onChange={changePaymentMethod}
              value={props.selectedPaymentMethod}
              isDisabled={props.editDisabled}
              tabIndex={0}
            />
          </div>
        </Col>

        <Col sm={12} md={6} lg={4}>
          <div>
            <Select
              name="channel"
              placeholder="Canal"
              options={props.channels}
              onChange={changeChannel}
              value={props.selectedChannel}
              isDisabled={props.editDisabled}
              tabIndex={0}
            />
          </div>
        </Col>

        <Col sm={12} md={6} lg={4}>
          <div>
            <Select
              name="invoicingSettings"
              placeholder="Tipo facturación"
              options={props.invoicingSettings}
              onChange={changeInvoicingSetting}
              value={props.selectedInvoicingSettings}
              isDisabled={props.editDisabled}
              tabIndex={0}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6} lg={4}>
          <div>
            <Select
              name="actionOnShipment"
              placeholder="Acción al despachar"
              options={props.actionOnShipment}
              onChange={changeActionOnShipment}
              value={props.selectedActionOnShipment}
              isDisabled={props.editDisabled}
              tabIndex={0}
            />
          </div>
        </Col>
      </Row>

      {/* {props.orderCaptureAction === "EDIT" && (
        <Row className="pt-4">
          <Col className="pr-6" sm={12} md={12} lg={4}>
            <div className="form-group box">
              <Select
                name="orderStatus"
                options={props.orderStatus}
                onChange={changeStatus}
                defaultValue={props.selectedStatus}
                isDisabled={props.editDisabled}
              />
            </div>
          </Col>
        </Row>
      )} */}
    </Container>
  </div>
);
