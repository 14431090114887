import OrderEventSaga from "@sagas/OrderEventSaga";
import { SagaIterator } from "redux-saga";
import { all, call } from "redux-saga/effects";
import customerSaga from "./sagas/CustomerSaga";
import DashboardSaga from "./sagas/DashboardSaga";
import DeliverySlotSaga from "./sagas/DeliverySlotSaga";
import ListPriceSaga from "./sagas/ListPriceSaga";
import MasterSaga from "./sagas/MasterDataSaga";
import orderSaga from "./sagas/OrderSaga";
import orderTrackingSaga from "./sagas/OrderTrackingSaga";
import paymentMethodSaga from "./sagas/PaymentMethodSaga";
import ProductListSaga from "./sagas/ProductionListSaga";
import productSaga from "./sagas/ProductSaga";
import securitySaga from "./sagas/SecuritySaga";
import signupSaga from "./sagas/SingupSaga";
import statusSaga from "./sagas/StatusSaga";

const sagas = [
  orderSaga,
  customerSaga,
  productSaga,
  orderTrackingSaga,
  OrderEventSaga,
  statusSaga,
  paymentMethodSaga,
  securitySaga,
  ListPriceSaga,
  DashboardSaga,
  DeliverySlotSaga,
  ProductListSaga,
  MasterSaga,
  signupSaga,
];

export function* mainSaga(): SagaIterator {
  yield all(sagas.map((saga) => call(saga)));
}

// export const initSagas = (sagaMiddleware) =>
//   sagas.forEach(sagaMiddleware.run.bind(sagaMiddleware));
