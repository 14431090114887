import React from 'react';
import store from '../store';
import { ERP, GET_ALEGRA_INVOICE, GET_ALEGRA_CREDIT_NOTE } from './Constants';

export const roundResult = result => {
  const { erp: currentERP, rounding } = store.getState().ordox.company;

  const roundingConfig = rounding ? rounding : 0;

  // if (currentERP === ERP.alegra) {
  //   if (roundingConfig === 0) {
  //     return Math.floor(result);
  //   } else {
  //     return parseFloat(result.toFixed(roundingConfig));
  //   }
  // }

  // return parseFloat(result.toFixed(roundingConfig));

  return parseFloat(result.toFixed(roundingConfig));
};

export const InvoiceLink = ({ order }) => {
  const { erp: currentERP } = store.getState().ordox.company;
  if (currentERP === ERP.alegra) {
    return (
      <div>
        <b>Factura: </b>
        <a
          href={GET_ALEGRA_INVOICE(order.invoiceIdERP)}
          className="stretched-link"
          target="_blank"
          rel="noreferrer"
        >
          {order.invoiceId}
        </a>
      </div>
    );
  } else if (currentERP === ERP.siigo) {
    return (
      <div>
        <b>Factura: </b>
        {order.invoiceId}
      </div>
    );
  }
  return <div></div>;
};
export const CreditNoteLink = ({ order }) => {
  const { erp: currentERP } = store.getState().ordox.company;
  if (currentERP === ERP.alegra) {
    return (
      <div>
        <b>Nota crédito: </b>
        <a
          href={GET_ALEGRA_CREDIT_NOTE(order.creditNotes[0].creditNoteIdERP)}
          className="stretched-link"
          target="_blank"
          rel="noreferrer"
        >
          {order.creditNotes[0].creditNoteId}
        </a>
      </div>
    );
  } else if (currentERP === ERP.siigo) {
    return (
      <div>
        <b>Nota crédito: </b>
        {order.creditNotes[0].creditNoteId}
      </div>
    );
  }
  return <div></div>;
};

export const openPDF = (pdf, erp, options) => {
  if (erp === ERP.alegra) {
    window.open(pdf.pdf, options);
  } else if (erp === ERP.siigo) {
    decodePDF(pdf.base64);
  }
};

const decodePDF = (data, options) => {
  const base64EncodedPDF = data;
  const decodedPdfContent = atob(base64EncodedPDF);
  const byteArray = new Uint8Array(decodedPdfContent.length);
  for (let i = 0; i < decodedPdfContent.length; i++) {
    byteArray[i] = decodedPdfContent.charCodeAt(i);
  }
  var blob = new Blob([byteArray.buffer], { type: 'application/pdf' });
  var _pdfurl = URL.createObjectURL(blob);
  window.open(_pdfurl, options);
};
