/* eslint-disable react-hooks/exhaustive-deps */
import { useIMask } from 'react-imask';

import React, { useEffect } from 'react';
import { Input, InputProps } from 'rsuite';

type InputCurrencyProps = InputProps & {
  onValueChange?(value: number, ev: React.FormEvent<HTMLInputElement>): void;
  value?: number;
};

const InputCurrency: React.FC<InputCurrencyProps> = ({
  value,
  onValueChange,
  ...inputProps
}) => {
  const { ref, ...mask } = useIMask(
    {
      mask: '$number',
      blocks: {
        number: {
          mask: Number,
          scale: 2,
          signed: false,
          thousandsSeparator: ' ',
          radix: ',',
          mapToRadix: ['.'],
          min: 0,
          max: Number.MAX_SAFE_INTEGER,
          normalizeZeros: false,
          padFractionalZeros: false,
        },
      },
    },
    {
      onAccept(_, { unmaskedValue, masked }, ev) {
        console.log(ev);
        onValueChange?.(
          Number(unmaskedValue),
          ev as unknown as React.FormEvent<HTMLInputElement>,
        );
      },
    },
  );

  useEffect(() => {
    if (`${value}` !== mask.unmaskedValue) mask.setUnmaskedValue(`${value}`);
  }, [value]);

  return (
    <Input
      className='mt-2'
      {...inputProps}
      inputRef={ref}
      onFocus={ev => {
        ev.target.select();
        inputProps.onFocus?.(ev);
      }}
    />
  );
};

export default InputCurrency;
