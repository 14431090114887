import React from 'react';
import { DatePicker, Form } from 'rsuite';

export function DateTimePickerField (props) {
    const {
      name,
      label,
      accepter,
      tooltip,
      tooltipText,
      rows,
      ...restProps
    } = props;
    return (
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Form.Control
          editable={false}
          name={name}
          accepter={DatePicker}
          format="dd-MM-yyyy HH:mm"
          cleanable={false}
          className='w-full'
          {...restProps}
        />
        {tooltip && <Form.HelpText tooltip>{tooltipText}</Form.HelpText>}
      </Form.Group>
    );
  }
