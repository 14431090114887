import { PriceListItem, Product } from '@api/query/priceListApi';
import PageHeader, { PageHeaderProvider } from '@components/PageHeader';
import PageLayout from '@components/PageLayout';

import useLocationState from '@router/useLocationState';
import React, { useState } from 'react';
import { BiArrowBack, BiFace, BiPackage } from 'react-icons/bi';
import { FormattedMessage } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';
import { IconButton, Nav } from 'rsuite';
import { CustomerListView } from './CustomerListView';
import { FABAddPriceListCustomer } from './FABAddPriceListCustomer';
import { FABAddPriceListProduct } from './FABAddPriceListProduct';
import { ProductsListView } from './ProductsListView';

const PriceListEditorView: React.FC = () => {
  const priceList = useLocationState<PriceListItem>()!;

  const [productsPrice, setProductsPrice] = useState<Product[]>([]);

  const [activeTab, setActiveTab] = useState<string>('products');

  const { goBack } = useHistory();

  return (
    <PageHeaderProvider>
      <PageLayout
        header={
          <PageHeader
            left={
              <IconButton
                icon={<BiArrowBack className="text-base" />}
                onClick={goBack}
              />
            }
            title={
              <span className="leading-none">{priceList.description}</span>
            }
          />
        }>
        <div className="flex flex-nowrap items-end gap-2 px-2 mr-10">
          <Nav
            className="font-medium flex-1"
            appearance="subtle"
            activeKey={activeTab}
            onSelect={setActiveTab}>
            <Nav.Item
              eventKey="products"
              icon={
                <span>
                  <BiPackage className="inline" />
                </span>
              }>
              <FormattedMessage id="products" />
            </Nav.Item>
            <Nav.Item
              eventKey="customers"
              icon={
                <span>
                  <BiFace className="inline" />
                </span>
              }>
              <FormattedMessage id="customers" />
            </Nav.Item>
          </Nav>
        </div>
        {activeTab === 'products' && (
          <div className="flex-1">
            <ProductsListView setProductsPrice={setProductsPrice} />
            <FABAddPriceListProduct productsPrice={productsPrice} />
          </div>
        )}
        {activeTab === 'customers' && (
          <div className="flex-1">
            <CustomerListView />
            <FABAddPriceListCustomer />
          </div>
        )}
      </PageLayout>
    </PageHeaderProvider>
  );
};

const PriceListEditorViewWrapper = () => {
  const priceList = useLocationState<PriceListItem>();

  if (!priceList) return <Redirect to="/price-lists" />;

  return <PriceListEditorView />;
};

export default PriceListEditorViewWrapper;
