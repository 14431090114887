import Fuse from 'fuse.js';
import {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export type UseFuzzyPattern = string | Fuse.Expression;

export type UseFuzzySearchProps<T> = Fuse.IFuseOptions<T> & {
  data?: T[];
  limit?: number;
  defaultSearchResult?: 'empty' | 'originalData';
};

export type UseFuzzyResult<T> = Fuse.FuseResult<T>;

export type UseFuzzySearchInstance<T> = {
  data: T[];
  pattern: UseFuzzyPattern;
  patternIsEmpty: boolean;
  search(pattern: UseFuzzyPattern): void;
  searchResult: UseFuzzyResult<T>[];
  isSearching: boolean;
  isSearchFound: boolean;
};

export type UseFuzzySearchMatches = Fuse.FuseResultMatch;

export type FuzzyResultMatches = ReadonlyArray<Fuse.FuseResultMatch>;

export type RangeTuples = readonly Fuse.RangeTuple[];

export function getMatchIndices(
  key: string,
  matches?: readonly UseFuzzySearchMatches[],
): RangeTuples {
  return matches?.find(match => match.key === key)?.indices || [];
}

const defaultData = [];

const useFuzzySearch = <T>({
  data = defaultData,
  limit = 500,
  defaultSearchResult,
  ...options
}: UseFuzzySearchProps<T>): UseFuzzySearchInstance<T> => {
  const fuseRef = useRef<Fuse<T>>();

  useEffect(() => {
    fuseRef.current = new Fuse(data, {
      ...options,
      includeMatches: true,
      shouldSort: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, ...Object.values(options)]);

  const fuse = fuseRef.current!;

  const defaultResult = useMemo<Fuse.FuseResult<T>[]>(() => {
    if ((data?.length || 0) === 0) return [];

    if (defaultSearchResult === 'originalData') {
      return data.map((item, refIndex) => ({ item, refIndex }));
    }

    return [];
  }, [data, defaultSearchResult]);

  const [searchResult, setSearchResult] =
    useState<Fuse.FuseResult<T>[]>(defaultResult);
  const [pattern, setPattern] = useState<UseFuzzyPattern>('');
  const patternDeferred = useDeferredValue<UseFuzzyPattern>(pattern);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchFound, setIsSearchFound] = useState(false);

  const search = useCallback((pattern: UseFuzzyPattern) => {
    setIsSearching(true);
    setPattern(pattern);
  }, []);

  useEffect(() => {
    if (patternDeferred) {
      const result = fuse.search(patternDeferred, { limit });
      setIsSearchFound(result.length > 0);
      setSearchResult(result.length > 0 ? result : defaultResult);
    } else {
      setIsSearchFound(true);
      setSearchResult(defaultResult);
    }
    setIsSearching(false);
  }, [patternDeferred, fuse, limit, defaultResult]);

  const patternIsEmpty = useMemo(() => {
    if (typeof patternDeferred === 'string')
      return patternDeferred.trim().length === 0;

    return Object.keys(patternDeferred).length > 0;
  }, [patternDeferred]);

  return {
    data,
    pattern,
    patternIsEmpty,
    search,
    searchResult,
    isSearching,
    isSearchFound,
  };
};

export default useFuzzySearch;
