import { RangeTuples } from '@utils/useFuzzySearch';
import React, { HTMLAttributes, ReactNode } from 'react';
import slice from './slice';

type HighlightMatchTextProps = HTMLAttributes<HTMLSpanElement> & {
  text: string;
  matches?: RangeTuples;
};

export const HighlightMatchText: React.FC<HighlightMatchTextProps> = ({
  text,
  matches,
  ...props
}) => {
  if (!matches || matches.length === 0) return <span>{text}</span>;

  const [firstStart] = matches[0] || [0];

  return (
    <span {...props}>
      {firstStart > 0 && (
        <span key={`${0}-${firstStart - 1}`}>
          {slice(text, 0, firstStart, '[)')}
        </span>
      )}
      {matches.reduce((nodes, [start, end], rangeIndex) => {
        const [nextStart] = matches[rangeIndex + 1] || [text.length];

        return [
          ...nodes,
          <span
            key={`${start}-${end}`}
            className="rounded-sm underline decoration-2 underline-offset-2 decoration-blue-400 transition-all">
            {slice(text, start, end, '[]')}
          </span>,
          <span key={`${end + 1}-${nextStart - 1}`}>
            {slice(text, end, nextStart, '()')}
          </span>,
        ];
      }, [] as ReactNode[])}
    </span>
  );
};

export default HighlightMatchText;
