import { createApi } from '@reduxjs/toolkit/query/react';
import { ordoxBaseQuery } from "../baseQuery";
import queryString from "query-string";
import { DeliverySlot, FindDeliverySlotRequest, FindDeliverySlotResponse, UpdateDeliverySlotRequest } from "./types";

export const deliverySlotApi = createApi({
  reducerPath: 'deliverySlot-api',
  tagTypes: ['deliverySlot'],
  baseQuery: ordoxBaseQuery(),
  endpoints: builder => ({
    findDeliverySlots: builder.query<FindDeliverySlotResponse, FindDeliverySlotRequest>({
      query: (filters) => {
        const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })    
        return {
          url: `delivery-slots${params ? `?${params}` : ''}`,
          method: 'GET',
        }
      },
    }),
    getDeliverySlots: builder.query({
      providesTags: ['deliverySlot'],
      query: (data?: FindDeliverySlotRequest) => {
        return {
          params: data,
          url: 'delivery-slots',
          method: 'GET'
        };
      },
      transformResponse: (data: FindDeliverySlotResponse, _, request) => {
        return {
          limit: request.limit,
          offset: request.offset,
          total: data.total,
          items: data.items,
        };
      },
    }),
    updateDeliverySlots: builder.mutation<DeliverySlot, UpdateDeliverySlotRequest>({
      invalidatesTags: ['deliverySlot'],
      query: ({ id, data }) => {
        return {
          url: `/delivery-slots/${id}`,
          method: 'PATCH',
          body: data,
        }
      },
    }),
  }),
});

export const { 
  useUpdateDeliverySlotsMutation,
  useFindDeliverySlotsQuery,
  useLazyGetDeliverySlotsQuery
} = deliverySlotApi;

export default deliverySlotApi;