import React, { useMemo } from 'react';
import { matchPath, useHistory } from 'react-router-dom';
import routes from './RouteEntries';

const NotFoundRoute: React.FCC = ({ children }) => {
  const {
    location: { pathname },
  } = useHistory();

  const match = useMemo(
    () => routes.pathConfig.some(path => !!matchPath(pathname, path)),
    [pathname],
  );

  if (match) return null;

  return <>{children}</>;
};

export default NotFoundRoute;
