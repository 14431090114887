import types from './ActionTypes';

function createAction(type, payload) {
  return { type, payload };
}

function createActionCallback(type, payload, callback) {
  return { type, payload, callback };
}

export const setOrderItems = orderItem =>
  createAction(types.SET_ODER_ITEM, orderItem);
export const saveOrder = (order, callback) =>
  createActionCallback(types.SAVE_ORDER, order, callback);
export const getOrderTracking = (params, callback) =>
  createActionCallback(types.GET_ORDER_TRACKING, params, callback);
export const getOrderData = (params, callback) =>
  createActionCallback(types.GET_ORDER_DATA, params, callback);
export const hideOrderTracking = action =>
  createAction(types.HIDE_ORDER_TRACKING, action);
export const cancelOrder = () => createAction(types.CANCEL_ORDER);

// show modal windows
export const showOrderTracking = orderTrackingInformation =>
  createAction(types.SHOW_ORDER_TRACKING, orderTrackingInformation);

// update order status modal window
export const showUpdateOrderStatus = orderTracking =>
  createAction(types.SHOW_UPDATE_ORDER_STATUS, orderTracking);
export const hideUpdateOrderStatus = () =>
  createAction(types.HIDE_UPDATE_ORDER_STATUS);
export const updateOrderStatusStatusChange = status =>
  createAction(types.UPDATE_ORDER_STATUS_STATUS_CHANGE, status);
export const updateTrackingComments = comments =>
  createAction(types.UPDATE_TRACKING_COMMENTS, comments);
export const updateOrderStatus = orderDto =>
  createAction(types.UPDATE_ORDER_STATUS, orderDto);

// master data loaders
export const getProducts = payload =>
  createAction(types.GET_PRODUCTS, payload);
export const getCustomers = () => createAction(types.GET_CUSTOMERS);

export const getCustomersMaster = () =>
  createAction(types.GET_CUSTOMERS_MASTER);
export const getStatus = companyId => createAction(types.GET_STATUS, companyId);
export const getPaymentMethods = payload =>
  createAction(types.GET_PAYMENT_METHODS, payload);
export const getListPrice = payload =>
  createAction(types.GET_LIST_PRICE, payload);
export const getUserDevice = () => createAction(types.GET_USER_DEVICE);
export const getTags = companyId => createAction(types.GET_TAGS, companyId);
export const updateUserDevice = isMobile =>
  createAction(types.UPDATE_USER_DEVICE, isMobile);

// update state for data master loaders
export const updateStateCustomers = customers =>
  createAction(types.UPDATE_STATE_CUSTOMERS, customers);
export const updateStateProducts = products =>
  createAction(types.UPDATE_STATE_PRODUCTS, products);
export const updateStateStatus = status =>
  createAction(types.UPDATE_STATE_STATUS, status);
export const updateStatePaymentMethods = paymentMethods =>
  createAction(types.UPDATE_STATE_PAYMENT_METHODS, paymentMethods);
export const updateStateDeliverySlots = deliverySlots =>
  createAction(types.UPDATE_STATE_DELIVERY_SLOTS, deliverySlots);

export const updateStateTags = tags =>
  createAction(types.UPDATE_STATE_TAGS, tags);
export const loadSelectedDateDeliverySlots = selectedDate =>
  createAction(types.LOAD_SELECTED_DATE_DELIVERY_SLOTS, selectedDate);

// actions on list order grid
export const loadEditOrder = order =>
  createAction(types.LOAD_EDIT_ORDER, order);
export const copyOrder = order => createAction(types.COPY_ORDER, order);
export const loadItemsEditOrder = orderId =>
  createAction(types.LOAD_ITEMS_FOR_EDIT_ORDER, orderId);
export const openUpdateOrderStatus = orderId =>
  createAction(types.OPEN_UPDATE_ORDER_STATUS, orderId);
export const setConfirmOrderActionVisible = visible =>
  createAction(types.SET_CONFIRM_ORDER_ACTION_VISIBLE, visible);
export const setSlotStatus = slotStatus =>
  createAction(types.SET_SLOT_STATUS, slotStatus);
export const invoiceFromPurchaseOrder = (orderDto, callback) =>
  createActionCallback(types.INVOICE_FROM_PURCHASE_ORDER, orderDto, callback);
export const isInvoiceable = status =>
  createAction(types.IS_INVOICEABLE, status);
export const invoiceOrder = (orderDto, callback) =>
  createActionCallback(types.INVOICE_ORDER, orderDto, callback);
export const createPurchaseOrder = (orderDto, callback) =>
  createActionCallback(types.CREATE_PURCHASE_ORDER, orderDto, callback);
export const openConfirmInvoiceAction = orderId =>
  createAction(types.OPEN_CONFIRM_INVOICE_ACTION, orderId);
export const setOrderTracking = orderTracking =>
  createAction(types.SET_ORDER_TRACKING, orderTracking);
export const setInvoiceConfirmActionVisible = visible =>
  createAction(types.SET_INVOICE_CONFIRM_ACTION_VISIBLE, visible);

export const getAllOrders = user => createAction(types.GET_ALL_ORDERS, user);
export const getCurrentOrders = user =>
  createAction(types.GET_CURRENT_ORDERS, user);
export const searchOrders = filters =>
  createAction(types.SEARCH_ORDERS, filters);
export const searchActiveOrders = filters =>
  createAction(types.SEARCH_ACTIVE_ORDERS, filters);
export const updateOrdersPaging = paging =>
  createAction(types.UPDATE_ORDERS_PAGING, paging);
export const updateOrdersCheckedKeys = checkedKeys =>
  createAction(types.UPDATE_ORDERS_CHECKED_KEYS, checkedKeys);
export const updateOrdersFilters = filters =>
  createAction(types.UPDATE_ORDERS_FILTERS, filters);
export const updateOrdersHistoryFilters = filters =>
  createAction(types.UPDATE_ORDERS_HISTORY_FILTERS, filters);
export const updateOrdersGridRows = orders =>
  createAction(types.UPDATE_ORDERS_GRID_ROWS, orders);
export const updateOrdersIsFirstLoad = isFirstLoad =>
  createAction(types.UPDATE_ORDERS_FIRST_LOAD, isFirstLoad);

export const updateActiveUser = () => createAction(types.UPDATE_ACTIVE_USER);
export const addRowGridItems = () => createAction(types.ADD_ROWS_GRID_ITEMS);
export const deleteRowGridItems = indexRow =>
  createAction(types.DELETE_ROWS_GRID_ITEMS, indexRow);
export const updateLoading = loadingVisible =>
  createAction(types.UPDATE_LOADING, loadingVisible);
export const updateStateOrders = orders =>
  createAction(types.UPDATE_STATE_ORDERS, orders);
export const redirect = redirectTo => createAction(types.REDIRECT, redirectTo);
export const editOrder = order => createActionCallback(types.EDIT_ORDER, order);

// actions related to create order form
export const updateCustomer = customer =>
  createAction(types.CHANGE_ORDER_CUSTOMER, customer);
export const updateSelectedCustomer = customer =>
  createAction(types.UPDATE_SELECTED_CUSTOMER, customer);
export const updateSelectedPaymentMethod = paymentMethod =>
  createAction(types.UPDATE_SELECTED_PAYMENT_METHOD, paymentMethod);
export const updateSelectedDeliverySlot = deliverySlot =>
  createAction(types.UPDATE_SELECTED_DELIVERY_SLOT, deliverySlot);
export const updateSelectedPriceList = priceList =>
  createAction(types.UPDATE_SELECTED_PRICE_LIST, priceList);
export const updateSelectedChannel = channel =>
  createAction(types.UPDATE_SELECTED_CHANNEL, channel);
export const updateSelectedInvoicingSettings = invoicingSettings =>
  createAction(types.UPDATE_SELECTED_INVOICING_SETTINGS, invoicingSettings);
export const updateSelectedActionOnShipment = actionOnShipment =>
  createAction(types.UPDATE_SELECTED_ACTION_ON_SHIPMENT, actionOnShipment);
export const updateFullListPrice = fullListPrice =>
  createAction(types.UPDATE_FULL_LIST_PRICE, fullListPrice);
export const changeStatus = idStatus =>
  createAction(types.CHANGE_ORDER_STATUS, idStatus);
export const changeComments = comments =>
  createAction(types.CHANGE_ORDER_COMMENTS, comments);
export const changeNotes = notes => createAction(types.CHANGE_NOTES, notes);
export const changePaymentMethod = paymentMethod =>
  createAction(types.CHANGE_ORDER_PAYMENT_METHOD, paymentMethod);
export const changePromisedDeliveryDate = promisedDeliveryDate =>
  createAction(types.CHANGE_ORDER_PROMISED_DELIVERY_DATE, promisedDeliveryDate);
export const updateFormAction = action =>
  createAction(types.UPDATE_FORM_ACTION, action);
export const clearOrderItems = () => createAction(types.CLEAR_ORDER_ITEMS);

// security-related actions
export const getUserAuthorization = (email, callback) =>
  createActionCallback(types.GET_USER_AUTHORIZATION, email, callback);
export const updateUserAuthorization = authorizedUser =>
  createAction(types.UPDATE_USER_AUTHORIZATION, authorizedUser);
export const resetFullState = () => createAction(types.RESET_FULL_STATE);
export const logOutUser = () => createAction(types.LOG_OUT_USER);
export const showSignUp = isOpen => createAction(types.SHOW_SIGN_UP, isOpen);
export const setSignupForm = values =>
  createAction(types.SET_SIGNUP_FORM, values);
export const signupCompany = data => createAction(types.SIGNUP_COMPANY, data);

export const setShowSpinner = show =>
  createAction(types.SET_SHOW_SPINNER, show);
export const setOrderFilter = filter =>
  createAction(types.SET_ORDER_FILTERS, filter);
export const resetNewOrder = () => createAction(types.RESET_NEW_ORDER);
export const disableButton = disabled =>
  createAction(types.ACTIVATE_BUTTON, disabled);

// data master CRUD operations
export const createCustomer = values =>
  createAction(types.CREATE_CUSTOMER, values);
export const setCustomerIdType = idType =>
  createAction(types.SET_CUSTOMER_ID_TYPE, idType);
export const getStates = () => createAction(types.GET_STATES);
export const setFoundStates = states =>
  createAction(types.SET_FOUND_STATES, states);
export const setFoundCities = cities =>
  createAction(types.SET_FOUND_CITIES, cities);
export const setFoundCitiesContact = cities =>
  createAction(types.SET_FOUND_CITIES_CONTACT, cities);
export const setCitiesDisabled = value =>
  createAction(types.SET_CITIES_DISABLED, value);
export const updateStates = states => createAction(types.UPDATE_STATES, states);
export const searchStates = filters =>
  createAction(types.SEARCH_STATES, filters);
export const searchCities = filters =>
  createAction(types.SEARCH_CITIES, filters);
export const searchCitiesContact = filters =>
  createAction(types.SEARCH_CITIES_CONTACT, filters);
export const getSelectedStateCities = (selectedState, callback) =>
  createActionCallback(
    types.GET_SELECTED_STATE_CITIES,
    selectedState,
    callback,
  );
export const updateSelectedStateCities = cities =>
  createAction(types.UPDATE_SELECTED_STATE_CITIES, cities);
export const getChannels = payload =>
  createAction(types.GET_CHANNELS, payload);
export const updateChannels = channels =>
  createAction(types.UPDATE_CHANNELS, channels);
export const getInvoicingSettings = payload =>
  createAction(types.GET_INVOICING_SETTINGS, payload);
export const updateInvoicingSettings = invoicingSettings =>
  createAction(types.UPDATE_INVOICING_SETTINGS, invoicingSettings);
export const getTaxes = payload => createAction(types.GET_TAXES, payload);
export const updateTaxes = taxes => createAction(types.UPDATE_TAXES, taxes);
export const getFulFillmentTypes = payload => createAction(types.GET_FULFILLMENT_TYPES, payload);
export const updateFulFillmentTypes = fulfillmentTypes => createAction(types.UPDATE_FULFILLMENT_TYPES, fulfillmentTypes);

// customer data
export const loadEditCustomer = payload =>
  createAction(types.LOAD_EDIT_CUSTOMER, payload);
export const updateEditedCustomer = customer =>
  createAction(types.UPDATE_EDITED_CUSTOMER, customer);
export const editCustomer = customer =>
  createAction(types.EDIT_CUSTOMER, customer);
export const searchCustomers = filters =>
  createAction(types.SEARCH_CUSTOMERS, filters);
export const updateGridCustomers = customers =>
  createAction(types.UPDATE_GRID_CUSTOMERS, customers);
export const updateCustomerFilters = filters =>
  createAction(types.UPDATE_CUSTOMER_FILTERS, filters);
export const updateCustomersPaging = paging =>
  createAction(types.UPDATE_CUSTOMERS_PAGING, paging);
export const updateCustomerFormValues = value =>
  createAction(types.UPDATE_CUSTOMER_FORM_VALUES, value);
export const updateContactFormValues = value =>
  createAction(types.UPDATE_CONTACT_FORM_VALUES, value);
export const updateCustomerFormErrors = error =>
  createAction(types.UPDATE_CUSTOMER_FORM_ERROR, error);
export const updateContactFormErrors = error =>
  createAction(types.UPDATE_CONTACT_FORM_ERROR, error);
export const updateCustomerFormModel = model =>
  createAction(types.UPDATE_CUSTOMER_FORM_MODEL, model);
export const updateContactFormModel = model =>
  createAction(types.UPDATE_CONTACT_FORM_MODEL, model);

// grids handling actions
export const setLastUpdatedOrdersGrid = timeStamp =>
  createAction(types.LAST_UPDATED_ORDERS_GRID, timeStamp);
export const updateExpandedRowKeys = expandedRowKeys =>
  createAction(types.UPDATE_EXPANDED_ROW_KEYS, expandedRowKeys);

// dashboard data
export const getDashboardData = daysRange =>
  createAction(types.GET_DASHBOARD_DATA, daysRange);
export const getDailyLiveStatus = daysRange =>
  createAction(types.GET_LIVE_STATUS_STATUS, daysRange);
export const setDashboardData = data =>
  createAction(types.SET_DASHBOARD_DATA, data);
export const setLiveStatusData = data =>
  createAction(types.SET_LIVE_STATUS_DATA, data);
export const setDashboardControllers = dashboardControllers =>
  createAction(types.SET_DASHBOARD_CONTROLLERS, dashboardControllers);
export const updateTopTenDimension = dimension =>
  createAction(types.UPDATE_TOPTEN_DIMENSION, dimension);

// production list
export const getProductionList = daysRange =>
  createAction(types.GET_PRODUCTION_LIST, daysRange);
export const setProductionListData = data =>
  createAction(types.SET_PRODUCTION_LIST_DATA, data);
export const setProductionListFilters = filters =>
  createAction(types.SET_PRODUCTION_GRID_FILTERS, filters);

// set of redirect Actions
export const redirectCreateCustomer = () =>
  createAction(types.REDIRECT_CREATE_CUSTOMER);
export const redirectCreateProduct = () =>
  createAction(types.REDIRECT_CREATE_PRODUCT);

// multi-order invoicing
export const getCustomerPendingInvoicingOrders = customerId =>
  createAction(types.GET_PENDING_INVOICING_ORDERS, customerId);
export const setPendingInvoicingOrders = orders =>
  createAction(types.SET_PENDING_INVOICING_ORDERS, orders);
export const setSelectedCustomerMultiOrderInvoicing = customerId =>
  createAction(types.SET_SELECTED_CUSTOMER_MULTIORDERINVOICING, customerId);
export const setMultiOrderInvoicingCheckedKeys = checkedKeys =>
  createAction(types.SET_MULTI_ORDER_INVOICING_CHECKED_KEYS, checkedKeys);
export const updateOrderInvoiceData = (order, callback) =>
  createActionCallback(types.UPDATE_ORDER_INVOICE_DATA, order, callback);
export const invoiceMultiOrder = (orders, callback) =>
  createActionCallback(types.INVOICE_MULTI_ORDER, orders, callback);
export const setMultiOrderInvoicingModalVisible = visible =>
  createAction(types.SET_MULTI_ORDER_MODAL_VISIBLE, visible);
export const setMultiOrderInvoiceNotes = notes =>
  createAction(types.SET_MULTI_ORDERS_INVOICE_NOTES, notes);
export const setOrderInvoiceData = invoiceData =>
  createAction(types.SET_ORDER_INVOICE_DATA, invoiceData);

// version control
export const setNewVersionAvailable = flag =>
  createAction(types.SET_NEW_VERSION_AVAILABLE, flag);
export const setWaitingWorker = worker =>
  createAction(types.SET_WAITING_WORKER, worker);
// file uploader
export const setFileUploaderData = data =>
  createAction(types.SET_FILE_UPLOADER_DATA, data);

// delivery slots
export const getDeliverySlots = companyId =>
  createAction(types.GET_DELIVERY_SLOTS, companyId);
export const createDeliverySlots = (slots, callback) =>
  createActionCallback(types.CREATE_DELIVERY_SLOTS, slots, callback);
export const updateDeliverySlotsPaging = paging =>
  createAction(types.UPDATE_DELIVERY_SLOTS_PAGING, paging);
export const searchDeliverySlots = filters =>
  createAction(types.SEARCH_DELIVERY_SLOTS, filters);
export const updateDeliverySlotsGrid = deliverySlots =>
  createAction(types.UPDATE_DELIVERY_SLOTS_GRID, deliverySlots);
export const updateProcessEnabled = enabled =>
  createAction(types.UPDATE_PROCESS_ENABLED, enabled);
export const getDeliverySlot = id => createAction(types.GET_DELIVERY_SLOT, id);
export const updateDeliverySlot = (slot, callback) =>
  createActionCallback(types.UPDATE_DELIVERY_SLOT, slot, callback);
export const setEditedSlot = slot => createAction(types.SET_EDITED_SLOT, slot);
export const setEditedSlotVisible = visible =>
  createAction(types.SET_EDITED_SLOT_VISIBLE, visible);

// products
export const searchProducts = filters =>
  createAction(types.SEARCH_PRODUCTS, filters);
export const updateProductsPaging = paging =>
  createAction(types.UPDATE_PRODUCTS_PAGING, paging);
export const updateProductsGrid = products =>
  createAction(types.UPDATE_PRODUCTS_GRID, products);
export const updateProductFilters = filters =>
  createAction(types.UPDATE_PRODUCT_FILTERS, filters);
export const updateProductsFormValue = value =>
  createAction(types.UPDATE_PRODUCTS_FORM_VALUE, value);
export const updateProductsFormError = error =>
  createAction(types.UPDATE_PRODUCTS_FORM_ERROR, error);
export const createProduct = (product, callback) =>
  createActionCallback(types.CREATE_PRODUCT, product, callback);
export const editProduct = (product, callback) =>
  createActionCallback(types.EDIT_PRODUCT, product, callback);

// tags
export const setUpdateTagsVisible = visible =>
  createAction(types.SET_UPDATE_TAGS_VISIBLE, visible);
export const updateSelectedTags = tags =>
  createAction(types.UPDATE_SELECTED_TAGS, tags);
export const updateOrderTags = (orderTags, callback) =>
  createActionCallback(types.UPDATE_ORDER_TAGS, orderTags, callback);
export const updateMaxTags = maxTags =>
  createAction(types.UPDATE_MAX_TAGS, maxTags);
export const getOrderInvoiceURL = (id, callback) =>
  createActionCallback(types.GET_ORDER_INVOICE_URL, id, callback);
export const updateStateOrderInvoiceURL = URL =>
  createAction(types.UPDATE_STATE_ORDER_INVOICE_URL, URL);
export const updateStatePurchaseOrderURL = URL =>
  createAction(types.UPDATE_STATE_PURCHASE_ORDER_URL, URL);
export const getPurchaseOrderURL = (purchaseOrderIdERP, callback) =>
  createActionCallback(
    types.GET_PURCHASE_ORDER_URL,
    purchaseOrderIdERP,
    callback,
  );

// order event
export const triggerOrderEvent = orderEvent =>
  createAction(types.TRIGGER_ORDER_EVENT, orderEvent);
export const triggerInvoicing = order =>
  createAction(types.TRIGGER_INVOICING, order);
// export const triggerPurchaseOrder = order =>
//   createAction(types.TRIGGER_PURCHASE_ORDER, order);

// pdf export
export const showExportedPDF = show =>
  createAction(types.SHOW_EXPORTED_PDF, show);

// token
export const setToken = auth => createAction(types.SET_TOKEN_DATA, auth);
export const clearToken = () =>
  createAction(types.CLEAR_TOKEN_DATA, { token: '' });

// modal
export const setOpenAuthModal = data =>
  createAction(types.OPEN_AUTH_MODAL, data);
export const closeAuthModal = () =>
  createAction(types.CLOSE_AUTH_MODAL, {
    open: false,
  });

// invoicing in progress
export const setInvoicingInProgress = isInProgress =>
  createAction(types.SET_INVOICING_IN_PROGRESS, isInProgress);

    // temp to be deleted
export const triggerMultipleInvoicing = orders =>
  createAction(types.TRIGGER_MULTIPLE_INVOICING,orders)  