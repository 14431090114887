import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useLocationState = <T>() => {
  const { state } = useLocation<T | undefined | null>();
  const { current } = useRef(state);

  return current;
};

export default useLocationState;
