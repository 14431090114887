import React from 'react';
import { Input } from 'rsuite';
import { Col, Container, Row } from 'rsuite-migration';
import '../styles/General.css';
import * as constants from '../utils/Constants';
import { Grid } from './Inputs';
import { OrderSummary } from './OrderSummary';

export const OrderDetail = ({
  props,
  changeComments,
  changeNotes,
  onGridRowsUpdated,
  addGridOrder,
  numberOfRecords,
}) => (
  <Container fluid>
    <Row className="pl-0 ml-0">
      <Col className="no-spaces-left">
        <button onClick={addGridOrder} className="btn btn-secondary">
          Agregar +
        </button>
      </Col>
    </Row>

    {props.orderRows.length > 0 && (
      <Row className="pl-0 ml-0">
        <Col className="no-spaces-left" sm={12} md={12} lg={12}>
          <Grid
            columns={props.itemsColumns}
            rows={props.orderRows}
            onGridRowsUpdated={onGridRowsUpdated}
            minHeight={
              numberOfRecords * constants.GRID_ROWS_HEIGHT +
              constants.GRID_HEADER_HEIGHT +
              3
            }
          />
        </Col>
      </Row>
    )}

    <br />
    <Row className="pl-0 ml-0">
      <Col className="no-spaces-left" sm={12} md={6} lg={6}>
        <Input
          as="textarea"
          placeholder="Observaciones"
          onChange={changeComments}
          value={props.comments}
          defaultValue={props.selectedComments}
          rows="2"
        />
      </Col>
      <Col sm={12} md={6} lg={6}>
        <OrderSummary props={props.summary} />
      </Col>
    </Row>

    <Row className="pl-0 ml-0">
      <Col className="no-spaces-left" sm={12} md={6} lg={6}>
        <Input
          as="textarea"
          placeholder="Notas en la factura"
          onChange={changeNotes}
          value={props.notes}
          defaultValue={props.selectedNotes}
          rows="2"
        />
      </Col>
    </Row>
  </Container>
);
