import React, { useState } from 'react'
import { Form, Checkbox } from "rsuite";

const CheckboxComponent = React.forwardRef<any, any>((props, ref) => {
    return <Checkbox {...props} ref={ref} checked={props.value}
        value={props.value}
        onChange={(val) => props.onChange(!val)}
    >{props.label}</Checkbox>
});

export function CheckboxField({ name, label, tooltip, tooltipText, value = false, ...props }) {
    return (
        <Form.Group>
            <Form.Control
                name={name}
                value={value}
                label={label}
                accepter={CheckboxComponent}
                {...props}
            />
            {tooltip && <Form.HelpText tooltip>{tooltipText}</Form.HelpText>}
        </Form.Group>
    );

}