/* eslint-disable */
import storage from 'local-storage-fallback';
import merge from 'lodash.merge';
import pick from 'lodash.pick';
// import * as homeActions from './Home/Actions'
// import * as appActions from './app/Actions'

function tryParse(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    if (str == 'undefined') {
      return;
    } else {
      return str;
    }
  }
}

// function isStateValid(horaUltimaActividad){
//     let now = Math.round(new Date().getTime()/1000);
//     return (now - horaUltimaActividad ) < (20*60)?true:false
// }

export const save = (key, value) => {
  storage.setItem(key, value);
};

export const get = key => tryParse(storage.getItem(key));

// export const removeItem =  (key) => storage.removeItem(key);

// export const clearStateStorage =  () => save('STATE', null);

export function restoreState(defaultState) {
  let oldState = get('STATE');
  if (oldState !== 'undefined' && oldState) {
    //  oldState.tabContainer.visitedPages=new Set([1]);
    //  oldState.appReducer.showLoader=false;
    const copy = JSON.parse(JSON.stringify(defaultState));
    return merge(copy, oldState);
  } else {
    return defaultState;
  }
}

export function persistState(store) {
  let state = store.getState();
  // let horaUltimaActividad=state.homeReducer.horaUltimaActividad;

  if (state.ordox.activeUser) {
    // state.homeReducer.horaUltimaActividad = Math.round(new Date().getTime()/1000);
    // window.clearInterval(state.homeReducer.timer);
    // state.homeReducer.timer = window.setInterval(function() { validateUserActivity(store); },10*60*1000);

    const newState = pick(state.ordox, [
      'activeUser',
      'versionUpdates',
      'overallParams',
      'loadingVisible',
      'listPrice',
      'fullDeliverySlots',
      'redirectTo',
      'showSpinner',
      'versionUpdates',
      'mobileClient',
      'company',
      'status',
      'auth',
      'invoicingInProgress',
      'fulfillmentTypes'
    ]);

    save(
      'STATE',
      JSON.stringify({
        router: state.router,
        ordox: newState,
      }),
    );

    // validateUserActivity(store);
  }
}

// export function validateUserActivity(store){
//     let state = store.getState();
//     if(state.homeReducer.horaUltimaActividad!==null){
//         if(!isStateValid(state.homeReducer.horaUltimaActividad)){
//           store.dispatch(homeActions.logout({
//             userName:state.homeReducer.login.userName,
//             nombre: null,
//             logueado: false,
//             token: null,
//             mensaje: null
//           }))
//         }
//     }
// }
