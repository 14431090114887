import dayjs from '@utils/dayjs';

export function formatDateTimeSpanishCO(value) {
  return new Intl.DateTimeFormat('es-CO', {
    year: 'numeric',
    month: 'numeric',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(new Date(value));
}

export function getStatusColor(remainingTime, status) {
  if (status.role === 'DELIVERY') {
    return 'green';
  } else if (status.role === 'CANCEL') {
    return 'cyan';
  } else if (remainingTime > 2) {
    return 'blue';
  } else if (remainingTime < 2 && remainingTime > 0) {
    return 'yellow';
  } else {
    return 'red';
  }
}
export function getStatusColorInventory(status, warning, availableQuantity) {
  if (status === 'AVAILABLE') {
    const numberWarning = warning ? warning : 3
    if (availableQuantity > numberWarning) {
      return 'green'
    } else if (availableQuantity < numberWarning && availableQuantity > 0) {
      return 'yellow'
    } else if (availableQuantity <= 0) {
      return 'red';
    }
  } else if (status === 'OUT_OF_STOCK') {
    return 'red';
  }
}

export function formatDateSpanishCO(value) {
  return new dayjs(value).format('DD/MM/YYYY');
}

export function formatDateSpanishWeekDay(value) {
  return new dayjs(value).format('dddd, DD/MM/YYYY');
}

export function formatDateAndHourSpanishCO(value) {
  const formattedDate = new dayjs(value).format('DD/MM/YYYY, HH:mm');
  return formattedDate;
}

export function formatDateAndHourExcel(value) {
  const formattedDate = new dayjs(value).format('DD/MM/YYYY');
  return formattedDate;
}

export function formatToCalendarDate(value) {
  const formattedDate = new dayjs(value).format('YYYY-MM-DDTHH:mm');
  return formattedDate;
}

export function formatDateAndHourSpanishCOShort(value) {
  const formattedDate = new dayjs(value).format('DD/MM, HH:mm');
  return formattedDate;
}

export function getDateTimeNow() {
  return new dayjs();
}

export function formatCurrencySpanishCO(value) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
}

export function formatNumber(value) {
  return new Intl.NumberFormat().format(value);
}

export function getDayFromFullDate(value) {
  const fullDate = dayjs(value);
  return fullDate.date();
}

export function composeDateAndTime(date, time) {
  let formattedDate = dayjs();
  date = dayjs(date);
  time = dayjs(time);

  formattedDate = formattedDate.set({
    year: date.year(),
    month: date.month(),
    date: date.date(),
    hour: time.hour(),
    minutes: time.minute(),
  });
  return formatToDatabaseDate(formattedDate);
}

// export function getLocalDateTime(date) {
//   let utcDate = new Date(date);
//   return utcDate.setHours(utcDate.getHours() - TIME_ZONE_DIFF);
// }

export function composeDateAndTimeCalendar(date, time) {
  let formattedDate = dayjs();
  date = dayjs(date);

  formattedDate = formattedDate.set({
    year: date.year(),
    month: date.month(),
    date: date.date(),
    hour: time,
    minutes: 0,
  });
  return formattedDate.format('YYYY-MM-DDTHH:mm');
}

export function formatFromESCODateToDateTime(value) {
  if (!value) return null;

  var array = value.split('/');
  var day = array[0];
  var month = array[1] - 1;
  var year = array[2].split(',')[0];
  var hour = array[2].split(',')[1].split(':')[0];
  var mins = array[2].split(',')[1].split(':')[1];

  var date = new Date(year, month, day, hour, mins);
  return date;
}

export function formatPercentage(value) {
  return value + '%';
}

export function formatToDatabaseDate(value) {
  var tmp = new Date(value);
  var formattedDate;
  formattedDate =
    tmp.getFullYear() +
    '-' +
    pad(tmp.getMonth() + 1) +
    '-' +
    pad(tmp.getDate()) +
    'T' +
    pad(tmp.getHours()) +
    ':' +
    pad(tmp.getMinutes()) +
    ':' +
    pad(tmp.getSeconds()) +
    'Z';
  return formattedDate;
}

function pad(n) {
  return n < 10 ? '0' + n : n;
}

export const localeCalendarSpanish = {
  sunday: 'Do',
  monday: 'Lu',
  tuesday: 'Ma',
  wednesday: 'Mi',
  thursday: 'Ju',
  friday: 'Vi',
  saturday: 'Sa',
  ok: 'OK',
  today: 'Hoy',
  yesterday: '',
  tomorrow: 'Mañana',
  hours: 'Hora',
  minutes: 'Minutos',
  seconds: 'Segundos',
};

export const formatCalendar = [
  {
    label: 'tomorrow',
    value: new Date().getDate() + 1,
    closeOverlay: true,
  },
];

export const shortName = (value, length) => {
  return value.substring(0, length);
};

export const getCustomerIdentification = (type, id) => {
  return type + ' - ' + id;
};

export const getStateCityData = (state, city) => {
  return city + ', ' + state;
};

export const getDateTimeValue = value => {
  dayjs(value).toISOString();
};
