import React, { HTMLAttributes, useCallback } from 'react';
import countryCodes from './countryCodes.json';

export type FormattedPhoneNumberProps = {
  value?: string;
  placeholder?: React.ReactNode;
} & Omit<HTMLAttributes<HTMLSpanElement>, 'placeholder'>;

const FormattedPhoneNumber: React.FC<FormattedPhoneNumberProps> = ({
  value = '',
  placeholder,
  className,
  ...props
}) => {
  const copyHandler = useCallback((ev: React.ClipboardEvent<HTMLElement>) => {
    const phone = ev.currentTarget.innerText.replace(/[() ]/g, '');
    navigator.clipboard.writeText(phone);
    ev.preventDefault();
  }, []);

  return (
    <span className={`select-all ${className}`} onCopy={copyHandler} {...props}>
      {value ? formatPhoneNumber(value).phone : placeholder}
    </span>
  );
};

export default FormattedPhoneNumber;

const PHONE_NUMBER_REGEX = /(?<group1>\d{3})(?<group2>\d{3})(?<group3>\d*)/g;
const PHONE_NUMBER_FORMAT = '$<group1> $<group2> $<group3>';

export function formatPhoneNumber(phone: string) {
  // eslint-disable-next-line no-underscore-dangle
  const phone_ = phone?.trim() || '';

  const countryCode = countryCodes.find(({ dialCode }) =>
    phone_.startsWith(dialCode),
  );

  if (countryCode) {
    return {
      ...countryCode,
      phone: `${phone_.slice(0, countryCode.dialCode.length)} ${phone_
        .slice(countryCode.dialCode.length)
        .replace(PHONE_NUMBER_REGEX, PHONE_NUMBER_FORMAT)}`,
    };
  }

  return {
    code: '',
    name: '',
    phone: `${phone_.replace(PHONE_NUMBER_REGEX, PHONE_NUMBER_FORMAT)}`,
    dialCode: '',
  };
}
