import axios from 'axios';
import store from '../../store';
import * as constants from '../../utils/Constants';

const instance = axios.create({
  baseURL: constants.API_BACKEND_URL,
  timeout: 35000,
});

instance.interceptors.request.use(
  config => {
    const { ordox } = store.getState();

    if (ordox.auth.token) {
      config.headers.Authorization = `Bearer ${ordox.auth.token}`;
    }
    return config;
  },
  null,
  { synchronous: true },
);

export default instance;
