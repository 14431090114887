import { createSelector } from "reselect";

export const STATUS_ACTIONS = {
  START: "START",
  SHIPMENT: "SHIPMENT",
  DELIVERY: "DELIVERY",
  CANCEL: "CANCEL",
  EDIT: "EDIT",
};

export const getStatusList = (state) => state.ordox.status;
export const getDeliveryStatusId = createSelector(
  [getStatusList],
  (statusList) => {
    return statusList.find((status) => status.role === "DELIVERY")?.id;
  }
);
export const getCancelStatusId = createSelector(
  [getStatusList],
  (statusList) => {
    return statusList.find((status) => status.role === "CANCEL")?.id;
  }
);

export const getFormattedStatusList = createSelector(
  [getStatusList],
  (statusList) => {
    const manualStatus = statusList.filter((status) => !status.automatic);
    return manualStatus.map((status) => {
      return {
        value: status.id,
        label: status.description,
        listingOrder: status.listingOrder,
        role: status.role,
      };
    });
  }
);

export const getStatus = (statusList, action) => {
  return statusList.find((status) => status.role == action);
};
