export const getCustomerIdType = (state) => state.ordox.customerIdType;
export const getCustomers = (state) => state.ordox.masterData.customers.list;
export const getEditedCustomer = (state) =>
  state.ordox.masterData.customers.editedCustomer;
export const getCustomerFormAction = (state) =>
  state.ordox.customerCaptureAction;
export const getCustomerFilters = (state) =>
  state.ordox.masterData.customers.filters;
export const getCustomerPaging = (state, params) =>
  state.ordox.masterData.customers.paging[params];

export const getCustomerGridColumns = () => {
  const columns = [
    {
      key: "key",
      headerAlign: "center",
      headerText: "",
      flexGrow: 2,
      width: 0,
      headerClassName: "grid-header",
      contentClassName: "grid-row-left",
    },
    {
      key: "name",
      headerAlign: "center",
      headerText: "Cliente",
      flexGrow: 2,
      width: 450,
      headerClassName: "grid-header",
      contentClassName: "grid-row-left",
    },
    {
      key: "email",
      headerAlign: "center",
      headerText: "e-mail",
      flexGrow: 2,
      width: 290,
      headerClassName: "grid-header",
      contentClassName: "grid-row-center",
    },
    {
      key: "mobile",
      headerAlign: "center",
      headerText: "Celular",
      flexGrow: 2,
      width: 150,
      headerClassName: "grid-header",
      contentClassName: "grid-row-center",
    },
    {
      key: "idERP",
      headerAlign: "center",
      headerText: "Id Ext.",
      flexGrow: 2,
      width: 130,
      headerClassName: "grid-header",
      contentClassName: "grid-row-center",
    },
  ];
  return columns;
};

export const getCustomerFormValues = (state) =>
  state.ordox.masterData.customers.formValue;
export const getcontactFormValues = (state) =>
  state.ordox.masterData.contacts.formValue;
export const getCustomerFormModel = (state) =>
  state.ordox.masterData.customers.formModel;
export const getContactFormModel = (state) =>
  state.ordox.masterData.contacts.formModel;
