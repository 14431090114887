import { reactIntl } from '@src/locale';
import getLocale from '@src/locale/getLocale';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

dayjs.locale(getLocale());
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);
dayjs.extend(calendar);

export const dayjsCalendar = (
  date?: dayjs.ConfigType,
  referenceTime: dayjs.ConfigType = null,
) =>
  dayjs(date).calendar(referenceTime, {
    sameDay: reactIntl['calendar.sameDay'],
    nextDay: reactIntl['calendar.nextDay'],
    nextWeek: reactIntl['calendar.nextWeek'],
    lastDay: reactIntl['calendar.lastDay'],
    lastWeek: reactIntl['calendar.lastWeek'],
    sameElse: 'DD/MM/YYYY h:mm A',
  });

type DateRange = 'today' | 'yesterday' | 'tomorrow' | 'last7Days' | 'next7Days';

export const dayjsRange = (
  range: DateRange,
  reference?: dayjs.ConfigType,
): { label: string; value: [Date, Date] } => {
  const date = dayjs(reference);

  const label: string = reactIntl[`calendar.${range}`];

  switch (range) {
    case 'last7Days':
      return {
        label,
        value: [
          date.subtract(6, 'day').startOf('day').toDate(),
          date.endOf('day').toDate(),
        ],
      };
    case 'yesterday':
      return {
        label,
        value: [
          date.subtract(1, 'day').startOf('day').toDate(),
          date.subtract(1, 'day').endOf('day').toDate(),
        ],
      };
    case 'today':
      return {
        label,
        value: [date.startOf('day').toDate(), date.endOf('day').toDate()],
      };
    case 'tomorrow':
      return {
        label,
        value: [
          date.add(1, 'day').startOf('day').toDate(),
          date.add(1, 'day').endOf('day').toDate(),
        ],
      };
    case 'next7Days':
      return {
        label,
        value: [
          date.startOf('day').toDate(),
          date.add(6, 'day').endOf('day').toDate(),
        ],
      };
  }
};

export const dayjsRanges = (
  ranges: DateRange[],
  reference?: dayjs.ConfigType,
) => ranges.map(range => dayjsRange(range, reference));

export const formats = {
  datetime: 'DD/MM/YYYY hh:mm A',
  time: 'hh:mm A',
} as const;

export default dayjs;
