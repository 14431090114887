import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Animation } from 'rsuite';
import AuthModalComponent from './Modals/Auth';
import { useSideNavContext } from './SideNavMenu';
import { mergeStyle } from './ui/mergeStyle';
import { getAuthModalData } from '../selectors/SystemSelector';
import { useDispatch, useSelector } from 'react-redux';
import { closeAuthModal, redirect, setOpenAuthModal } from '@actions/index';

type PageHeaderProps = {
  left?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  right?: ReactNode;
};

type PageHeaderContextValue = {
  [K in keyof PageHeaderProps as `set${Capitalize<K>}`]-?: Dispatch<
    SetStateAction<PageHeaderProps[K]>
  >;
} & PageHeaderProps & {
    acquireResource(): number;
    clearResource(key: number): void;
  };

const PageHeaderContext = createContext<PageHeaderContextValue>({
  setLeft() {},
  setRight() {},
  setSubtitle() {},
  setTitle() {},
  acquireResource() {
    return 0;
  },
  clearResource() {},
});

export const PageHeaderProvider: React.FCC = ({ children }) => {
  const [title, setTitle] = useState();
  const [subtitle, setSubtitle] = useState();
  const [left, setLeft] = useState();
  const [right, setRight] = useState();
  const resourceKeyRef = useRef(0);

  const acquireResource = useCallback(
    () => (resourceKeyRef.current = resourceKeyRef.current + 1),
    [],
  );

  const clearResource = useCallback((key: number) => {
    if (key === resourceKeyRef.current) {
      setTitle(null);
      setSubtitle(null);
      setLeft(null);
      setRight(null);
    }
  }, []);

  return (
    <PageHeaderContext.Provider
      value={{
        title,
        subtitle,
        left,
        right,
        setLeft,
        setRight,
        setTitle,
        setSubtitle,
        acquireResource,
        clearResource,
      }}>
      {children}
    </PageHeaderContext.Provider>
  );
};

export const usePageHeaderContext = () => useContext(PageHeaderContext);

export const PageHeaderPortal: React.FC<PageHeaderProps> = ({
  left,
  right,
  subtitle,
  title,
}) => {
  const {
    setLeft,
    setRight,
    setSubtitle,
    setTitle,
    acquireResource,
    clearResource,
  } = usePageHeaderContext();

  const key = useMemo(acquireResource, [acquireResource]);

  useEffect(() => {
    setTitle(title);
    setSubtitle(subtitle);
    setLeft(left);
    setRight(right);

    return () => clearResource(key);
  }, [
    title,
    subtitle,
    left,
    right,
    setTitle,
    setSubtitle,
    setLeft,
    setRight,
    clearResource,
    key,
  ]);

  return null;
};

const PageHeader: React.FCC<PageHeaderProps> = ({
  title,
  subtitle,
  children,
  left,
  right,
}) => {
  const { setVisible, visible } = useSideNavContext();
  const context = usePageHeaderContext();

  const left_ = context.left || left;
  const right_ = context.right || right;
  const title_ = context.title || title;
  const subtitle_ = context.subtitle || subtitle;
  const authModalVisible = useSelector(getAuthModalData);
  const dispatch = useDispatch();

  useEffect(() => {
    setVisible(!left_);
  }, [left_, setVisible, visible]);

  const handleCloseModal = () => {
    dispatch(closeAuthModal());
    dispatch(setOpenAuthModal({ open: false }))
    dispatch(redirect('/logout'));
    window.location.reload()
  };

  useEffect(() => {
    return () => setVisible(true);
  }, []);
  return (
    <div className="page-header text-base text-black w-full pr-4 pb-2 pt-2.5 flex flex-row items-center">
      <Animation.Fade in={!!left_} transitionAppear>
        {(props, ref) => (
          <div
            ref={ref}
            {...mergeStyle(
              {
                className:
                  'flex flex-row flex-nowrap pl-2 pr-2 w-12 items-center',
              },
              props,
            )}>
            {left_}
          </div>
        )}
      </Animation.Fade>
      <Animation.Fade in={!!title_} transitionAppear>
        {(props, ref) => (
          <h3
            ref={ref}
            {...props}
            className={`flex-1 text-lg font-medium ${props.className}`}>
            {title_}
          </h3>
        )}
      </Animation.Fade>
      {subtitle_ && <h4 className="text-sm font-normal">{subtitle_}</h4>}
      {children}
      <Animation.Fade in={!!right_} transitionAppear>
        {(props, ref) => (
          <div
            ref={ref}
            {...mergeStyle(
              { className: 'flex flex-row flex-nowrap pl-2 ml-auto' },
              props,
            )}>
            {right_}
          </div>
        )}
      </Animation.Fade>
      <AuthModalComponent
        open={authModalVisible.open}
        handleClose={handleCloseModal}
      />
    </div>
  );
};

export default PageHeader;
