import React, { ReactNode } from 'react';
import {
  FloatingActionContainerProvider,
  RenderFloatingActionContainer,
} from './ui/FloatingActionButton';

type PageProps = {
  header?: ReactNode;
  footer?: ReactNode;
};

const PageLayout: React.FCC<PageProps> = ({ children, header, footer }) => {
  return (
    <FloatingActionContainerProvider>
      <div id="page-layout" className="h-screen flex flex-col">
        {header}
        <div className="flex-1 relative">
          <div className="absolute -left-0 -top-2 -right-0 h-1 z-10 shadow-gray-400 shadow-md" />
          <div className="absolute inset-0 overflow-auto flex items-stretch">
            <div
              id="page-layout-body"
              className="flex-1 flex flex-col items-stretch">
              {children}
            </div>
          </div>
          <RenderFloatingActionContainer />
        </div>
        {footer}
      </div>
    </FloatingActionContainerProvider>
  );
};

export default PageLayout;
