import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonToolbar, Modal } from 'rsuite';


export function ModalExportData(props) {
  const {
    visible,
    onClose,
    title,
    submitExport,
    disabled,
    pages,
  } = props;
  const { control, handleSubmit, setValue, formState: { errors } } = useForm();
  const numPages = Math.ceil(pages)

  const onSubmit = (data) => {
    submitExport(data)
    onCloseModal()
  };

  const onCloseModal = () => {
    setValue('page', 1);
    onClose()
  }

  const handleLengthChange = (e) => {
    const inputValue = e.target.value;
    if (parseInt(inputValue) <= numPages) {
      setValue('page', inputValue);
    } else {
      if (inputValue === '') {
        setValue('page', inputValue);
      }
    }
  };
  return (
    <div>
      <Modal
        className="modal-dialog-container"
        open={visible}
        size="xs"
        keyboard
        onClose={onCloseModal}
      >
        <Modal.Header>
          <Modal.Title > {title}</Modal.Title>
        </Modal.Header>
      <Modal.Body>
        <div style={{ padding: '0px 20px 20px 20px' }}>
          <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 gap-x-4 gap-y-2">
              <div >
              <div className="mb-2">
                <label className="block text-sm text-gray-700"><FormattedMessage id="page" /></label>
                  <Controller
                  control={control}
                  name="page"
                  defaultValue={1}
                  rules={{ required: 'Página is required' }}
                  render={({ field }) => (
                    <div>
                      <input
                        type="number"
                        {...field}
                        min={0}
                        maxLength={3}
                        disabled={!disabled}
                        value={field.value}
                        onChange={handleLengthChange}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                        className={`mt-1 p-2 border rounded-md w-full focus:outline-none w-1/2 `}
                      />
                      {errors?.page?.message && <span className="text-red-500 text-sm">{errors?.page?.message}</span>}
                    </div>
                  )}
                />
              </div>
            </div>
            <ButtonToolbar>
              <Button
                appearance="primary"
                type="submit"
              >
                Aceptar
              </Button>
              <Button onClick={onCloseModal}>Cancelar</Button>
            </ButtonToolbar>
          </form>
        </div>
      </Modal.Body>
      </Modal>
    </div>
  );
};