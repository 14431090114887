const orderValidations = (values) => {
  const MESSAGES_REQUIRED_FIELDS =
    'Los campos "Cliente", "Método de Pago", "Franja horaria", "Items" son obligatorios. ';
  let errors = {
    customerId: false,
    paymentMethodId: false,
    promisedDeliveryDate: false,
    deliverySlotId: false,
    items: false,
    errormessages: [],
    errorsCounter: 0,
  };

  let requiredFields = [
    "customerId",
    "paymentMethodId",
    "promisedDeliveryDate",
    "items",
    "deliverySlotId",
  ];
  requiredFieldsvalidations(
    values,
    requiredFields,
    errors,
    MESSAGES_REQUIRED_FIELDS
  );
  itemsValidations(values.items, errors);
  return errors;
};

const requiredFieldsvalidations = (
  values,
  requiredFields,
  errors,
  MESSAGES_REQUIRED_FIELDS
) => {
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = true;
      errors.errorsCounter++;
    } else if (values[field].length === 0) {
      errors[field] = true;
      errors.errorsCounter++;
    } else {
      errors[field] = false;
    }
  });
  if (errors.errorsCounter > 0) {
    errors.errormessages.push(MESSAGES_REQUIRED_FIELDS);
  }
  return errors;
};

const itemsValidations = (items, errors) => {
  items.forEach((item) => {
    if (!item.productId || !item.quantity || parseInt(item.quantity) <= 0) {
      errors.errorsCounter++;
      errors.errormessages.push(
        "Las cantidades de productos deben ser mayores a cero"
      );
    }
  });
};

export default orderValidations;
