import { cleanupString } from "./Tools";

export function mapCreateCustomerDto(
  {
    cityId,
    stateId,
    phone,
    createdAt,
    updatedAt,
    status,
    fullName,
    idERP,
    idShopify,
    companyId,
    ...fullPayload
  },
  fullDataValidation,
) {
  if (fullDataValidation) {
    // fullPayload.idShopify = "230034444";
    fullPayload.source = "savia";
    return fullPayload;
  }

  // if (!fullPayload.lastName) {
  //   fullPayload.lastName = " - "
  // }

  if (!fullPayload.identification) {
    fullPayload.identification = "1"
  }

  if (!fullPayload.address) {
    fullPayload.address = "Calle ABC"
  }

  // if (!fullPayload.email) {
  //   fullPayload.email = "defaultSavia@savia.com"
  // }

  fullPayload.source = "savia";

  return fullPayload;
}

export const mapCustomersList = (parentArray) => {
  let childArray = [];

  childArray = parentArray.map((childNode) => {
    const container = {};
    container["value"] = childNode.id;
    container["key"] = childNode.id;
    container["id"] = childNode.id;
    container["label"] = childNode.fullName;
    container["name"] = childNode.fullName;
    container["city"] = childNode.city;
    container["state"] = childNode.state;
    container["cityId"] = (childNode.city && childNode.city.id) || '';
    container["stateId"] = (childNode.state && childNode.state.id) || '';
    container["mobile"] = childNode.mobile;
    container["listPriceId"] = childNode.listPriceId;
    container["address"] = childNode.primaryAddress;
    container["email"] = childNode.primaryEmail;
    container["paymentTerms"] = childNode.paymentTerms;
    container["idERP"] = childNode.idERP;
    container["paymentMethodId"] = childNode.paymentMethodId;
    container["channelId"] = childNode.channelId;
    container["invoicingSettingId"] = childNode.invoicingSettingId;
    container["actionOnShipment"] = childNode.actionOnShipment;
    return container;
  });
  return childArray;
};

export const mapSearchFilters = (payload) => {
  let fields, values;
  let searchFilter = {};

  let columns = Object.keys(payload);
  let filters = Object.values(payload);

  fields = cleanupString(columns.join(","));
  values = cleanupString(filters.join(","));

  searchFilter.columns = fields;
  searchFilter.filters = values;

  return searchFilter;
};
