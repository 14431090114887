import { selectIsAuthorized } from "@selectors/AuthorizationSelector";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const RootRoute = () => {
  const isAuthorized = useSelector(selectIsAuthorized);

  return <Redirect to={isAuthorized ? "/home" : "/login"} />;
};

export default RootRoute;
