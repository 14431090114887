import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { AppState } from '@src/store';
import { API_BACKEND_URL } from '@utils/Constants';

export const ordoxBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: `${API_BACKEND_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const { ordox } = getState() as AppState;
      const token = ordox.auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });
