type SliceRange = `${'[' | '('}${']' | ')'}`;

const RANGES = {
  '[': 0,
  ']': 1,
  '(': 1,
  ')': 0,
};

const slice = (
  text: string | undefined | null,
  start: number,
  end: number,
  [l, r]: SliceRange,
) => (text || '').slice(start + RANGES[l], end + RANGES[r]);

export default slice;
