const SupportedLocales = ["es", "en"] as const;

type Locale = typeof SupportedLocales[number];

let currentLocale: Locale;

const getLocale = (): Locale => {
  if (currentLocale) return currentLocale;

  const locale = navigator.language.split("-")[0] as Locale;
  currentLocale = SupportedLocales.includes(locale) ? locale : "en";

  return currentLocale;
};

export default getLocale;
