import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Message, toaster } from 'rsuite';
import * as actions from '../actions';
import { Login } from '../components/Security/Login';
import { Signup } from '../components/Security/Signup';
import {
  getActiveUser,
  getMainLoggingParams,
  getShowSignUp,
  getSignupForm,
} from '../selectors/SystemSelector';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.responseGoogleSuccess = this.responseGoogleSuccess.bind(this);
    this.closeSignup = this.closeSignup.bind(this);
    this.showSignup = this.showSignup.bind(this);
    this.handlOnChangeSignupForm = this.handlOnChangeSignupForm.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  responseGoogleSuccess(response, type) {
    this.props.getUserAuthorization(response, type);
  }

  closeSignup() {
    this.props.showSignUp(false);
  }

  showSignup() {
    this.props.showSignUp(true);
  }

  handlOnChangeSignupForm(values) {
    this.props.setSignupForm(values);
  }

  handleOnSubmit(form) {
    if (form.check()) {
      this.props.signupCompany(this.props.signupForm);
      this.closeSignup();
      this.handlOnChangeSignupForm(null);
    }
  }

  render() {
    this.props.setShowSpinner(false);
    return (
      <div>
        {this.props.activeUser.authorized && (
          <Redirect to="/list-order" push={false} />
        )}
        <Login
          success={this.responseGoogleSuccess}
          signUpClicked={this.showSignup}
        />
        {this.props.showSignUp && (
          <div>
            <Signup
              visible={this.props.signUpVisible}
              formValues={this.props.signupForm}
              onClose={this.closeSignup}
              onChangedForm={this.handlOnChangeSignupForm}
              onSubmit={this.handleOnSubmit}
            ></Signup>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    activeUser: getActiveUser(state),
    companyId: getMainLoggingParams(state).companyId,
    signUpVisible: getShowSignUp(state),
    signupForm: getSignupForm(state),
  };
};
export default connect(mapStateToProps, actions)(LoginContainer);
