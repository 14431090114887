import React from "react";
import { Button, ButtonToolbar } from "rsuite";
import "../styles/General.css";

export const OrderButtons = ({
  saveOrder,
  saveAndCreate,
  cancelOrder,
  buttonDisabled,
  orderCaptureAction,
}) => (
  <ButtonToolbar>
    <Button appearance="primary" onClick={saveOrder}>
      Guardar
    </Button>
    {orderCaptureAction !== "EDIT" && (
      <Button appearance="primary" onClick={saveAndCreate}>
        Guardar y crear otra
      </Button>
    )}
    <Button appearance="ghost" onClick={cancelOrder}>
      Cancelar
    </Button>
  </ButtonToolbar>
);
