import PageHeader from '@components/PageHeader';
import * as MasterDataSelector from '../../selectors/MasterDataSelector';
import { connect, useSelector } from 'react-redux';
import * as actions from '../../actions';
import { storageService } from '../../firebase/firebaseConfig';
import { dataEntities } from '../../utils/Constants'
import { FaFileExcel } from 'react-icons/fa';


import {
  getMainLoggingParams,
  selectCompany,
} from '@selectors/SystemSelector';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  getCustomerFormAction,
  getContactFormModel,
  getcontactFormValues,
  getEditedCustomer,
} from '../../selectors/CustomerSelector';
import { Button, Container, InputPicker, Message, Panel, PanelGroup, Radio, RadioGroup, Row, toaster } from 'rsuite';
import { FileUploader } from "../FileUploader/FileUploaderBulk";
import { rollbarErrors } from '@utils/rollbarErrors';
import { useLoadDataMutation, useUpdateDataMutation } from '@api/query/customerApi/customerApi';


type CreateOrderProps = {};

const CreateOrder: React.FC<CreateOrderProps> = () => {
  const { permissions, company, companyId, user } = useSelector(getMainLoggingParams);
  const [valueAction, setValueAction] = React.useState('create');
  const [file, setFile] = React.useState(null);
  const [body, setBody] = React.useState({});

  const fileInput = React.createRef();

  const [updateLoad, updateLoadResponse] = useUpdateDataMutation({});
  const [createLoad, createLoadResponse] = useLoadDataMutation({});

  useEffect(() => {
    if (updateLoadResponse?.isError) {
      rollbarErrors(updateLoadResponse?.error, { fileName: body }, 'PATCH/customers/_bulk', companyId, user)
    }
  }, [updateLoadResponse?.isError]);

  useEffect(() => {
    if (createLoadResponse?.isError) {
      rollbarErrors(createLoadResponse?.error, { fileName: body }, 'POST/customers/_bulk', companyId, user)
    }
  }, [createLoadResponse?.isError]);


  useEffect(() => {
    if (updateLoadResponse?.data || createLoadResponse?.data) {
      setFile(null)
      window.location.reload()
    }
  }, [updateLoadResponse, createLoadResponse]);


  const createDataEntities = (permissions, dataEntities) => {
    const array = []
    dataEntities.map(
      item => {
        let flag = false
        if (item?.label === 'Clientes' && permissions?.LOAD_DATA_CUSTOMERS !== false) {
          flag = true
        }
        if (item?.label === '/Ordenes' && permissions?.LOAD_DATA_ORDERS !== false) {
          flag = true
        }
        if (item?.label === 'Productos' && permissions?.LOAD_DATA_PRODUCTS !== false) {
          flag = true
        }
        if (item?.label === '/Franjas horarias' && permissions?.LOAD_DATADELIVERY_SLOT !== false) {
          flag = true
        }
        if (item?.label === 'Inventario') {
          flag = true
        }
        if (flag) {
          array.push(item)
        }
      }
    );
    return array
  };

  const data = createDataEntities(permissions, dataEntities)
  const [entity, setEntity] = React.useState(data[0]?.value);
  const fileHandler = (value) => {
    if (value.length) {
      setFile(value)
    }
  };

  const handleOpenFileBrowser = () => {
    console.log('submit')
  };

  const handleActionChanged = value => {
    setValueAction(value)
  };

  const download = () => {
    const starsRef = storageService.ref(`bulks/templates/${entity}_${valueAction}.xlsx`);
    starsRef.getDownloadURL()
    .then((url) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.click();
      })
      .catch((error) => {
        console.log('error', error)
      });
  };

  const handleSubmit = async () => {
    const { blobFile } = file[0]
    const fileName = `${Date.now()}_${blobFile.name}`
    setBody(fileName)
    if (entity && valueAction) {
      await storageService.ref(`bulks/${company}/${entity}/${valueAction}/${fileName}`).put(blobFile, { contentType: blobFile.type });
      setFile(null)
      if (valueAction === 'update') {
        updateLoad({ fileName, entity })
      } else {
        createLoad({ fileName, entity })
      }

      toaster.push(
        <Message
          type="success"
          showIcon
          closable
          duration={5000}
        >
          {'procesando archivo'}
        </Message>
      );
    } else {
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={5000}
        >
          {'debe seleccionar una acción'}
        </Message>
      );
    }
  };
  return (
    <div className="h-screen flex flex-col pb-1">
      <PageHeader title={<FormattedMessage id="data-load" />} />
      <div className="mx-8" >
        <div>
          <label>Entidad: </label>
          <InputPicker value={entity || data[0]?.value} onChange={setEntity} data={data} />
        </div>
        <div className='flex'>
          <RadioGroup
            className='mr-6'
            value={valueAction}
            onChange={value => {
              handleActionChanged(value);
            }}
            defaultValue="create"
            inline
            name="radio-name" >
            <label className='pt-2 pl-2'>Acción: </label>
            <Radio defaultChecked={true} value="create" >Creación</Radio>
            <Radio value="update" >Actualización</Radio>
          </RadioGroup>
          <Button
              as="a"
              appearance="link"
              size="sm"
              target="_blank"
              className="mt-1"
              onClick={download}
          >
              <FaFileExcel className="rs-icon text-green-500 mb-1" />
              Descargar plantilla
          </Button>
        </div>
        <div>
          <Container className="border w-full">
            <Row className="pt-4 pl-4">
              <FileUploader
                fileHandler={fileHandler}
                handleOpenFileBrowser={handleOpenFileBrowser}
                uploadedFileName={file ? file[0]?.blobFile?.name : ''}
                fileInput={fileInput}
              />
            </Row>
            <Row className="pb-2 pl-4">
              <hr />
              <Button
                appearance="primary"
                disabled={!file ? true : false}
                className="mr-2"
                onClick={handleSubmit}
              >
                Procesar datos
              </Button>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = state => {
  return {
    states: MasterDataSelector.getFoundStates(state),
    cities: MasterDataSelector.getFoundCities(state),
    citiesDisabled: MasterDataSelector.getCitiesDisabled(state),
    formValuesContact: getcontactFormValues(state),
    formModelContact: getContactFormModel(state),
    formAction: getCustomerFormAction(state),
    loggingParams: getMainLoggingParams(state),
    editedCustomer: getEditedCustomer(state),
    company: selectCompany(state),
  };
};

export default connect(mapStateToProps, actions)(CreateOrder);
