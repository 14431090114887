import React, { Component } from "react";
// import { useIntl } from 'react-intl';
import { Button, ButtonToolbar, Checkbox, Form, Input, InputPicker, Modal } from "rsuite";
// import * as constants from "../../../utils/Constants";

export class TextField extends React.PureComponent {
  render() {
    const {
      name,
      label,
      accepter,
      tooltip,
      tooltipText,
      ...props
    } = this.props;
    return (
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Form.Control name={name} accepter={accepter} {...props} />
        {tooltip && <Form.HelpText tooltip>{tooltipText}</Form.HelpText>}
      </Form.Group>
    );
  }
}

export class TextAreaField extends React.PureComponent {
  render() {
    const {
      name,
      label,
      accepter,
      tooltip,
      tooltipText,
      rows,
      ...props
    } = this.props;
    return (
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Form.Control
          name="textarea"
          rows={rows}
          name={name}
          accepter={Textarea}
          {...props}
        />
        {tooltip && <Form.HelpText tooltip>{tooltipText}</Form.HelpText>}
      </Form.Group>
    );
  }
}

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

export class DropDownField extends React.PureComponent {
  render() {
    const {
      name,
      label,
      data,
      plaintext,
      value,
      accepter,
      tooltip,
      tooltipText,
      onSearch,
      onChange,
      disabled,
      ...props
    } = this.props;
    return (
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Form.Control
          name={name}
          onSearch={onSearch}
          onChange={onChange}
          accepter={InputPicker}
          cleanable={false}
          data={data}
          disabled={disabled}
          value={value}
          style={{ width: 350 }}
          {...props}
        />
        {tooltip && <Form.HelpText tooltip>{tooltipText}</Form.HelpText>}
      </Form.Group>
    );
  }
}


export class ModalCreateOrEditCustomer extends Component {
  render() {
    const {
      visible,
      onStatesSearch,
      onStatesChange,
      states,
      props,
      citiesDisabled,
      cities,
      onCitiesChange,
      formValues,
      model,
      onChange,
      onErrorValidation,
      onSubmit,
      onCloseModal,
      actionModal,
      defaultAdress,
    } = this.props;
    
    const handleCheckAll = (value, checked) => {
      defaultAdress(checked)
    }

    const submit = () => {
      onSubmit(this.form, false, true)
      if (this.form.check()) {
        onCloseModal()
      }
    }
  
    return (
      <div>
        <Modal
          className="modal-dialog-container"
          open={visible}
          size="xs"
          keyboard
          onClose={onCloseModal}
        >
          <Modal.Header>
            <Modal.Title style={{ marginBottom: '10px' }}> {actionModal === 'create' ? 'Crear Contacto' : 'Editar Contacto'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form
                fluid
                ref={(ref) => (this.form = ref)}
                onChange={(formValue) => {
                  onChange(formValue);
                }}
                onCheck={(formError) => {
                  onErrorValidation(formError);
                }}
                formValue={formValues}
                model={model}
              >
                <div>
                  <div className="pb-2.5">
                    <TextField name="name" label="Nombres" />
                  </div>
                  <div className="pb-2.5">
                    <TextField name="lastName" label="Apellidos" />
                  </div>
                  <div className="pb-2.5">
                    <TextField name="primaryEmail" label="e-mail" />
                  </div>
                  <div className="pb-2.5">
                    <TextField name="mobile" label="Celular" />
                  </div>
                  <div className="pb-2.5">
                    <DropDownField
                      name="stateId"
                      label="Departamento"
                      onSearch={onStatesSearch}
                      onChange={onStatesChange}
                      disabled={false}
                      data={states}
                      {...props}
                    />
                  </div>
                  <div className="pb-2.5">
                    <DropDownField
                      name="cityId"
                      label="Ciudad"
                      data={cities}
                      disabled={citiesDisabled}
                      onChange={onCitiesChange}
                      {...props}
                    />
                  </div>
                  <div className="pb-2.5">
                    <TextAreaField rows={2} name="address" label="Dirección" />
                  </div>
                  <div>
                    <Checkbox onChange={handleCheckAll}> Dirección por defecto</Checkbox>
                  </div>
                </div>
              </Form>
            </div>
            <div style={{ paddingTop: '20px' }}>
              <ButtonToolbar>
                <Button
                  appearance="primary"
                  onClick={submit}
                >
                  Guardar
                </Button>
                <Button onClick={onCloseModal}>Cancelar</Button>
              </ButtonToolbar>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}