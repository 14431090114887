import { useUpdateStatusOrdersMutation } from '@api/query/orderApi/orderApi';
import { useInvoiceConsolidatedMutation } from '@api/query/orderEventApi/orderEventApi';
import React from "react";
import { useIntl } from 'react-intl';
import { Button, ButtonToolbar, Input, Message, Modal, toaster } from "rsuite";
import * as constants from "../../../utils/Constants";




const ModalConsolidatedBilling = ({
  visible,
  statusList,
  status,
  onClose,
  ordersSelected
}) => {
  const [valueStatus, setValueStatus] = React.useState(null);
  const [dataStatus, setDataStatus] = React.useState(null);
  const [comment, setComment] = React.useState('');
  const [triggerInvoiceOrders, invoiceConsolidatedState] = useInvoiceConsolidatedMutation({});
  const [triggerUpdateStatusOrders, UpdateStatusOrdersResponse] = useUpdateStatusOrdersMutation({});

  const changeComments = value => {
    setComment(value)
  }
  const onCloseModal = () => {
    setValueStatus(null)
    setComment('')
    onClose()
  }

  const onChageStatus = value => {
    const result = status.find(
      status => status.value === value,
    )
    setValueStatus(value)
    setDataStatus(result)
  }

  const orders = [];
  const purchaseOrderId = [];
  const notes = [];

  ordersSelected?.forEach((key) => {
    const object = {
      orderId: key?.id,
    }
    orders.push(object);
    notes.push(key?.notes)
  });

  let ordersNotes = notes.join('|');

  const getDocumentCreationStatus = type => {
    const status = statusList.find(status => status.role == type);
    return status.id;
  };

  const trigerInvoicing = async () => {
    try {
      const data = {
        orders: orders,
        statusId: getDocumentCreationStatus('INVOICE'),
      }

      if (comment) {
        data.invoiceNotes = comment
      }

      triggerInvoiceOrders(data);
    } catch (error) {
      console.log(error);
    }

    if (dataStatus) {
      try {
        triggerUpdateStatusOrders({
          status: {
            id: dataStatus.value,
            role: dataStatus.role,
            description: dataStatus.label,
            listingOrder: dataStatus.listingOrder,
          },
          orders: orders,
        });
      } catch (error) {
        console.log(error);
      }
    }
    toaster.push(
      <Message
        type="info"
        showIcon
        closable
        duration={constants.NOTIFICATION_SUCESS_QUICK_DURATION}
      >
        {$t({ id: 'TheBillingRequestHasBeenSentSuccessfully' })}
      </Message>,
    );
    setValueStatus(null)
    setDataStatus(null)
    setComment('')
    onClose()
  }

  const { $t } = useIntl();

  return (
    <div>
      <Modal
        className="modal-dialog-container"
        open={visible}
        size="xs"
        keyboard
        onClose={onCloseModal}
      >
        <Modal.Header>
          <Modal.Title style={{ marginBottom: '15px' }}>{$t({ id: 'route.consolidated-billing' })}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="pb-2 pt-0">
            <InputPicker
              data={status}
              cleanable={false}
              onChange={onChageStatus}
              value={valueStatus || []}
              block
              placeholder={$t({ id: 'action.change-state' })}
            />
          </div> */}
          <div>
            <Input
              as="textarea"
              placeholder="Notas visibles en la factura"
              onChange={changeComments}
              value={comment}
              rows="2"
            />
          </div>
          <div>
            <ButtonToolbar className="pt-4">
              <Button appearance="primary" onClick={trigerInvoicing} >
                {$t({ id: 'action.invoice' })}
              </Button>
              <Button appearance="ghost" onClick={onCloseModal}>
                {$t({ id: 'cancel' })}
              </Button>
            </ButtonToolbar>
          </div>
          <div style={{ marginTop: '8px', fontSize: 'smaller' }}>{ordersSelected?.length} {' '}orden(es) selecionada(s)</div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalConsolidatedBilling;
