import React from 'react'
import { FormattedMessage } from "react-intl";

export function BillingInformation({ customer }) {
  return <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table className="w-full text-sm text-left text-gray-500">
      <tbody>
        <tr className="border-b border-gray-200 ">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
          <FormattedMessage id="address" />
          </th>
          <td className="px-6 py-2">
            {customer.address}
          </td>

        </tr>
        <tr className="border-b border-gray-200 ">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
          <FormattedMessage id="department" />
          </th>
          <td className="px-6 py-2">
            {customer.state.name}
          </td>
        </tr>
        <tr className="border-b border-gray-200 ">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
          <FormattedMessage id="city" />
          </th>
          <td className="px-6 py-2">
            {customer.city.name}
          </td>
        </tr>
        <tr className="border-b border-gray-200 ">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
          <FormattedMessage id="payment-deadline" />
          </th>
          <td className="px-6 py-2">
            {customer.paymentTerms}
          </td>
        </tr>
        <tr className="border-b border-gray-200 ">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
          <FormattedMessage id="method-payment" />
          </th>
          <td className="px-6 py-2">
            {customer.paymentMethod?.description}
          </td>
        </tr>
        <tr className="border-b border-gray-200 ">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
          <FormattedMessage id="tax-liability" />
          </th>
          <td className="px-6 py-2">
            {customer.fiscalResponsibility}
          </td>
        </tr>
        <tr className="border-b border-gray-200 ">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
          <FormattedMessage id="tax-regime" />
          </th>
          <td className="px-6 py-2">
            {customer.taxRegime}
          </td>
        </tr>
        <tr>
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
          <FormattedMessage id="cost-center" />
          </th>
          <td className="px-6 py-2">
            {customer.costCenter?.name}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
}