import React from 'react';
import { Modal } from 'rsuite';
import LegacyRemindIcon from "@rsuite/icons/legacy/Remind";
import {getOrderSameSlotValidationMessage} from '../../utils/Validations/OrderValidations';
import '../../styles/General.css';

const ConfirmOrderAction = ({slotCapacity,confirmOrderActionVisible,onHideConfirmOrderAction,onClickConfirmAction}) => 

    <div>
        <Modal 
            className="modal-dialog-container"
            show={confirmOrderActionVisible}
            size="xs"
            keyboard="true"
            onHide={onHideConfirmOrderAction}
        >
            <Modal.Header>
                <Modal.Title className="modal-title-center">Alerta de franja horaria</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <LegacyRemindIcon style={{color: '#FFB300', fontSize:24}} />
                {'   '}
                {getOrderSameSlotValidationMessage(slotCapacity)}

                <div className="pt-6">
                    <br/>
                    <button className="btn btn-primary mx-1" onClick={onClickConfirmAction}> Confirmar </button>
                    <button type="button" onClick={onHideConfirmOrderAction} className="btn btn-outline-primary"> Cancelar </button>
                </div>
                
            </Modal.Body>

        </Modal>
    </div>

export default ConfirmOrderAction;