import firebase from 'firebase/app';
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyCbZKTxpcZn0ozImjGoTibSq_vjDziYJVs",
  authDomain: "savia-app-pdn.firebaseapp.com",
  projectId: "savia-app-pdn",
  storageBucket: "savia-app-pdn.appspot.com",
  messagingSenderId: "416305213591",
  appId: "1:416305213591:web:7e7a373e8a7583e068b9d2"
};

// export const firebaseConfig = {
//   apiKey: "AIzaSyC0a6UzYmHG9X-IGjAKeBl1RMx6Pt429dU",
//   authDomain: "ordoxqa.firebaseapp.com",
//   projectId: "ordoxqa",
//   storageBucket: "ordoxqa.appspot.com",
//   messagingSenderId: "494331394253",
//   appId: "1:494331394253:web:f91b04839bc8ecb12fd184",
//   measurementId: "G-ZTZ46YEC0T"
// };

  
  firebase.initializeApp(firebaseConfig);
  export const firebaseAnalytics = firebase.analytics();
  export const auth = firebase.auth();
  export const storageService = firebase.storage();

  export const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });

  
export default firebase
  


  