import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as actions from "../actions";
import { getActiveUser } from "../selectors/SystemSelector";

class LogoutContainer extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.setShowSpinner(false);
    this.props.logOutUser();
  }

  render() {
    this.props.setNewVersionAvailable(false);
    this.props.setWaitingWorker({});
    if (!this.props.activeUser.authorized) {
      return <Redirect to="/" push={true} />;
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    activeUser: getActiveUser(state),
  };
};

export default connect(mapStateToProps, actions)(LogoutContainer);
