import { createApi } from '@reduxjs/toolkit/query/react';
import { ordoxBaseQuery } from "../baseQuery";
import { Customer, FindCustomerRequest, FindCustomerResponse, UpdateCustomerRequest, synchronizationsRequest, bulkResponse, bulkRequest, LocationResponse, Message, downloadRequest } from "./types";
import queryString from "query-string";


const customerApi = createApi({
    reducerPath: 'customer-api',
    tagTypes: ['customers'],
    baseQuery: ordoxBaseQuery(),
    endpoints: ({ query, mutation }) => ({
      findCustomer: query<FindCustomerResponse, FindCustomerRequest>({
        providesTags: ['customers'],
        query: filters => {
          const params = queryString.stringify(filters, {
            skipNull: true,
            skipEmptyString: true,
          });
  
          return {
            url: `/customers${(params && `?${params}`) || ''}`,
            method: 'GET',
          };
        },
        transformResponse: (response: FindCustomerResponse, _, request) => {
          return {
            ...response,
            offset: request.offset,
            limit: request.limit,
          };
        },
      }),
      findCustomerById: query<Customer, String>({
        providesTags: ['customers'],
        query: (id) => ({
          url: `/customers/${id}`,
          method: 'GET'
        }),
      }),
      findLocations: query<LocationResponse, {}>({
        providesTags: ['customers'],
        query: () => ({
          url: `/locations/`,
          method: 'GET'
        }),
      }),
      updateCustomer: mutation<Customer, UpdateCustomerRequest>({
        invalidatesTags: ['customers'],
        query: ({ id, data }) => ({
          url: `/customers/${id}`,
          method: 'PATCH',
          body: data,
        }),
      }),
      updateData: mutation<bulkResponse, bulkRequest>({
        invalidatesTags: ['customers'],
        query: data => ({
          url: `/${data?.entity}/upload/_bulk`,
          method: 'PATCH',
          body: { fileName: data?.fileName },
        }),
      }),
      loadData: mutation<bulkResponse, bulkRequest>({
        invalidatesTags: ['customers'],
        query: data => ({
          url: `/${data?.entity}/upload/_bulk`,
          method: 'POST',
          body: { fileName: data?.fileName },
        }),
      }),
      synchronizationsCustomer: mutation<Message, synchronizationsRequest>({
        invalidatesTags: ['customers'],
        query: ({ ids }) => ({
          url: `/customers/synchronizations`,
          method: 'PATCH',
          body: { ids },
        }),
      }),
      downloadData: mutation<Customer, downloadRequest>({
        invalidatesTags: ['customers'],
        query: data => ({
          url: '/customers/downloads',
          method: 'POST',
          body: data,
        }),
      }),
    }),
  });
  
  export const {
    useFindCustomerQuery,
    useLazyFindCustomerQuery,
    useLazyFindCustomerByIdQuery,
    useFindLocationsQuery,
    useUpdateCustomerMutation,
    useDownloadDataMutation,
    useSynchronizationsCustomerMutation,
    useUpdateDataMutation,
    useLoadDataMutation
  } = customerApi;
  
export default customerApi;
  