import { createApi } from '@reduxjs/toolkit/query/react';
import { ordoxBaseQuery } from '../baseQuery';
import { CreateOrderRequest, FindCreditNoteRequest, FindCreditNoteResponse, FindOrderIdRequest, FindOrdersRequest, FindOrdersResponse, Order, OrderResponse, UpdateOrderRequest, UpdateStatusBulk } from './types';
import queryString from 'query-string';
import { downloadRequest } from '../customerApi/types';

export const orderApi = createApi({
  reducerPath: 'order-api',
  tagTypes: ['order'],
  baseQuery: ordoxBaseQuery(),
  endpoints: builder => ({
    findCreditNoteCauses: builder.query<FindCreditNoteResponse, FindCreditNoteRequest>(
      {
        query: (filters) => ({
          url: `creditNoteCauses`,
          method: 'GET'
        }),
      },
    ),
    findById: builder.query<OrderResponse, FindOrderIdRequest>({
      providesTags: ['order'],
      query: ({ data = {}, id = String }) => {
        const params = queryString.stringify(data)

        return {
          url: `orders/${id}${params ? `?${params}` : ''}`,
          method: 'GET'
        }
      },
    }),
    findOrders: builder.query({
      providesTags: ['order'],
      query: (data: FindOrdersRequest) => {
        return {
          params: data,
          url: `orders`,
          method: 'GET',
        };
      },
      transformResponse: (data: FindOrdersResponse, _, request) => {
        // const lastOrders = [];
        // const orders = [];
        // data.items.forEach(item => {
        //   if (
        //     item.status.role === 'CANCEL' ||
        //     item.status.role === 'DELIVERY'
        //   ) {
        //     lastOrders.push(item);
        //   } else {
        //     orders.push(item);
        //   }
        // });

        return {
          limit: request.limit,
          offset: request.offset,
          total: data.total,
          items: data.items,
        };
      },
    }),
    createOrder: builder.mutation<Order, CreateOrderRequest>({
      invalidatesTags: ['order'],
      query: data => ({
        url: 'orders',
        method: 'POST',
        body: data,
      }),
    }),
    updateOrder: builder.mutation<Order, UpdateOrderRequest>({
      invalidatesTags: ['order'],
      query: ({ id, data }) => ({
        url: `/orders/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    updateStatusOrders: builder.mutation<Order[], UpdateStatusBulk>({
      invalidatesTags: ['order'],
      query: data => ({
        url: '/orders/status/_bulk',
        method: 'PATCH',
        body: data,
      }),
    }),
    downloadData: builder.mutation<Order, downloadRequest>({
      invalidatesTags: ['order'],
      query: data => ({
        url: '/orders/downloads',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useFindOrdersQuery,
  useLazyFindOrdersQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useUpdateStatusOrdersMutation,
  useFindByIdQuery,
  useFindCreditNoteCausesQuery,
  useDownloadDataMutation,
} = orderApi;

export default orderApi;
