/* eslint-disable react-hooks/exhaustive-deps */
import { useIMask } from 'react-imask';

import React, { useEffect } from 'react';
import { Input, InputProps } from 'rsuite';

type InputCurrencyProps = InputProps & {
  onValueChange?(value: number, ev: React.FormEvent<HTMLInputElement>): void;
  value?: number;
  signed?: boolean;
  scale?: number;
};

const InputPercent: React.FC<InputCurrencyProps> = ({
  value,
  defaultValue,
  signed = false,
  max = 9999,
  min = 0,
  scale = 2,
  onValueChange,
  ...inputProps
}) => {
  const { ref, ...mask } = useIMask(
    {
      mask: 'number%',
      blocks: {
        number: {
          mask: Number,
          scale: 2,
          signed,
          thousandsSeparator: ' ',
          radix: ',',
          mapToRadix: ['.'],
          min,
          max,
          normalizeZeros: false,
          autofix: true,
          eager: true,
          padFractionalZeros: false,
        },
      },
    },
    {
      onComplete(_, { unmaskedValue }, ev) {
        onValueChange?.(
          Number(unmaskedValue),
          ev as unknown as React.FormEvent<HTMLInputElement>,
        );
      },
    },
  );

  useEffect(() => {
    if (`${value}` !== mask.unmaskedValue) mask.setUnmaskedValue(`${value}%`);
  }, [value]);

  useEffect(() => {
    if (defaultValue) mask.setUnmaskedValue(`${defaultValue}%`);

    ref.current.select(0, 100);
  }, []);

  return (
    <Input
      className='mt-2'
      {...inputProps}
      inputRef={ref}
      onFocus={ev => {
        ev.target.select();
        inputProps.onFocus?.(ev);
      }}
      onBlur={ev => {
        mask.setValue(`${ev.target.value}%`);
        inputProps.onBlur?.(ev);
      }}
    />
  );
};

export default InputPercent;
