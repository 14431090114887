import { Schema } from "rsuite";

export const customerFormValidation = (idType, company) => {
  const { StringType, NumberType } = Schema.Types;
  const fullDataValidation = company.fullDataValidation;
  let schema;

  if (idType != "NIT" && fullDataValidation) {
    schema = Schema.Model({
      idType: StringType().isRequired("Requerido"),
      identification: NumberType("Ingresa un valor válido. Solo números")
        .isRequired("Requerido")
        .range(0, 99999999999, "Valor inválido"),
      name: StringType()
        .isRequired("Requerido")
        .maxLength(100, "La longitud debe ser inferior a 100"),
      lastName: StringType()
        .isRequired("Requerido")
        .maxLength(100, "La longitud debe ser inferior a 100"),
      brandName: StringType()
        .maxLength(150, "La longitud debe ser inferior a 150"),
      mobile: NumberType("Ingresa un valor válido. Debe tener 10 dígitos")
        .isRequired("Requerido")
        .addRule((value) => {
          return value && value.toString().length === 10;
        }, "Debe tener exactamente 10 dígitos"),
      primaryEmail: StringType()
        .isRequired("Requerido")
        .isEmail("Ingresa un email válido"),
      stateId: StringType("Selecciona un departamento").isRequired("Requerido"),
      cityId: StringType("Selecciona una ciudad").isRequired("Requerido"),
      address: StringType()
        .isRequired("Requerido")
        .maxLength(150, "La longitud debe ser inferior a 150"),
      paymentMethodId: StringType().isRequired("Requerido"),
      paymentTerms: NumberType().isRequired("Requerido"),
      listPriceId: StringType().isRequired("Requerido"),
      fiscalResponsibility: StringType().isRequired("Requerido"),
      taxRegime: StringType().isRequired("Requerido"),
      costCenter: StringType(),
      fulfillmentTypeId: StringType(),
      defaultLocationId: StringType(),
      channelId: StringType().isRequired("Requerido"),
      invoicingSettingId: StringType().isRequired("Requerido"),
      actionOnShipment: StringType().isRequired("Requerido"),
    });
  } else if (idType == "NIT" && fullDataValidation) {
    schema = Schema.Model({
      idType: StringType().isRequired("Requerido"),
      identification: NumberType()
        .isRequired("Requerido")
        .range(0, 999999999, "Valor inválido"),
      name: StringType()
        .isRequired("Requerido")
        .maxLength(100, "La longitud debe ser inferior a 100"),
      lastName: StringType().maxLength(
        100,
        "La longitud debe ser inferior a 100"
      ),
      mobile: NumberType("Ingresa un valor válido. Debe tener 10 dígitos")
        .isRequired("Requerido")
        .addRule((value) => {
          return value && value.toString().length === 10;
        }, "Debe tener exactamente 10 dígitos"),
      primaryEmail: StringType()
        .isRequired("Requerido")
        .isEmail("Ingresa un email válido"),
      stateId: StringType().isRequired("Requerido"),
      cityId: StringType().isRequired("Requerido"),
      address: StringType()
        .isRequired("Requerido")
        .maxLength(150, "La longitud debe ser inferior a 150"),
      paymentMethodId: StringType().isRequired("Requerido"),
      paymentTerms: NumberType().isRequired("Requerido"),
      listPriceId: StringType().isRequired("Requerido"),
      fiscalResponsibility: StringType().isRequired("Requerido"),
      taxRegime: StringType().isRequired("Requerido"),
      costCenter: StringType(),
      fulfillmentTypeId: StringType(),
      defaultLocationId: StringType(),
      channelId: StringType().isRequired("Requerido"),
      invoicingSettingId: StringType().isRequired("Requerido"),
      actionOnShipment: StringType().isRequired("Requerido"),
    });
  } else if (idType != "NIT" && !fullDataValidation) {
    schema = Schema.Model({
      idType: StringType().maxLength(50, "La longitud debe ser inferior a 50"),
      identification: NumberType()
        .range(0, 99999999999, "Valor inválido")
        .isRequired("Requerido"),
      name: StringType()
        .isRequired("Requerido")
        .maxLength(100, "La longitud debe ser inferior a 100"),
      lastName: StringType()
        .isRequired("Requerido")
        .maxLength(100, "La longitud debe ser inferior a 100"),
      stateId: StringType().isRequired("Requerido"),
      cityId: StringType().isRequired("Requerido"),
      address: StringType()
        .isRequired("Requerido")
        .maxLength(150, "La longitud debe ser inferior a 150"),
      mobile: NumberType("Ingresa un valor válido. Debe tener 10 dígitos")
        .isRequired("Requerido")
        .addRule((value) => {
          return value && value.toString().length === 10;
        }, "Debe tener exactamente 10 dígitos"),
      primaryEmail: StringType().isEmail("Ingresa un email válido"),
    });
  } else if (idType == "NIT" && !fullDataValidation) {
    schema = Schema.Model({
      idType: StringType().maxLength(50, "La longitud debe ser inferior a 50"),
      identification: NumberType()
        .range(0, 99999999999, "Valor inválido")
        .isRequired("Requerido"),
      name: StringType()
        .isRequired("Requerido")
        .maxLength(100, "La longitud debe ser inferior a 100"),
      address: StringType()
        .isRequired("Requerido")
        .maxLength(150, "La longitud debe ser inferior a 150"),
      stateId: StringType().isRequired("Requerido"),
      cityId: StringType().isRequired("Requerido"),
      mobile: NumberType("Ingresa un valor válido. Debe tener 10 dígitos")
        .isRequired("Requerido")
        .addRule((value) => {
          return value && value.toString().length === 10;
        }, "Debe tener exactamente 10 dígitos"),
      primaryEmail: StringType().isEmail("Ingresa un email válido"),
    });
  }
  return schema;
};
export const contactFormValidation = () => {
  const { StringType, NumberType } = Schema.Types;
  let schema;
  schema = Schema.Model({
    name: StringType()
      .isRequired("Requerido")
      .maxLength(100, "La longitud debe ser inferior a 100"),
    lastName: StringType()
      .maxLength(100, "La longitud debe ser inferior a 100"),
    mobile: NumberType("Ingresa un valor válido. Debe tener 10 dígitos")
      .isRequired("Requerido")
      .addRule((value) => {
        return value && value.toString().length === 10;
      }, "Debe tener exactamente 10 dígitos"),
    primaryEmail: StringType()
      .isEmail("Ingresa un email válido"),
    cityId: StringType("Selecciona una ciudad").isRequired("Requerido"),
    stateId: StringType("Selecciona una ciudad").isRequired("Requerido"),
    address: StringType()
      .isRequired("Requerido")
      .maxLength(150, "La longitud debe ser inferior a 150"),
  });
  return schema;
};

export const customerFormInitialValues = (
  paymentMethodId,
  listPriceId,
  channelId,
  invoicingSettingId,
  stateId
) => {
  let initialValues = {
    idType: "CC",
    identification: "",
    name: "",
    lastName: "",
    mobile: "",
    phone: "",
    primaryEmail: "",
    stateId: stateId,
    cityId: "",
    address: "",
    paymentMethodId: paymentMethodId,
    paymentTerms: 0,
    listPriceId: listPriceId,
    fiscalResponsibility: "114",
    taxRegime: "SIMPLIFIED_REGIME",
    costCenter: undefined,
    fulfillmentTypeId: '',
    defaultLocationId: undefined,
    channelId: channelId,
    invoicingSettingId: invoicingSettingId,
    actionOnShipment: "INVOICE",
  };
  return initialValues;
};

export const contactFormInitialValues = (
  stateId
) => {
  let initialValues = {
    name: "",
    lastName: "",
    mobile: "",
    phone: "",
    primaryEmail: "",
    stateId: stateId,
    cityId: "",
    address: "",
  };
  return initialValues;
};
