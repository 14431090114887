import getLocale from "@src/locale/getLocale";
import rsuiteEN from "rsuite/locales/en_US";
import rsuiteES from "rsuite/locales/es_ES";
import en from "./en.json";
import es from "./es.json";

export const reactIntl = { es, en }[getLocale()];
export const rsuiteIntl = { es: rsuiteES, en: rsuiteEN }[getLocale()];

rsuiteIntl.Calendar.ok = "Ok";
