import { useLazyFindCustomerQuery, useUpdateCustomerMutation } from '@api/query/customerApi/customerApi';
import { Customer, FindCustomerRequest } from '@api/query/customerApi/types';
import { PriceListItem, useFindPriceListQuery } from '@api/query/priceListApi';
import { cellsById, DataGrid, DataGridRowContent } from '@components/DataGrid';
import DrawerModal from '@components/ui/DrawerModal';
import { FloatingActionContainer } from '@components/ui/FloatingActionButton';
import HighlightMatchText from '@components/ui/HighlightMatchText';
import useLocationState from '@router/useLocationState';
import useFuzzySearch, {
  getMatchIndices,
  UseFuzzyResult,
} from '@utils/useFuzzySearch';
import useViewport from '@utils/useViewport';
import { ZERO_WIDTH_SPACE } from '@utils/zeroWidthSpace';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiPlus, BiX } from 'react-icons/bi';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { CellProps, Column, Row } from 'react-table';
import {
  Animation,
  Button,
  Checkbox,
  IconButton,
  Input,
  InputGroup,
} from 'rsuite';
import ListItem from 'rsuite/esm/List/ListItem';
import { useLazyPagination } from './UseLazyPagination';

export const FABAddPriceListCustomer = () => {
  const { $t } = useIntl();
  const { id: priceListId } = useLocationState<PriceListItem>();
  const viewport = useViewport();
  const [selectedRows, setSelectedRows] = useState<
    Row<Customer>[]
  >([]);

  const [trigger_, result] = useLazyFindCustomerQuery();
  const [valueSearch, setValueSearch] = useState('');
  const trigger = useCallback(
    (value: FindCustomerRequest) => {
      return trigger_({
        ...value,
      });
    },
    [trigger_],
  );

  const { setExtraQuery } =
    useLazyPagination({
      trigger,
      result,
      initialLimit: 100,
    });
  
  useEffect(() => {
    trigger_({ active: true });
  }, []);

  const [mutateCustomer, resultCustomerMuation] = useUpdateCustomerMutation();

  const addCustomers = useCallback(
    async (rows: Row<Customer>[]) => {
      const it = await Promise.allSettled(
        rows.map(row => {
          const customer = row.original;
          return mutateCustomer({
            id: customer.id,
            data: {
              source: 'savia',
              listPriceId: priceListId,
            }
          }).unwrap();
        }),
      );
      setOpen(false);
    },
    [],
  );

  const search = (searchValue) => {
    setValueSearch(searchValue)
    setExtraQuery(value => ({
      ...value,
      orderBy: 'createdAt:ASC',
      keyword: searchValue.trim() ? searchValue : undefined,
    }));
  };

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      trigger_({ orderBy: 'createdAt:ASC'});
    }
  }, [open]);

  const onOpen = () => {
    setOpen(true);
    trigger_({ orderBy: 'createdAt:ASC'});
  };

  const onClose = () => {
    setOpen(false);
  };

  const rowContent = useCallback<DataGridRowContent<Customer>>(
    (index, row, context) => {
      if (context.viewport.isWide) return;

      return <CustomerItemRow row={row} />;
    },
    [],
  );

  const columns = useMemo<Column<Customer>[]>(
    () => [
      {
        id: 'fullName',
        width: '100%',
        accessor: row => row.fullName,
        Header: () => {
          return <FormattedMessage id="fullname" />;
        },
        Cell: ({ row, value }: CellProps<Customer>) => {
          return (
            <HighlightMatchText
              text={value}
            />
          );
        },
      },
      {
        id: 'priceList',
        accessor: row => row.listPriceId,
        className: 'justify-end',
        Header: () => {
          return (
            <span className="whitespace-nowrap text-right">
              <FormattedMessage id="price-list" />
            </span>
          );
        },
        Cell: ({ row, value, cell }: CellProps<Customer>) => {
          const currentPriceList = useLocationState<PriceListItem>();

          const priceLists = useFindPriceListQuery({ active: true });

          const priceList = useMemo(
            () => priceLists.data?.items.find(priceList => priceList.id === value),
            [priceLists],
          );

          return (
            <>
              {priceList && (
                <span className="flex justify-end flex-wrap h-10">
                  <span
                    className={`whitespace-nowrap text-black ${
                      row.isSelected && 'line-through'
                    }`}>
                    {priceList?.description || ZERO_WIDTH_SPACE}
                  </span>
                  <Animation.Fade in={row.isSelected} unmountOnExit>
                    <span>
                      <span className="px-1">→</span>
                      <span className="whitespace-nowrap text-black font-semibold">
                        {currentPriceList.description}
                      </span>
                    </span>
                  </Animation.Fade>
                </span>
              )}
            </>
          );
        },
      },
    ],
    [],
  );

  const topToolbar = useCallback(() => {
    return (
      <div className="flex-1 mx-2">
        <InputGroup className="flex-1" size="md" inside>
          <Input
            autoFocus
            placeholder={$t({ id: 'search' })}
            value={valueSearch as string}
            onChange={value => search(value.trimStart().normalize('NFC'))}
            inputMode="search"
            type="search"
            autoComplete="off"
          />
          <InputGroup.Addon
            className="cursor-pointer"
            onClick={() => {
              search('');
            }}>
            <BiX className="text-base" />
          </InputGroup.Addon>
        </InputGroup>
      </div>
    );
  }, [valueSearch]);

  return (
    <>
      <FloatingActionContainer>
        <IconButton
          className="shadow-md shadow-gray-500"
          appearance="primary"
          circle
          icon={<BiPlus className="text-2xl" />}
          onClick={onOpen}
        />
      </FloatingActionContainer>
      <DrawerModal
        DrawerProps={{ size: 'full' }}
        open={open}
        onClose={onClose}
        placement={viewport.isNarrow ? 'bottom' : 'floating'}
        title={<FormattedMessage id="customers" />}
        body={
          <>
            {result.data && (
              <DataGrid
                style={{ minHeight: 400 }}
                className="h-full"
                data={result.data?.items}
                columns={columns}
                disableFilters
                disableGlobalFilter
                rowContent={rowContent}
                selectable
                topToolbar={topToolbar}
                onSelectedRowsChange={setSelectedRows}
                increaseViewportBy={100}
              />
            )}
          </>
        }
        actions={
          <div>
            <Button
              appearance="primary"
              size="sm"
              disabled={selectedRows.length === 0}
              onClick={() => addCustomers(selectedRows)}
              loading={resultCustomerMuation.isLoading}>
              <FormattedMessage id="add" />
            </Button>
          </div>
        }
      />
    </>
  );
};

type CustomerItemRowProps = {
  row: Row<Customer>;
};

const CustomerItemRow: React.FC<CustomerItemRowProps> = ({
  row,
}: CustomerItemRowProps) => {
  const { fullName, listPriceId, companyId } = row?.original;

  const currentPriceList = useLocationState<PriceListItem>();

  const priceLists = useFindPriceListQuery({ active: true });

  const priceList = useMemo(
    () => priceLists.data?.items.find(priceList => priceList.id === listPriceId),
    [priceLists],
  );

  const cells = cellsById(row.cells);

  return (
    <ListItem
      className={`grid grid-cols-2 items-center pr-4 py-2 ${
        row.isSelected ? 'bg-blue-50' : ''
      }`}>
      <label className="flex items-center text-base font-semibold">
        <Checkbox
          checked={row.isSelected}
          onChange={(_, checked) => row.toggleRowSelected(checked)}
        />
        <HighlightMatchText
          text={fullName}
        />
      </label>
      {cells.priceList.render('Cell')}
    </ListItem>
  );
};
