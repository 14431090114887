import { Schema } from "rsuite";

export const SignupModel = Schema.Model({
  fullName: Schema.Types.StringType()
    .isRequired("Requerido")
    .maxLength(150, "Longitud excedida"),
  company: Schema.Types.StringType()
    .isRequired("Requerido")
    .maxLength(150, "Longitud excedida"),
  primaryEmail: Schema.Types.StringType()
    .isRequired("Requerido")
    .isEmail("Ingresa un email válido"),
  accounting: Schema.Types.StringType().isRequired("Requerido"),
  ecommerce: Schema.Types.StringType().isRequired("Requerido"),
});
