import { ENTREGADA } from '../../utils/Constants';
import { formatDateAndHourExcel } from '../DataFormat';
import { OrderCalendarEventColor } from '../Styling.js';
import { cleanupString } from './Tools';

export function getMappedCalendarOrders(rawOrders, deliverySlots) {
  let mappedCalendarOrders = [];

  try {
    mappedCalendarOrders = rawOrders.map(order => {
      const calendarOrder = {};
      calendarOrder.id = order.Id;
      calendarOrder.title =
        order.Id + ' - ' + order.CustomerName + ' (' + order.TotalUnits + ')';

      // get slot info
      const orderSlot = deliverySlots.find(
        slot => slot.value === order.DeliverySlotId,
      );

      if (orderSlot !== undefined) {
        calendarOrder.start = orderSlot.startDateTime;
        calendarOrder.end = orderSlot.endDateTime;
        calendarOrder.backgroundColor = OrderCalendarEventColor(
          order.TimeRemaining,
          order.StatusId,
        );
      }
      return calendarOrder;
    });
  } catch (error) {
    console.log(error);
  }

  return mappedCalendarOrders;
}

export const mapInvoiceMultiOrder = (
  checkedOrders,
  orders,
  notes,
  customer,
) => {
  let allPurchaseOrders, allPurchaseOrdersId, allOrderId, orderDto;
  let selectedOrders = [];

  checkedOrders.forEach(checked => {
    const foundOrder = orders.find(order => order.id === checked);
    if (foundOrder) {
      selectedOrders.push(foundOrder);
    }
  });

  let firstOrder = selectedOrders[0];

  allPurchaseOrders = selectedOrders.map(item => item.purchaseOrderIdERP);
  allPurchaseOrdersId = selectedOrders.map(item => item.purchaseOrderId);
  allOrderId = selectedOrders.map(item => item.id);
  const customerObj = { idERP: customer.idERP };

  orderDto = {
    invoicedOrders: allOrderId.join(','),
    notes: notes,
    comments: firstOrder.comments,
    customerId: firstOrder.customerId,
    paymentMethodId: firstOrder.paymentMethodId,
    paymentMethodIdERP: firstOrder.paymentMethodIdERP,
    invoicedPurchaseOrders: allPurchaseOrders.join(','),
    invoicedPurchaseOrdersId: allPurchaseOrdersId.join(','),
    purchaseOrderIdERP: firstOrder.purchaseOrderIdERP,
    currentStatusId: ENTREGADA,
    newStatusId: ENTREGADA,
    invoicingSettingsId: firstOrder.invoicingSettingsId,
    paymentIdERP: firstOrder.paymentMethodIdERP,
    OrderId: allOrderId[0],
    customerMatch: customerObj,
  };
  return orderDto;
};

export const getOrderCreationDto = orderDto => {
  return {
    source: 'savia',
    data: {
      ...orderDto,
    },
  };
};

export const mapOrderItems = items => {
  return items.map(item => {
    return {
      productName: item.product.name,
      ...item,
    };
  });
};

export const getOutputExcelOrders = rawOrders => {
  return rawOrders.map(order => {
    const formattedOrder = {};
    formattedOrder.saviaId = order.orderNumber;
    formattedOrder.fecha_Creacion = formatDateAndHourExcel(order.createdAt);
    formattedOrder.cliente = order.customer.fullName;
    formattedOrder.telefono = order.shippingAddress.mobile;
    formattedOrder.direccion = order.shippingAddress.address;
    formattedOrder.totalDespuesImpuestos = order.totalAfterTax;
    formattedOrder.totalAntesImpuestos = order.totalBeforeTax;
    formattedOrder.totalUnidades = order.totalUnits;
    formattedOrder.metodoPago = order.paymentMethod.description;
    formattedOrder.promesaEntrega = formatDateAndHourExcel(
      order.promisedDeliveryDateTime,
    );
    formattedOrder.estado = order.status.description;
    formattedOrder.comentarios = order.comments ? order.comments : '';
    formattedOrder.notas = order.notes ? order.notes : '';
    formattedOrder.facturaId = order.invoiceId ? order.invoiceId : '';
    formattedOrder.facturaIdERP = order.invoiceIdERP ? order.invoiceIdERP : '';
    formattedOrder.remisionId = order.purchaseOrderId
      ? order.purchaseOrderId
      : '';
    formattedOrder.remisionIdERP = order.purchaseOrderIdERP
      ? order.purchaseOrderIdERP
      : '';
    formattedOrder.canal = order.channel.description;
    formattedOrder.listaPrecios = '';
    formattedOrder.etiquetas = order.tags
      ? order.tags?.map(tag => tag.text).join(',')
      : '';

    formattedOrder.cityId = order.shippingAddress.city?.id
    formattedOrder.cityName = order.shippingAddress.city?.name
    return formattedOrder;
  });
};

export const mapSearchFilters = payload => {
  let fields, values;
  let searchFilter = {};

  let copyFilters = { ...payload };

  if (copyFilters.statusId)
    copyFilters.statusId = copyFilters.statusId.join('-');

  if (copyFilters.tags) copyFilters.tags = copyFilters.tags.join('-');

  let columns = Object.keys(copyFilters);
  let filters = Object.values(copyFilters);

  fields = cleanupString(columns.join(','));
  values = cleanupString(filters.join(','));

  searchFilter.columns = fields;
  searchFilter.filters = values;
  return searchFilter;
};

export const mapStatusIdFilter = filters => {
  return filters.join(',');
};
