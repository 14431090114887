import React from 'react'
import { Form, InputPicker, PickerHandle } from "rsuite";

const InputPickerComponent = React.forwardRef<PickerHandle, any>((props, ref) => (
  <InputPicker {...props} ref={ref} />
));

export function InputPickerField({ name, label, data, accepter, tooltip, tooltipText, ...props }) {
  return (
    <Form.Group>
      <Form.ControlLabel>{label}</Form.ControlLabel>
      <Form.Control
        name={name}
        data={data}
        accepter={InputPickerComponent}
        {...props}
      />
      {tooltip && <Form.HelpText tooltip>{tooltipText}</Form.HelpText>}
    </Form.Group>
  );

}