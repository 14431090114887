// -- after OS update

export const STATUS_CREADA = 1;

export const TIME_ZONE_DIFF = 5;

// message notifications to users - SUCESS
export const ORDER_CREATED_SUCCESS1 = 'ORDER_CREATED_SUCCESS1';
export const ORDER_CREATED_SUCCESS2 = 'ORDER_CREATED_SUCCESS2';
export const ORDER_EDITED_SUCCESS = 'ORDER_EDITED_SUCCESS';
export const ORDER_STATUS_UPDATED_SUCCESS = 'ORDER_STATUS_UPDATED_SUCCESS';
export const ORDER_EDIT_FAILURE = 'ORDER_EDIT_FAILURE';
export const ORDER_INVOICED = 'ORDER_INVOICED';
export const PURCHASE_ORDER_CREATED = 'PURCHASE_ORDER_CREATED';
export const NO_ACTION_PERFORMED = 'NO_ACTION_PERFORMED';
export const NOTIFICATION_DURATION = 5000;
export const SIGNUP_NOTIFICATION_DURATION = 8000;
export const NOTIFICATION_SUCESS_QUICK_DURATION = 3000;
export const ERROR_NOTIFICATION_DURATION = 12000;
export const INVOICING_ERROR_NOTIFICATION_DURATION = 20000;
export const MAX_LENGTH_BACKGROUND_PROCESSING = 10;
export const MAX_BATCH_PROCESSING = 200;
export const MAX_BATCH_PROCESSING_EXPORT_PDF = 40;

// message notifications to users - FAILURE
export const ORDER_CREATED_FAILURE = 'ORDER_CREATED_FAILURE';
export const MASTER_DATA_LOADING_FAILURE = 'MASTER_DATA_LOADING_FAILURE';

// help messages
export const CLICK_ORDER_DETAILS = 'Haz clic aquí para ver detalles';

export const ORDOX_COMPANY = 'dev';
export const ORDOX_VERSION = '2.0.19';
export const ACTIVE_EVENT_LOGGING = false;

export const TOKEN_ROLLBAR = '5847434e75cd478ca47ed52e2202c328';
export const ENVIRONMENT_ROLLBAR = 'pdn'
export const ENABLED_ROLLBAR = true

// export const API_BACKEND_URL = 'http://104.43.162.168:3102/v2/';
// export const API_BACKEND_URL = 'http://localhost:3030/v2/';

// ********************* QA ENVIRONMENT!!! ************************
// export const API_BACKEND_URL =
//   'https://vm-saviaservices-uat.centralus.cloudapp.azure.com/v2/';

// ********************* PRODUCTION ENVIRONMENT!!! ************************
export const API_BACKEND_URL = 'https://vm-savia-services.centralus.cloudapp.azure.com/v2/';
// export const API_BACKEND_URL = 'http://20.12.223.25/v2/';

//status
export const CREADA = 1;
export const EN_PROCESO = 2;
export const EN_DESPACHO = 3;
export const ENTREGADA = 4;
export const CANCELADA = 5;
export const ON_TIME = 'ON_TIME';
export const DELAYED = 'DELAYED';
export const NULL_DATE = '0001-01-01T00:00:00';
export const COMPANY_NO_COPY = '635ed411fe207cfae3fea96c';

// order status alerts
export const ORDER_DELAYED = 'Delayed';
export const ORDER_CLOSE_TO_DELAY = 'Close to delay';
export const ORDER_ON_TIME = 'Order on time';
export const MINUTES_TO_ORDER_ALERT = 120;

// related to GUI
export const GRID_HEADER_HEIGHT = 40;
export const GRID_ROWS_HEIGHT = 33;
export const GRID_FILTER_HEADER_HEIGHT = 52;

// mobile GUI
export const GRID_HEADER_HEIGHT_MOBILE = 35;
export const GRID_ROWS_HEIGHT_MOBILE_SELECTOR = 1;
export const GRID_ROWS_HEIGHT_MOBILE = 125;
export const GRID_FILTER_HEADER_HEIGHT_MOBILE = 50;

// slot size
export const SLOT_SIZE = 30; // in mins
export const SLOT_CAPACITY = 2; // measured in number of orders

// real-time updates
export const UPDATE_MAXIMUM_ELAPSED_TIME = 600000;

// Dashboards
export const TOP_CUSTOMERS_SHOWN = 10;
export const TOTAL_ROW_LABELS = [
  'Total',
  'Facturadas',
  'Por facturar',
  'Canceladas',
];
export const LIVE_STATUS_FIRST_ROW = [
  'Total',
  'Entregadas',
  'Pendientes',
  'Canceladas',
];

// alegra links
export const GET_ALEGRA_INVOICE = invoiceIdERP =>
  'https://app.alegra.com/invoice/view/id/' + invoiceIdERP;
export const GET_ALEGRA_CREDIT_NOTE = creditNoteIdERP =>
  'https://app.alegra.com/credit-note/view/id/' + creditNoteIdERP;
export const GET_ALEGRA_PURCHASE_ORDER = purchaseOrderIdERP =>
  'https://app.alegra.com/remission/view/id/' + purchaseOrderIdERP;

// Shopify links
export const GET_SHOPIFY_LINK = (site, idShopify) =>
  `https://${site}/admin/orders/${idShopify}`;

// excel data export
export const getCSVOrderOptions = user => {
  return {
    fieldSeparator: user.listDelimiter,
    filename: 'ordox_ordenes_' + Date.now(),
    quoteStrings: '"',
    decimalSeparator: user.decimalSeparator,
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useKeysAsHeaders: true,
  };
};

export const getCSVListProductionOptions = user => {
  return {
    fieldSeparator: user.listDelimiter,
    filename: 'ordox_lista_produccion_' + Date.now(),
    quoteStrings: '"',
    decimalSeparator: user.decimalSeparator,
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useKeysAsHeaders: true,
  };
};

export const PROD_VIEW_OPTIONS = [
  {
    value: 1,
    label: 'Proyección',
  },
  {
    value: 2,
    label: 'Histórico',
  },
];

export const dataEntities = [
  { label: 'Listas de precio', value: 'priceList' },
  { label: 'Clientes', value: 'customers' },
  { label: 'Franjas horarias', value: 'deliverySlots' },
  { label: 'Ordenes', value: 'orders' },
  { label: 'Productos', value: 'products' },
  { label: 'Inventario', value: 'inventory' },
];
export const PROD_VIEW_SELECTED_VALUE = [
  {
    value: 1,
    label: 'Proyección',
  },
];

export const ACTIVE = [
  {
      label: "Activo",
      value: true
  },
  {
      label: "Inactivo",
      value: false
  }
]
export const TYPES_CHANNELS = [
  {
      label: "SHOPIFY",
      value: "shopify"
  },
  {
      label: "MERCADO_LIBRE",
      value: "mercado_libre"
  }
]

export const TOPTEN_DIMENSION_LIST = [
  {
    value: 'cop',
    label: 'Facturación',
  },
  {
    value: 'uds',
    label: 'Unidades',
  },
];

export const UNITS_LIST = [
  {
    value: 'unit',
    label: 'Unidad',
  },
  {
    value: 'gram',
    label: 'Gramo',
  },
  {
    value: 'kilogram',
    label: 'Kilogramo',
  },
  {
    value: 'liter',
    label: 'Litro',
  },
  {
    value: 'centimeter',
    label: 'Centímetro',
  },
  {
    value: 'meter',
    label: 'Metro',
  },
  {
    value: 'centimeterSquared',
    label: 'Centímetro cuadrado',
  },
  {
    value: 'meterSquared',
    label: 'Metro cuadrado',
  },
  {
    value: 'mililiter',
    label: 'Mililitro',
  },
  {
    value: 'gallon',
    label: 'Galón',
  },
  {
    value: 'piece',
    label: 'Pieza',
  },
  {
    value: 'service',
    label: 'Servicio',
  },
];

// constants used in customer form
export const PAYMENT_METHODS = [
  { value: 1, label: 'Efectivo' },
  { value: 2, label: 'Transferencia' },
];

export const PAYMENT_TERMS = [
  { value: 0, label: 'Contado' },
  { value: 8, label: '8 días' },
  { value: 15, label: '15 días' },
  { value: 30, label: '30 días' },
  { value: 45, label: '45 días' },
  { value: 60, label: '60 días' },
];

export const FISCAL_RESPONSIBILITY = [
  { value: '5', label: 'Gran contribuyente' },
  { value: '114', label: 'Régimen simple de tributación' },
  { value: '7', label: 'Autorretenedor' },
  { value: '12', label: 'Agente de retención en el impuesto sobre las ventas' },
];

export const TAX_REGIME = [
  { value: 'SIMPLIFIED_REGIME', label: 'Régimen simplificado (no resp. IVA)' },
  { value: 'COMMON_REGIME', label: 'Régimen común (resp. IVA)' },
  {
    value: 'NOT_REPONSIBLE_FOR_CONSUMPTION',
    label: 'Régimen simplificado del INC',
  },
  { value: 'SPECIAL_REGIME', label: 'Régimen especial' },
  { value: 'NATIONAL_CONSUMPTION_TAX', label: 'Impuesto nacional al consumo' },
];

export const STATUS_LIST_FILTER = [
  { value: 1, label: 'Creada' },
  { value: 2, label: 'En proceso' },
  { value: 3, label: 'En despacho' },
  { value: 4, label: 'Entregada' },
  { value: 5, label: 'Cancelada' },
];

export const CUSTOMER_IDTYPE = [
  { value: 'NIT', label: 'NIT' },
  { value: 'CC', label: 'Cédula de ciudadanía' },
  { value: 'CE', label: 'Cédula de extranjería' },
  { value: 'PP', label: 'Pasaporte' },
  { value: 'CE', label: 'Cédula de extranjería' },
  { value: 'DIE', label: 'Documento de identificación extranjero' },
];

export const ACTIONS_ONSHIPMENT = [
  { value: 'INVOICE', label: 'Facturar' },
  { value: 'MANUAL_INVOICING', label: 'Facturar manual' },
  { value: 'PURCHASE_ORDER', label: 'Remisionar' },
  { value: 'NO_ACTION', label: 'Ninguna' },
];

export const INVOICING_STATUS_LIST_FILTER = [
  { value: 1, label: 'Todas' },
  { value: 2, label: 'Facturadas' },
  { value: 3, label: 'Sin facturar' },
];

export const DEFAULT_SELECTED_STATE = 1;

// file uploader context
export const DELIVERY_SLOTS_CONTEXT = 'DELIVERY_SLOTS';
export const DELIVERY_SLOTS_DATETIME_STANDARD_FORMAT = 'yyyy/MM/dd HH:mm';
export const DELIVERY_SLOTS_DATETIME_SECONDARY_FORMAT = 'dd/MM/yyyy, HH:mm';

// number of retries for api calls
export const INVOICE_ORDER_API = {
  retries: 5,
  actionId: 1,
};

export const CREATE_DELIVERY_SLOTS_API = {
  retries: 3,
  actionId: 2,
};

// inicialization data
export const ORDER_FILTER_RESET_DATA = [
  { name: 'id', value: '' },
  { name: 'customerName', value: '' },
  { name: 'statusId', value: [] },
  { name: 'date', value: '' },
  { name: 'invoicingStatus', value: '' },
  { name: 'tags', value: [] },
];

// root path to public images
export const IMG_ROOT_PATH = '../img/';

// return appropiate image
export const CUSTOMER_IMG = customer => {
  let imgProperties = { img: '', width: 0, height: 0 };

  switch (customer) {
    case 'pavaarte':
      imgProperties.src = 'img/pavaarte.jpeg';
      imgProperties.width = 90;
      imgProperties.height = 90;
      return imgProperties;

    case 'lamaga':
      imgProperties.src = 'img/lamaga.jpeg';
      imgProperties.width = 90;
      imgProperties.height = 90;
      return imgProperties;

    case '62efdd63e75e6af790c99568':
      imgProperties.src = 'img/melika.png';
      imgProperties.width = 80;
      imgProperties.height = 32;
      return imgProperties;

    case '635ed411fe207cfae3fea96c':
      imgProperties.src = 'img/silesia.jpeg';
      imgProperties.width = 61;
      imgProperties.height = 43;
      return imgProperties;

    case 'ordox-qa':
      imgProperties.src = 'img/ordox.jpeg';
      imgProperties.width = 90;
      imgProperties.height = 90;
      return imgProperties;

    case '63102572e2ebbb9977379f44':
      imgProperties.src = 'img/toledales.jpeg';
      imgProperties.width = 90;
      imgProperties.height = 90;
      return imgProperties;

    case '6325d3adbbcd1e4697f913e5':
      imgProperties.src = 'img/consuelitos.jpeg';
      imgProperties.width = 90;
      imgProperties.height = 90;
      return imgProperties;

    default:
      imgProperties.src = 'img/savia.jpg';
      imgProperties.width = 90;
      imgProperties.height = 90;
      return imgProperties;
  }
};

// allowed seconds to validate invoicesId update
export const ALLOWED_MILLISECONDS = 650000;
export const MILLISECONDS_TO_HOURS = 3600000;

// features to open additional windows in browser
export const WINDOW_FEATURES =
  'location=yes,height=790,width=650,scrollbars=yes,status=yes';

// handle differences in validations and formatting depending on erp
export const ERP = {
  alegra: 'alegra',
  siigo: 'siigo',
};

export const SAVIA_CORE = 'SAVIA_CORE';

export const PRICE_LIST_EXCLUDED_ID = '63ddd7c10ff95f68cc9ee43c';
