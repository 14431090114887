import { HighlightText } from '@components/DataGrid';
import FormattedCurrency from '@components/ui/FormattedCurrency';
import { dayjsCalendar } from '@utils/dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export function ModifiedData({ event }) {
  if (!event?.data) {
    return null;
  }

  const renderProperties = (data) => {
    const dataKeys = Object.keys(data);

    return (
      <div className=" overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <tbody>
            {dataKeys.map((key) => {
              let value = data[key];
              value = value === false ? 'false' : value
              value = value === true ? 'true' : value
              if (typeof value === 'object' && !Array.isArray(value)) {
                return (
                  <tr key={key} className="border-b border-gray-200">
                    <th
                      scope="row"
                      className="px-3 py-1 font-medium text-gray-900 whitespace-nowrap w-1 max-w-min bg-gray-50"
                    >
                      <FormattedMessage id={key} />
                    </th>
                    <td className="px-3 py-1 w-1 max-w-min">
                      {renderProperties(value)}
                    </td>
                  </tr>
                );
              } else if(!Array.isArray(value) && key !== 'companyId') {
                return (
                  <tr key={key} className="border-b border-gray-200">
                    <th
                      scope="row"
                      className="px-3 py-1 font-medium text-gray-900 whitespace-nowrap w-1 max-w-min bg-gray-50"
                    >
                      <FormattedMessage id={key} />
                    </th>
                    {
                      key === 'totalBeforeTax' ||
                      key === 'totalAfterTax' ||
                      key === 'totalCredited' ||
                      key === 'discount' ? (
                        <td className="px-3 py-1">
                          <FormattedCurrency value={value} />
                        </td> 
                    ) : null}
                    {
                      key === 'updatedAt' ||
                      key === 'createdAt' ||
                      key === 'promisedDeliveryDateTime' ? (
                         <td className="px-3 py-1 w-1 max-w-min">
                          <HighlightText
                            text={dayjsCalendar(value)}
                          />
                        </td> 
                    ) : null}
                    {
                      key !== 'totalBeforeTax' &&
                      key !== 'totalAfterTax' &&
                      key !== 'totalCredited' &&
                      key !== 'discount' &&
                      key !== 'updatedAt' &&
                      key !== 'createdAt' &&
                      key !== 'promisedDeliveryDateTime' ? (
                        <td className="px-3 py-1 w-1 max-w-min">{value}</td>
                    ) : null}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return renderProperties(event.data);
}