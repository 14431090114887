import api from '@api/OrderEventApi';
import {
  INVOICING_ERROR_NOTIFICATION_DURATION,
  NOTIFICATION_DURATION,
} from '@utils/Constants';
import {
  ALREADY_INVOICED,
  getDocumentSucessNotification,
} from '@utils/Notifications';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Message, toaster } from 'rsuite';
import * as actions from '../actions';
import types from '../actions/ActionTypes';
import { rollbarErrors } from '@utils/rollbarErrors';

function* triggerInvoicing({ payload: { orderId, ...data } }) {
  try {
    yield put(actions.setShowSpinner(true));
    const invoice = yield call(api.trigerInvoicing, orderId, data);
    toaster.push(
      <Message type="info" showIcon closable duration={NOTIFICATION_DURATION}>
        {getDocumentSucessNotification({
          documentType: 'INVOICE',
          id: invoice.data.invoiceId,
        })}
      </Message>,
    );
  } catch (error) {
    rollbarErrors(error, { orderId, data }, 'PATCH /orders/$id/invoices', null, null)
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else if (
      error?.response?.status === 500 ||
      error?.response?.status === 503
    ) {
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={INVOICING_ERROR_NOTIFICATION_DURATION}>
          {error.response.data.message}
        </Message>,
      );
    } else if (error?.response?.status === 409) {
      toaster.push(
        <Message
          type="warning"
          showIcon
          closable
          duration={NOTIFICATION_DURATION}>
          {ALREADY_INVOICED}
        </Message>,
      );
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

export function* triggerOrderEvent({ payload: { orderId, ...data } }) {
  try {
    yield put(actions.setShowSpinner(true));
    const orderEvent = yield call(api.triggerOrderEvent, orderId, data);
    const documentId =
      data.action === 'invoice'
        ? orderEvent.data.invoiceId
        : orderEvent.data.purchaseOrderId;

    toaster.push(
      <Message type="info" showIcon closable duration={NOTIFICATION_DURATION}>
        {getDocumentSucessNotification({
          documentType: data.action,
          id: documentId,
        })}
      </Message>,
    );
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else if (
      error?.response?.status === 500 ||
      error?.response?.status === 503
    ) {
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={INVOICING_ERROR_NOTIFICATION_DURATION}>
          {error.response.data.message}
        </Message>,
      );
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

export default function* () {
  yield all([
    takeLatest(types.TRIGGER_INVOICING, triggerInvoicing),
    takeLatest(types.TRIGGER_ORDER_EVENT, triggerOrderEvent),
  ]);
}
