import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Message, toaster } from 'rsuite';
import * as actions from '../actions';
import types from '../actions/ActionTypes';
import api from '../api/SecurityApi';
import apiPayment from '../api/PaymentMethodApi';
import { SAVIA_CORE } from '../utils/Constants';
import { mapUser } from '../utils/Mappers.js';
import { getTags, getTaxes, searchStates, getFulFillmentTypes } from './MasterDataSaga';
import { getStatus } from './StatusSaga';
import * as constants from '../utils/Constants';
import { rollbarErrors } from '@utils/rollbarErrors';

function* getUserAuthorization(action) {
  let authorizedUser = false;
  let email, name;
  let user = null;

  let body = {}
  if (action.callback === 'google') {
    body = {
      email: action.payload?.user?.email,
      appType: SAVIA_CORE
    };
  } else {
    body = {
      email: action.payload?.email,
      appType: SAVIA_CORE,
      authType: 'OTP',
      otp: action.payload?.code
    };
  }

  yield put(actions.setShowSpinner(true));
  if (action.callback === 'google' || action.callback === 'login otp') {
    try {
      if (action.callback === 'google') {

        const { data } = yield call(api.login, body);

        authorizedUser = true;
        email = action.payload?.user?.email;
        name = action.payload?.user?.displayName;

        user = mapUser(data.user, email, name, authorizedUser);

        yield put(actions.setToken({ token: data.token }));
        yield call(getStatus, { payload: {} });
        yield call(getTags, { payload: {} });
        yield call(getTaxes, { payload: {} });
        yield call(getFulFillmentTypes, { payload: {} });
        yield call(searchStates, { payload: { orderBy: 'name:ASC' } });
        yield put(actions.updateUserAuthorization(user));
        const paymentMethodsList = yield call(apiPayment.getPaymentMethods, { active: true });
    yield put (actions.updateStatePaymentMethods(paymentMethodsList.data.items));
      } else {
        const { data } = yield call(api.login, body);

        authorizedUser = true;
        email = action.payload?.user?.email;
        name = action.payload?.user?.displayName;

        user = mapUser(data.user, email, name, authorizedUser);

        yield put(actions.setToken({ token: data.token }));
        yield call(getStatus, { payload: {} });
        yield call(getTags, { payload: {} });
        yield call(getTaxes, { payload: {} });
        yield call(getFulFillmentTypes, { payload: {} });
        yield call(searchStates, { payload: { orderBy: 'name:ASC' } });
        yield put(actions.updateUserAuthorization(user));
        const paymentMethodsList = yield call(apiPayment.getPaymentMethods, { active: true });
    yield put (actions.updateStatePaymentMethods(paymentMethodsList.data.items));
      }
    } catch (error) {
      console.log(error)
      rollbarErrors(error, body, 'POST/login', null, action.payload?.email)
      toaster.push(
          <Message
            type="error"
            showIcon
            closable
            duration={constants.NOTIFICATION_DURATION}
          >
            El código ingresado es inválido o expiró
          </Message>,
        );
      yield put(actions.updateUserAuthorization({ authorized: false }));
    } finally {
      yield put(actions.setShowSpinner(false));
    }
  }
}

function* getUserDevice(action) {
  var isMobile = false;

  if (window.innerWidth <= 800) isMobile = true;

  yield put(actions.updateUserDevice(isMobile));
  yield put(actions.setShowSpinner(false));
}

export default function* () {
  yield all([
    takeLatest(types.GET_USER_AUTHORIZATION, getUserAuthorization),
    takeLatest(types.GET_USER_DEVICE, getUserDevice),
  ]);
}
