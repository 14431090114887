import { createSelector } from "reselect";
import * as constants from "../utils/Constants";
import { composeDateAndTime } from "../utils/DataFormat";
import { getActiveUser } from "./SystemSelector";

export const getOrderTrackingData = (state) => state.ordox.orderTracking;
export const getOrderTrackingVisible = (state) =>
  state.ordox.orderTrackingVisible;
export const getShowExportedPDF = (state) => state.ordox.showExportedPDF;
export const getOrderExportVisible = (state) => state.ordox.orderExportVisible;
export const getUpdateOrderStatusVisible = (state) =>
  state.ordox.updateOrderStatusVisible;
export const getNewOrderStatus = (state) => state.ordox.updateOrderStatusStatus;
export const getAllOrders = (state) => state.ordox.orders.list;
export const getTrackingComments = (state) => state.ordox.trackingComments;
export const getInvoiceData = (state) => {
  return state.ordox.invoicedOrderData;
};

const getIsMobile = (state) => state.ordox.isMobile;
const getDeliverySlots = (state) => state.ordox.fullDeliverySlots;

export const getOrderStatusList = (state) =>
  state.ordox.status.map((status) => {
    return {
      value: status.id,
      label: status.description,
      listingOrder: status.listingOrder,
    };
  });

export const getOrderItems = createSelector(
  [getOrderTrackingData],
  (orderTracking) => {
    return orderTracking.orderItems.map((item) => {
      const itemTax = item.tax;
      const itemDiscount =
        item.priceBeforeTax * item.quantity * (item.discount / 100);
      const itemValue = (
        (item.quantity * item.priceBeforeTax - itemDiscount) *
        (1 + itemTax / 100)
      ).toFixed(0);
      return {
        productName: item.product.name,
        quantity: item.quantity,
        priceAfterTax: itemValue,
        unitsCount: item.product.unitsCount,
      };
    });
  }
);

export const getOrderStatusFiltered = createSelector(
  [getOrderTrackingData, getOrderStatusList],
  (selectedOrderTracking, orderStatusList) => {
    try {
      let currentStatus =
        selectedOrderTracking.trackingData[
          selectedOrderTracking.trackingData.length - 1
        ];
      const currentStatusItem = orderStatusList.find(
        (status) => status.value == currentStatus.statusId
      );
      let orderStatusFiltered = orderStatusList.filter(
        (x) => x.listingOrder > currentStatusItem.listingOrder
      );

      if (
        currentStatus.statusId == constants.EN_DESPACHO ||
        currentStatus.statusId == constants.ENTREGADA
      ) {
        orderStatusFiltered = orderStatusFiltered.filter(
          (x) => x.value !== constants.CANCELADA
        );
      }
      return orderStatusFiltered;
    } catch (error) {
      console.error(error);
    }
  }
);

export const getOrderStatusWillUpdate = (state) =>
  state.ordox.orderStatusWillUpdate;

export const getNextStatus = createSelector(
  [getOrderStatusList, getOrderStatusWillUpdate],
  (orderStatusList, order) => {
    return orderStatusList.find(
      (status) => status.listingOrder == order.status.listingOrder + 1
    );
  }
);

export const createOrderDto = createSelector(
  [
    getOrderTrackingData,
    getNewOrderStatus,
    getAllOrders,
    getActiveUser,
    getIsMobile,
    getDeliverySlots,
    getTrackingComments,
  ],
  (
    orderTracking,
    newOrderStatus,
    orders,
    activeUser,
    isMobile,
    deliverySlots,
    trackingNotes
  ) => {
    if (!orderTracking.orderItems) {
      return null;
    }
    // object orderTracking is composed of 3 sections: customer / orderItems[] / trackingData[]
    const selectedOrder =
      orderTracking.trackingData[orderTracking.trackingData.length - 1];
    const currentStatus = selectedOrder.statusId;

    let customer = orderTracking.customer;
    let orderItems = mapItems(orderTracking.orderItems);
    let productMatch = buildProductMatch(orderTracking.orderItems);
    let trackingData = orderTracking.trackingData;

    // retrieve order general info
    let orderHeader = orders.find((x) => x.Id === trackingData[0].orderId);

    if (!orderHeader) return null;

    let orderDto = {};

    // retrieve selected slot information
    const orderDeliverySlot = deliverySlots.find(
      (x) => x.value === orderHeader.DeliverySlotId
    );

    if (orderHeader) {
      orderDto = {
        OrderId: trackingData[0].orderId,
        CustomerId: customer.id,
        PaymentMethodId: orderHeader.PaymentMethodId,
        PaymentIdERP: orderHeader.PaymentIdERP,
        TotalAfterTax: orderHeader.TotalAfterTax,
        TotalBeforeTax: orderHeader.TotalBeforeTax,
        TotalUnits: orderHeader.TotalUnits,
        Comments: orderHeader.Comments,
        Notes: orderHeader.Notes,
        CreatedAt: orderHeader.Date,
        PromisedDeliveryDate: composeDateAndTime(
          orderHeader.PromisedDeliveryDate,
          orderDeliverySlot.endDateTime
        ),
        CustomerPaymentTerms: customer.paymentTerms,
        NewStatusId: newOrderStatus ? newOrderStatus : 4,
        CurrentStatusId: currentStatus,
        InvoiceId: selectedOrder.order.invoiceId,
        InvoiceIdERP: selectedOrder.order.invoiceIdERP,
        InvoicedAt: selectedOrder.order.invoicedAt,
        PurchaseOrderId: selectedOrder.order.purchaseOrderId,
        PurchaseOrderIdERP: selectedOrder.order.purchaseOrderIdERP,
        DeliverySlotId: orderHeader.DeliverySlotId,
        CustomerMatch: { id: customer.id, idERP: customer.idERP },
        ProductMatch: productMatch,
        Items: orderItems,
        UserId: activeUser.id,
        IsMobile: isMobile,
        TrackingNotes: trackingNotes,
        ChannelId: orderHeader.ChannelId,
        InvoicingSettingsId: orderHeader.InvoicingSettingsId,
        LocationId: orderHeader.LocationId,
        CustomerName: customer.name,
        InvoiceOnShipment: orderHeader.InvoiceOnShipment,
        InvoicedPurchaseOrders: selectedOrder.order.purchaseOrderIdERP,
        InvoicedOrders: trackingData[0].orderId,
        InvoicedPurchaseOrdersId: selectedOrder.order.purchaseOrderId,
        Tags: orderHeader.Tags,
      };
    }
    return orderDto;
  }
);

function buildProductMatch(rawItems) {
  return rawItems.map((item) => {
    return {
      id: item.productId,
      idERP: item.product.idERP,
    };
  });
}

function mapItems(rawItems) {
  return rawItems.map((item) => {
    return {
      orderId: item.orderId,
      productId: item.productId,
      quantity: item.quantity,
      priceBeforeTax: item.priceBeforeTax,
      priceAfterTax: item.priceAfterTax,
      discount: item.discount,
      taxIdERP: item.taxIdERP,
      tax: item.tax,
    };
  });
}
