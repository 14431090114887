import { Customer } from "@api/query/customerApi/types";
import React, { useState } from "react"
import { FormattedMessage } from "react-intl";
import { Drawer, Modal } from "rsuite";

import { TabsComponent } from '../../../components/Tabs'
import { TabContent } from '../../../components/Tabs/TabContent'
import { GeneralInformation } from "./GeneralInformation";
import { BillingInformation } from "./BillingInformation";
import { Configuration } from "./Configuration";
import { DispatchInformation } from "./DispatchInformation";
import { CustomFields } from "./CustomFields";


interface Props {
	customer: Customer;
	oncloseModal: () => void;
	isOpenDetailModal: boolean;
}
const getTabs = (customer): any[] => {
    const tabs = [
      {
        id: 0,
        label: <FormattedMessage id="general-information" />
      },
      {
        id: 1,
        label: <FormattedMessage id="billing-information" />
      },
      {
        id: 2,
        label: <FormattedMessage id="configuration" />
      },
      {
        id: 3,
        label: <FormattedMessage id="dispatch-information" />
      }
    ];

    if (customer?.customFields?.length > 0) {
      tabs.push({
        id: 4,
        label: <FormattedMessage id="custom" />
      });
    }

    return tabs;
  };

export function ModalCustomerDetail({ isOpenDetailModal, oncloseModal, customer }: Props) {
	return (
		<div>
			<Modal
				size="md"
				// placement="top"
				open={isOpenDetailModal}
				onClose={() => oncloseModal()}
				autoFocus
				keyboard
			>
				<Modal.Header>
					<Drawer.Title className="font-medium  text-xl">
						{customer.fullName}
					</Drawer.Title>
				</Modal.Header>
				<Modal.Body  >
					<TabsComponent tabs={getTabs(customer)}>
						<TabContent id={0}>
							<GeneralInformation customer={customer}/>
						</TabContent>
						<TabContent id={1}>
							<BillingInformation customer={customer}/>
						</TabContent>
						<TabContent id={2}>
							<Configuration customer={customer}/>
						</TabContent>
						<TabContent id={3}>
							<DispatchInformation customer={customer} />
						</TabContent>
						{customer?.customFields?.length > 0 && (
							<TabContent id={4}>
								<CustomFields customer={customer} />
							</TabContent>
						)}
					</TabsComponent>
					<div className="bg-red container">
					</div>
				</Modal.Body>
			</Modal>
		</div>
	)
};