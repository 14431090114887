import { GetEventRequest, Event } from "@api/query/eventApi/types";
import { useLazyGetEventsQuery } from "@api/query/eventApi/eventApi";
import { DataGrid, DataGridRowContent, GlobalFilterCallback, HighlightText, cellsById } from "@components/DataGrid";
import PageHeader from "@components/PageHeader";
import { useLazyPagination } from "@containers/PriceList/UseLazyPagination";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CellProps, Column } from "react-table";
import { CheckPicker, DateRangePicker, IconButton, Tooltip, Whisper } from "rsuite";
import dayjs, { dayjsCalendar, dayjsRanges, formats } from "@utils/dayjs";
import { APP_TYPES } from "./constants/event"
import { useDispatch } from "react-redux";
import { setOpenAuthModal } from "@actions/index";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import InfoIcon from '@rsuite/icons/InfoRound';
import { ModalEventDetail } from "./components/ModalEventDetail";

export default function ListEventContainer() {
	const dispatch = useDispatch();
	const [triggerGetEvents, result] = useLazyGetEventsQuery()
	const [openDetailModal, setOpenDetailModal] = useState(false);
	const [eventDetail, setEventDetail] = useState<Event | undefined>(undefined)


	const trigger = useCallback(
		(value: GetEventRequest) => {
			return triggerGetEvents({
				...value,
			})
				.then(res => {
					if ((res?.error as FetchBaseQueryError)?.status === 401) {
						dispatch(setOpenAuthModal({ open: true }))
					}
				})
				.catch(err => {
					dispatch(setOpenAuthModal({ open: true }))
				})
		},
		[triggerGetEvents],
	);

	const defaultEndtDate = new Date();
	const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 15);

	const { virtualArray, setViewRange, setExtraQuery } =
		useLazyPagination({
			trigger,
			result,
			initialLimit: 100,
			initialExtraQuery: {
				orderBy: 'tracking.loggedTime:DESC',
				fromTrackingLoggedTime: defaultStartDate.toString(),
				toTrackingLoggedTime: defaultEndtDate.toString(),
			}
		});

	const columns = useMemo<Column<Event>[]>(
		(): Column<Event>[] => [
			{
				id: 'event',
				Header: <FormattedMessage id="event" />,
				accessor: row => row.event,
				width: 200,
				maxWidth: 200,
				minWidth: 200,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
					}));
				},
				Filter({ column }) {
					const { id, filterValue, setFilter } = column;

					return (
						<CheckPicker
							id={id}
							name={id}
							placeholder="-"
							size="xs"
							data={APP_TYPES}
							block
							searchable={false}
							cleanable={false}
							value={filterValue || []}
							onChange={setFilter}
						/>
					);
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						event: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'shortDescription',
				Header: <FormattedMessage id="shortDescription" />,
				accessor: row => row.shortDescription,
				width: 80,
				maxWidth: 100,
				minWidth: 100,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
					}));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						shortDescription: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'longDescription',
				Header: <FormattedMessage id="longDescription" />,
				accessor: row => row.longDescription,
				width: 200,
				maxWidth: 250,
				minWidth: 250,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
					}));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						longDescription: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},


			},
			{
				id: 'transactionId',
				Header: <FormattedMessage id="transactionId" />,
				accessor: row => row.transactionId,
				width: 200,
				maxWidth: 200,
				minWidth: 200,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
					}));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						transactionId: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'userName',
				Header: <FormattedMessage id="userName" />,
				accessor: row => row.tracking.userName,
				width: 80,
				maxWidth: 100,
				minWidth: 100,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
					}));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						trackingUserName: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'loggedTime',
				Header: <FormattedMessage id="loggedTime" />,
				accessor: row => row.tracking.loggedTime,
				minWidth: 100,
				width: 150,
				maxWidth: 150,
				Cell: ({ value, inline, state }: CellProps<Event, Date>) => {
					if (value.toString() === "Invalid Date") {
						return null
					}
					const date = dayjs(value);
					if (inline) {
						return (
							<HighlightText
								text={dayjsCalendar(date)}
								subtext={state.globalFilter}
							/>
						);
					}

					return (
						<>
							<Whisper
								trigger={['click', 'hover', 'active']}
								placement="bottomStart"
								speaker={<Tooltip>{date.format(formats.datetime)}</Tooltip>}>
								<div className="text-sm font-light first-letter:capitalize">
									<HighlightText
										text={dayjsCalendar(date)}
										subtext={state.globalFilter}></HighlightText>
								</div>
							</Whisper>
						</>
					);
				},
				Filter({ column }) {
				const { id, filterValue, setFilter } = column;
				return (
					<DateRangePicker
						id={id}
						name={id}
						size="xs"
						block
						placement="auto"
						value={filterValue}
						onChange={dates => {
							setFilter(dates);
						}}
						format="dd/MM/yyyy HH:mm:ss"
						ranges={dayjsRanges([
							'today',
							'tomorrow',
							'yesterday',
							'last7Days',
							'next7Days',
						])}
						disabledDate={date =>
							dayjs(date).isBefore(dayjs().subtract(15, 'days'), 'day')
						}
					/>
				);
			},
				filter: (rows, _, filterValue) => {
					setExtraQuery(value => ({
						...value,
						fromTrackingLoggedTime: filterValue?.[0],
						toTrackingLoggedTime: filterValue?.[1],
					}));

					return rows;
				},
			},
			{
				id: 'actions',
				Header: <FormattedMessage id="action" />,
				width: 100,
				maxWidth: 100,
				minWidth: 100,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
					}));
				},
				Cell: ({ value, row }) => {
					const id = row?.original?.id
					return (
						<div>
							<IconButton
								title="Ver detalles"
								size="sm"
								icon={<InfoIcon />}
								onClick={() => {
									setOpenDetailModal(true)
									setEventDetail(row?.original as Event)
								}}
							/>
						</div>
					)

				}
				,
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;
					setExtraQuery(value => ({
						...value,
						actions: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
		], []
	)


	const rowContent = useCallback<DataGridRowContent<Event>>(
		(index, row, { viewport }) => {
			if (viewport.isWide) return;

			const cells = cellsById(row.cells);

			return (
				<div
					className={`flex flex-row justify-between items-stretch py-2 px-4 border-y -mb-px border-gray-200`}>
					<div className="flex flex-col flex-nowrap flex-1">
						<div className="mb-1 flex items-center text-xs text-gray-500">
							<span className="flex flex-row">
								{cells.shortDescription.render('Cell')}
							</span>
							<span className="middle-dot w-1 h-1 mx-2 bg-current rounded" />
						</div>

						<span className="mb-0.5 text-lg font-normal text-gray-700">
							{cells.event.render('Cell')}
						</span>

						<div className="mb-0.5 text-gray-500">

							<span className="flex flex-row">
								{cells.longDescription.render('Cell')}
							</span>
							<span className="flex flex-row">
								{cells.userName.render('Cell')}
							</span>
							<span className="flex flex-row">
								{cells.transactionId.render('Cell')}
							</span>
						</div>
					</div>

					<div className="flex flex-col flex-nowrap items-end">
						<div className="mb-1">
							<span className="text-sm">
								{cells.loggedTime.render('Cell')}
							</span>
						</div>
					</div>
				</div>
			);
		},
		[],
	);


	const globalFilter = useCallback<GlobalFilterCallback<Event>>(
		(rows, columnIds, filterValue: string) => {
			setExtraQuery(value => ({
				...value,
				keyword: filterValue.trim() ? filterValue : undefined,
			}));
			return rows;
		},
		[],
	);

	return (<div className="h-screen flex flex-col pb-1">
		<PageHeader title={<FormattedMessage id="events" />} />
		{openDetailModal
			? <ModalEventDetail
				isOpenDetailModal={openDetailModal}
				oncloseModal={() => setOpenDetailModal(false)}
				event={eventDetail} />
			: null}
		<DataGrid
			className="flex-1"
			data={virtualArray.ref}
			totalCount={virtualArray.ref.length}
			rangeChanged={setViewRange}
			columns={columns}
			selectable
			rowContent={rowContent}
			globalFilter={globalFilter}
			overscan={0}
			increaseViewportBy={500}
			autoResetFilters={false}
			autoResetSelectedRows={false}
			autoResetSortBy={false}
			loading={result.isFetching}
		/>
	</div>)
}
