import {
  PriceListItem,
  useAddProductInPriceListMutation,
} from '@api/query/priceListApi';
import {
  cellsById,
  DataGrid,
  DataGridRowContent,
} from '@components/DataGrid';
import DrawerModal from '@components/ui/DrawerModal';
import { FloatingActionContainer } from '@components/ui/FloatingActionButton';
import FormattedCurrency from '@components/ui/FormattedCurrency';
import HighlightMatchText from '@components/ui/HighlightMatchText';
import useLocationState from '@router/useLocationState';
import { rollbarErrors } from '@utils/rollbarErrors';
import { getMainLoggingParams } from '@selectors/SystemSelector';
import useViewport from '@utils/useViewport';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiPlus, BiX } from 'react-icons/bi';
import { FormattedMessage, useIntl } from 'react-intl';
import { CellProps, Column, Row } from 'react-table';
import {
  Button,
  Checkbox,
  IconButton,
  Input,
  InputGroup,
} from 'rsuite';
import ListItem from 'rsuite/esm/List/ListItem';
import { useSelector } from 'react-redux';
import { FindProductRequest, Product } from '@api/query/productApi/types';
import { useLazyFindProductQuery } from '@api/query/productApi/productApi';
import { useLazyPagination } from './UseLazyPagination';

export const FABAddPriceListProduct = (productsPrice) => {
  const { $t } = useIntl();
  const viewport = useViewport();
  const { id: priceListId, companyId, description } = useLocationState<PriceListItem>();
  const { user } = useSelector(getMainLoggingParams);

  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
    triggerProducts({ active: true });
  };

  const onClose = () => {
    setOpen(false);
  };

  const [selectedRows, setSelectedRows] = useState<
    Row<Product>[]
    >([]);
  
  const [valueSearch, setValueSearch] = useState('');

  const [triggerProducts, result] = useLazyFindProductQuery();

  const filteredItems = useMemo(() => {
    if (!result.data?.items || !Array.isArray(productsPrice?.productsPrice)) {
      return [];
    }

    return result.data.items.filter((item) => {
      return !productsPrice?.productsPrice.some(
        (productPrice) => productPrice?.product?.id === item?.id
      );
    });
  }, [result.data?.items, productsPrice?.productsPrice]);

  const trigger = useCallback(
    (value: FindProductRequest) => {
      return triggerProducts({
        ...value
      });
    },
    [triggerProducts],
  );
  const { setExtraQuery } =
    useLazyPagination({
      trigger,
      result,
      initialLimit: 100,
    });

  const [mutate, responseMutate] = useAddProductInPriceListMutation();

  useEffect(() => {
    if (responseMutate?.isError) {
      rollbarErrors(responseMutate?.error, null, 'POST/products/prices', companyId, user)
    }
  }, [responseMutate?.isError]);

  const addProductsIntoPriceList = useCallback(async () => {
    const it = await Promise.allSettled(
      selectedRows.map(row => {
        const { id: productId, priceBeforeTax, isShippingItem, name, sku } = row?.original;

        if (isShippingItem) {
          return mutate({
            product: {
              id: productId,
              name: name,
              priceBeforeTax: priceBeforeTax,
              sku: sku,
            },
            listPrice: {
              id: priceListId,
              name: description,
            },
            price: priceBeforeTax,
            isShippingItem: false,
            minQuantity: 1,
          }).unwrap();
        } else {
          return mutate({
            product: {
              id: productId,
              name: name,
              priceBeforeTax: priceBeforeTax,
              sku: sku,
            },
            listPrice: {
              id: priceListId,
              name: description
            },
            price: priceBeforeTax,
            isShippingItem: false,
            minQuantity: 1,
          }).unwrap();
        }
      }),
    );

    setOpen(false);

    const fails = it.filter(response => response.status === 'rejected');

  }, [companyId, mutate, selectedRows]);

  useEffect(() => {
    triggerProducts({ active: true });
  }, []);

  const searchKeys = useMemo(() => ['name'], []);

  const search = (searchValue) => {
    setValueSearch(searchValue)
    setExtraQuery(value => ({
      ...value,
      active: true,
      keyword: searchValue.trim() ? searchValue : undefined,
    }));
  };

  const rowContent = useCallback<DataGridRowContent<Product>>(
    (index, row, context) => {
      if (context.viewport.isWide) return;

      return <ProductItemRow row={row} compact={true} />;
    },
    [],
  );

  const columns = useMemo<Column<Product>[]>(
    () => [
      {
        id: 'product',
        width: '100%',
        disableFilters: true,
        accessor: row => row?.name,
        Cell: ({ value, row }: CellProps<Product>) => {
          return (
            <span>
              <HighlightMatchText
                text={value}
                // matches={getMatchIndices('name', row.original.matches)}
              />
            </span>
          );
        },
        Header: () => {
          return <FormattedMessage id="product" />;
        },
      },
      {
        id: 'price',
        minWidth: 150,
        width: '300px',
        accessor: row => row?.priceBeforeTax,
        disableFilters: true,
        Header: ({ row }) => {
          return (
            <span className="whitespace-nowrap">
              <FormattedMessage id="base-price" />
            </span>
          );
        },
        Cell: ({ value }: CellProps<Product>) => {
          return (
            <span className="col-span-2 text-right text-black">
              <FormattedCurrency value={value} />
            </span>
          );
        },
      },
    ],
    [],
  );

  const topToolbar = useCallback(() => {
    return (
      <div className="flex-1 mx-2">
        <InputGroup className="flex-1" size="md" inside>
          <Input
            autoFocus
            placeholder={$t({ id: 'search' })}
            value={valueSearch as string}
            onChange={value => search(value.trimStart().normalize('NFC'))}
            inputMode="search"
            type="search"
            autoComplete="off"
          />
          <InputGroup.Addon
            className="cursor-pointer"
            onClick={() => {
              search('');
            }}>
            <BiX className="text-base" />
          </InputGroup.Addon>
        </InputGroup>
      </div>
    );
  }, [valueSearch]);

  return (
    <>
      <FloatingActionContainer>
        <IconButton
          className="shadow-md shadow-gray-500"
          appearance="primary"
          circle
          icon={<BiPlus className="text-2xl" />}
          onClick={onOpen}
        />
      </FloatingActionContainer>
      <DrawerModal
        DrawerProps={{ size: 'full' }}
        open={open}
        onClose={onClose}
        placement={viewport.isNarrow ? 'bottom' : 'floating'}
        title={<FormattedMessage id="products" />}
        body={
          <>
            {filteredItems && (
              <DataGrid
                style={{ minHeight: 400 }}
                className="h-full"
                data={filteredItems}
                columns={columns}
                disableGlobalFilter
                disableFilters
                rowContent={rowContent}
                selectable
                topToolbar={topToolbar}
                onSelectedRowsChange={setSelectedRows}
                autoResetSelectedRows={false}
                increaseViewportBy={100}
                // getRowId={getRowId}
              />
            )}
          </>
        }
        actions={
          <div>
            <Button
              appearance="primary"
              size={viewport.isNarrow ? 'sm' : 'md'}
              onClick={addProductsIntoPriceList}
              disabled={selectedRows.length === 0}>
              <FormattedMessage id="add" />
            </Button>
          </div>
        }
      />
    </>
  );
};

type ProductItemRowProps = {
  row: Row<Product>;
  compact: boolean;
};

const ProductItemRow: React.FC<ProductItemRowProps> = ({
  row,
  compact,
}: ProductItemRowProps) => {
  const cells = cellsById(row.cells);

  return (
    <ListItem
      className={`flex-1 grid grid-cols-2 items-center pr-4 py-2 ${
        row.isSelected ? 'bg-blue-50' : ''
      }`}>
      <label className="flex items-center font-semibold">
        {compact && (
          <Checkbox
            checked={row.isSelected}
            onChange={(_, checked) => row.toggleRowSelected(checked)}
          />
        )}
        {cells.product.render('Cell')}
      </label>
      <span className="text-right">{cells.price.render('Header')}</span>
      <span className="col-span-2 text-right text-black">
        {cells.price.render('Cell')}
      </span>
    </ListItem>
  );
};
