import React from "react";
import "../../styles/Dashboard.css";

const SLA = ({ currentMonthOnTime }) => (
  <div className="flex-col flex items-center">
    <div>
      <p className="sla-percentage pt-2">{currentMonthOnTime}</p>{" "}
    </div>
    <div>
      <label className="kpi-description">nivel de servicio</label>
    </div>
  </div>
);

export default SLA;
