import channelApi from './channelApi';
import customerApi from './customerApi/customerApi';
import deliverySlotApi from './deliverySlot/deliverySlot';
import eventApi from './eventApi/eventApi';
import inventoryApi from './InventoryApi';
import invoicingSettingApi from './invoicingSetting';
import orderApi from './orderApi/orderApi';
import paymentMethodApi from './paymentMethod';
import priceListApi from './priceListApi';
import inventory from './inventoryApi/inventory';
import productApi from './productApi/productApi';
import taxApi from './taxApi';
import userApi from './userApi/userApi'
import customFieldsApi from './customFieldsApi/customFields'

const apis = [
  orderApi,
  inventoryApi,
  productApi,
  customerApi,
  paymentMethodApi,
  invoicingSettingApi,
  channelApi,
  deliverySlotApi,
  taxApi,
  priceListApi,
  userApi,
  eventApi,
  inventory,
  customFieldsApi
];

export default apis;
