import { PaginationQuery } from '@api/query/types';
import { assert } from 'console';
import { off } from 'rsuite/esm/DOMHelper';

const ArrayProp = {
  splice: 'splice',
  length: 'length',
};

const createVirtualArray = <T extends unknown>(reRender: () => void) => {
  const origin: T[] = [];

  const newRef = (handler: ProxyHandler<T[]>) => new Proxy(origin, handler);

  const handler: ProxyHandler<T[]> = {
    get(target, key, receiver) {
      if (key === ArrayProp.splice) {
        setTimeout(() => {
          ref = newRef(handler);
          reRender();
        }, 0);
      }

      return Reflect.get(target, key, receiver);
    },
    set(target, key, value, receiver) {
      const ok = Reflect.set(target, key, value, receiver);

      if (key === ArrayProp.length) {
        ref = newRef(handler);
        reRender();
      }

      return ok;
    },
  };

  let ref = newRef(handler);

  const constRef = newRef({ set: handler.set });

  return {
    getPageFree({ offset, limit }: PaginationQuery) {
      const page = origin.slice(offset, offset + limit);

      const localOffset = page.findIndex((_, index, arr) => {
        return !Object.hasOwn(arr, index);
      });

      if (localOffset === -1) return { offset, limit };

      const fixedLimit = page.slice(localOffset).findIndex((_, index, arr) => {
        return Object.hasOwn(arr, index);
      });

      if (fixedLimit === -1)
        return {
          offset: offset + localOffset,
          limit: page.slice(localOffset).length,
        };

      return { offset: offset + localOffset, limit: fixedLimit };
    },
    hasPage(offset: number, limit: number) {
      const pageSlice = origin.slice(offset, offset + limit);

      limit = Math.min(pageSlice.length, limit);

      return Object.keys(pageSlice).length === limit;
    },
    putPage(offset: number, data: T[]) {
      ref.splice(offset, data.length, ...data);
    },
    clearArray() {
      origin.length = 0;
    },
    get ref() {
      return ref;
    },
    constRef,
  } as const;
};

export default createVirtualArray;
