import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

type FloatingActionButtonContextValue = {
  node?: ReactNode;
  setNode: Dispatch<SetStateAction<ReactNode>>;
};

const FloatingActionContainerContext =
  createContext<FloatingActionButtonContextValue>({
    setNode() {},
  });

export const useFloatingActionContainer = () =>
  useContext(FloatingActionContainerContext);

export const FloatingActionContainerProvider: React.FCC = ({ children }) => {
  const [node, setNode] = useState<ReactNode>();

  return (
    <FloatingActionContainerContext.Provider value={{ node, setNode }}>
      {children}
    </FloatingActionContainerContext.Provider>
  );
};

export const RenderFloatingActionContainer: React.FCC = () => {
  const { node } = useFloatingActionContainer();

  return <div className="absolute right-3 bottom-3">{node}</div>;
};

export const FloatingActionContainer: React.FCC = ({ children }) => {
  const { setNode } = useFloatingActionContainer();

  useEffect(() => {
    setNode(children);
    return () => setNode(undefined);
  }, [children, setNode]);

  return null;
};
