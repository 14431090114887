import FileCenterContainer from '@containers/Config/FileCenterContainer';
import CreateOrderContainer from '@containers/CreateOrderContainer';
import AddEditCustomerContainer from '@containers/Customers/AddEditCustomerContainer';
import HomeContainer from '@containers/HomeContainer';
import LoginContainer from '@containers/LoginContainer';
import LogoutContainer from '@containers/LogoutContainer';
import CreateOrder from '@containers/Orders/CreateOrder';
import BulkUpload from '@containers/BulkUpload/BulkUpload';
import CustomFields from '@containers/CustomFields/CustomFieldsContainer';
import ListOrders from '@containers/Orders/ListOrders';
import PriceListEditorView from '@containers/PriceList/PriceListEditorView';
import PriceListSettings from '@containers/PriceList/PriceListSettings';
import DashboardIcon from '@rsuite/icons/Dashboard';
import ExitIcon from '@rsuite/icons/Exit';
import SearchIcon from '@rsuite/icons/Search'
import PageIcon from '@rsuite/icons/Page';
import ProjectIcon from '@rsuite/icons/Project';
import TaskIcon from '@rsuite/icons/Task';
import RootRoute from './RootRoute';
import { createRouteTree } from './types';
import ListUsersContainer from '@containers/Users/ListUsersContainer';
import ListEventContainer from '@containers/Event/ListEventContainer';
import ListKardexContainer from '@containers/Kardex/ListKardexContainer';
import ListChannelsContainer from '@containers/Channels/ListChannelsContainer';
import ListInventoryContainer from '@containers/Inventory/ListInventoryContainer';
import ListCustomersContainer from '@containers/Customers/ListCustomersContainer';
import ListProductsContainer from '@containers/Products/ListProductsContainer';
import ListDeliverySlotsContainer from '@containers/DeliverySlots/ListDeliverySlotsContainer';

export default createRouteTree(({ $t }) => ({
  '/': {
    Component: RootRoute,
    exact: true,
  },
  '/home': {
    key: '1',
    Icon: DashboardIcon,
    label: $t('route.home'),
    Component: HomeContainer,
    placement: 'sidenav',
    private: true,
  },
  orders: {
    key: '2',
    label: $t('route.orders'),
    Icon: PageIcon,
    private: true,
    routes: {
      '/create-order': {
        key: '2-1',
        label: $t('route.create-order'),
        Component: CreateOrder,
        placement: 'sidenav',
        private: true,
      },
      '/list-order': {
        key: '2-2',
        label: $t('route.list-order'),
        Component: ListOrders,
        placement: 'sidenav',
        private: true,
      }
      // '/list-order-history': {
      //   key: '2-3',
      //   label: $t('route.list-order-history'),
      //   placement: 'sidenav',
      //   Component: ListOrdersHistory,
      //   private: true,
      // },
    },
  },
  inventory: {
    key: '3',
    label: $t('route.inventory'),
    Icon: PageIcon,
    private: true,
    routes: {
      '/consult-inventory': {
        key: '3-1',
        label: $t('route.consult'),
        Component: ListInventoryContainer,
        placement: 'sidenav',
        private: true,
      },
      '/kardex': {
        key: '3-2',
        label: $t('route.kardex'),
        Component: ListKardexContainer,
        placement: 'sidenav',
        private: true,
      },
      '/channels': {
        key: '3-3',
        label: $t('route.channels'),
        Component: ListChannelsContainer,
        placement: 'sidenav',
        private: true,
      },
    },
  },
  masters: {
    key: '4',
    label: $t('route.masters'),
    Icon: ProjectIcon,
    private: true,
    routes: {
      '/list-users': {
        key: '4-1',
        label: $t('route.list-users'),
        Component: ListUsersContainer,
        placement: 'sidenav',
        private: true,
      },
      '/list-customers': {
        key: '4-2',
        label: $t('route.list-customers'),
        Component: ListCustomersContainer,
        placement: 'sidenav',
        private: true,
      },
      '/list-products': {
        key: '4-3',
        label: $t('route.list-products'),
        Component: ListProductsContainer,
        placement: 'sidenav',
        private: true,
      },
      '/price-lists': {
        key: '4-4',
        Component: PriceListSettings,
        placement: 'sidenav',
        label: $t('route.price-list-settings'),
        private: true,
        exact: true,
      },
      '/list-delivery-slots': {
        key: '4-5',
        label: $t('route.list-delivery-slots'),
        Component: ListDeliverySlotsContainer,
        placement: 'sidenav',
        private: true,
      },
      '/price-lists/edit': {
        Component: PriceListEditorView,
        private: true,
        exact: true,
      },
    },
  },
  myData: {
    key: '5',
    label: $t('route.myData'),
    Icon: TaskIcon,
    private: true,
    routes: {
      '/list-events': {
        key: '5-1',
        label: $t('route.event'),
        Component: ListEventContainer,
        placement: 'sidenav',
        private: true,
      },
      '/custom-fields': {
        key: '5-2',
        label: $t('route.custom-fields'),
        Component: CustomFields,
        placement: 'sidenav',
        private: true,
      },
      '/data-load': {
        key: '6-1',
        label: $t('route.data-load'),
        Component: BulkUpload,
        placement: 'sidenav',
        private: true,
      }
    },
  },
  '/edit-order': {
    Component: CreateOrderContainer,
    private: true,
  },
  
  '/file-center': {
    Component: FileCenterContainer,
    private: true,
  },
  '/addEdit-customer': {
    Component: AddEditCustomerContainer,
    private: true,
  },
  '/login': {
    Component: LoginContainer,
  },
  '/logout': {
    key: '7',
    label: $t('route.logout'),
    Icon: ExitIcon,
    Component: LogoutContainer,
    placement: 'sidenav',
    private: true,
  },
}));
