export const APP_TYPES = [
    {
        label: 'Orden creada',
        value: 'ORDER_CREATED',
    },
    {
        label: 'Trazabilidad orden creada',
        value: 'ORDER_TRACKING_CREATED',
    },
    {
        label: 'Detalle orden creada',
        value: 'ORDER_ITEM_CREATED',
    },
    {
        label: 'Cliente creado',
        value: 'CUSTOMER_CREATED',
    },
    {
        label: 'Cliente actualizado',
        value: 'CUSTOMER_UPDATED',
    },
    {
        label: 'Franja creada',
        value: 'DELIVERY_SLOT_CREATED',
    },
    {
        label: 'Franja actualizada ',
        value: 'DELIVERY_SLOT_UPDATED',
    }
]