import React, { useDeferredValue, useEffect, useMemo, useRef, useState } from "react";
import { DropDownField, TextField } from "@components/Customers/AddEditCustomer";
import { Button, Form, Modal, ButtonToolbar, useToaster, Message } from "rsuite";
import { useCreateProductMutation, useUpdateProductMutation } from "@api/query/productApi/productApi";
import { NOTIFICATION_DURATION, UNITS_LIST } from "@utils/Constants";
import { useSelector } from "react-redux";
import { getTaxesList } from "@selectors/MasterDataSelector";
import { productsFormInitialValues, productsFormInitialValuesEdit, validateCreateProduct } from "../helpers/product";
import { DEFAULT_PRODUCT_DATA } from "../constants/product";
import { mapCreateProductDto, mapEditProductDto } from "@utils/Mappers/ProductMapper";
import { getProductTaxAccountable } from "@selectors/ProductSelector";
import { CheckboxField } from "@components/Forms/CheckboxField";
import { FormattedMessage } from "react-intl";

export function AddEditProductModal(props) {

  const {
    visible,
    onClose,
    productInfo,
    title,
    isEditing
  } = props;
  const taxes = useSelector(getTaxesList);
  const taxAccountable = useSelector(getProductTaxAccountable);

  const [addProduct] = useCreateProductMutation()
  const [editProduct] = useUpdateProductMutation()
  const [searchProduct, setSearchProduct] = useState('')
  const formRef = useRef();
  const [formValue, setFormValue] = useState(productInfo)
  const searchDeferred = useDeferredValue(searchProduct);
  const model = validateCreateProduct()
  const toaster = useToaster();


  useEffect(() => {
    let initialValues = {}
    if (isEditing && productInfo) {
      initialValues = productsFormInitialValuesEdit(productInfo);
    } else {
      initialValues = productsFormInitialValues(taxes[0]?.value);
    }
    setFormValue(initialValues)
  }, [visible])

  const submit = (type) => {
    if (!formRef.current.check()) {
      return
    }
    if (isEditing) {
      const data = {
        product: formValue,
        tax: taxes.find((tax) => tax.value === formValue.tax),
        taxIdERP: formValue.tax,
        taxAccountable: taxAccountable,
        productId: productInfo?.id
      }
      const newProduct = mapEditProductDto(data);
      editProduct({ id: productInfo?.id, data: newProduct })
        .then(_resp => {

          toaster.push(
            <Message
              type="success"
              showIcon
              closable
              duration={NOTIFICATION_DURATION}
            >
              <FormattedMessage id="edit-product-success" />
            </Message>
          );

        })
    } else {
      const data = {
        product: formValue,
        tax: taxes.find((tax) => tax.value === formValue.tax),
        taxAccountable: taxAccountable,
      }
      const newProduct = mapCreateProductDto(data);
      addProduct(newProduct)
        .then(resp => {
          const menssageError = resp?.error?.data?.message
          if (resp?.error?.status === 422) {
            toaster.push(
              <Message
                type="error"
                showIcon
                closable
                duration={NOTIFICATION_DURATION}
              >
                {menssageError}
              </Message>
            );
          } else {
            toaster.push(
              <Message
                type="success"
                showIcon
                closable
                duration={NOTIFICATION_DURATION}
              >
                <FormattedMessage id="create-product.success" />
              </Message>
            );
          }
        })
        .catch(_error => {
          toaster.push(
            <Message
              type="error"
              showIcon
              closable
              duration={NOTIFICATION_DURATION}
            >
              <FormattedMessage id="create-product.error" />
            </Message>
          );
        })
    }



    setFormValue(DEFAULT_PRODUCT_DATA)
    if (type === 1) {
      onCloseModal()
    }
  }


  const onCloseModal = () => {
    setFormValue(DEFAULT_PRODUCT_DATA)
    onClose()
  }

  return (
    <div>
      <Modal
        className="modal-dialog-container"
        open={visible}
        size="xs"
        keyboard
        onClose={onCloseModal}
      >
        <Modal.Header>
          <Modal.Title style={{ marginBottom: '10px' }}> {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form
              fluid
              ref={formRef}
              onChange={setFormValue}
              formValue={formValue}
              model={model}
            >
              <div className="grid grid-cols-1 gap-x-4 gap-y-2">
                <div>
                  {" "}
                  <TextField name="name" label="Descripción *" />{" "}
                  <TextField
                    style={{ width: '100%' }}
                    name="priceBeforeTax"
                    label="Precio de venta *"
                    tooltip={true}
                    tooltipText="Valor antes de impuestos"
                  />
                  <DropDownField
                    style={{ width: '100%' }}
                    name="tax"
                    label="Impuesto *"
                    data={taxes}
                  />
                  <TextField name="cost" label="Costo *" />{" "}

                  <DropDownField
                    name="unit"
                    label="Unidad *"
                    data={UNITS_LIST}
                  />
                  <TextField name="sku" label="Referencia *" /> {" "}
                  <CheckboxField name="active" label="Activo" value={formValue.active} />

                </div>
              </div>
            </Form>
          </div>
          <div style={{ paddingTop: '20px' }}>
            <ButtonToolbar>
              <Button
                appearance="primary"
                onClick={() => submit(1)}
              >
                Guardar
              </Button>
              {isEditing ? null : <Button onClick={() => submit(2)} appearance="primary">Guardar y crear otro</Button>}

              <Button onClick={onCloseModal}>Cancelar</Button>
            </ButtonToolbar>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );




}