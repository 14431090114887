import React from "react";
import { Button, ButtonToolbar, Modal, TagPicker } from "rsuite";
import "../../styles/General.css";

const AssignOrderTag = ({
  tagsList,
  visible,
  confirmDisabled,
  selectedTags,
  onClickAssignTag,
  onTagChanged,
  onHideAssignTag,
}) => (
  <div>
    <Modal
      className="modal-dialog-container"
      open={visible}
      size="xs"
      keyboard={true}
      onClose={onHideAssignTag}
    >
      <Modal.Header>
        <Modal.Title className="modal-title-center">
          Asignar etiquetas
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <TagPicker
          data={tagsList}
          block
          onChange={onTagChanged}
          value={selectedTags}
          placement={false ? "auto" : "rightStart"}
          cleanable={false}
        />

        <ButtonToolbar className="pt-4">
          <Button
            appearance="primary"
            onClick={onClickAssignTag}
            disabled={confirmDisabled}
          >
            Aceptar
          </Button>
          <Button appearance="ghost" onClick={onHideAssignTag}>
            Cancelar
          </Button>
        </ButtonToolbar>
      </Modal.Body>
    </Modal>
  </div>
);

export default AssignOrderTag;
