import React from "react";
import "../../styles/Dashboard.css";

const TotalOrders = ({ status, totalOrders }) => (
  <div>
    {status === "ON_TIME" ? (
      <div className="flex-col flex items-center">
        <div>
          <p className="ontime-orders pt-2">{totalOrders}</p>
        </div>
        <div>
          <label className="kpi-description">ordenes a tiempo</label>
        </div>
      </div>
    ) : (
      <div className="flex-col flex items-center">
        <div>
          <p className="offtime-orders pt-2">{totalOrders}</p>
        </div>
        <div>
          <label className="kpi-description">ordenes vencidas</label>
        </div>
      </div>
    )}
  </div>
);

export default TotalOrders;
