import { Schema } from "rsuite";
import { B2BPORTAL } from "../constants/user";

export const validateCreateUser = () => {
  const { StringType, NumberType } = Schema.Types;
  let schema = Schema.Model({
    fullName: StringType().isRequired("Requerido").maxLength(100, "La longitud debe ser inferior a 100"),
    primaryEmail: StringType().isEmail("El email es invalido"),
    mobile: NumberType().isRequired("Requerido"),
    appType: StringType().isRequired("Requerido"),
    listDelimiter: StringType().isRequired("Requerido"),
    decimalSeparator: StringType().isRequired("Requerido"),
    customerId: StringType().when(schema => {
      const checkResult = schema.appType.value
      return checkResult === B2BPORTAL
        ? StringType().isRequired('Requerido')
        : StringType();
    })
  });
  return schema;
};