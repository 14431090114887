import React from "react";
import AppRoute from "./AppRoute";
import routes from "./RouteEntries";
import { isRouteEntry } from "./types";

import { Redirect } from "react-router-dom";
import BrowserRouter from "./BrowserRouter";
import NotFoundRoute from "./NotFoundRoute";

const AppRouter: React.FCC = ({ children }) => (
  <BrowserRouter>
    {routes.flatTree.filter(isRouteEntry).map((entry) => (
      <AppRoute
        {...entry}
        key={
          Array.isArray(entry.path)
            ? entry.path.join(";")
            : (entry.path as string)
        }
      />
    ))}
    <NotFoundRoute>
      <Redirect to="/" push={false} />
    </NotFoundRoute>
    {children}
  </BrowserRouter>
);

export default AppRouter;
